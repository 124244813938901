import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const DownloadIcon = (props: SvgIconProps) => (
    <SvgIcon {...props}>
        <path
            className="MuiSvgIcon-root"
            id="Vector"
            d="M17.625 8.25H12.75V15.0469C12.75 15.2458 12.671 15.4366 12.5303 15.5772C12.3897 15.7179 12.1989 15.7969 12 15.7969C11.8011 15.7969 11.6103 15.7179 11.4697 15.5772C11.329 15.4366 11.25 15.2458 11.25 15.0469V8.25H6.375C5.67904 8.25074 5.01179 8.52754 4.51967 9.01967C4.02755 9.51179 3.75074 10.179 3.75 10.875V19.875C3.75074 20.571 4.02755 21.2382 4.51967 21.7303C5.01179 22.2225 5.67904 22.4993 6.375 22.5H17.625C18.321 22.4993 18.9882 22.2225 19.4803 21.7303C19.9725 21.2382 20.2493 20.571 20.25 19.875V10.875C20.2493 10.179 19.9725 9.51179 19.4803 9.01967C18.9882 8.52754 18.321 8.25074 17.625 8.25Z"
        />
        <path
            id="Vector_2"
            d="M12.7501 4.0608L15.2199 6.53018C15.3617 6.66489 15.5505 6.73888 15.7461 6.73638C15.9417 6.73388 16.1285 6.65507 16.2668 6.51677C16.4051 6.37847 16.484 6.19161 16.4865 5.99604C16.489 5.80047 16.415 5.61166 16.2803 5.46986L12.5303 1.71986C12.3896 1.57932 12.1989 1.50037 12.0001 1.50037C11.8013 1.50037 11.6106 1.57932 11.4699 1.71986L7.71994 5.46986C7.58522 5.61166 7.51123 5.80047 7.51373 5.99604C7.51624 6.19161 7.59504 6.37847 7.73334 6.51677C7.87164 6.65507 8.0585 6.73388 8.25407 6.73638C8.44964 6.73888 8.63845 6.66489 8.78025 6.53018L11.2501 4.0608V8.25002H12.7501V4.0608Z"
        />
    </SvgIcon>
);

export default DownloadIcon;
