import React from 'react';
import { observer } from 'mobx-react-lite';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Grid,
    Typography,
    makeStyles,
    IconButton,
    useMediaQuery,
    useTheme,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import CancelIcon from '@material-ui/icons/Cancel';
import clsx from 'clsx';

import { IFavoriteFolder } from '../../types/FavoriteTypes';
import { setObjectEnding } from '../../utils/setObjectEnding';
import { useStores } from '../../hooks/useStores';
import { useModal } from '../../hooks/useModal';
import folder from '../../img/folder.svg';
import folderSelected from '../../img/folder_selected.svg';
import folderHover from '../../img/folder_hover.svg';

import FavoriteApartments from './FavoriteApartments';
import Loader from '../Loader/Loader';
import DownloadIcon from '../UI/DownloadIcon';
import FavoriteCreateFolder from './FavoriteCreateFolder';
import FavoriteFolderModal from './FavoriteFolderModal';
import FavoriteRemoveModal from './FavoriteRemoveModal';

const FavoriteFolders = observer(() => {
    const { FavoriteStore: store } = useStores();
    const { isOpen: isOpenRename, openModal: openRename, closeModal: closeRename } = useModal();
    const { isOpen: isOpenRemove, openModal: openRemove, closeModal: closeRemove } = useModal();
    const classes = useStyles();
    const theme = useTheme();
    const isSM = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <>
            <Accordion classes={{ root: classes.accordionRoot }} expanded={!!store.activeFolderId}>
                <AccordionSummary classes={{ root: classes.accordionSummaryRoot, content: classes.accordionSummaryContent }}>
                    <Grid container spacing={4} justify={isSM ? 'center' : undefined}>
                        {store.favorites?.folders.map((folder: IFavoriteFolder) => (
                            <Grid item key={folder.id} className={classes.gridItem}>
                                {store.activeFolderId === folder.id && (
                                    <Box position="absolute" top={34} right={18} zIndex={2}>
                                        <IconButton className={classes.folderIcons} onClick={store.downloadFolder}>
                                            <DownloadIcon />
                                        </IconButton>
                                        <IconButton className={classes.folderIcons} onClick={openRename}>
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton className={classes.folderIcons} onClick={openRemove}>
                                            <CancelIcon />
                                        </IconButton>
                                    </Box>
                                )}

                                <Box
                                    className={clsx(classes.folder, store.activeFolderId === folder.id && classes.folderSelected)}
                                    p={2}
                                    onClick={store.selectFolder(folder.id, folder.title)}
                                >
                                    <Box mt="auto">
                                        <Typography variant="body1" noWrap>
                                            {folder.title}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            {folder.total === 0 ? 'Нет объектов' : `${folder.total} ${setObjectEnding(folder.total)}`}{' '}
                                            внутри
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                        ))}
                        <Grid item className={classes.gridItem}>
                            <FavoriteCreateFolder />
                        </Grid>
                    </Grid>
                </AccordionSummary>

                <AccordionDetails classes={{ root: classes.accordionDetailsRoot }}>
                    <Box mb={3}>
                        <Typography variant="h3">{store.activeFolderTitle}</Typography>
                    </Box>

                    {store.folderFavorite?.length ? (
                        <FavoriteApartments favorites={store.folderFavorite} />
                    ) : store.loadingFolder ? (
                        <Loader />
                    ) : (
                        <Typography>Папка пуста</Typography>
                    )}
                </AccordionDetails>
            </Accordion>

            <FavoriteFolderModal isOpen={isOpenRename} closeModal={closeRename} onSubmit={store.renameFolder} type="rename" />

            <FavoriteRemoveModal isOpen={isOpenRemove} closeModal={closeRemove} onSubmit={store.removeFolder} />
        </>
    );
});

const useStyles = makeStyles(theme => ({
    folder: {
        display: 'flex',
        flexDirection: 'column',
        background: `url(${folder}) no-repeat`,
        width: 232,
        height: 144,
        cursor: 'pointer',
        '&:hover': {
            background: `url(${folderHover}) no-repeat`,
            filter:
                'drop-shadow(0px 43px 58px rgba(94, 1, 198, 0.04)) drop-shadow(0px 15px 19px rgba(0, 0, 0, 0.03)) drop-shadow(0px 7.36424px 9px rgba(0, 0, 0, 0.01))',
        },
    },
    folderSelected: {
        background: `url(${folderSelected}) no-repeat`,
        filter:
            'drop-shadow(0px 27px 34px rgba(94, 1, 198, 0.07)) drop-shadow(0px 14px 9px rgba(94, 1, 198, 0.0402488)) drop-shadow(0px 8px 4px rgba(0, 0, 0, 0.03))',
        '&:hover': {
            background: `url(${folderSelected}) no-repeat`,
            filter:
                'drop-shadow(0px 27px 34px rgba(94, 1, 198, 0.07)) drop-shadow(0px 14px 9px rgba(94, 1, 198, 0.0402488)) drop-shadow(0px 8px 4px rgba(0, 0, 0, 0.03))',
        },
    },
    gridItem: {
        position: 'relative',
    },
    accordionRoot: {
        boxShadow: 'none',
        background: theme.palette.surface.main,
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    accordionSummaryRoot: {
        padding: 0,
        marginBottom: theme.spacing(4),
        background: `url(${folderHover}) no-repeat -9999px -9999px`,
    },
    accordionSummaryContent: {
        margin: '0 !important',
        cursor: 'initial',
        background: theme.palette.surface.main,
    },
    accordionDetailsRoot: {
        background: `url(${folderSelected}) no-repeat -9999px -9999px`,
        padding: 0,
        flexDirection: 'column',
    },
    folderIcons: {
        //@ts-ignore
        color: theme.palette.primary.A200,
        padding: 10,
    },
}));

export default FavoriteFolders;
