import React from 'react';
import { Box, Grid, Paper, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';

import { useStyles } from '../style';
import { IAppDealPaymentView } from '../../../types/AppDealTypes';
import DocumentItemView from '../AppDealDocs/DocumentItemView';

const AppDealPaymentView = observer((props: IAppDealPaymentView) => {
    const classes = useStyles();
    const {
        file_balance_certificate,
        file_maternity_certificate,
        file_military_certificate,
        file_mortgage_certificate,
        installmentPeriodCode,
        paymentText,
        installmentItems,
    } = props.data;

    const text = paymentText?.split('+');
    const installment = installmentItems?.filter(({ code }) => code === installmentPeriodCode)[0]?.name;

    return (
        <Box mt={4}>
            <Grid container spacing={2}>
                {!!text[0] && (
                    <Grid item xs={12} sm={5}>
                        <Paper className={classes.border} variant="outlined">
                            <Box p={2}>
                                <Typography variant="subtitle2">{text[0]}</Typography>
                                <Typography variant="caption" color="textSecondary">
                                    Способ оплаты
                                </Typography>
                            </Box>
                        </Paper>
                    </Grid>
                )}

                {!!text[1] && (
                    <Grid item xs={12} sm={5}>
                        <Paper className={classes.border} variant="outlined">
                            <Box p={2}>
                                <Typography variant="subtitle2">{text[1]}</Typography>
                                <Typography variant="caption" color="textSecondary">
                                    Льготы
                                </Typography>
                            </Box>
                        </Paper>
                    </Grid>
                )}

                {!!installment && (
                    <Grid item xs={12} sm={5}>
                        <Paper className={classes.border} variant="outlined">
                            <Box p={2}>
                                <Typography variant="subtitle2">{installment}</Typography>
                                <Typography variant="caption" color="textSecondary">
                                    Срок рассрочки
                                </Typography>
                            </Box>
                        </Paper>
                    </Grid>
                )}
            </Grid>

            {(!!file_maternity_certificate || !!file_military_certificate || !!file_mortgage_certificate) && (
                <Box mt={3} mb={2}>
                    <Typography variant="subtitle1">Документы</Typography>
                </Box>
            )}

            {!!file_maternity_certificate && (
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={5}>
                        <DocumentItemView fileUrl={file_maternity_certificate}>Скан сертификата о материнском капитале</DocumentItemView>
                    </Grid>
                    <Grid item xs={12} sm={5}>
                        <DocumentItemView fileUrl={file_balance_certificate}>Справка об остатке на текущий день</DocumentItemView>
                    </Grid>
                </Grid>
            )}

            {!!file_military_certificate && (
                <Grid container>
                    <Grid item xs={12} sm={5}>
                        <DocumentItemView fileUrl={file_military_certificate}>Скан военного сертификата</DocumentItemView>
                    </Grid>
                </Grid>
            )}

            {!!file_mortgage_certificate && (
                <Grid container>
                    <Grid item xs={12} sm={5}>
                        <DocumentItemView fileUrl={file_mortgage_certificate}>Скан сертификата об одобрении ипотеки</DocumentItemView>
                    </Grid>
                </Grid>
            )}
        </Box>
    );
});

export default AppDealPaymentView;
