import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Button, Popover, useTheme, useMediaQuery, Grid, Badge } from '@material-ui/core';

import { useStyles } from './style';
import { useMenu } from '../../hooks/useMenu';

import TextButton from '../UI/TextButton';
import ApartmentsFilterPriceFromTo from './ApartmentsFilter/ApartmentsFilterPriceFromTo';
import ApartmentsFilterAreaFromTo from './ApartmentsFilter/ApartmentsFilterAreaFromTo';
import ApartmentsFilterFacing from './ApartmentsFilter/ApartmentsFilterFacing';
import ApartmentsFilterHideBooked from './ApartmentsFilter/ApartmentsFilterHideBooked';
import ApartmentsFilterFloorFromTo from './ApartmentsFilter/ApartmentsFilterFloorFromTo';
import ApartmentsFilterFloorExcept from './ApartmentsFilter/ApartmentsFilterFloorExcept';
import TuneIcon from '@material-ui/icons/Tune';
import ApartmentsFilterDevEnd from './ApartmentsFilter/ApartmentsFilterDevEnd';
import ApartmentsFilterObjectNumber from './ApartmentsFilter/ApartmentsFilterObjectNumber';

const ApartmentsFilterMenu = observer(
    ({ handleChange, handleChangeCheckboxes, resetOptionalFilters, numberOfActiveFilters, listMenu }: any) => {
        const { anchorEl, handleOpen, handleClose } = useMenu();
        const classes = useStyles();

        const theme = useTheme();
        const isSM = useMediaQuery(theme.breakpoints.down('xs'));

        return (
            <>
                <Badge
                    badgeContent={numberOfActiveFilters}
                    anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
                    classes={{ badge: classes.badge }}
                >
                    <Box className={classes.filterButton} onClick={handleOpen}>
                        <TextButton endIcon={<TuneIcon />} className={classes.buttonText}>
                            Фильтры
                        </TextButton>
                    </Box>
                </Badge>

                <Popover
                    id="filter-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={!!anchorEl}
                    onClose={handleClose}
                    classes={{ paper: classes.menuPaper }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <Box p={3}>
                        <Grid container direction={isSM ? 'column' : 'row'}>
                            <Box width={{ xxs: 250, sm: 312 }} mr={{ xxs: 2, sm: 3, md: 4, lg: 6 }}>
                                <ApartmentsFilterPriceFromTo onChange={handleChange} />

                                <ApartmentsFilterAreaFromTo onChange={handleChange} />

                                <ApartmentsFilterFacing onChange={handleChange} />

                                <ApartmentsFilterHideBooked onChange={handleChangeCheckboxes} />
                            </Box>
                            <Box width={{ xxs: 250, sm: 312 }}>
                                <ApartmentsFilterFloorFromTo onChange={handleChange} />

                                <ApartmentsFilterFloorExcept onChange={handleChangeCheckboxes} />

                                <ApartmentsFilterObjectNumber onChange={handleChange} />

                                {listMenu && <ApartmentsFilterDevEnd />}
                            </Box>
                        </Grid>
                        <Grid container justify="flex-end" alignItems="center" direction={isSM ? 'column' : 'row'}>
                            <Box mb={{ xxs: 2, sm: 0 }} mr={{ sm: 5 }}>
                                <TextButton onClick={resetOptionalFilters}>Сбросить фильтры</TextButton>
                            </Box>
                            <Button type="submit" variant="contained" color="primary" size="large" onClick={handleClose}>
                                Показать объекты
                            </Button>
                        </Grid>
                    </Box>
                </Popover>
            </>
        );
    },
);

export default ApartmentsFilterMenu;
