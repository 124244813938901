import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import clsx from 'clsx';

import { useStyles } from '../style';

import ButtonTab from '../../UI/ButtonTab';
import { useStores } from '../../../hooks/useStores';

const AppDealClientSelect = observer(({ store }: any) => {
    const classes = useStyles();
    const { AppDealFormStore: globalStore } = useStores();
    const { activeClientTab } = store;
    const theme = useTheme();
    const isSM = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Box mt={1} position="relative">
            <Box mb={6}>
                <Typography variant="subtitle1">Выберите вариант</Typography>
            </Box>
            {store.formErrors?.client && (
                <Box position="absolute" top={28}>
                    <Typography variant="caption" color="error">
                        Вариант не выбран
                    </Typography>
                </Box>
            )}
            <Grid container spacing={isSM ? 1 : 2}>
                {globalStore.activePropertyTab !== 'together' && (
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                        <ButtonTab
                            activeTabStyle={classes.activeTabStyle}
                            tabStyle={classes.tabStyle}
                            name="over18Single"
                            setActiveTab={store.setActiveClientTab}
                            activeTab={activeClientTab}
                        >
                            <Box>
                                <Typography variant="subtitle1">Старше 18 лет, не в браке</Typography>
                                <Typography
                                    variant="caption"
                                    color="textSecondary"
                                    className={clsx({
                                        [classes.activeTextColor]: activeClientTab === 'over18Single',
                                    })}
                                >
                                    • Паспорт, ИНН, СНИЛС
                                </Typography>
                            </Box>
                        </ButtonTab>
                    </Grid>
                )}

                {globalStore.activePropertyTab !== 'together' && (
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                        <ButtonTab
                            activeTabStyle={classes.activeTabStyle}
                            tabStyle={classes.tabStyle}
                            name="over14"
                            setActiveTab={store.setActiveClientTab}
                            activeTab={activeClientTab}
                        >
                            <Box>
                                <Typography variant="subtitle1">Старше 14 лет</Typography>
                                <Typography
                                    variant="caption"
                                    color="textSecondary"
                                    className={clsx({
                                        [classes.activeTextColor]: activeClientTab === 'over14',
                                    })}
                                >
                                    • Паспорт, ИНН, СНИЛС
                                    <br />• Согласие законного представителя / одного из родителей
                                </Typography>
                            </Box>
                        </ButtonTab>
                    </Grid>
                )}

                <Grid item xs={12} sm={6} md={6} lg={6}>
                    <ButtonTab
                        activeTabStyle={classes.activeTabStyle}
                        tabStyle={classes.tabStyle}
                        name="over18Married"
                        setActiveTab={store.setActiveClientTab}
                        activeTab={activeClientTab}
                    >
                        <Box>
                            <Typography variant="subtitle1">Старше 18 лет, в браке</Typography>
                            <Typography
                                variant="caption"
                                color="textSecondary"
                                className={clsx({
                                    [classes.activeTextColor]: activeClientTab === 'over18Married',
                                })}
                            >
                                • Паспорт, ИНН, СНИЛС <br />
                                • Нотариальное согласие другого супруга <br />• Брачный договор (не обязательно)
                            </Typography>
                        </Box>
                    </ButtonTab>
                </Grid>

                {globalStore.activePropertyTab !== 'together' && (
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                        <ButtonTab
                            activeTabStyle={classes.activeTabStyle}
                            tabStyle={classes.tabStyle}
                            name="under14"
                            setActiveTab={store.setActiveClientTab}
                            activeTab={activeClientTab}
                        >
                            <Box>
                                <Typography variant="subtitle1">Младше 14 лет</Typography>
                                <Typography
                                    variant="caption"
                                    color="textSecondary"
                                    className={clsx({
                                        [classes.activeTextColor]: activeClientTab === 'under14',
                                    })}
                                >
                                    • Свидетельство о рождении <br />• Согласие законного представителя / одного из родителей
                                </Typography>
                            </Box>
                        </ButtonTab>
                    </Grid>
                )}
            </Grid>
        </Box>
    );
});

export default AppDealClientSelect;
