import axios from 'axios';

class FavoriteAPI {
    getAllFavorites = () => axios.get('/favorites/get-all');

    getFavorite = (folderId: number | null) =>
        folderId ? axios.get(`/favorites/favorites?folderId=${folderId}`) : axios.get(`/favorites/favorites`);

    addToFavorite = (objectId: number) => axios.post('/favorites/add-favorite', { objectId });

    removeFromFavorite = (objectIds: number[]) => axios.post('/favorites/remove-favorites', { objectIds });

    createFolder = (title: string) => axios.post('/favorites/add-folder', { title });

    shareFolder = (folderId: number) => axios.get(`/favorites/share-folder?folderId=${folderId}`);

    renameFolder = (folderId: number, title: string) => axios.post('/favorites/rename-folder', { folderId, title });

    removeFolder = (folderId: number) => axios.post('/favorites/remove-folder', { folderId });

    moveToFolder = (folderId: number | null, objectIds: number[]) => axios.post('/favorites/move-favorites', { folderId, objectIds });

    removeAll = () => axios.post('/favorites/remove-all');
}

const favoriteAPI = new FavoriteAPI();

export default favoriteAPI;
