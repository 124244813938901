// @ts-nocheck
import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import queryString from 'query-string';
import { useHistory } from 'react-router-dom';
import { Box, FormControlLabel, Grid, IconButton, Paper, Radio, RadioGroup, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';

import { useStores } from '../../../hooks/useStores';

import TextButton from '../../UI/TextButton';
import MuiModalRangePicker from '../../UI/MuiModalRangePicker';

const AppDealHistoryFilter = observer(({ onCloseFilter }: { onCloseFilter?: any }) => {
    const { AppsDealsStore: store } = useStores();
    const history = useHistory();
    const theme = useTheme();
    const isMD = useMediaQuery(theme.breakpoints.down('md'));

    const {
        location: { search },
    } = history;
    const parsed = queryString.parse(search);

    const handleResetFilters = () => {
        store.resetFilters();
        history.push(`${history.location.pathname}${history.location.hash}`);
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        store.setFilter('historyStatus', event.target.value);
        store.handleChange();
    };

    useEffect(() => {
        if (parsed.dateFrom && parsed.dateTo) {
            store.setFilter('historyDateRange', [moment(parsed.dateFrom as any, 'YYYY-MM-DD'), moment(parsed.dateTo as any, 'YYYY-MM-DD')]);
        }

        if (parsed.status) {
            store.setFilter('historyStatus', parsed.status);
        }
    }, [store, parsed.dateFrom, parsed.dateTo, parsed.status]);

    return (
        <Paper elevation={0}>
            <Box p={2}>
                <Box mb={4}>
                    <Grid container justify="space-between" alignItems="center">
                        <Typography variant="h4">Фильтр</Typography>
                        {isMD ? (
                            <IconButton size="small" onClick={onCloseFilter}>
                                <CloseOutlinedIcon />
                            </IconButton>
                        ) : (
                            <TextButton onClick={handleResetFilters} disabled={store.filtersIsReset}>
                                Сбросить
                            </TextButton>
                        )}
                    </Grid>
                </Box>

                <Box mb={4}>
                    <Typography variant="subtitle2">Дата</Typography>
                    <MuiModalRangePicker
                        date={store.filters.historyDateRange}
                        onChange={store.handleHistoryDateChange}
                        modalTitle="Выбрать период"
                    />
                </Box>

                <Box mb={2}>
                    <Typography variant="subtitle2">Статус</Typography>
                </Box>

                <RadioGroup aria-label="status" name="historyStatus" value={store.filters.historyStatus} onChange={handleChange}>
                    <FormControlLabel value="" control={<Radio />} label="Все" />
                    <FormControlLabel value="closed" control={<Radio />} label="Закрыто и не реализовано" />
                    <FormControlLabel value="sold" control={<Radio />} label="Успешно реализовано" />
                </RadioGroup>
            </Box>
        </Paper>
    );
});

export default AppDealHistoryFilter;
