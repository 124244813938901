import React from 'react';
import { Grid, Box, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';

import { useMenu } from '../../hooks/useMenu';
import { useStores } from '../../hooks/useStores';

import TextButton from '../UI/TextButton';
import FolderMoveIcon from '../UI/FolderMoveIcon';
import ShareButtonIcon from '../UI/ShareButtonIcon';
import FavoriteMoveMenu from './FavoriteMoveMenu';

const FavoriteActionButtons = () => {
    const { ApartmentStore, FavoriteStore: store } = useStores();
    const { anchorEl, handleOpen, handleClose } = useMenu();
    const classes = useStyles();

    return (
        <Box position="absolute" top={0} left="37%">
            <Grid container spacing={2} className={classes.buttonContainer}>
                <Grid item>
                    <TextButton className={classes.button} startIcon={<CloseIcon color="primary" />} onClick={store.clearSelectedObjects}>
                        Отмена
                    </TextButton>
                </Grid>
                <Grid item>
                    <TextButton
                        className={classes.button}
                        startIcon={<FavoriteBorderIcon color="primary" />}
                        onClick={store.removeSelectedFromFavorite}
                    >
                        Удалить
                    </TextButton>
                </Grid>
                <Grid item>
                    <TextButton className={classes.button} startIcon={<FolderMoveIcon color="primary" />} onClick={handleOpen}>
                        Переместить
                    </TextButton>
                </Grid>
                <Grid item>
                    <TextButton
                        className={classes.button}
                        startIcon={<ShareButtonIcon color="primary" />}
                        onClick={() => ApartmentStore.downloadObject(store.selectedObjectsIds)}
                    >
                        Поделиться
                    </TextButton>
                </Grid>
                <FavoriteMoveMenu anchorEl={anchorEl} handleClose={handleClose} />
            </Grid>
        </Box>
    );
};

const useStyles = makeStyles({
    buttonContainer: {
        position: 'fixed',
        zIndex: 99,
        width: 'auto',
    },
    button: {
        padding: '5px 20px',
        background: '#fff',
        border: '1px solid #EBE0F7',
        boxShadow: '0px 43px 58px rgba(94, 1, 198, 0.04), 0px 15px 19px rgba(0, 0, 0, 0.03), 0px 7.36424px 9px rgba(0, 0, 0, 0.01)',
        borderRadius: 10,
        '&:hover': {
            background: '#fff',
        },
    },
});

export default FavoriteActionButtons;
