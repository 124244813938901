import React from 'react';
import { IMyClientsCard } from '../../types/MyClientsTypes';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Chip, Paper, Typography } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import { Done, HourglassEmpty } from '@material-ui/icons';

const MyClientsItem = (props: IMyClientsCard) => {
    const classes = useStyles();
    const { name, phone, status } = props;

    //Renders
    return (
        <Paper variant="outlined" className={classes.paper}>
            <Box>
                <Box mb={1}>
                    <Typography variant="h4">{name}</Typography>
                </Box>
                <Typography variant={'body2'}>
                    {phone && <NumberFormat value={phone} format="+# ### ###-##-##" mask="_" displayType="text" />}
                </Typography>
            </Box>
            <Box>
                <Chip
                    className={classes.chip}
                    size={'small'}
                    label={
                        <Box display={'flex'} alignItems={'center'}>
                            <Typography variant={'body2'}>
                                {status === 'Запрос на фиксацию...' ? (
                                    <HourglassEmpty fontSize="inherit" className={classes.icon} />
                                ) : (
                                    <Done fontSize="inherit" className={classes.icon} />
                                )}
                                {status}
                            </Typography>
                        </Box>
                    }
                />
            </Box>
        </Paper>
    );
};

const useStyles = makeStyles(theme => ({
    paper: {
        borderRadius: 8,
        padding: theme.spacing(2),
        boxShadow: 'none',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            alignItems: 'flex-start',
        },
    },
    chip: {
        marginTop: theme.spacing(1),
        backgroundColor: theme.palette.material.light,
        color: theme.palette.primary.main,
    },
    icon: {
        top: '2px',
        position: 'relative',
        marginRight: theme.spacing(1),
    },
}));

export default MyClientsItem;
