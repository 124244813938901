import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';

import { useMenu } from '../../hooks/useMenu';
import { useStores } from '../../hooks/useStores';

import TextButton from '../UI/TextButton';
import FolderMoveIcon from '../UI/FolderMoveIcon';
import ShareButtonIcon from '../UI/ShareButtonIcon';
import FavoriteMoveMenu from './FavoriteMoveMenu';

const FavoriteActionButtonsMobile = () => {
    const { ApartmentStore, FavoriteStore: store } = useStores();
    const { anchorEl, handleOpen, handleClose } = useMenu();
    const classes = useStyles();

    return (
        <Box display="flex" justifyContent="space-between" className={classes.buttonContainer}>
            <TextButton
                classes={{ root: classes.button, label: classes.buttonLabel, startIcon: classes.startIcon }}
                startIcon={<CloseIcon color="primary" />}
                onClick={store.clearSelectedObjects}
                size="small"
            >
                <Box mt={0.5}>Отмена</Box>
            </TextButton>

            <TextButton
                classes={{ root: classes.button, label: classes.buttonLabel, startIcon: classes.startIcon }}
                startIcon={<FavoriteBorderIcon color="primary" />}
                onClick={store.removeSelectedFromFavorite}
                size="small"
            >
                <Box mt={0.5}>Удалить</Box>
            </TextButton>

            <TextButton
                classes={{ root: classes.button, label: classes.buttonLabel, startIcon: classes.startIcon }}
                startIcon={<FolderMoveIcon color="primary" />}
                onClick={handleOpen}
                size="small"
            >
                <Box mt={0.5}>Переместить</Box>
            </TextButton>

            <TextButton
                classes={{ root: classes.button, label: classes.buttonLabel, startIcon: classes.startIcon }}
                startIcon={<ShareButtonIcon color="primary" />}
                onClick={() => ApartmentStore.downloadObject(store.selectedObjectsIds)}
                size="small"
            >
                <Box mt={0.5}>Поделиться</Box>
            </TextButton>

            <FavoriteMoveMenu anchorEl={anchorEl} handleClose={handleClose} />
        </Box>
    );
};

const useStyles = makeStyles(theme => ({
    buttonContainer: {
        position: 'fixed',
        zIndex: 99,
        width: 320,
        bottom: 0,
        left: '50%',
        transform: 'translate(-50%, 0)',
    },
    button: {
        padding: theme.spacing(1),
        fontSize: 11,
        background: '#fff',
        color: theme.palette.primary.main,
        border: '1px solid #EBE0F7',
        boxShadow: '0px 43px 58px rgba(94, 1, 198, 0.04), 0px 15px 19px rgba(0, 0, 0, 0.03), 0px 7.36424px 9px rgba(0, 0, 0, 0.01)',
        borderRadius: 10,
        '&:hover': {
            background: '#fff',
        },
    },
    buttonLabel: {
        display: 'flex',
        flexDirection: 'column',
    },
    startIcon: {
        margin: 0,
    },
}));

export default FavoriteActionButtonsMobile;
