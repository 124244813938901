import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { IAppDealHistory } from '../../../types/AppDealTypes';
import AppDealObjectInfo from '../AppDealObjectInfo';

const AppDealHistoryItem = observer(({ historyItem }: { historyItem: IAppDealHistory }) => {
    const [expanded, setExpanded] = useState<string | false>();
    const classes = useStyles();

    const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : false);
    };

    return (
        <Box mb={3}>
            <Paper variant="outlined" className={classes.paper}>
                <Box p={2}>
                    <Typography variant="body2" color="primary">
                        {historyItem.stage_title}
                    </Typography>
                    <Box mt={3} display="flex" justifyContent="space-between">
                        <Typography variant="h3">{historyItem.client?.name}</Typography>
                        <Box textAlign="right">
                            <Typography variant="body2">№ {historyItem.subagent_number}</Typography>
                            {
                                historyItem.subagentDate ? (<Typography variant="body2" className={classes.faded}>
                                    {historyItem.subagentDate}
                                </Typography>) : null
                            }

                        </Box>
                    </Box>

                    <AppDealObjectInfo object={historyItem.object} type="history" />

                    <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} classes={{ root: classes.accordionRoot }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} classes={{ root: classes.padding }}>
                            <Typography variant="subtitle2">История изменений</Typography>
                        </AccordionSummary>
                        <AccordionDetails classes={{ root: classes.padding }}>
                            <Grid container direction="column">
                                {historyItem.history.map(history => (
                                    <Grid container item justify="space-between" key={history.date} className={classes.historyItem}>
                                        <Box width={32} height={32}>
                                            <img src={history.icon} alt="история" />
                                        </Box>
                                        <Box ml={1} mr="auto">
                                            <Typography variant="body2">{history.title}</Typography>
                                            <Typography variant="body2" className={classes.faded}>
                                                {history.stage_title}
                                            </Typography>
                                        </Box>
                                        <Typography variant="body2" color="textSecondary">
                                            {history.date}
                                        </Typography>
                                    </Grid>
                                ))}
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Box>
            </Paper>
        </Box>
    );
});

const useStyles = makeStyles(theme => ({
    paper: {
        //@ts-ignore
        borderColor: theme.palette.primary.A50,
        borderRadius: 8,
    },
    faded: {
        //@ts-ignore
        color: theme.palette.material.blackMedium,
    },
    accordionRoot: {
        boxShadow: 'none',
        background: 'transparent',
        marginTop: theme.spacing(2),
    },
    historyItem: {
        marginBottom: theme.spacing(1.5),
    },
    padding: {
        padding: 0,
    },
}));

export default AppDealHistoryItem;
