import { observable, action } from 'mobx';
import API from '../api';

export class AgencyStore {
    @observable loading = false;

    @observable agency: any = [];

    @action
    getAgency = (data: string) => {
        this.loading = true;

        API.agency
            .getAgency(data)
            .then(response => {
                if (response && response.data && response.data.suggestions) {
                    this.agency = response.data.suggestions;
                }
            })
            .finally(() => {
                this.loading = false;
            });
    };

    @action
    resetStore = () => {
        this.loading = false;
    };
}
