import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Paper, Typography } from '@material-ui/core';
import { IAppDealParticipant } from '../../../types/AppDealTypes';

import { useStyles } from '../style';
import { useStores } from '../../../hooks/useStores';

import AppDealParticipantView from '../AppDealParticipant/AppDealParticipantView';

const AppDealClientView = observer(() => {
    const classes = useStyles();
    const { AppDealFormStore: store } = useStores();

    useEffect(() => store.getPropertyTypes(), [store]);

    const setPropertyName = () => {
        switch (store.appDeal?.propertyTypeCode) {
            case 'personal':
                return 'Индивидуальная (не женат/не замужем)';
            case 'personal_married':
                return 'Индивидуальная (женат/замужем)';
            case 'together':
                return 'Совместная (для супругов 50/50)';
            case 'part':
                return 'Долевая';
            case 'children':
                return 'Сделка на несовершеннолетнего';
            default:
                return '';
        }
    };

    return (
        <Box mb={{ xxs: 2, md: 4 }}>
            <Paper className={classes.paper}>
                <Box p={2} pt={3}>
                    <Box mb={3}>
                        <Typography variant="h3">Участники</Typography>
                    </Box>

                    {setPropertyName() && (
                        <Box mb={3}>
                            <Paper className={classes.border} variant="outlined">
                                <Box p={2}>
                                    <Typography variant="subtitle1">{setPropertyName()}</Typography>
                                    <Typography variant="caption" color="textSecondary">
                                        Тип собственности
                                    </Typography>
                                </Box>
                            </Paper>
                        </Box>
                    )}

                    {store.appDeal?.participants.map((participant: IAppDealParticipant, index) => (
                        <Box mb={3} key={participant.id}>
                            <AppDealParticipantView data={participant} id={index} status={store?.appDeal?.stage} />
                        </Box>
                    ))}
                </Box>
            </Paper>
        </Box>
    );
});

export default AppDealClientView;
