import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core';

import { useStores } from '../../../hooks/useStores';

import DocumentItemForm from '../AppDealDocs/DocumentItemForm';

const AppDealPaymentDocs = observer(() => {
    const { AppDealFormStore: store } = useStores();
    const theme = useTheme();
    const isXS = useMediaQuery(theme.breakpoints.down('xs'));

    return (
        <Box mb={2}>
            <Box mb={2}>
                <Typography variant="subtitle1">Заполните документы</Typography>
            </Box>
            <Grid container spacing={isXS ? 1 : 2}>
                {store.values.secondaryPayment === 'Материнский капитал' && (
                    <>
                        <Grid item xs={12} sm={5}>
                            <DocumentItemForm
                                objKey="matCapitalScan"
                                defaultFilePath={store.appDeal?.file_maternity_certificate}
                                defaultFileUuid={store.appDeal?.matCapitalScanUuid}
                                store={store}
                            >
                                Скан сертификата о материнском капитале
                            </DocumentItemForm>
                        </Grid>
                        <Grid item xs={12} sm={5}>
                            <DocumentItemForm
                                objKey="matCapitalCashScan"
                                defaultFilePath={store.appDeal?.file_balance_certificate}
                                defaultFileUuid={store.appDeal?.matCapitalCashScanUuid}
                                store={store}
                            >
                                Справка об остатке на текущий день
                            </DocumentItemForm>
                        </Grid>
                    </>
                )}

                {store.values.secondaryPayment === 'Жилищный сертификат' && (
                    <Grid item xs={12} sm={5}>
                        <DocumentItemForm objKey="housingCertScan" defaultFilePath="" defaultFileUuid="" store={store}>
                            Скан жилищного сертификата
                        </DocumentItemForm>
                    </Grid>
                )}

                {store.values.secondaryPayment === 'Военный сертификат' && (
                    <Grid item xs={12} sm={5}>
                        <DocumentItemForm
                            objKey="militaryCertScan"
                            defaultFilePath={store.appDeal?.file_military_certificate}
                            defaultFileUuid={store.appDeal?.militaryCertScanUuid}
                            store={store}
                        >
                            Скан военного сертификата
                        </DocumentItemForm>
                    </Grid>
                )}
            </Grid>
        </Box>
    );
});

export default AppDealPaymentDocs;
