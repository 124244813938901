import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Grid, Typography } from '@material-ui/core';
import { IAPpDealBirthCertView } from '../../../types/AppDealTypes';
import DocumentItemView from '../AppDealDocs/DocumentItemView';

const AppDealBirthCertificateView = observer((props: IAPpDealBirthCertView) => {
    const { birthCertNumber, birthCertSerial, file_birth_cert_scan } = props.data;

    return (
        <>
            <Box mt={3} mb={2}>
                <Typography variant="body2">Свидетельство о рождении ребенка</Typography>
            </Box>

            <Grid container spacing={2}>
                <Grid item xs={6} sm={4} lg={2}>
                    <Box mb={2}>
                        <Typography color="textSecondary" variant="caption">
                            Серия
                        </Typography>
                        <Typography variant="body1">{birthCertNumber || '-'}</Typography>
                    </Box>
                </Grid>

                <Grid item xs={6} sm={4} lg={2}>
                    <Box mb={2}>
                        <Typography color="textSecondary" variant="caption">
                            Серия
                        </Typography>
                        <Typography variant="body1">{birthCertSerial || '-'}</Typography>
                    </Box>
                </Grid>

                <Grid item lg={4} />

                <Grid item xs={12} sm={5}>
                    <DocumentItemView fileUrl={file_birth_cert_scan}>Скан свидетельства о рождении</DocumentItemView>
                </Grid>
            </Grid>
        </>
    );
});

export default AppDealBirthCertificateView;
