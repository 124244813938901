import React from 'react';
import { Box, makeStyles } from '@material-ui/core';

const AppDealSidebar = ({ children }: { children: React.ReactNode }) => {
    const classes = useStyles();

    return <Box className={classes.sideBar}>{children}</Box>;
};

const useStyles = makeStyles(theme => ({
    sideBar: {
        position: 'sticky',
        top: 112,
        width: 256,
        alignSelf: 'flex-start',
        marginRight: theme.spacing(4),
        [theme.breakpoints.down('md')]: {
            marginRight: theme.spacing(1),
        },
    },
}));

export default AppDealSidebar;
