import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Button, Paper, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import { useStyles } from '../style';
import { useStores } from '../../../hooks/useStores';

import AppDealPropertySelect from './AppDealPropertySelect';
import AppDealModal from '../AppDealModal';
import AppDealParticipantsForm from '../AppDealParticipant/AppDealParticipantsForm';

const AppDealClientForm = observer(() => {
    const classes = useStyles();
    const { AppDealFormStore: store } = useStores();

    return (
        <Box mb={{ xxs: 2, md: 4 }}>
            <Paper className={classes.paper}>
                <Box p={2} pt={3}>
                    <Box mb={3}>
                        <Typography variant="h3">Участники</Typography>
                    </Box>
                    <Box mb={3}>
                        <Typography variant="subtitle2">Выберите тип собственности</Typography>
                    </Box>

                    <AppDealPropertySelect />

                    <AppDealModal modalMessage={store.modalMessage} closeModal={store.clearMessage} />

                    <Box mt={3}>
                        <AppDealParticipantsForm participants={store.participants} />
                    </Box>

                    {((store.activePropertyTab === 'part' && store.participants.length < 20) ||
                        (store.activePropertyTab === 'together' && store.participants.length < 2)) && (
                        <Box display="flex" justifyContent="center">
                            <Button color="primary" variant="outlined" onClick={store.addParticipant}>
                                <Box py={2} px={1} display="flex" alignItems="center">
                                    <AddIcon color="primary" />
                                    <Box ml={1}>
                                        <Typography variant="button">
                                            {store.activePropertyTab === 'together' ? 'Добавить супруга(у)' : 'Добавить участника сделки'}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Button>
                        </Box>
                    )}
                </Box>
            </Paper>
        </Box>
    );
});

export default AppDealClientForm;
