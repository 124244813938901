import { useState } from 'react';

export const useTooltip = () => {
    const [isOpened, setIsOpened] = useState(false);

    const openTooltip = () => setIsOpened(true);
    const closeTooltip = () => setIsOpened(false);
    const toggleTooltip = () => setIsOpened(!isOpened);

    return { isOpened, toggleTooltip, openTooltip, closeTooltip };
};
