import { useState } from 'react';

export const usePreview = () => {
    const [sizeError, setSizeError] = useState<boolean>(false);
    const [pdfPreview, setPdfPreview] = useState<File | null>(null);
    const [imgPreview, setImgPreview] = useState<string>('');
    const [showPreview, setShowPreview] = useState<boolean>(false);

    const openPreview = (): void => {
        if (pdfPreview || imgPreview) {
            setShowPreview(true);
        }
    };

    const closePreview = () => setShowPreview(false);

    return { sizeError, setSizeError, pdfPreview, setPdfPreview, imgPreview, setImgPreview, showPreview, openPreview, closePreview };
};
