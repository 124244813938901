import React from 'react';
import QuestionIcon from '../UI/QuestionIcon';
import { makeStyles } from '@material-ui/core';
import moneyFormat from '../../utils/moneyFormat';
import clsx from 'clsx';
import { useTooltip } from '../../hooks/useTooltip';

const useStyles = makeStyles(theme => ({
    wrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '16px',
        height: '16px',
        position: 'relative',
    },
    icon: {
        position: 'relative',
        cursor: 'pointer',
    },
    description: {
        position: 'absolute',
        top: '14px',
        backgroundColor: '#F2F1F8',
        borderRadius: '12px',
        padding: '6px 12px',
        whiteSpace: 'nowrap',
    },
}));

const CommissionDetails = ({ commission }: any) => {
    const classes = useStyles();

    return (
        <div className={classes.description}>
            ~ {moneyFormat(commission)}<sup>*</sup>
        </div>
    )
}

const CommissionIcon = ({ className, commission }: any) => {
    const classes = useStyles();
    const { isOpened, toggleTooltip } = useTooltip();

    return (
        <>
            <div className={clsx(className, classes.wrapper)}>
                <QuestionIcon className={classes.icon} onClick={toggleTooltip} />

                {isOpened ? <CommissionDetails commission={commission} /> : null }
            </div>
        </>
    );
};

export default CommissionIcon;
