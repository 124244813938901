import React from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../../hooks/useStores';
import { Box, Grid, makeStyles, Paper, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import DocumentItemView from '../AppDealDocs/DocumentItemView';

const AppDealPassportScanView = observer(() => {
    const { AppDealFormStore } = useStores();
    const store = AppDealFormStore.participants[0].store;
    const theme = useTheme();
    const isXS = useMediaQuery(theme.breakpoints.down('xs'));
    const classes = useStyles();

    if (!store.appDeal?.client?.file_passport_main) return null;

    return (
        <Box mb={{ xxs: 2, md: 4 }}>
            <Paper className={classes.paper}>
                <Box p={2}>
                    <Box mb={2}>
                        <Typography variant="subtitle1">Паспорт</Typography>
                    </Box>
                    <Grid container spacing={isXS ? 1 : 2}>
                        <Grid item xs={12} sm={6}>
                            <DocumentItemView fileUrl={store.appDeal?.client?.file_passport_main}>
                                Основной разворот паспорта
                            </DocumentItemView>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <DocumentItemView fileUrl={store.appDeal?.client?.file_passport_registration}>
                                Страница с пропиской
                            </DocumentItemView>
                        </Grid>
                    </Grid>
                </Box>
            </Paper>
        </Box>
    );
});

const useStyles = makeStyles(theme => ({
    paper: {
        borderRadius: 16,
        background: theme.palette.surface.main,
        boxShadow: 'none',
    },
}));

export default AppDealPassportScanView;
