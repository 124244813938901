import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Grid, Typography } from '@material-ui/core';

import { IAppDealPassportView } from '../../../types/AppDealTypes';

import DocumentItemView from '../AppDealDocs/DocumentItemView';

export const AppDealPassportView = observer((props: IAppDealPassportView) => {
    const {
        passport_number,
        passport_issued,
        passport_date_of_issue,
        passport_department_code,
        birthday,
        birthPlace,
        address,
        file_passport_main,
        file_passport_registration,
    } = props.data;

    return (
        <>
            <Box mb={2}>
                <Typography variant="body2">Паспорт</Typography>
            </Box>

            <Grid container spacing={2}>
                <Grid item xs={6} sm={4} lg={2}>
                    <Box mb={2}>
                        <Typography color="textSecondary" variant="caption">
                            Серия
                        </Typography>
                        <Typography variant="body1">{passport_number.split(' ')[0]}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={6} sm={4} lg={2}>
                    <Box mb={2}>
                        <Typography color="textSecondary" variant="caption">
                            Номер
                        </Typography>
                        <Typography variant="body1">{passport_number.split(' ')[1]}</Typography>
                    </Box>
                </Grid>

                <Grid item lg={3} />
                <Grid item xs={12} sm={6}>
                    <Box mb={2}>
                        <Typography color="textSecondary" variant="caption">
                            Кем выдан
                        </Typography>
                        <Typography variant="body1">{passport_issued || '-'}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                    <Box mb={2}>
                        <Typography color="textSecondary" variant="caption">
                            Дата выдачи
                        </Typography>
                        <Typography variant="body1">
                            {passport_date_of_issue ? passport_date_of_issue.split('-').reverse().join('.') : '-'}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                    <Box mb={2}>
                        <Typography color="textSecondary" variant="caption">
                            Код подразделения
                        </Typography>
                        <Typography variant="body1">{passport_department_code || '-'}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} lg={2}>
                    <Box mb={2}>
                        <Typography color="textSecondary" variant="caption">
                            Дата рождения
                        </Typography>
                        <Typography variant="body1">{birthday ? birthday.split('-').reverse().join('.') : '-'}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} lg={5}>
                    <Box mb={2}>
                        <Typography color="textSecondary" variant="caption">
                            Место рождения
                        </Typography>
                        <Typography variant="body1">{birthPlace || '-'}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} lg={5}>
                    <Box mb={2}>
                        <Typography color="textSecondary" variant="caption">
                            Адрес регистрации
                        </Typography>
                        <Typography variant="body1">{address || '-'}</Typography>
                    </Box>
                </Grid>
            </Grid>

            <Box mt={3} mb={2}>
                <Typography variant="body2">Фото паспорта</Typography>
            </Box>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={5}>
                    <DocumentItemView fileUrl={file_passport_main}>Основной разворот паспорта</DocumentItemView>
                </Grid>
                <Grid item xs={12} sm={5}>
                    <DocumentItemView fileUrl={file_passport_registration}>Страница с пропиской</DocumentItemView>
                </Grid>
            </Grid>
        </>
    );
});

export default AppDealPassportView;
