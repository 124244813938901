import { observable, action } from 'mobx';
import { DateRange } from '@material-ui/pickers';
import moment from 'moment';

import {
    IAppDealFiltersState,
    IAppDealStatuses,
    IAppDealStatus,
    IAppDealType,
    IAppDealHistory,
    IAppDealState1,
} from '../types/AppDealTypes';
import { IComplexField, IPagination } from '../types/CommonTypes';
import { IEmployee } from '../types/EmployeesTypes';
import API from '../api';
import { stores } from '../contexts';

export class AppsDealsStore {
    @observable loading = false;
    @observable filterLoading = false;

    // Empty flag
    @observable emptyAppsDeals = false;
    @observable emptyAppsDealsHistory = false;

    // List
    @observable appsDeals: IAppDealState1[] = [];
    @observable appsDealsPagination: IPagination | null = null;
    @observable paginationPage = 0;
    @observable appsDealsHistory: IAppDealHistory[] = [];

    // Filters
    @observable filtersIsReset = false;
    @observable complexes = [];
    @observable houseNumbers: string[] = [];
    @observable freeSoloHouseNumber: string = '';
    @observable rooms = [];
    @observable statuses: IAppDealStatuses | null = null;
    @observable filters: IAppDealFiltersState = {
        realtor: null,
        date: [moment().set('date', 1), moment()],
        historyDateRange: [null, null],
        status: null,
        historyStatus: '',
        complex: null,
        houseNumbers: null,
        objectNumber: '',
        priceFrom: '',
        priceTo: '',
    };
    @observable appsDealsTabs: IAppDealType[] = ['applications', 'deals', 'history'];

    // Apps Deals List
    @action
    getAppsDeals = (offset: number, limit: number, filters: any) => {
        this.loading = true;

        API.appDeals
            .getAppsDeals(offset * limit, limit, filters)
            .then(response => {
                if (response?.data.success) {
                    this.appsDeals = [...this.appsDeals, ...response.data.data.items];
                    this.appsDealsPagination = response.data.data.pagination;

                    this.emptyAppsDeals = Boolean(!response.data.data?.items.length);
                }
            })
            .finally(() => {
                this.loading = false;
            });
    };

    @action
    getHistory = (offset: number, limit: number, filters: any) => {
        this.loading = true;

        API.appDeals
            .getHistory(offset * limit, limit, filters)
            .then(response => {
                if (response?.data.success) {
                    this.appsDealsHistory = [...this.appsDealsHistory, ...response.data.data.items];

                    this.appsDealsPagination = response.data.data.pagination;
                    this.emptyAppsDealsHistory = Boolean(!response.data?.data.items.length);
                }
            })
            .finally(() => {
                this.loading = false;
            });
    };

    @action
    resetAppsDeals = () => {
        this.appsDeals = [];
        this.appsDealsHistory = [];
        this.paginationPage = 0;
    };

    @action
    setPaginationPage = (page: number) => {
        this.paginationPage = page;
    };

    // get filters data
    @action
    getComplexes = () => {
        this.filterLoading = true;

        API.filters
            .getComplexes()
            .then(response => {
                this.complexes = response.data?.data;
            })
            .finally(() => {
                this.filterLoading = false;
            });
    };

    @action
    getHouses = (ids: string) => {
        const complexIds = ids.split(',');
        this.filterLoading = true;

        if (complexIds.length) {
            complexIds.forEach((complexId: string) => {
                API.filters
                    .getHouses(parseInt(complexId))
                    .then(response => {
                        if (response?.data.success) {
                            this.houseNumbers = [...this.houseNumbers, ...response.data.data];
                        }
                    })
                    .finally(() => {
                        this.filterLoading = false;
                    });
            });
        }
    };

    @action
    getFilters = () => {
        this.filterLoading = true;

        API.filters
            .getFilters()
            .then(response => {
                this.rooms = response.data?.data.rooms;
            })
            .finally(() => {
                this.filterLoading = false;
            });
    };

    @action
    getStatuses = () => {
        this.filterLoading = true;

        API.appDeals
            .getStatuses()
            .then(response => {
                this.statuses = response.data?.data;
            })
            .finally(() => {
                this.filterLoading = false;
            });
    };

    @action
    resetFilters = () => {
        // this.resetAppsDeals();
        this.filtersIsReset = true;
        this.houseNumbers = [];
        this.freeSoloHouseNumber = '';
        this.filters = {
            realtor: null,
            date: [moment().set('date', 1), moment()],
            historyDateRange: [null, null],
            status: null,
            historyStatus: '',
            complex: null,
            houseNumbers: '',
            objectNumber: '',
            priceFrom: '',
            priceTo: '',
        };
    };

    @action
    setFiltersIsReset = (value: boolean) => {
        this.filtersIsReset = value;
    };

    // set filters
    @action
    setFilter = (name: string, value: any) => {
        this.filters[name as keyof IAppDealFiltersState] = value;
    };

    @action
    setFilterComplex = (ids: string) => {
        const complexes: IComplexField[] = [];
        const complexIds = ids.split(',');

        complexIds.forEach((complexId: string) => {
            const index = this.complexes.findIndex((complex: IComplexField) => complex.id === parseInt(complexId));

            complexes.push(this.complexes[index]);
        });

        if (complexes.length) {
            this.filters.complex = complexes;
        }
    };

    @action
    setFilterRealtor = (employees: IEmployee[], ids: string) => {
        const realtors: IEmployee[] = [];
        const realtorIds = ids.split(',');

        realtorIds.forEach((realtorId: string) => {
            const index = employees.findIndex((realtor: IEmployee) => realtor.id === realtorId);

            realtors.push(employees[index]);
        });

        if (realtors.length) {
            this.filters.realtor = realtors;
        }
    };

    @action
    setFilterStatus = (ids: string, type: IAppDealType) => {
        const statuses: IAppDealStatus[] = [];
        const statusIds = ids.split(',');

        statusIds.forEach((statusId: string) => {
            let index = null;

            if (this.statuses && this.statuses.request.length && this.statuses.deal.length) {
                if (type === 'applications') {
                    index = this.statuses.request.findIndex((status: IAppDealStatus) => status.id === parseInt(statusId));

                    statuses.push(this.statuses.request[index]);
                } else if (type === 'deals') {
                    index = this.statuses?.deal.findIndex((status: IAppDealStatus) => status.id === parseInt(statusId));

                    statuses.push(this.statuses?.deal[index]);
                }
            }
        });

        if (statuses.length) {
            this.filters.status = statuses;
        }
    };

    @action
    setFilterHouseNumbers = (houseNumbers: string) => {
        if (houseNumbers.length) {
            this.filters.houseNumbers = houseNumbers.split(',');
        }
    };

    // filter changes
    @action
    handleChangeFilters = (event: any) => {
        this.filters[event.target.name as keyof IAppDealFiltersState] = event.target.value;

        this.filtersIsReset = false;
    };

    @action
    handleChangeComplex = (value: any) => {
        this.filters.complex = value;

        // reset
        this.filtersIsReset = false;
        this.houseNumbers = [];
        this.freeSoloHouseNumber = '';
        this.filters.houseNumbers = '';

        if (value && value.length) {
            const complexes = this.filters.complex?.map((complex: IComplexField) => complex.id);

            this.getHouses(complexes.join(','));
        }
    };

    @action
    handleChangeHouseNumber = (value: any) => {
        this.filters.houseNumbers = value;

        this.filtersIsReset = false;
        this.freeSoloHouseNumber = '';
    };

    @action
    handleHouseNumberFreeSoloInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.freeSoloHouseNumber = event.target.value;

        this.filtersIsReset = false;
    };

    @action
    handleAutocompleteChange = (filter: string, value: any) => {
        this.setFilter(filter, value);

        this.filtersIsReset = false;
    };

    @action
    handleDateChange = (date: DateRange) => {
        this.filters.date = date;

        this.filtersIsReset = false;
    };

    @action
    handleHistoryDateChange = (date: DateRange) => {
        this.filters.historyDateRange = date;
        this.filtersIsReset = false;

        this.handleChange();
    };

    handleChange = () => {
        const { history } = stores.HistoryStore;
        this.resetAppsDeals();

        let filterParams = '';

        if (this.filters.historyDateRange[0] && this.filters.historyDateRange[1]) {
            filterParams += `&dateFrom=${moment(this.filters.historyDateRange[0]).format('YYYY-MM-DD')}`;
            filterParams += `&dateTo=${moment(this.filters.historyDateRange[1]).format('YYYY-MM-DD')}`;
        }

        if (this.filters.historyStatus) {
            filterParams += `&status=${this.filters.historyStatus}`;
        }

        history.push(`${history.location.pathname}?${filterParams}${history.location.hash}`);
    };

    @action
    handleResetFilters = () => {
        const { history } = stores.HistoryStore;

        this.resetFilters();
        history.push(`${history.location.pathname}${history.location.hash}`);
    };
}
