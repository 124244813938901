import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import { Box, Container, Typography, useMediaQuery, useTheme, makeStyles, Tabs, Tab } from '@material-ui/core';
import clsx from 'clsx';

import listIcon from '../../img/object_list.svg';
import tilesIcon from '../../img/tiles.svg';
import { useStores } from '../../hooks/useStores';

import { a11yProps } from '../../components/UI/TabPanel';
import ApartmentsList from '../../components/Apartments/ApartmentsList/ApartmentsList';
import ApartmentsTiles from '../../components/Apartments/ApartmentsTiles/ApartmentsTiles';

const Apartments = observer(() => {
    const { ApartmentStore: store } = useStores();
    const [activeTab, setActiveTab] = useState<string>('');
    const history = useHistory();
    const classes = useStyles();
    const theme = useTheme();
    const isMD = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {
        return () => {
            store.resetFilters();
        };
    }, [store]);

    useEffect(() => {
        history.location.hash.includes('tiles') ? setActiveTab('tiles') : setActiveTab('');
    }, [history.location.hash]);

    const handleChange = (event: React.ChangeEvent<{}>, id: string) => {
        id === 'tiles' ? setActiveTab('tiles') : setActiveTab('');
        history.push({ hash: `#${id}` });
        store.handleFiltersObjectTabChange();
    };

    return (
        <Container className={clsx(classes.catalogContainer, activeTab === 'tiles' ? classes.tiles : classes.list)}>
            <Box mt={3} display="flex" flexDirection="column" flex="1 1 100%">
                <Box mb={2} display="flex" alignItems="flex-start" flexDirection="column">
                    <Typography variant="h2">Каталог объектов</Typography>

                    <Box mt={2}>
                        <Tabs
                            scrollButtons="off"
                            variant={isMD ? 'fullWidth' : 'standard'}
                            value={activeTab}
                            onChange={handleChange}
                            classes={{ root: classes.tabsRoot, indicator: classes.indicator }}
                        >
                            <Tab
                                label={
                                    <Box display="flex" alignItems="center">
                                        <Box mr={1}>
                                            <img src={listIcon} alt="" />
                                        </Box>
                                        <Typography variant="subtitle2">Список</Typography>
                                    </Box>
                                }
                                {...a11yProps('')}
                                classes={{ root: classes.tabRoot }}
                            />

                            <Tab
                                label={
                                    <Box display="flex" alignItems="center">
                                        <Box mr={1}>
                                            <img src={tilesIcon} alt="" />
                                        </Box>
                                        <Typography variant="subtitle2">Шахматка</Typography>
                                    </Box>
                                }
                                {...a11yProps('tiles')}
                                classes={{ root: classes.tabRoot }}
                            />
                        </Tabs>
                    </Box>
                </Box>

                {activeTab === 'tiles' ? <ApartmentsTiles /> : <ApartmentsList />}
            </Box>
        </Container>
    );
});

const useStyles = makeStyles(theme => ({
    catalogContainer: {
        maxWidth: '100%',
        display: 'flex',
        flex: '1 1',
        overflow: 'hidden',
        padding: '0 40px',
        [theme.breakpoints.down('md')]: {
            padding: '0 20px',
        },
    },
    tiles: {
        // minHeight: '100vh',
    },
    list: {
        // maxWidth: 1200,
        // height: '100vh',
    },
    indicator: {
        height: '100%',
        width: '50%',
        zIndex: -1,
        borderRadius: '10px',
        //@ts-ignore
        background: theme.palette.primary.A50,
    },
    tabsRoot: {
        minHeight: 34,
        maxHeight: 34,
    },
    tabRoot: {
        minHeight: 34,
        maxHeight: 34,
        maxWidth: 136,
        color: theme.palette.primary.main,
        opacity: 1,
    },
}));

export default Apartments;
