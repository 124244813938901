import React, { useState } from 'react';
import { Box, Button, IconButton, makeStyles, Modal, Paper, Typography } from '@material-ui/core';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';

import { IFavoriteModalProps } from '../../types/FavoriteTypes';

import TextButton from '../UI/TextButton';
import TextFieldWithoutHelper from '../UI/TextFieldWithoutHelper';

const FavoriteFolderModal = ({ isOpen, closeModal, onSubmit, type }: IFavoriteModalProps) => {
    const [input, setValue] = useState<string>('Новая папка');
    const classes = useStyles();

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => setValue(e.target.value);

    return (
        <Modal open={isOpen} onClose={closeModal} className={classes.container}>
            <Paper elevation={3} className={classes.modal}>
                <Box p={2}>
                    <Box mb={3} display="flex" justifyContent="space-between">
                        <Typography variant="subtitle1">
                            {type === 'rename' && 'Переименовать'}
                            {type === 'create' && 'Новая папка'}
                            {type === 'move' && 'Переместить в новую папку'}
                        </Typography>

                        <IconButton size="small" onClick={closeModal}>
                            <CloseOutlinedIcon />
                        </IconButton>
                    </Box>

                    <TextFieldWithoutHelper
                        fullWidth
                        required
                        variant="outlined"
                        name="folderName"
                        value={input}
                        onChange={onChange}
                        InputProps={{ notched: false }}
                    />

                    <Box display="flex" justifyContent="space-between" pl={3} mt={2}>
                        <TextButton onClick={closeModal}>Отмена</TextButton>
                        <Button
                            color="primary"
                            variant="contained"
                            className={classes.button}
                            onClick={() => {
                                onSubmit(input);
                                closeModal();
                                setValue('Новая папка');
                            }}
                        >
                            {type === 'rename' && 'Сохранить'}
                            {type === 'create' && 'Создать'}
                            {type === 'move' && 'Переместить'}
                        </Button>
                    </Box>
                </Box>
            </Paper>
        </Modal>
    );
};

const useStyles = makeStyles(theme => ({
    modal: {
        borderRadius: 8,
        flex: '0 1 348px',
    },
    button: {
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5),
    },
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));

export default FavoriteFolderModal;
