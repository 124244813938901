import React from 'react';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import { Box, Button, Grid, makeStyles, Typography } from '@material-ui/core';
import { Element, scroller } from 'react-scroll';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import { useStores } from '../../../hooks/useStores';

import DottedListItem from '../../UI/DottedListItem';

const ApartmentInfoDetails = observer(({ hideCardDetails }: any) => {
    const { ApartmentStore: store } = useStores();
    const item = store.selectedApartment;
    const classes = useStyles();

    const scrollToTop = () => {
        scroller.scrollTo('cardTop', {
            duration: 500,
            smooth: 'easeOutQuad',
            containerId: 'details',
            offset: -20,
        });
        setTimeout(() => hideCardDetails(), 500);
    };

    return (
        <Box p={2} display="flex" flexDirection="column" maxWidth={346}>
            <Grid container justify="space-between" alignItems="center">
                <DottedListItem
                    leftContent="Общая площадь"
                    rightContent={
                        <Box mb={0.5}>
                            <Typography variant="subtitle1">{item?.total_area} м²</Typography>
                        </Box>
                    }
                />
                <DottedListItem
                    leftContent="Жилая площадь"
                    rightContent={
                        <Box mb={0.5}>
                            <Typography variant="subtitle1">{item?.living_space} м²</Typography>
                        </Box>
                    }
                />
                <DottedListItem
                    leftContent="Кухня"
                    rightContent={
                        <Box mb={0.5}>
                            <Typography variant="subtitle1">{item?.kitchen_space} м²</Typography>
                        </Box>
                    }
                />
                <DottedListItem
                    leftContent="Этаж"
                    rightContent={
                        <Box mb={0.5}>
                            <Typography variant="subtitle1">
                                {item?.floor_number} / {item?.max_floor}
                            </Typography>
                        </Box>
                    }
                />
                <DottedListItem
                    leftContent="Корпус"
                    rightContent={
                        <Box mb={0.5}>
                            <Typography variant="subtitle1">{item?.house_number}</Typography>
                        </Box>
                    }
                />
                <DottedListItem
                    leftContent="Срок сдачи"
                    rightContent={
                        <Box mb={0.5}>
                            <Typography variant="subtitle1">
                                {item?.development_end.slice(0, item?.development_end.indexOf('года'))}
                            </Typography>
                        </Box>
                    }
                />
                <DottedListItem
                    leftContent="Окна смотрят"
                    rightContent={
                        <Box mb={0.5}>
                            <Typography variant="subtitle1">{item?.window_view || '-'}</Typography>
                        </Box>
                    }
                />

                <DottedListItem leftContent="Комната-1" rightContent={<Box mb={1.5}>17 м²</Box>} />

                <DottedListItem leftContent="Санузел-1" rightContent={<Box mb={1.5}>-</Box>} />

                <DottedListItem leftContent="Прихожая" rightContent={<Box mb={1.5}>-</Box>} />

                <DottedListItem leftContent="Балкон-1" rightContent={<Box mb={1.5}>-</Box>} />

                <DottedListItem leftContent="Кладовая" rightContent={<Box mb={1.5}>-</Box>} />
            </Grid>
            <Box mt={4}>
                <Link to={`apartments/${item?.object_id}`}>
                    <Button size="large" className={classes.btn}>
                        Перейти к объекту
                        <Box ml={1} display="flex">
                            <ArrowForwardIcon color="primary" />
                        </Box>
                    </Button>
                </Link>
            </Box>
            <Box mt={2.5} alignSelf="center" display="flex" className={classes.pointer} onClick={scrollToTop}>
                <Typography variant="button" color="primary">
                    <Element id="cardDown" name="cardDown">
                        Скрыть
                    </Element>
                </Typography>

                <Box ml={1}>
                    <ExpandLessIcon color="primary" />
                </Box>
            </Box>
        </Box>
    );
});

const useStyles = makeStyles(theme => ({
    btn: {
        //@ts-ignore
        background: theme.palette.primary.A50,
        width: '100%',
        alignSelf: 'center',
        color: theme.palette.primary.main,
        '&:hover': {
            //@ts-ignore
            background: theme.palette.primary.A50,
        },
    },
    pointer: {
        cursor: 'pointer',
    },
}));

export default ApartmentInfoDetails;
