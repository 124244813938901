import React from 'react';
import { makeStyles, Box } from '@material-ui/core';
import PanoramaOutlinedIcon from '@material-ui/icons/PanoramaOutlined';
import emptyImg from '../../img/emptyImg.svg';
import clsx from 'clsx';

const EmptyImage = ({ variant, tile }: { variant?: string | 'apartment'; tile?: boolean }) => {
    const classes = useStyles();

    if (variant === 'apartment') {
        return (
            <Box className={classes.imgWrap}>
                <img src={emptyImg} alt="" className={clsx(!tile && classes.img)} />
            </Box>
        );
    }

    return (
        <Box className={classes.iconWrap}>
            <PanoramaOutlinedIcon fontSize="large" className={classes.icon} />
        </Box>
    );
};

const useStyles = makeStyles(theme => ({
    imgWrap: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
    },
    img: {
        width: '50%',
        maxWidth: 176,
    },
    iconWrap: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        backgroundColor: '#ebe0f7',
    },

    icon: {
        color: theme.palette.text.hint,
        opacity: 0.5,
    },
}));

export default EmptyImage;
