import { useEffect, useState } from 'react';
import queryString from 'query-string';
import { useHistory } from 'react-router-dom';

import { useStores } from './useStores';

export const useSetFiltersFromQueryString = () => {
    const { ApartmentStore: store } = useStores();
    const [houseNumbersAutoFillIsDone, setHouseNumbersAutoFillIsDone] = useState(false);
    const {
        location: { search, hash },
    } = useHistory();
    const parsed = queryString.parse(search || hash);

    useEffect(() => {
        const filters = [
            'objectNumber',
            'totalAreaFrom',
            'totalAreaTo',
            'floorNumberFrom',
            'floorNumberTo',
            'isHideBooked',
            'exceptFirstFloor',
            'exceptLastFloor',
            'facing',
        ];

        filters.forEach(filter => {
            if (parsed[filter]) {
                store.setFilter(filter, parsed[filter]);
            }
        });

        store.setFilter('priceFrom', parseFloat(parsed.priceFrom as string) / 1000000 || '');
        store.setFilter('priceTo', parseFloat(parsed.priceTo as string) / 1000000 || '');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const complexesIds = parsed.complex as string;

        if (parsed.complex) {
            if (store.complexes.length) {
                store.setFilterComplex(complexesIds);
                store.getHouses(complexesIds);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.complexes]);

    useEffect(() => {
        const complexesIds = parsed.complex as string;

        if (parsed.complex) {
            store.getHouses(complexesIds);
        }
    }, [store, parsed.complex]);

    useEffect(() => {
        if (!houseNumbersAutoFillIsDone && parsed.houseNumber) {
            if (store.houseNumbers.length) {
                store.setFilter('houseNumber', parsed.houseNumber);
                setHouseNumbersAutoFillIsDone(true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.houseNumbers]);

    useEffect(() => {
        const roomsIds = parsed.roomsNumber as string;

        if (parsed.roomsNumber) {
            if (store.rooms.length) {
                store.setFilterRoomsNumber(roomsIds);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.rooms]);

    useEffect(() => {
        const types = parsed.types as string;

        if (parsed.types) {
            if (store.types.length) {
                store.setFilterTypes(types);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.types]);

    useEffect(() => {
        const devEndDates = parsed.devEnd as string;

        if (parsed.devEnd) {
            if (store.devEnd.length) {
                store.setFilterdevEnd(devEndDates);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.devEnd]);

    useEffect(() => {
        if (parsed.sort) {
            if (store.sorts) {
                store.setSort(+parsed.sort);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};
