import React from 'react';
import { Box, Typography, Container } from '@material-ui/core';

const RequestError = () => {
    return (
        <Container>
            <Box py={3} textAlign="center">
                <Typography variant="subtitle1" color="error">
                    Что-то пошло не так...
                </Typography>
            </Box>
        </Container>
    );
};

export default RequestError;
