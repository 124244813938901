import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const FolderMoveIcon = (props: SvgIconProps) => (
    <SvgIcon {...props}>
        <path d="M20.5 18H4.5V8H20.5V18ZM12.5 6L10.5 4H4.5C3.96957 4 3.46086 4.21071 3.08579 4.58579C2.71071 4.96086 2.5 5.46957 2.5 6V18C2.5 18.5304 2.71071 19.0391 3.08579 19.4142C3.46086 19.7893 3.96957 20 4.5 20H20.5C21.61 20 22.5 19.11 22.5 18V8C22.5 7.46957 22.2893 6.96086 21.9142 6.58579C21.5391 6.21071 21.0304 6 20.5 6H12.5ZM11.5 14V12H15.5V9L19.5 13L15.5 17V14H11.5Z" />
    </SvgIcon>
);

export default FolderMoveIcon;
