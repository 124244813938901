import React from 'react';
import { observer } from 'mobx-react-lite';
import { Paper, Box, Typography, makeStyles } from '@material-ui/core';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { INewsCarousel, INewsItem } from '../../types/NewsTypes';

import CarouselArrow from '../CarouselArrow/CarouselArrow';
import EmptyImage from '../UI/EmptyImage';
import Loader from '../Loader/Loader';
import SliderLink from '../UI/SliderLink';

const NewsCarousel = observer(({ data, isLoading }: INewsCarousel) => {
    const classes = useStyles();

    const sliderSettings = {
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        prevArrow: <CarouselArrow type="prev" />,
        nextArrow: <CarouselArrow type="next" />,
        responsive: [
            {
                breakpoint: 579,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                },
            },
        ],
    };

    const renderItems = data?.map((item: INewsItem) => (
        <Box key={item.id} px={1} py={4}>
            <SliderLink to={`/news/${item.id}`} className={classes.itemWrap}>
                <Paper variant="outlined" className={classes.paper}>
                    <Box className={classes.imgWrap}>
                        {item.photo ? <img src={item.photo.fullUrl} alt="" className={classes.img} /> : <EmptyImage />}
                    </Box>
                    <Box p={2}>
                        <Box mb={2}>
                            <Typography variant="h4">{item.title}</Typography>
                        </Box>
                        <Typography variant="caption" color="textSecondary">
                            {item.createdAt}
                        </Typography>
                    </Box>
                </Paper>
            </SliderLink>
        </Box>
    ));

    return (
        <>
            {!!data.length && <Typography variant="h2">Новости</Typography>}
            <Box mx={-1}>
                <Slider {...sliderSettings} className="slick-slider__visible">
                    {isLoading ? <Loader /> : renderItems}
                </Slider>
            </Box>
        </>
    );
});

const useStyles = makeStyles(theme => ({
    itemWrap: {
        [theme.breakpoints.down(669)]: {
            maxWidth: 244,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        //@ts-ignore
        ...theme.overrides.MuiPaper.elevation4,
        //@ts-ignore
        borderColor: theme.palette.primary.A50,
        borderRadius: 8,
        cursor: 'pointer',
        '&:hover': {
            //@ts-ignore
            ...theme.overrides.MuiPaper.elevation3,
            borderColor: theme.palette.primary.main,
        },
    },
    imgWrap: {
        width: '100%',
        height: 143,
        overflow: 'hidden',
        [theme.breakpoints.down('lg')]: {
            height: '13.2vw',
        },
        [theme.breakpoints.down('md')]: {
            height: '17.9vw',
        },
        [theme.breakpoints.down('sm')]: {
            height: '26.4vw',
        },
        [theme.breakpoints.down(669)]: {
            height: 143,
        },
    },
    img: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    },
}));

export default NewsCarousel;
