import React from 'react';
import { Box, Dialog, DialogProps, Grid, IconButton, makeStyles, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';

import { BookingType } from '../../types/ApartmentTypes';
import { bookingTypes } from '../../data/bookingTypes';
import BookingTypeCard from './BookingTypeCard';

interface IBookTypeModalProps extends Omit<DialogProps, 'onSelect' | 'onClose' | 'children'> {
    onSelect: (type: BookingType) => void;
    onClose: () => void;
}

const BookingTypeModal: React.FC<IBookTypeModalProps> = ({ onSelect, onClose, ...props }) => {
    const classes = useStyles();
    const theme = useTheme();
    const isSM = useMediaQuery(theme.breakpoints.down('xs'));

    return (
        <Dialog onClose={onClose} scroll="body" PaperProps={{ className: classes.modal, elevation: 24 }} {...props}>
            <Box p={{ xxs: 3, md: 4 }} display="flex" alignItems="center" textAlign="center" flexDirection="column">
                <Box mb={{ xxs: 3, md: 4 }} width="100%" display="flex" justifyContent="space-between">
                    <Typography variant="h3" align="left">
                        Выберите способ бронирования
                    </Typography>

                    <IconButton size="small" onClick={onClose}>
                        <CloseOutlinedIcon />
                    </IconButton>
                </Box>

                <Grid container spacing={isSM ? 2 : 3}>
                    {bookingTypes.map(item => (
                        <Grid item xs={12} md={6}>
                            <BookingTypeCard data={item} onSelect={onSelect} />
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </Dialog>
    );
};

const useStyles = makeStyles({
    modal: {
        borderRadius: 16,
        maxWidth: 928,
    },
});

export default BookingTypeModal;
