import React from 'react';
import { Box, Button, Dialog, IconButton, makeStyles, Theme, Typography, useMediaQuery } from '@material-ui/core';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStores } from '../hooks/useStores';
import { theme } from '../styles/muiTheme';

const NotificationModal: React.FC = observer(() => {
    const { StockStore } = useStores();
    const { stockNotification } = StockStore;

    const history = useHistory();

    const classes = useStyles();
    const isSM = useMediaQuery(theme.breakpoints.up('sm'));

    //Handlers
    const handleClose = () => {
        StockStore.closeNotification();
    };

    const handleOpenLink = () => {
        if (!stockNotification?.id) {
            return;
        }

        StockStore.closeNotification();
        history.push(`/stock/${stockNotification?.id}`);
    };

    //Renders
    const renderWebImage = () => {
        return (
            <>
                {stockNotification?.imageWeb?.fullUrl && (
                    <img width={'100%'} alt={stockNotification.imageWeb.fileName} src={stockNotification.imageWeb.fullUrl} />
                )}
            </>
        );
    };

    const renderMobileImage = () => {
        return (
            <>
                {stockNotification?.imageMobile?.fullUrl && (
                    <img
                        className={classes.imageFit}
                        alt={stockNotification.imageMobile.fileName}
                        src={stockNotification.imageMobile.fullUrl}
                    />
                )}
            </>
        );
    };

    return (
        <Dialog
            open={StockStore.isShowNotification}
            PaperProps={{ className: classes.modal, elevation: 24 }}
            onClose={handleClose}
            scroll="body"
            fullScreen={!isSM}
        >
            <Box position={'relative'}>
                {isSM ? renderWebImage() : renderMobileImage()}
                <IconButton size="small" className={classes.closeBtn} onClick={handleClose}>
                    <CloseOutlinedIcon />
                </IconButton>
                <Box className={classes.absoluteContent}>
                    <Box p={{ xxs: 3, sm: 4 }} className={classes.contentWrapper}>
                        <Box mb={{ xxs: 2, sm: 2.5 }} className={classes.textContainer}>
                            <Box mr={3} mb={{ xxs: 2, sm: 2.5 }}>
                                <Typography
                                    variant={'h2'}
                                    align="left"
                                    className={stockNotification?.textColor === 'dark' ? classes.darkText : classes.whiteText}
                                >
                                    {stockNotification?.title}
                                </Typography>
                            </Box>
                            <Box mb={{ xxs: 2 }}>
                                <Typography
                                    variant="body2"
                                    className={stockNotification?.textColor === 'dark' ? classes.darkText : classes.whiteText}
                                >
                                    {stockNotification?.shortDescription}
                                </Typography>
                            </Box>
                        </Box>
                        <Box>
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth={!isSM}
                                className={classes.moreBtn}
                                onClick={handleOpenLink}
                            >
                                Подробнее
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Dialog>
    );
});

const useStyles = makeStyles((theme: Theme) => ({
    modal: {
        borderRadius: 16,
        width: '100%',
        [theme.breakpoints.up('md')]: {
            maxWidth: 672,
        },
    },
    closeBtn: {
        position: 'absolute',
        top: 24,
        right: 24,
        zIndex: 100,
        backgroundColor: 'rgba(255, 255, 255, 0.6)',
        '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 1)',
        },
    },
    moreBtn: {
        fontWeight: 400,
        padding: theme.spacing(1.5, 3),
    },
    absoluteContent: {
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 0,
        width: '100%',
        height: '100%',
    },
    contentWrapper: {
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        [theme.breakpoints.up('sm')]: {
            justifyContent: 'space-between',
        },
    },
    textContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        [theme.breakpoints.up('md')]: {
            width: '47%',
        },
    },
    imageFit: {
        objectFit: 'cover',
        height: '100vh',
        width: '100vw',
    },
    whiteText: {
        color: '#FFFFFF',
    },
    darkText: {
        color: '#000000',
    },
}));

export default NotificationModal;
