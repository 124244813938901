import React from 'react';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import { Box, Grid, Typography, Paper } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import PhotoCameraOutlinedIcon from '@material-ui/icons/PhotoCameraOutlined';

import { IAppDealObjectForm } from '../../../types/AppDealTypes';
import { useStores } from '../../../hooks/useStores';
import { useStyles } from '../style';
import { formatDevEnd } from '../../../utils/formatDevEnd';
import { useModal } from '../../../hooks/useModal';

import ModalApartmentSelect from '../ModalApartmentSelect/ModalApartmentSelect';
import TextButton from '../../UI/TextButton';

const AppDealObject = observer((props: IAppDealObjectForm) => {
    const { AppDealFormStore: store } = useStores();
    const {
        objectNumber,
        complex,
        roomsNumber,
        totalArea,
        floorNumber,
        houseNumber,
        image,
        developmentEnd,
        maxFloor,
        priceFormat,
    } = props.data;

    const classes = useStyles();

    const { isOpen, openModal, closeModal } = useModal();

    // Handlers
    const handleOpen = () => {
        openModal();
        store.getRidOfObjectError();
    };

    // Render
    return (
        <Box mb={{ xxs: 2, md: 4 }}>
            <Paper className={classes.paper}>
                <Box p={2} pt={3}>
                    <Grid container justify="space-between">
                        <Typography variant="h3">Объект</Typography>

                        {props.isBookForm && complex && (
                            <>
                                <TextButton onClick={handleOpen} startIcon={!store.values.objectId && <WarningIcon color="error" />}>
                                    Выбрать другой объект
                                </TextButton>
                            </>
                        )}
                    </Grid>

                    <ModalApartmentSelect open={isOpen} onClose={closeModal} />

                    {!complex ? (
                        <Box mt={3.5} position="relative">
                            {store.formErrors?.objectId && (
                                <Box position="absolute" top="-24px">
                                    <Typography variant="caption" color="error">
                                        Объект не выбран
                                    </Typography>
                                </Box>
                            )}
                            <Grid container>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Box
                                        className={clsx(classes.tabStyle, {
                                            [classes.errorBorderStyle]: store.formErrors?.objectId,
                                        })}
                                        onClick={handleOpen}
                                    >
                                        <Box py={1.5}>
                                            <Typography variant="subtitle1">Выбрать объект</Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    ) : (
                        <Box className={classes.wrapper}>
                            <Box width={103} mr={2} mb={{ xxs: 2, md: 0 }} alignSelf="center">
                                <Box width={103} mr={2} alignSelf="center" display="flex" justifyContent="center" alignItems="center">
                                    {image ? <img src={image} alt="план" /> : <PhotoCameraOutlinedIcon className={classes.noImageIcon} />}
                                </Box>
                            </Box>

                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Typography color="textSecondary" variant="caption">
                                        Стоимость
                                    </Typography>
                                    <Typography variant="subtitle1">{priceFormat ? priceFormat : '-'}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Typography color="textSecondary" variant="caption">
                                        Жилой комплекс
                                    </Typography>
                                    <Typography variant="subtitle1">{complex || '-'}</Typography>
                                </Grid>

                                <Grid item xs={12} sm={6} md={4} lg={2}>
                                    <Typography color="textSecondary" variant="caption">
                                        Площадь, м²
                                    </Typography>
                                    <Typography variant="subtitle1">{totalArea || '-'}</Typography>
                                </Grid>

                                <Grid item xs={12} sm={6} md={4} lg={2}>
                                    <Typography color="textSecondary" variant="caption">
                                        Номер дома
                                    </Typography>
                                    <Typography variant="subtitle1">{houseNumber || '-'}</Typography>
                                </Grid>

                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Typography color="textSecondary" variant="caption">
                                        Комнат
                                    </Typography>
                                    <Typography variant="subtitle1">{roomsNumber || '-'}</Typography>
                                </Grid>

                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Typography color="textSecondary" variant="caption">
                                        Номер квартиры
                                    </Typography>
                                    <Typography variant="subtitle1">{objectNumber || '-'}</Typography>
                                </Grid>

                                <Grid item xs={12} sm={6} md={4} lg={2}>
                                    <Typography color="textSecondary" variant="caption">
                                        Этаж
                                    </Typography>
                                    <Typography variant="subtitle1">{`${floorNumber}/${maxFloor}` || '-'}</Typography>
                                </Grid>

                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Typography color="textSecondary" variant="caption">
                                        Срок сдачи
                                    </Typography>
                                    <Typography variant="subtitle1">{formatDevEnd(developmentEnd) || '-'}</Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    )}
                </Box>
            </Paper>
        </Box>
    );
});

export default AppDealObject;
