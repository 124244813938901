import { makeStyles } from '@material-ui/core';
import backgroundPayment from '../../img/payment_background.svg';

export const useStyles = makeStyles(theme => ({
    paper: {
        borderRadius: 16,
        background: theme.palette.surface.main,
        boxShadow: 'none',
    },
    docItem: {
        borderRadius: 8,
        //boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.1), 0px 3px 8px rgba(0, 0, 0, 0.06), 0px 2px 8px rgba(0, 0, 0, 0.07)',
        overflow: 'visible',
    },
    docPreview: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 48,
        height: 48,
        border: '2px solid #EBE0F7',
        background: '#EBE0F7',
        transition: 'border 0.3s ease',
    },
    clickableDocPreview: {
        cursor: 'pointer',
        '&:hover': {
            borderColor: theme.palette.primary.main,
        },
    },
    closePreviewIcon: {
        width: 48,
        height: 48,
        background: theme.palette.primary.main,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 10,
        marginLeft: 'auto',
        position: 'relative',
        right: -52,
        top: -6,
        cursor: 'pointer',
        [theme.breakpoints.down('sm')]: {
            right: -38,
        },
    },
    docEmptyIcon: {
        marginTop: 3,
    },
    commentPaper: {
        borderRadius: 8,
        boxShadow: 'none',
        background: '#fff',
    },
    wrapper: {
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            justifyContent: 'center',
        },
        marginTop: theme.spacing(2.5),
        padding: theme.spacing(2),
        // @ts-ignore
        border: `1px solid ${theme.palette.primary.A100}`,
        borderRadius: 8,
        background: '#ffffff',
    },
    noImageIcon: {
        fontSize: 38,
        //@ts-ignore
        color: theme.palette.primary.A50,
    },
    zeroPadding: {
        padding: '0 !important',
    },
    circleIcon: {
        position: 'absolute',
        top: -18,
        left: -18,
    },
    circleIconView: {
        position: 'absolute',
        top: -22,
        left: -22,
    },
    tabStyle: {
        // @ts-ignore
        border: `1px solid ${theme.palette.primary.A50}`,
        background: '#fff',
        borderRadius: 8,
        boxShadow: '0px 43px 58px rgba(94, 1, 198, 0.04), 0px 15px 19px rgba(0, 0, 0, 0.03), 0px 7.36424px 9px rgba(0, 0, 0, 0.01)',
        color: theme.palette.primary.main,
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
        textAlign: 'left',

        '&:hover': {
            border: `1px solid ${theme.palette.primary.main}`,
            background: '#fff',
        },
    },
    activeTabStyle: {
        background: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        boxShadow: 'none',
        '&:hover': {
            // @ts-ignore
            background: theme.palette.primary.main,
        },
    },
    errorBorderStyle: {
        border: `1px solid ${theme.palette.error.main}`,
        '&:hover': {
            borderColor: theme.palette.error.main,
        },
    },
    activeTextColor: {
        //@ts-ignore
        color: theme.palette.material.whiteMedium,
    },
    backgroundWhite: {
        background: '#fff',
    },
    border: {
        borderRadius: 8,
        //@ts-ignore
        border: `1px solid ${theme.palette.primary.A100}`,
        background: 'transparent',
    },
    errorFormTextInput: {
        position: 'absolute',
        bottom: -22,
    },
    accordionRoot: {
        marginLeft: theme.spacing(4),
    },
    expandIcon: {
        position: 'absolute',
        left: -28,
    },
    rounded: {
        borderRadius: 8,
    },
    deleteIcon: {
        //@ts-ignore
        color: theme.palette.primary.A200,
        position: 'absolute',
        right: 20,
    },
    paddingBottom: {
        paddingBottom: `24px !important`,
        position: 'relative',
    },
    label: {
        marginRight: 0,
    },
    payment: {
        backgroundColor: theme.palette.primary.main,
        background: `url(${backgroundPayment}) no-repeat 101% / auto 100%`,
        borderRadius: 16,
        color: '#fff',
    },
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    whiteIcon: {
        color: '#fff',
    },
    textUnderline: {
        textDecoration: 'underline',
        cursor: 'pointer',
    },
    input: {
        padding: '16px 4px 6px !important',
    },
}));
