import React from 'react';
import { Box, Input, makeStyles, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import PersonIcon from '@material-ui/icons/Person';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import PhoneOutlinedIcon from '@material-ui/icons/PhoneOutlined';
import MailOutlinedIcon from '@material-ui/icons/MailOutlined';

import { IAppDealManager } from '../../../types/AppDealTypes';

import { PhoneFormat, PhoneFormatLogin } from '../../UI/Form';

const AppDealManagerContacts = observer(
    ({ data, isUnread, showMessages }: { data: IAppDealManager; isUnread: boolean; showMessages: any }) => {
        const { user_name, work_time, phone, email } = data || {};
        const classes = useStyles();

        return (
            <>
                <Box mb={2} display="flex" justifyContent="center">
                    <Typography variant="subtitle2">Связь с менеджером</Typography>
                </Box>

                <Box mb={2} display="flex" alignItems="center">
                    <Box className={classes.photo} display="flex" justifyContent="center" alignItems="center" mr={1.5}>
                        <PersonIcon className={classes.icon} />
                    </Box>
                    <Typography variant="subtitle1">{user_name}</Typography>
                </Box>

                <Box display="flex" className={classes.colorDisabled} mb={1}>
                    <AccessTimeIcon />

                    <Box ml={1.5}>
                        <Typography variant="body2">
                            <span dangerouslySetInnerHTML={{ __html: work_time }} />
                        </Typography>
                    </Box>
                </Box>

                <Box className={classes.contactSection}>
                    <Box display="flex" mb={1}>
                        <PhoneOutlinedIcon />
                        <Box ml={1.5}>
                            <a href={`tel:${phone}`} className={classes.link}>
                                <Input
                                    disabled
                                    inputComponent={phone?.charAt(0) === '+' ? PhoneFormat : PhoneFormatLogin}
                                    value={phone}
                                    classes={{
                                        root: classes.input,
                                        input: classes.pointer,
                                    }}
                                />
                            </a>
                        </Box>
                    </Box>
                    <Box display="flex">
                        <MailOutlinedIcon />
                        <Box ml={1.5}>
                            <Typography variant="subtitle2">
                                <a target="_top" rel="noopener noreferrer" href={`mailto: ${email}`} className={classes.link}>
                                    {email}
                                </a>
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </>
        );
    },
);

const useStyles = makeStyles(theme => ({
    contactSection: {
        textDecoration: 'underline',
    },
    colorDisabled: {
        // @ts-ignore
        color: theme.palette.material.blackMedium,
    },
    input: {
        color: 'rgba(0, 0, 0, 0.87) !important',
        '&:before': {
            content: 'none',
        },
        '&after': {
            content: 'none',
        },
    },
    photo: {
        background: theme.palette.surface.main,
        borderRadius: 8,
        flex: '0 0 52px',
        height: 52,
    },
    icon: {
        //@ts-ignore
        color: theme.palette.primary.A100,
        width: 34,
        height: 34,
    },
    link: {
        color: 'rgba(0, 0, 0, 0.87)',
    },
    pointer: {
        cursor: 'pointer',
    },
    messagesIndicator: {
        width: 24,
        height: 24,
        background: '#FCD657',
        borderRadius: '50%',
        position: 'absolute',
        right: -10,
        top: -10,
    },
}));

export default AppDealManagerContacts;
