import React from 'react';
import { Container, Box, Typography } from '@material-ui/core';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import ProfileEditForm from '../../components/ProfileEditForm/ProfileEditForm';

const ProfileEdit = () => {
    return (
        <Container maxWidth="xl">
            <Box py={4}>
                <Breadcrumbs data={['Главная', 'Профиль']} />

                <Box my={6}>
                    <Typography variant="h3" component="span">
                        Настройки профиля
                    </Typography>
                </Box>
                <ProfileEditForm />
            </Box>
        </Container>
    );
};

export default ProfileEdit;
