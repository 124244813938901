import React from 'react';
import { observer } from 'mobx-react-lite';
import { saveAs } from 'file-saver';
import { Box, Typography, makeStyles, Grid } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';

import { IAppDealChatMessage } from '../../../types/AppDealTypes';

const AppDealManagerMessages = observer(({ hideMessages, messages }: { hideMessages: any; messages: IAppDealChatMessage[] }) => {
    const classes = useStyles();

    return (
        <>
            <Box display="flex" justifyContent="center" mb={2}>
                <Box onClick={hideMessages} position="absolute" top={15} left={15} className={classes.pointer}>
                    <ArrowBackIcon color="primary" />
                </Box>

                <Typography variant="subtitle2">Сообщения</Typography>
            </Box>

            <Grid container direction="column" wrap="nowrap" className={classes.messagesContainer}>
                {messages?.map(message => (
                    <Grid container item key={message.message} className={classes.message}>
                        {message.type === 'text' ? (
                            <Typography variant="body2">{message.message}</Typography>
                        ) : (
                            <Box display="flex" onClick={() => saveAs(message?.link, message.message)} className={classes.pointer}>
                                <InsertDriveFileOutlinedIcon className={classes.icon} />
                                <Box ml={1.5}>
                                    <Typography variant="body2">{message.message}</Typography>
                                    <Typography variant="caption" color="secondary">
                                        {message.size?.toUpperCase()}
                                    </Typography>
                                </Box>
                            </Box>
                        )}

                        <Box mt="auto" ml="auto">
                            <Typography variant="caption" color="textSecondary">
                                {message.datetime}
                            </Typography>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </>
    );
});

const useStyles = makeStyles(theme => ({
    messagesContainer: {
        height: 274,
        overflowY: 'auto',
        paddingRight: 10,
        '&::-webkit-scrollbar': {
            width: 4,
            backgroundColor: '#ebe0f7',
            borderRadius: 4,
        },

        '&::-webkit-scrollbar-thumb': {
            background: '#ac85e0',
            borderRadius: 4,
        },
    },
    message: {
        background: theme.palette.surface.main,
        borderRadius: 8,
        minHeight: 74,
        marginBottom: theme.spacing(2),
        padding: theme.spacing(1),
    },
    pointer: {
        cursor: 'pointer',
    },
    icon: {
        //@ts-ignore
        color: theme.palette.primary.A100,
    },
}));

export default AppDealManagerMessages;
