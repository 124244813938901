import React, { useEffect } from 'react';
import { observer, useLocalStore } from 'mobx-react-lite';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import moment from 'moment';
import { Container, Box, Grid, Typography, Paper, Button, TextField, CircularProgress, useTheme, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Element } from 'react-scroll';
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined';

import { useStores } from '../../hooks/useStores';
import { IObjectData } from '../../types/AppDealTypes';
import { AppDealChatStore } from '../../stores/AppDealChatStore';

import TextButton from '../../components/UI/TextButton';
import AppDealClientForm from '../../components/AppsDeals/AppDealClient/AppDealClientForm';
import AppDealObject from '../../components/AppsDeals/AppDealObject/AppDealObject';
import Loader from '../../components/Loader/Loader';
import AppDealPaymentForm from '../../components/AppsDeals/AppDealPayment/AppDealPaymentForm';
import AppDealNav from '../../components/AppsDeals/AppDealNav/AppDealNav';
import AppDealSimpleClientForm from '../../components/AppsDeals/AppDealClient/AppDealSimpleClientForm';
import AppDealPaymentOptions from '../../components/AppsDeals/AppDealPayment/AppDealPaymentOptions';
import AppDealPaymentBank from '../../components/AppsDeals/AppDealPayment/AppDealPaymentBank';
import AppDealPaymentInstallment from '../../components/AppsDeals/AppDealPayment/AppDealPaymentInstallment';
import AppDealClientView from '../../components/AppsDeals/AppDealClient/AppDealClientView';
import AppDealPaymentView from '../../components/AppsDeals/AppDealPayment/AppDealPaymentView';
import AppDealPetitionForm from '../../components/AppsDeals/AppDealPetition/AppDealPetitionForm';
import AppDealPetitionView from '../../components/AppsDeals/AppDealPetition/AppDealPetitionView';
import AppDealManager from '../../components/AppsDeals/AppDealManager/AppDealManager';
import AppDealSidebar from '../../components/AppsDeals/AppDealSidebar/AppDealSidebar';
import AppDealBookingType from '../../components/AppsDeals/AppDealBookingType/AppDealBookingType';
import AppDealObjectIsBooked from '../../components/AppsDeals/AppDealObjectIsBooked';
import { showNotification } from '../../utils/Notification';
import AppDealPayLinkModal from '../../components/AppsDeals/AppDealPayLinkModal';
import AppDealPassportScanForm from '../../components/AppsDeals/AppDealPassportScan/AppDealPassportScanForm';
import AppDealPassportScanView from '../../components/AppsDeals/AppDealPassportScan/AppDealPassportScanView';

const AppDealForm = observer(() => {
    const { AppDealFormStore: store } = useStores();
    const chatStore = useLocalStore(() => new AppDealChatStore());

    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    const theme = useTheme();
    const isSM = useMediaQuery(theme.breakpoints.down('sm'));

    const { appDealId } = useParams();
    const status = store.appDeal?.stage || 0;

    const isCreatePage = location.pathname === '/applications-and-deals/create';

    const {
        object_id,
        complex,
        house_number,
        object_number,
        floor_number,
        total_area,
        rooms_number,
        image,
        development_end,
        max_floor,
        priceFormat,
        bookingType,
        can_paid_booking,
    } = (location.state as IObjectData) || {};

    //// Effects
    useEffect(() => {
        window.scroll(0, 0);
    }, []);

    useEffect(() => {
        if (appDealId) {
            store.getAppDeal(appDealId);
        }
    }, [appDealId, store]);

    useEffect(() => {
        store.getPaymentTypes();
        store.getInstallmentLength();
    }, [store]);

    useEffect(() => {
        appDealId && chatStore.getMessages(appDealId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appDealId]);

    // set data effects
    useEffect(() => {
        store.setValues('complex', complex || '');
        store.setValues('houseNumber', house_number || '');
        store.setValues('objectNumber', object_number || '');
        store.setValues('floorNumber', floor_number || '');
        store.setValues('totalArea', total_area || '');
        store.setValues('roomsNumber', rooms_number || '');
        store.setValues('image', image || '');
        store.setValues('developmentEnd', development_end || '');
        store.setValues('maxFloor', max_floor || '');
        store.setValues('objectId', object_id || '');
        store.setValues('priceFormat', priceFormat || '');
        can_paid_booking && store.setValues('canPaidBooking', can_paid_booking);

        if (isCreatePage) {
            if (bookingType === 'paid' && can_paid_booking) {
                store.setValues('bookingType', 'paid');
            } else {
                store.setValues('bookingType', 'free');
            }
        } else {
            store.setValues('bookingType', bookingType);
        }

        //store.setObjectIsSelected(true);
    }, [
        object_id,
        complex,
        floor_number,
        house_number,
        object_number,
        rooms_number,
        store,
        total_area,
        image,
        development_end,
        max_floor,
        priceFormat,
        bookingType,
        can_paid_booking,
        location.state,
        isCreatePage,
    ]);

    useEffect(() => {
        if (isCreatePage && store.values.bookingType === 'paid' && !store.values.canPaidBooking) {
            showNotification('danger', 'Платная бронь не доступен для данного объекта');
            store.setValues('bookingType', 'free');
        }
    }, [isCreatePage, store, store.values.bookingType, store.values.canPaidBooking]);

    // Reset
    useEffect(() => {
        return () => {
            store.setObjectIsBookedModalOpen(false);
            store.resetData();
        };
    }, [history, store]);

    const handleBackToDeals = () => {
        history.push('/applications-and-deals#deals');
        // history.length === 1 ? history.push('/applications-and-deals#deals') : history.goBack();
    };

    const markAsRead = () => chatStore.markAsRead(appDealId);

    const handleOpenPayLinkModal = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();

        store.setPaymentLink(store.appDeal?.booking_payment_link!);
        store.setPaymentLinkModalOpen(true);
    };

    //// Renders
    const renderBackBtns = () => {
        switch (status) {
            case 0:
            case 25052917:
            case 15923527:
            default:
                return (
                    <TextButton color="primary" startIcon={<ArrowBackOutlinedIcon />} onClick={handleBackToDeals}>
                        К сделкам
                    </TextButton>
                );
        }
    };

    const renderTitle = () => {
        switch (status) {
            case 0:
                return 'Новая заявка на бронь';

            case 15923527:
                return 'Заявка на бронь';

            case 25052917:
            case 143:
                return 'Заявка на оформление';

            default:
                return `Сделка № ${store.appDeal?.subagent_number || ''}`;
        }
    };

    const renderNumber = () => {
        switch (status) {
            case 25052917:
            case 15923527:
            case 143:
                return (
                    <Box mb={4} width="fit-content">
                        <Paper variant="outlined" className={classes.numberData}>
                            <Box display="flex" p={1} flexBasis={378}>
                                <Box>
                                    <Typography variant="caption" color="textSecondary">
                                        Номер заявки
                                    </Typography>
                                    <Typography variant="subtitle1" className={classes.textUnderline}>
                                        {store.appDeal?.subagent_number}
                                    </Typography>
                                </Box>
                                <Box ml={4} mr={5}>
                                    <Typography variant="caption" color="textSecondary">
                                        Дата
                                    </Typography>
                                    <Typography variant="subtitle1">
                                        {store.appDeal?.subagent_date && store.appDeal?.subagent_date !== '0000-00-00' && moment(store.appDeal?.subagent_date, 'YYYY-MM-DD').format('DD.MM.YYYY')}
                                    </Typography>
                                </Box>
                            </Box>
                        </Paper>
                    </Box>
                );

            case 0:
                return null;

            default:
                return (
                    <Box mb={4} maxWidth={800}>
                        <Paper variant="outlined" className={classes.numberData}>
                            <Box display="flex" p={1}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={3}>
                                        <Typography variant="caption" color="textSecondary">
                                            Номер заявки
                                        </Typography>
                                        <Typography variant="subtitle1" className={classes.textUnderline}>
                                            {store.appDeal?.subagent_number}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <Typography variant="caption" color="textSecondary">
                                            Дата
                                        </Typography>
                                        <Typography variant="subtitle1" className={classes.textUnderline}>
                                            {store.appDeal?.subagent_date && store.appDeal?.subagent_date !== '0000-00-00'
                                                ? moment(store.appDeal?.subagent_date, 'YYYY-MM-DD').format('DD.MM.YYYY')
                                                : ''}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <Typography variant="body2" color="textSecondary">
                                            Сумма договора
                                        </Typography>
                                        <Typography>{store.appDeal?.contract_sum || '-'}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <Typography variant="body2" color="textSecondary">
                                            Агентское вознаграждение
                                        </Typography>
                                        <Typography>{store.appDeal?.agencySum || '-'}</Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Paper>
                    </Box>
                );
        }
    };

    const renderHelperText = () => {
        switch (status) {
            case 0:
            case 25052917:
                return (
                    <Box mb={{ xxs: 2, md: 4 }}>
                        <Typography variant="h4">Заполните следующую информацию:</Typography>
                    </Box>
                );

            default:
                return null;
        }
    };

    const renderObject = () => {
        switch (status) {
            case 0:
                return (
                    <AppDealObject
                        data={{
                            complex: store.values.complex,
                            objectNumber: store.values.objectNumber,
                            floorNumber: store.values.floorNumber,
                            houseNumber: store.values.houseNumber,
                            totalArea: store.values.totalArea,
                            roomsNumber: store.values.roomsNumber,
                            image: store.values.image,
                            developmentEnd: store.values.developmentEnd,
                            maxFloor: store.values.maxFloor,
                            priceFormat: store.values.priceFormat,
                        }}
                        isBookForm={true}
                    />
                );

            default:
                return (
                    <AppDealObject
                        data={{
                            complex: store.appDeal?.object?.complex || '',
                            objectNumber: store.appDeal?.object?.object_number || '',
                            floorNumber: store.appDeal?.object?.floor_number || '',
                            houseNumber: store.appDeal?.object?.house_number || '',
                            totalArea: store.appDeal?.object?.total_area || '',
                            roomsNumber: store.appDeal?.object?.rooms_number || '',
                            image: store.appDeal?.object?.images.length ? store.appDeal?.object?.images[0] : '',
                            developmentEnd: store.appDeal?.object?.development_end || '',
                            maxFloor: store.appDeal?.object?.max_floor || '',
                            priceFormat: store.appDeal?.object?.price_full || '',
                        }}
                    />
                );
        }
    };

    const renderClient = () => {
        switch (status) {
            case 0:
                return <AppDealSimpleClientForm />;

            case 25052917:
            case 143:
                return <AppDealClientForm />;

            default:
                return <AppDealClientView />;
        }
    };

    const renderComment = () => {
        switch (status) {
            case 0:
            case 25052917:
                return (
                    <Box p={2}>
                        <TextField
                            multiline
                            fullWidth
                            rows={3}
                            name="comment"
                            placeholder="Условия программы: ПВ, комментарии и т.д."
                            value={store.values.comment}
                            onChange={store.handleChangeForm}
                            InputProps={{ className: classes.commentInput, disableUnderline: true }}
                            disabled={store.loading}
                        />
                    </Box>
                );

            default:
                return (
                    <Box p={2} fontSize={16} lineHeight="24px" minHeight={117}>
                        {store.appDeal?.comment}
                    </Box>
                );
        }
    };

    const renderButton = () => {
        switch (status) {
            case 0:
                return (
                    <Box display="flex" justifyContent={{ xxs: 'center', md: 'flex-end' }}>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            size="large"
                            className={classes.sendBtn}
                            onClick={store.handleSendToBook}
                        >
                            {store.sendLoading ? <CircularProgress size={28} color="inherit" /> : 'Отправить заявку на бронь'}
                        </Button>
                    </Box>
                );

            case 25052917:
                return (
                    <Box display="flex" justifyContent={{ xxs: 'center', md: 'flex-end' }}>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            size="large"
                            className={classes.sendBtn}
                            onClick={() => store.handleSendToUpdate(appDealId)}
                        >
                            {store.sendLoading || store.fileUploading ? (
                                <CircularProgress size={28} color="inherit" />
                            ) : (
                                'Отправить заявку на оформление'
                            )}
                        </Button>
                    </Box>
                );

            default:
                return null;
        }
    };

    const renderSidebar = () => {
        return (
            <AppDealSidebar>
                <AppDealNav />
                {store.appDeal?.manager && (
                    <Box mt={4}>
                        <AppDealManager data={store.appDeal.manager} messages={chatStore.messages} markAsRead={markAsRead} />
                    </Box>
                )}
            </AppDealSidebar>
        );
    };

    const renderPaymentMethod = () => {
        switch (status) {
            case 0:
                return <AppDealPaymentForm />;

            case 25052917:
                return (
                    <>
                        <AppDealPaymentForm />
                        {store.values.mainPayment === 'Ипотека' && <AppDealPaymentBank />}
                        {store.values.mainPayment === 'Беспроцентная рассрочка' && <AppDealPaymentInstallment />}
                        {(store.values.mainPayment === '100% оплата' || store.values.mainPayment === 'Ипотека') && (
                            <AppDealPaymentOptions />
                        )}
                    </>
                );

            default:
                return (
                    <AppDealPaymentView
                        data={{
                            file_balance_certificate: store.appDeal?.file_balance_certificate || '',
                            file_maternity_certificate: store.appDeal?.file_maternity_certificate || '',
                            file_military_certificate: store.appDeal?.file_military_certificate || '',
                            file_mortgage_certificate: store.appDeal?.file_mortgage_certificate || '',
                            installmentPeriodCode: store.appDeal?.installmentPeriodCode,
                            paymentText: store.appDeal?.paymentText || '',
                            installmentItems: store.installmentItems,
                        }}
                    />
                );
        }
    };

    const renderPassportScan = () => {
        switch (status) {
            case 0:
                return <AppDealPassportScanForm />;

            // case 25052917:
            // case 143:
            //     return

            default:
                return <AppDealPassportScanView />;
        }
    };

    const renderPetition = () => {
        switch (status) {
            case 0:
                return <AppDealPetitionForm />;

            // case 25052917:
            // case 143:
            //     return

            default:
                return <AppDealPetitionView />;
        }
    };

    const renderPayLink = () => {
        if (store.appDeal?.booking_status === 'paid_wait' && store.appDeal?.booking_payment_link?.length) {
            return (
                <a href="/" onClick={handleOpenPayLinkModal}>
                    • ссылка на оплату
                </a>
            );
        }
    };

    const renderContent = () => {
        return (
            <>
                <Box mb={{ xxs: 2, md: 4 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="h2">{renderTitle()}</Typography>
                        </Grid>
                    </Grid>
                </Box>

                {status !== 0 && (
                    <Box mb={{ xxs: 2, md: 4 }}>
                        <Typography variant="body1">
                            Текущий статус: {store.appDeal?.stage_title.toLowerCase()} {renderPayLink()}
                        </Typography>
                    </Box>
                )}

                {renderNumber()}

                {renderHelperText()}

                {isSM && store.appDeal?.manager && (
                    <Box mb={2} width={256}>
                        <AppDealManager data={store.appDeal.manager} messages={chatStore.messages} markAsRead={markAsRead} />
                    </Box>
                )}

                <Box className={classes.navWrapper}>
                    {!isSM && renderSidebar()}

                    <Box width={832}>
                        <Element id="object" name="object">
                            {renderObject()}
                        </Element>

                        {isCreatePage && <AppDealBookingType />}

                        <Element id="client" name="client">
                            {renderClient()}
                        </Element>

                        <Box mb={{ xxs: 2, md: 4 }}>
                            <Paper className={classes.paper}>
                                <Box p={2} pt={3}>
                                    <Box mb={4}>
                                        <Typography variant="h3">Способ оплаты</Typography>
                                    </Box>
                                    <Element id="payment" name="payment">
                                        {renderPaymentMethod()}
                                    </Element>
                                </Box>
                            </Paper>
                        </Box>

                        {renderPassportScan()}

                        {renderPetition()}

                        <Box mb={{ xxs: 2, md: 4 }}>
                            <Paper className={classes.paper}>
                                <Box p={2} pt={3}>
                                    <Box mb={4}>
                                        <Typography variant="h3">Комментарий для менеджера</Typography>
                                    </Box>

                                    <Paper className={classes.commentPaper}>{renderComment()}</Paper>
                                </Box>
                            </Paper>
                        </Box>
                    </Box>
                </Box>

                {renderButton()}
            </>
        );
    };

    return (
        <Container className={classes.formContainer}>
            <Box mt={{ xxs: 4, md: 6 }} mb={{ xxs: 6, xs: 10, lg: 18 }}>
                <Box mb={{ xxs: 2, sm: 3 }}>{renderBackBtns()}</Box>

                {store.loading ? (
                    <Box
                        position="relative"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        flexDirection="column"
                        textAlign="center"
                        width="100%"
                        minHeight="31vh"
                    >
                        <Loader isAbsolute />
                    </Box>
                ) : (
                    renderContent()
                )}

                <AppDealObjectIsBooked open={store.objectIsBookedModalOpen} onClose={() => store.setObjectIsBookedModalOpen(false)} />

                <AppDealPayLinkModal />
            </Box>
        </Container>
    );
});

const useStyles = makeStyles(theme => ({
    paper: {
        borderRadius: 16,
        background: theme.palette.surface.main,
        boxShadow: 'none',
    },
    commentPaper: {
        borderRadius: 8,
        boxShadow: 'none',
        background: '#fff',
    },
    commentInput: {
        lineHeight: '24px',
    },
    sendBtn: {
        minWidth: 261,
    },
    navWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    numberData: {
        borderRadius: 8,
        //@ts-ignore
        borderColor: theme.palette.primary.A50,
    },
    textUnderline: {
        textDecoration: 'underline',
    },
    formContainer: {
        maxWidth: 1168,
    },
}));

export default AppDealForm;
