import React from 'react';
import { makeStyles, Paper, useMediaQuery, useTheme } from '@material-ui/core';

import { IComplexInfo } from '../../../types/CommonTypes';
import { Image } from './Image';
import { Banks } from './Banks';

export const ComplexInfo: React.FC<IComplexInfo> = ({ complex }) => {
    const classes = useStyle(complex?.banks?.length);
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Paper className={isMd ? classes.customPaper : classes.root}>
            <Image image={complex.image} address={complex.address} name={complex.name} />
            <Banks banks={complex.banks} />
        </Paper>
    );
};

const useStyle = (banksLen: number) =>
    makeStyles(theme => ({
        root: {
            display: 'flex',
            minHeight: banksLen > 2 ? 192 : 104,
        },
        customPaper: {
            display: 'block',
        },
    }))();
