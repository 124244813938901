import React from 'react';
import { observer } from 'mobx-react';
import { Box, Grid, Typography } from '@material-ui/core';
import clsx from 'clsx';

import { useStyles } from '../style';
import { useStores } from '../../../hooks/useStores';

import ButtonTab from '../../UI/ButtonTab';
import AppDealTabChangeModal from '../AppDealTabChangeModal';

const AppDealPaymentForm = observer(() => {
    const { AppDealFormStore: store } = useStores();
    const classes = useStyles();

    const renderMessage = () => {
        switch (store.values.mainPayment) {
            case '100% оплата':
                return <>Материнский капитал • Жилищный сертификат • Военный сертификат</>;
            case 'Ипотека':
                return <>Материнский капитал • Жилищный сертификат • Военный сертификат</>;
            case 'Беспроцентная рассрочка':
                return <>• Разрешение на проверку службой безопасности</>;
            case 'Trade-In':
                return 'Trade In';

            default:
                return null;
        }
    };

    return (
        <Box position="relative">
            {store.formErrors?.paymentCode && (
                <Box position="absolute" top="-24px">
                    <Typography variant="caption" color="error">
                        Способ оплаты не выбран
                    </Typography>
                </Box>
            )}
            <AppDealTabChangeModal
                showModal={store.showPaymentChangeModal}
                hideModal={store.hidePaymentChangeModal}
                setNewTab={store.proceedToNewTab}
            />

            <Box className={clsx(classes.wrapper, { [classes.errorBorderStyle]: store.formErrors?.payment })} mb={4} flexDirection="column">
                <Grid container spacing={1} alignItems="stretch">
                    <Grid item xs={12} sm={4} lg>
                        <ButtonTab
                            activeTab={store.values.mainPayment}
                            setActiveTab={store.setMainPayment}
                            name="100% оплата"
                            tabStyle={classes.tabStyle}
                            activeTabStyle={classes.activeTabStyle}
                            justifyContent="center"
                        >
                            <Typography variant="subtitle2">Вся сумма</Typography>
                        </ButtonTab>
                    </Grid>
                    <Grid item xs={12} sm={4} lg>
                        <ButtonTab
                            activeTab={store.values.mainPayment}
                            setActiveTab={store.setMainPayment}
                            name="Ипотека"
                            tabStyle={classes.tabStyle}
                            activeTabStyle={classes.activeTabStyle}
                            justifyContent="center"
                        >
                            <Typography variant="subtitle2">Ипотека</Typography>
                        </ButtonTab>
                    </Grid>
                    <Grid item xs={12} sm={4} lg>
                        <ButtonTab
                            activeTab={store.values.mainPayment}
                            setActiveTab={store.setMainPayment}
                            name="Беспроцентная рассрочка"
                            tabStyle={classes.tabStyle}
                            activeTabStyle={classes.activeTabStyle}
                        >
                            <Typography variant="subtitle2">Беспроцентная рассрочка</Typography>
                        </ButtonTab>
                    </Grid>
                    <Grid item xs={12} sm={4} lg>
                        <ButtonTab
                            activeTab={store.values.mainPayment}
                            setActiveTab={store.setMainPayment}
                            name="Trade-In"
                            tabStyle={classes.tabStyle}
                            activeTabStyle={classes.activeTabStyle}
                            justifyContent="center"
                        >
                            <Typography variant="subtitle2">Trade In</Typography>
                        </ButtonTab>
                    </Grid>
                </Grid>

                <Box mt={3}>
                    <Typography color="textSecondary" variant="caption">
                        {renderMessage()}
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
});

export default AppDealPaymentForm;
