import { makeStyles, Table, TableBody, TableCell, TableHead, TableRow, Theme, Typography } from '@material-ui/core';
import React from 'react';

import { IConsultants } from '../../types/FeedbackTypes';
import { transformNormalContentText } from '../../utils/transformNormalContentText';
import EmailLink from './EmailLink';
import PhoneLink from './PhoneLink';

interface IConsultantsTableProps {
    data: IConsultants[];
}

const ConsultantsTable: React.FC<IConsultantsTableProps> = ({ data }) => {
    const classes = useStyles();

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell width="20%" className={classes.tableThCell}>
                        <Typography variant="body2" color="textSecondary">
                            Имя
                        </Typography>
                    </TableCell>
                    <TableCell width="25%" className={classes.tableThCell}>
                        <Typography variant="body2" color="textSecondary">
                            Должность
                        </Typography>
                    </TableCell>
                    <TableCell width="35%" className={classes.tableThCell}>
                        <Typography variant="body2" color="textSecondary">
                            Обязанности
                        </Typography>
                    </TableCell>
                    <TableCell width="20%" className={classes.tableThCell}>
                        <Typography variant="body2" color="textSecondary">
                            Контакты
                        </Typography>
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map(item => (
                    <TableRow key={item.NAME}>
                        <TableCell width="20%" className={classes.tableTdCell}>
                            {item.NAME}
                        </TableCell>
                        <TableCell width="25%" className={classes.tableTdCell}>
                            {item.POSITION}
                        </TableCell>
                        <TableCell width="35%" className={classes.tableTdCell}>
                            <Typography
                                variant="body2"
                                dangerouslySetInnerHTML={{ __html: transformNormalContentText(item.DESCRIPTION) }}
                            />
                        </TableCell>
                        <TableCell width="20%" className={classes.tableTdCell}>
                            <EmailLink email={item.EMAIL} />
                            <PhoneLink phone={item.PHONE} />
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    tableThCell: {
        padding: theme.spacing(1, 2),
        borderBottom: 'unset',
    },
    tableTdCell: {
        padding: theme.spacing(2, 2),
    },
}));

export default ConsultantsTable;
