import React from 'react';
import { Typography, makeStyles, Box, useTheme, useMediaQuery } from '@material-ui/core';

import { IComplexBanks } from '../../types/CommonTypes';

export const BankItem: React.FC<IComplexBanks> = ({ PICTURE, NAME, PERCENT }) => {
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.down('md'));
    const classes = useStyle();
    return (
        <>
            {isMd ? (
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Box display="flex" alignItems="center">
                        <Box className={classes.imgWrapper}>
                            <img className={classes.bankImg} src={PICTURE} alt={NAME} />
                        </Box>

                        <Typography variant="h4" component="span">
                            {NAME}
                        </Typography>
                    </Box>

                    <Typography variant="subtitle1" color="textSecondary" component="span">
                        от {PERCENT}%
                    </Typography>
                </Box>
            ) : (
                <Box className={classes.root}>
                    <Box className={classes.imgWrapper}>
                        <img className={classes.bankImg} src={PICTURE} alt={NAME} />
                    </Box>

                    <Box display="flex" flexDirection="column">
                        <Typography variant="h4" component="span">
                            {NAME}
                        </Typography>

                        <Typography className={classes.customLineHeight} variant="subtitle1" color="textSecondary" component="span">
                            от {PERCENT}%
                        </Typography>
                    </Box>
                </Box>
            )}
        </>
    );
};

const useStyle = makeStyles(theme => ({
    root: {
        display: 'flex',
        minWidth: 100,
    },
    imgWrapper: {
        width: 48,
        height: 48,
        marginRight: 12,
        [theme.breakpoints.down('md')]: {
            marginRight: 16,
        },
    },
    bankImg: {
        width: '100%',
        height: '100%',
        objectFit: 'contain',
        objectPosition: 'center',
    },
    customLineHeight: {
        lineHeight: '15px',
    },
}));
