import React, { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Container } from '@material-ui/core';

import { useStores } from '../../hooks/useStores';
import Loader from 'components/Loader/Loader';
import { useSearchParams } from 'hooks/useSearchParams';
import { useHistory } from 'react-router-dom';

const AutologinScreen = observer(() => {
    const { AuthStore: store } = useStores();
    const history = useHistory();

    const searchParams = useSearchParams();

    const redirect = useCallback(() => {
        const path = searchParams.get('path');

        if (!path) {
            history.push('/learning');
            return;
        }
        window.location.href = path;
    }, []);

    // Effects
    useEffect(() => {
        if (store.isAuth) {
            redirect();
            return;
        }

        const userId = searchParams.get('userId');

        if (!userId) {
            return;
        }

        store.autoLogin({ user_id: userId }, redirect);
    }, [store.isAuth]);

    // Renders
    return (
        <Container maxWidth="xl">
            <Box minHeight="calc(100dvh - 70px)" display="flex" justifyContent="center" alignItems="center">
                <Loader />
            </Box>
        </Container>
    );
});

export default AutologinScreen;
