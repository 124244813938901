import React from 'react';
import { Box, Typography, Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

const Step2 = () => {
    const history = useHistory();

    return (
        <>
            <Box mb={{ xxs: 3, xs: 8 }} textAlign="center">
                <Typography variant="subtitle1" component="h1">
                    Ваша заявка принята!
                    <br />
                    Благодарим вас за регистрацию!
                </Typography>
            </Box>
            <Box mb={{ xxs: 4, xs: 7 }}>
                <Typography>Мы свяжемся с вами в ближайшее время</Typography>
            </Box>
            <Button fullWidth variant="contained" color="primary" size="large" onClick={() => history.push('/')}>
                На главную
            </Button>
        </>
    );
};

export default Step2;
