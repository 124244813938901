import axios from 'axios';

class AgencyAPI {
    callAgency: any = null;

    getAgency = (data: string) => {
        if (this.callAgency) {
            this.callAgency.cancel();
        }
        this.callAgency = axios.CancelToken.source();

        return axios.post(
            'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/party',
            JSON.stringify({ query: data, count: 20 }),
            {
                headers: {
                    Authorization: `Token ed564b7e8301b9210d4fe76d9ed163cc9a18ad52`,
                },
                cancelToken: this.callAgency.token,
            },
        );
    };
}

const agencyAPI = new AgencyAPI();
export default agencyAPI;
