import React from 'react';
import clsx from 'clsx';
import { Box } from '@material-ui/core';

const ButtonTab = (props: any) => {
    const { activeTab, setActiveTab, name, tabStyle, activeTabStyle, ...rest } = props || {};

    return (
        <Box
            size="small"
            className={clsx(tabStyle, { [activeTabStyle]: activeTab === name })}
            name={name}
            onClick={() => setActiveTab(name)}
            {...rest}
        >
            {props.children}
        </Box>
    );
};

export default ButtonTab;
