import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Grid, TextField, Typography, useMediaQuery, useTheme } from '@material-ui/core';

import { useStyles } from '../style';

import { RFBirthCertificateFormat, RFBirthCertificateSeriesFormat } from '../../UI/Form';
import { useStores } from '../../../hooks/useStores';
import DocumentItemForm from '../AppDealDocs/DocumentItemForm';

const AppDealBirthCertificateForm = observer(({ store }: any) => {
    const { AppDealFormStore: storeGlobal } = useStores();
    const theme = useTheme();
    const isSM = useMediaQuery(theme.breakpoints.down('sm'));
    const classes = useStyles();

    return (
        <Box mb={{ xxs: 2, md: 3 }}>
            <Box mb={2}>
                <Typography variant="body2">Свидетельство о рождении ребенка</Typography>
            </Box>
            <Grid container spacing={isSM ? 1 : 2}>
                <Grid item xs={12} sm={6} md={5} className={classes.paddingBottom}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        name="birthCertSerial"
                        label="Серия"
                        value={store.values.birthCertSerial}
                        onChange={store.handleChangeForm}
                        InputProps={{ inputComponent: RFBirthCertificateSeriesFormat, notched: false }}
                        disabled={store.loading}
                        // error={store.formErrors?.birthCertSerial}
                        // helperText={store.formErrors?.birthCertSerial && 'Введите серию свидетельства'}
                        // FormHelperTextProps={{ classes: { root: classes.errorFormTextInput } }}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={5} className={classes.paddingBottom}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        name="birthCertNumber"
                        label="Номер"
                        value={store.values.birthCertNumber}
                        onChange={store.handleChangeForm}
                        InputProps={{ inputComponent: RFBirthCertificateFormat, notched: false }}
                        disabled={store.loading}
                        // error={store.formErrors?.birthCertNumber}
                        // helperText={store.formErrors?.birthCertNumber && 'Введите номер свидетельства'}
                        // FormHelperTextProps={{ classes: { root: classes.errorFormTextInput } }}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <DocumentItemForm
                        objKey="birthCertScan"
                        defaultFilePath={storeGlobal.appDeal?.client?.file_birth_cert_scan || ''}
                        defaultFileUuid={storeGlobal.appDeal?.client?.birthCertScanUuid || ''}
                        store={store}
                    >
                        Скан свидетельства о рождении
                    </DocumentItemForm>
                </Grid>
                <Box mt={2} mb={1} ml={1}>
                    <Typography variant="body2">Согласие законного представителя / одного из родителей</Typography>
                </Box>
                <Grid item xs={12} sm={6}>
                    <DocumentItemForm
                        objKey="agreeParentScan"
                        defaultFilePath={storeGlobal.appDeal?.client?.file_agree_parent_scan || ''}
                        defaultFileUuid={storeGlobal.appDeal?.client?.agreeParentScanUuid || ''}
                        store={store}
                    >
                        Скан согласия
                    </DocumentItemForm>
                </Grid>
            </Grid>
        </Box>
    );
});

export default AppDealBirthCertificateForm;
