import { action, observable } from 'mobx';
import LearningsAPI from '../api/learningApi';
import { IPagination } from '../types/CommonTypes';
import { ILearningProgram } from '../types/LearningTypes';

export class LearningStore {
    @observable loading: boolean = false;
    @observable learningPrograms: ILearningProgram[] = [];

    @observable learningsPagination: IPagination | null = null;

    @action
    getLearnings = (offset: number, limit: number) => {
        this.loading = true;

        LearningsAPI.getLearnings(offset * limit, limit)
            .then(response => {
                if (offset === 0) {
                    this.learningPrograms = [...response?.data?.data?.items];
                } else {
                    this.learningPrograms = [...this.learningPrograms, ...response?.data?.data?.items];
                }

                this.learningsPagination = response?.data?.data?.pagination;
            })
            .finally(() => (this.loading = false));
    };
}
