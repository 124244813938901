import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
    filterWrap: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    modalFiltersWrap: {
        position: 'relative',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('lg')]: {
            backgroundColor: theme.palette.surface.main,
            '& .MuiInputBase-root': {
                backgroundColor: '#fff',
            },
            '& .MuiSelect-select:focus': {
                borderRadius: 8,
                backgroundColor: '#fff',
            },
            '& .MuiOutlinedInput-notchedOutline': {
                display: 'none',
            },
        },
    },
    scrollWrap: {
        height: '100%',
        overflowX: 'hidden',
    },
    complexSelect: {
        '& .MuiAutocomplete-inputRoot:not(.Mui-focused) .MuiChip-label': {
            width: 90,
        },
    },
    roomsNumberSelect: {
        '& .MuiAutocomplete-inputRoot:not(.Mui-focused) .MuiChip-label': {
            width: 91,
        },
    },
    devEndSelect: {
        '& .MuiAutocomplete-inputRoot:not(.Mui-focused) .MuiChip-label': {
            width: 91,
        },
    },
    radioGroup: {
        flexDirection: 'row',
    },
    radio: {
        display: 'none',
    },
    radioLabel: {
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 32,
        paddingLeft: 16,
        paddingRight: 16,
        marginLeft: 0,
        marginRight: 8,
        marginBottom: 8,
        borderRadius: 20,
        backgroundColor: 'rgba(0, 0, 0, 0.08)',
        transition: 'all ease 0.3s',
    },
    radioLabelChecked: {
        color: '#fff',
        backgroundColor: theme.palette.primary.main,
    },
    filterBtn: {
        minHeight: 47,
    },
    filterBtnLoader: {
        color: '#fff',
    },
    fixedBtn: {
        position: 'absolute',
        bottom: 16,
        left: 24,
        width: 266,
        [theme.breakpoints.down(1199)]: {
            width: 250,
        },
        [theme.breakpoints.down('md')]: {
            width: 'calc(100% - 48px)',
        },
        [theme.breakpoints.down('xs')]: {
            left: 16,
            width: 'calc(100% - 32px)',
        },
    },
    filterBtnDisabled: {
        pointerEvents: 'none',
    },
    filterButton: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 144,
        height: 48,
        //@ts-ignore
        border: `1px solid ${theme.palette.primary.A50}`,
        background: '#fff',
        borderRadius: 10,
        boxShadow: '0px 43px 58px rgba(94, 1, 198, 0.04), 0px 15px 19px rgba(0, 0, 0, 0.03), 0px 7.36424px 9px rgba(0, 0, 0, 0.01)',
        marginBottom: theme.spacing(2),
    },
    buttonText: {
        color: theme.palette.primary.main,
    },
    activeButtonText: {
        color: '#fff',
    },
    activeButton: {
        background: theme.palette.primary.main,
    },
    negativeMargin: {
        marginLeft: -48,
    },
    emptyTile: {
        //@ts-ignore
        background: theme.palette.primary.A50,
        width: 104,
        borderRadius: 4,
        height: 86,
        marginLeft: 4,
        marginTop: 4,
    },
    smallEmptyTile: {
        //@ts-ignore
        background: theme.palette.primary.A50,
        width: 48,
        height: 48,
        borderRadius: 8,
        marginLeft: 4,
        marginTop: 4,
    },
    tile: {
        // @ts-ignore
        ...theme.overrides.MuiPaper.elevation1,
        width: 104,
        height: 86,
        borderRadius: 4,
        marginLeft: 4,
        marginTop: 4,
        cursor: 'pointer',
    },
    smallTile: {
        // @ts-ignore
        ...theme.overrides.MuiPaper.elevation1,
        width: 48,
        height: 48,
        borderRadius: 8,
        marginLeft: 4,
        marginTop: 4,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
    },
    activeTile: {
        background: theme.palette.primary.main,
        color: '#fff',
    },
    tileData: {
        padding: theme.spacing(1),
        //@ts-ignore
        color: theme.palette.material.blackMedium,
        marginTop: 4,
    },
    activeTileText: {
        color: '#fff !important',
    },
    tileNumber: {
        ...theme.typography.h4,
        color: 'rgba(0, 0, 0, 0.87)',
        display: 'flex',
        justifyContent: 'space-between',
    },
    bookedTile: {
        //@ts-ignore
        border: `1px solid ${theme.palette.primary.disabled}`,
    },
    filteredTile: {
        border: 'none',
    },
    bookedText: {
        color: theme.palette.text.secondary,
    },
    filteredText: {
        //@ts-ignore
        color: theme.palette.material.overlay,
    },
    tileRooms: theme.typography.subtitle1,
    tileSize: theme.typography.body1,
    price: theme.typography.body2,
    fixedColumnCell: {
        display: 'flex',
        height: 86,
        width: 42,
        justifyContent: 'flex-end',
        alignItems: 'center',
        marginTop: 4,
    },
    smallFixedColumnCell: {
        display: 'flex',
        height: 48,
        width: 42,
        justifyContent: 'flex-end',
        alignItems: 'center',
        marginTop: 4,
    },
    smallTilesSeparator: {
        height: 48,
        width: 4,
        background: theme.palette.primary.main,
        borderRadius: 16,
    },
    tilesSeparator: {
        height: 86,
        width: 4,
        background: theme.palette.primary.main,
        borderRadius: 16,
    },
    floorNumber: {
        ...theme.typography.body1,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1),
    },
    marginRight: {
        marginRight: theme.spacing(2),
    },
    tableHeader: {
        background: '#ebe0f7',
        width: '100%',
        height: 24,
        marginBottom: theme.spacing(2),
        display: 'flex',
        justifyContent: 'center',
    },
    tableHeaderText: {
        fontSize: 12,
        lineHeight: '16px',
        letterSpacing: 0.4,
        paddingTop: 4,
        //@ts-ignore
        color: theme.palette.material.blackMedium,
        whiteSpace: 'nowrap',
        position: 'absolute',
        zIndex: 2,
    },
    tableHeaderSmallText: { whiteSpace: 'nowrap', position: 'absolute', zIndex: 2 },
    offsetTableHeaderText: {
        left: '70%',
    },
    offsetTableHeaderSmallText: {
        left: '50%',
    },
    smallHeader: {
        fontSize: 9,
    },
    leftBorders: {
        borderTopLeftRadius: 8,
        borderBottomLeftRadius: 8,
        marginLeft: theme.spacing(0.5),
    },
    rightBorders: {
        borderTopRightRadius: 8,
        borderBottomRightRadius: 8,
        marginRight: theme.spacing(2),
        width: 108,
    },
    smallRightBorders: {
        borderTopRightRadius: 8,
        borderBottomRightRadius: 8,
        marginRight: theme.spacing(2),
        width: 52,
    },
    sidebar: {
        maxWidth: 352,
        boxShadow: '-40px 0 20px rgba(255, 255, 255, 0.8)',
        zIndex: 10,
        [theme.breakpoints.down('sm')]: {
            margin: '20px auto',
        },
    },
    overflow: {
        overflowY: 'scroll',
    },
    toggleGridButton: {
        marginLeft: 20,
        cursor: 'pointer',
    },
    smallLockIcon: {
        position: 'absolute',
        top: 6,
        right: 6,
    },
    // textInput: {
    //     paddingTop: 15,
    //     paddingBottom: 14,
    // },
    menuPaper: {
        //@ts-ignore
        ...theme.overrides.MuiPaper.elevation3,
        borderRadius: 8,
        //@ts-ignore
        border: `1px solid ${theme.palette.primary.A50}`,
        left: 'calc(50% - 360px)',
        top: '28%',
    },
    object: {
        //@ts-ignore
        border: `1px solid ${theme.palette.primary.A50}`,
        borderRadius: 8,
        boxShadow: '0px 43px 58px rgba(94, 1, 198, 0.04), 0px 15px 19px rgba(0, 0, 0, 0.03), 0px 7.36424px 9px rgba(0, 0, 0, 0.01)',
        padding: '2px 10px',
        whiteSpace: 'nowrap',
        cursor: 'pointer',
        [theme.breakpoints.down('sm')]: {
            whiteSpace: 'normal',
        },
    },
    plainBackground: {
        background: '#fff',
    },
    emptyTileText: {
        //@ts-ignore
        color: theme.palette.primary.A100,
    },
    checkboxRoot: {
        display: 'none',
    },
    margin: {
        marginLeft: 0,
        marginBottom: 8,
    },
    badge: {
        background: '#FCD657',
        color: 'rgba(0, 0, 0, 0.87)',
    },
    autoWidthPopper: {
        [theme.breakpoints.down('xs')]: {
            width: 'auto !important',
            minWidth: '145px',
        },
    },
    priceWrapper: {
        display:'inline-block',
        position: 'relative',
    },
    commissionIcon: {
        position: 'absolute !important',
        top: '-10px',
        right: '-4px',
        zIndex: 99
    }
}));
