import { Box, CircularProgress, Dialog, IconButton, makeStyles, Theme } from '@material-ui/core';
import React, { ComponentRef, useCallback, useEffect, useRef, useState } from 'react';

import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import PrismaZoom from 'react-prismazoom';
import { ZoomIn, ZoomOut } from '@material-ui/icons';

export interface IImageViewerProps {
    open: boolean;
    src: string | null;
    onClose: () => void;
}

export const ImageViewer: React.FC<IImageViewerProps> = props => {
    const prismaZoom = useRef<ComponentRef<typeof PrismaZoom>>(null);
    const zoomCounterRef = useRef<HTMLSpanElement>(null);

    const [loading, setLoading] = useState(true);

    const classes = useStyles();

    //Effects
    useEffect(() => {
        if (props.open) {
            setLoading(true);
        }
    }, [props.open]);

    //Handlers
    const handleLoadingComplete = () => {
        setLoading(false);
    };

    const handleClickImage = (event: React.MouseEvent<HTMLImageElement>) => {
        event.stopPropagation();
    };

    const handleZoomChange = useCallback((zoom: number) => {
        if (!zoomCounterRef.current) {
            return;
        }
        zoomCounterRef.current.innerText = `${Math.round(zoom * 100)}%`;
    }, []);

    const handleClickOnZoomOut = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        prismaZoom.current?.zoomOut(1);
    };

    const handleClickOnZoomIn = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        prismaZoom.current?.zoomIn(1);
    };

    //Render
    return (
        <Dialog
            open={props.open}
            PaperProps={{ className: classes.paper, elevation: 24 }}
            onClose={props.onClose}
            onClick={props.onClose}
            scroll="body"
            fullScreen={true}
        >
            <Box position={'relative'} className={classes.container}>
                <Box className={classes.close}>
                    <IconButton className={classes.closeBtn} onClick={props.onClose}>
                        <CloseOutlinedIcon />
                    </IconButton>
                </Box>
                <Box className={classes.zoomController}>
                    <IconButton onClick={handleClickOnZoomOut}>
                        <ZoomOut />
                    </IconButton>
                    <span ref={zoomCounterRef} className={classes.zoomIndicator}>
                        100%
                    </span>
                    <IconButton onClick={handleClickOnZoomIn}>
                        <ZoomIn />
                    </IconButton>
                </Box>
                <PrismaZoom onZoomChange={handleZoomChange} ref={prismaZoom}>
                    {loading && (
                        <Box className={classes.absolute}>
                            <CircularProgress size={20} color={'primary'} />
                        </Box>
                    )}
                    {props.src && (
                        <img
                            alt={'img'}
                            src={props.src || ''}
                            className={classes.imageFit}
                            onClick={handleClickImage}
                            onLoad={handleLoadingComplete}
                        />
                    )}
                </PrismaZoom>
            </Box>
        </Dialog>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    close: {
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        right: 0,
        top: 0,
    },
    container: {
        width: '95vw',
        height: '95vh',
        maxWidth: '1400px',
        margin: 'auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    absolute: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        zIndex: 1400,
        transform: 'translate(-50%, -50%)',
    },
    paper: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        color: 'transparent',
        padding: 0,
        overflow: 'hidden',
    },
    closeBtn: {
        position: 'absolute',
        top: 24,
        right: 0,
        zIndex: 100,
        borderColor: theme.palette.secondary.main,
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 1)',
        },
        transform: 'translate(0, 50%)',
    },
    imageFit: {
        objectFit: 'cover',
        maxHeight: '93vh',
        backgroundColor: 'white',
    },
    zoomController: {
        position: 'absolute',
        bottom: 24,
        left: '50%',
        display: 'flex',
        alignItems: 'center',
        zIndex: 1400,
        backgroundColor: theme.palette.background.default,
        borderRadius: 4,
        transform: 'translate(-50%, 50%)',
    },
    zoomIndicator: {
        color: theme.palette.primary.main,
    },
}));
