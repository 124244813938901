import { observable, action, computed } from 'mobx';
import moment from 'moment';
import API from '../api';
import { downloadViaUrl } from '../utils/downloadViaUrl';
import { showNotification } from '../utils/Notification';

const getNumber = (s: string): number => +s.replace(/\D/g, '');
const getStep = (n: number): number => (n < 1_000_000 ? 50_000 : n < 10_000_000 ? 100_000 : 1_000_000);

export class CalculatorStore {
    @observable cost = 8_000_000;
    @observable contribution = 2_000_000;
    @observable timing = 20;
    @observable rate = 10.5;
    @observable pdfUrl = '';
    @observable loading = false;

    @action
    changeCost(value: string | number): void {
        if (typeof value === 'number') {
            this.cost = value;
            this.contribution = 0;
        }
        if (typeof value === 'string') {
            this.cost = Math.min(getNumber(value), 100_000_000);
            this.contribution = 0;
        }
    }

    @computed
    get getCostStep() {
        return getStep(this.cost);
    }

    @computed
    get paramsForDownload() {
        return { cost: this.cost };
    }

    @action
    changeContribution(value: string | number): void {
        if (typeof value === 'number') {
            this.contribution = value;
        }
        if (typeof value === 'string') {
            this.contribution = Math.min(getNumber(value), this.cost);
        }
    }

    @computed
    get getContributionPercent(): number {
        if (this.cost === 0) return 0;
        return (this.contribution * 100) / this.cost;
    }

    @action
    changeContributionPercent(value: string): void {
        const percent = Math.min(getNumber(value), 100);
        this.contribution = (percent * this.cost) / 100;
    }

    @computed
    get getContributinStep() {
        return getStep(this.contribution);
    }

    @action
    changeTiming(value: string | number): void {
        if (typeof value === 'number') {
            this.timing = value;
        }
        if (typeof value === 'string') {
            this.timing = Math.min(getNumber(value), 30);
        }
    }

    @action
    changeRate(value: string | number): void {
        if (typeof value === 'number') {
            this.rate = value;
        }
        if (typeof value === 'string') {
            this.rate = Math.min(getNumber(value), 99.9);
        }
    }

    @computed
    get firstPayment(): string {
        if (this.imposibleToCalculate()) return 'Расчет невозможен';

        const pr = this.rate / 100 / 12;
        const sum = this.cost - this.contribution;
        const time = this.timing * 12;
        const payment = sum * (pr + pr / (Math.pow(1 + pr, time) - 1));
        return payment.toLocaleString('ru-RU', { style: 'currency', currency: 'RUB', maximumFractionDigits: 2 });
    }

    @computed
    get closingDate(): string {
        if (this.imposibleToCalculate()) return 'Расчет невозможен';

        const currentDate = moment();
        return moment(currentDate)
            .add(this.timing * 12, 'M')
            .format('DD.MM.YYYY г.');
    }

    @action
    setParams(params: string[]): void {
        const [cost, contribution, timing, rate] = params.map(p => Number(p));

        this.cost = cost;
        this.contribution = contribution;
        this.timing = timing;
        this.rate = rate;
    }

    imposibleToCalculate(): boolean {
        return (
            0 === this.cost ||
            this.cost === 100_000_000 ||
            this.contribution === 0 ||
            this.contribution === this.cost ||
            this.timing <= 1 ||
            this.timing === 30 ||
            this.rate === 0 ||
            this.rate === 99.9
        );
    }

    @action
    downloadLink(): void {
        const params = { cost: this.cost, contribution: this.contribution, rate: this.rate, timing: this.timing };
        this.loading = true;
        API.apartment
            .calculatorFileLink(params)
            .then(response => {
                this.loading = false;
                this.pdfUrl = response.data.data.pdf_url;
                downloadViaUrl(response.data.data.pdf_url);
            })
            .catch(error => {
                console.log('error', error);
                showNotification('danger', 'Что-то пошло не так', 'bottom-right', 900);
                this.loading = false;
            })
            .finally(() => {
                this.loading = false;
            });
    }
}
