import React from 'react';
import { Box, Divider, Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';

// import { useStores } from '../../hooks/useStores';
import clsx from 'clsx';

const DocumentsForm = observer(() => {
    // const { DocumentsStore: store } = useStores();
    const classes = useStyle();

    return (
        <Paper className={classes.customPaper}>
            <Box p={1.75}>
                <Typography variant="h4">Реквизиты для вознаграждений</Typography>
            </Box>

            <Box mb={1.25}>
                <Grid container>
                    <Grid item xs={12} md={7} className={classes.customGrid}>
                        <Typography variant="caption" color="textSecondary">
                            Название
                        </Typography>
                        <Typography>Индивидуальный предприниматель Сафин Айнур Максутович</Typography>
                    </Grid>
                    <Grid item xs={6} className={clsx(classes.customGrid, classes.customContractWidth)}>
                        <Typography variant="caption" color="textSecondary">
                            Номер договора
                        </Typography>
                        <Typography>169</Typography>
                    </Grid>
                    <Grid item xs={6} className={clsx(classes.customGrid, classes.customContractWidth)}>
                        <Typography variant="caption" color="textSecondary">
                            Дата договора
                        </Typography>
                        <Typography>01.03.2019</Typography>
                    </Grid>
                    <Grid item xs={6} md={2} className={classes.customGrid}>
                        <Typography variant="caption" color="textSecondary">
                            ФИО директора
                        </Typography>
                        <Typography>Сафин А.М.</Typography>
                    </Grid>
                    <Grid item xs={10} className={classes.customGrid}>
                        <Typography variant="caption" color="textSecondary">
                            Действует на основании
                        </Typography>
                        <Typography>Свидетельства о Государственной регистрации в качестве Индивидуального предпринимателя</Typography>
                    </Grid>
                    <Grid item xs={9} className={classes.customGrid}>
                        <Typography variant="caption" color="textSecondary">
                            Адрес
                        </Typography>
                        <Typography>421001, г. Казань, ул. Бр. Касимовых, дом 66, кв. 44</Typography>
                    </Grid>
                </Grid>
            </Box>

            <Box mb={0.25} p={2}>
                <Divider />
            </Box>

            <Box>
                <Grid container>
                    <Grid item md={3} className={clsx(classes.customGrid, classes.customBankWidth)}>
                        <Typography variant="caption" color="textSecondary">
                            Банк
                        </Typography>
                        <Typography>АО «Тинькофф Банк»</Typography>
                    </Grid>
                    <Grid item xs={3} className={clsx(classes.customGrid, classes.customBankInfoWidth)}>
                        <Typography variant="caption" color="textSecondary">
                            БИК
                        </Typography>
                        <Typography>0000000000000000</Typography>
                    </Grid>
                    <Grid item xs={3} className={clsx(classes.customGrid, classes.customBankInfoWidth)}>
                        <Typography variant="caption" color="textSecondary">
                            ИНН
                        </Typography>
                        <Typography>0000000000000000</Typography>
                    </Grid>
                    <Grid item xs={3} className={clsx(classes.customGrid, classes.customBankInfoWidth)}>
                        <Typography variant="caption" color="textSecondary">
                            КПП
                        </Typography>
                        <Typography>0000000000000000</Typography>
                    </Grid>
                    <Grid item xs={3} className={clsx(classes.customGrid, classes.customBankInfoWidth)}>
                        <Typography variant="caption" color="textSecondary">
                            ОГРН/ОГРНИП
                        </Typography>
                        <Typography>0000000000000000</Typography>
                    </Grid>
                    <Grid item xs={3} className={clsx(classes.customGrid, classes.customBankInfoWidth)}>
                        <Typography variant="caption" color="textSecondary">
                            Р/C
                        </Typography>
                        <Typography>0000000000000000</Typography>
                    </Grid>

                    <Grid item xs={3} className={clsx(classes.customGrid, classes.customBankInfoWidth)}>
                        <Typography variant="caption" color="textSecondary">
                            К/С
                        </Typography>
                        <Typography>0000000000000000</Typography>
                    </Grid>
                </Grid>
            </Box>
        </Paper>
    );
});

export default DocumentsForm;

const useStyle = makeStyles(theme => ({
    customPaper: {
        boxShadow: 'none',
        borderRadius: 8,
    },
    customGrid: {
        padding: theme.spacing(1.75),
    },
    customContractWidth: {
        flexGrow: 0,
        flexBasis: 184,
        maxWidth: 184,
        [theme.breakpoints.down('xs')]: {
            flexGrow: 1,
            flexBasis: 'auto',
            maxWidth: 'none',
        },
    },
    customBankWidth: {
        flexGrow: 0,
        flexBasis: 304,
        maxWidth: 304,
        [theme.breakpoints.down('md')]: {
            flexBasis: 'auto',
            maxWidth: 'none',
        },
    },
    customBankInfoWidth: {
        flexGrow: 0,
        flexBasis: 230,
        maxWidth: 248,
        [theme.breakpoints.down('md')]: {
            flexBasis: 'auto',
            maxWidth: 'none',
        },
    },
}));
