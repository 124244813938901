import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { makeStyles, Paper } from '@material-ui/core';
import { Element } from 'react-scroll';
import clsx from 'clsx';

import { useStores } from '../../../hooks/useStores';

import ApartmentInfoMain from './ApartmentInfoMain';
import ApartmentInfoDetails from './ApartmentInfoDetails';

const ApartmentInfo = observer(() => {
    const { ApartmentStore: store } = useStores();
    const [showDetails, setShowDetails] = useState<boolean>(false);
    const classes = useStyles();

    const showCardDetails = () => setShowDetails(true);
    const hideCardDetails = () => setShowDetails(false);

    useEffect(() => {
        return () => {
            store.clearSelectedApartment();
        };
    }, [store]);

    return (
        <Paper variant="outlined" className={classes.borderColor}>
            <Element
                id="details"
                name="details"
                className={clsx(classes.container, showDetails && classes.showOverflow, store.selectedApartment && classes.fullHeight)}
            >
                <ApartmentInfoMain showCardDetails={showCardDetails} />
                <ApartmentInfoDetails hideCardDetails={hideCardDetails} />
            </Element>
        </Paper>
    );
});

const useStyles = makeStyles(theme => ({
    borderColor: {
        //@ts-ignore
        borderColor: theme.palette.primary.A100,
    },
    container: {
        height: 410,
        '&::-webkit-scrollbar': {
            width: 4,
            //@ts-ignore
            backgroundColor: theme.palette.primary.A50,
            borderRadius: 8,
        },
        '&::-webkit-scrollbar-thumb': {
            //@ts-ignore
            backgroundColor: theme.palette.primary.A200,
            width: 4,
            borderRadius: 8,
        },

        '&::-webkit-scrollbar-button': {
            height: 16,
            background: '#fff',
        },
    },
    showOverflow: {
        overflowY: 'scroll',
    },
    fullHeight: {
        height: 560,
        [theme.breakpoints.down('sm')]: {
            height: 520,
        },
    },
}));

export default ApartmentInfo;
