import React from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import { Avatar, Box, Divider, IconButton, lighten, ListItemIcon, makeStyles, Menu, MenuItem, Theme, Typography } from '@material-ui/core';
import PersonOutlinedIcon from '@material-ui/icons/PersonOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';

import avatar from '../../img/default-profile.png';
import { useStores } from '../../hooks/useStores';
import { useMenu } from '../../hooks/useMenu';

const ProfileMenu = observer(() => {
    const { ProfileStore: store, AuthStore } = useStores();

    const history = useHistory();
    const { anchorEl, handleOpen, handleClose } = useMenu();

    const classes = useStyles();

    const chooseOption = (e: any) => {
        handleClose();

        switch (e.currentTarget.dataset.value) {
            case 'profile':
                history.push('/profile');
                break;
            case 'settings':
                history.push('/profile/edit');
                break;
            case 'logout':
                store.resetStore();
                localStorage.removeItem('_abd_ratings');
                AuthStore.logout();
                break;
            default:
                break;
        }
    };

    return (
        <>
            <IconButton onClick={handleOpen} style={{ padding: 0 }}>
                <Avatar alt="" src={store.profileInfo?.data?.photo?.fullUrl || avatar} className={classes.avatar} />
            </IconButton>
            <Menu id="profile-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                <Box display="flex" alignItems="center" px={2} mb={2}>
                    <Box mr={1}>
                        <Avatar alt="" src={store.profileInfo?.data?.photo?.fullUrl || avatar} />
                    </Box>

                    <Typography variant="body2">{store.profileInfo?.data?.name}</Typography>
                </Box>
                <Box px={2}>
                    <Divider />
                </Box>
                <Box mt={2}>
                    <MenuItem onClick={chooseOption} data-value="profile">
                        <ListItemIcon style={{ marginTop: '-3px' }}>
                            <PersonOutlinedIcon fontSize="medium" />
                        </ListItemIcon>
                        <Typography variant="body2">Профиль</Typography>
                    </MenuItem>
                    <MenuItem onClick={chooseOption} data-value="settings">
                        <ListItemIcon style={{ marginTop: '-3px' }}>
                            <SettingsOutlinedIcon fontSize="medium" />
                        </ListItemIcon>
                        <Typography variant="body2">Настройки</Typography>
                    </MenuItem>
                    <MenuItem onClick={chooseOption} data-value="logout">
                        <ListItemIcon style={{ marginTop: '-3px' }}>
                            <ExitToAppOutlinedIcon fontSize="medium" />
                        </ListItemIcon>
                        <Typography variant="body2">Выйти</Typography>
                    </MenuItem>
                </Box>
            </Menu>
        </>
    );
});

const useStyles = makeStyles((theme: Theme) => ({
    avatar: {
        backgroundColor: lighten(theme.palette.material.overlay || '', 0.7),
    },
}));

export default ProfileMenu;
