import axios from 'axios';

class SystemAPI {
    getCompanyInfo = () => {
        return axios.get('/system/organization');
    };

    getDocsLinks = () => {
        return axios.get('/system/links-agreement');
    };
}

const systemAPI = new SystemAPI();
export default systemAPI;
