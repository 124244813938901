import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import { Box, Button, Grid, IconButton, makeStyles, Paper, Popover, Typography } from '@material-ui/core';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import CreateNewFolderOutlinedIcon from '@material-ui/icons/CreateNewFolderOutlined';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';

import { useStores } from '../../hooks/useStores';
import { useModal } from '../../hooks/useModal';

import FolderMoveIcon from '../UI/FolderMoveIcon';
import FavoriteFolderModal from './FavoriteFolderModal';

const FavoriteMoveMenu = observer(({ anchorEl, handleClose }: any) => {
    const { FavoriteStore: store } = useStores();
    const [selectedFolder, setSelectedFolder] = useState<number | null>(null);
    const { isOpen, openModal, closeModal } = useModal();
    const classes = useStyles();

    return (
        <Popover
            id="moveFolder-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={() => {
                handleClose();
                setSelectedFolder(null);
            }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            <Paper elevation={3} className={classes.container}>
                <Box p={2}>
                    <Box display="flex" justifyContent="space-between">
                        <Typography variant="subtitle1">Избранное</Typography>

                        <IconButton size="small" onClick={handleClose}>
                            <CloseOutlinedIcon />
                        </IconButton>
                    </Box>

                    <Grid container direction="column" wrap="nowrap" className={clsx('folders', classes.folderContainer)}>
                        <Grid
                            item
                            container
                            wrap="nowrap"
                            alignItems="center"
                            className={clsx(classes.folderItem, !selectedFolder && classes.selectedFolderItem)}
                            onClick={() => setSelectedFolder(null)}
                        >
                            <FavoriteBorderIcon className={classes.folderIcon} /> Общий раздел
                        </Grid>
                        {store.favorites?.folders.map(({ title, id }) => (
                            <Grid
                                key={id}
                                item
                                container
                                wrap="nowrap"
                                alignItems="center"
                                className={clsx(classes.folderItem, selectedFolder === id && classes.selectedFolderItem)}
                                onClick={() => setSelectedFolder(id)}
                            >
                                <FolderOpenIcon className={classes.folderIcon} />

                                <Typography variant="body2" noWrap>
                                    {title}
                                </Typography>
                            </Grid>
                        ))}
                        {!store.favorites?.folders.length && (
                            <Box margin="auto">
                                <Typography variant="h4" className={classes.noFoldersText}>
                                    Нет папок
                                </Typography>
                            </Box>
                        )}
                    </Grid>

                    <Grid container justify="space-between">
                        <Button color="primary" variant="contained" classes={{ root: classes.createFolder }} onClick={openModal}>
                            <CreateNewFolderOutlinedIcon />
                        </Button>

                        <Button
                            color="primary"
                            variant="contained"
                            className={classes.button}
                            startIcon={<FolderMoveIcon fontSize="large" />}
                            onClick={() => store.moveToFolder(selectedFolder)}
                        >
                            Переместить сюда
                        </Button>
                    </Grid>

                    <FavoriteFolderModal isOpen={isOpen} closeModal={closeModal} onSubmit={store.createAndMove} type="move" />
                </Box>
            </Paper>
        </Popover>
    );
});

const useStyles = makeStyles(theme => ({
    container: {
        width: 348,
    },
    button: {
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5),
        borderRadius: 10,
    },
    createFolder: {
        borderRadius: 10,
        padding: '8px 10px',
        minWidth: 'auto',
    },
    folderContainer: {
        padding: '8px 0',
        height: 226,
        overflowY: 'auto',
        marginBottom: theme.spacing(1),
    },
    folderItem: {
        padding: theme.spacing(1),
        border: '1px solid transparent',
        borderRadius: 8,
        width: '99%',
        '&:hover': {
            //@ts-ignore
            borderColor: theme.palette.primary.A200,
        },
        cursor: 'pointer',
    },
    selectedFolderItem: {
        border: `2px solid ${theme.palette.primary.main}`,
        '&:hover': {
            borderColor: theme.palette.primary.main,
        },
    },
    folderIcon: {
        marginRight: theme.spacing(2),
        //@ts-ignore
        color: theme.palette.material.blackMedium,
    },
    noFoldersText: {
        //@ts-ignore
        color: theme.palette.primary.A100,
    },
}));

export default FavoriteMoveMenu;
