import axios from 'axios';
import { ICalculatorParams } from '../types/CalculatorTypes';

class ApartmentAPI {
    call: any;

    getApartments = (offset: number, limit: number, filters: any) => {
        const callApi = () => {
            if (this.call) {
                this.call.cancel();
            }
            this.call = axios.CancelToken.source();

            let filterParams: string = '';
            for (let key in filters) {
                if (filters[key]) {
                    filterParams += `&${key}=${filters[key]}`;
                }
            }
            return axios.get(`/house/apartments?offset=${offset}&limit=${limit}${filterParams}`, { cancelToken: this.call.token });
        };

        return callApi();
    };

    getApartmentsTiles = (objectId: number, houseId: number) => {
        return axios.get(`house/object/${objectId}/${houseId}`);
    };

    getApartment = (id: number) => {
        return axios.get(`/house/apartment/${id}`);
    };

    getApartmentsCount = (filters: any) => {
        let filterParams: string = '';

        for (let key in filters) {
            if (filters[key]) {
                filterParams += `&${key}=${filters[key]}`;
            }
        }

        return axios.get(`/house/apartments-count?${filterParams}`);
    };

    getCoords = () => {
        return axios.get(`/house/map`);
    };

    downloadObject = (objectId: number[]) => {
        const ids = objectId.join(',');
        return axios.get(`/house/share-pdf?objectIds=${ids}`);
    };

    calculatorFileLink = (params: ICalculatorParams) => {
        return axios.post(`/house/mortgage-pdf`, params);
    };
}

const apartmentAPI = new ApartmentAPI();
export default apartmentAPI;
