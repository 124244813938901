export const setObjectEnding = (total: number): string => {
    // @ts-ignore
    const lastNumber = +total.toString().split('').pop();

    switch (lastNumber) {
        case 1:
            return 'объект';
        case 2:
        case 3:
        case 4:
            return 'объекта';
        default:
            return 'объектов';
    }
};
