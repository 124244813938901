export const formatDevEnd = (date: string): string => {
    const table: Record<string, string> = {
        '1': 'I',
        '2': 'II',
        '3': 'III',
        '4': 'IV',
    };
    const dateArr: string[] = date.split(' ');
    dateArr[0] = table[dateArr[0]];
    dateArr[1] = 'кв.';
    dateArr[3] = 'г.';
    return dateArr.join(' ');
};
