import React from 'react';
import { useTheme, useMediaQuery, Grid, makeStyles, Box } from '@material-ui/core';

import { IAccreditationBanks } from '../../../types/CalculatorTypes';
import BankSlider from './BankSlider';
import { BankAccordion } from '../../UI/BankAccordion';

export const Banks: React.FC<IAccreditationBanks> = ({ banks }) => {
    const theme = useTheme();
    const classes = useStyle();
    const isMd = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Box className={classes.bankSection}>
            {isMd ? (
                <Grid container className={classes.customGrid}>
                    <BankAccordion fullWidth banks={banks} itemsToShow={3} />
                </Grid>
            ) : (
                <Box>
                    <BankSlider data={banks} />
                </Box>
            )}
        </Box>
    );
};

const useStyle = makeStyles(theme => ({
    customGrid: {
        padding: `10px 24px`,
    },
    bankSection: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flex: '1 0 auto',
    },
}));
