import React from 'react';
import { Box, Paper, Typography, Grid } from '@material-ui/core';
import { observer } from 'mobx-react-lite';

import { useStyles } from '../style';
import { useStores } from '../../../hooks/useStores';
import { bookingTypes } from '../../../data/bookingTypes';
import { BookingType } from '../../../types/ApartmentTypes';
import AppDealBookingTypeCard from './AppDealBookingTypeCard';

interface IAppDealBookingType {}

const AppDealBookingType: React.FC<IAppDealBookingType> = observer(() => {
    const { AppDealFormStore: store } = useStores();

    const classes = useStyles();

    const handleSelect = (type: BookingType) => {
        store.setBookingType(type);
    };

    return (
        <Box mb={{ xxs: 2, md: 4 }}>
            <Paper className={classes.paper}>
                <Box p={2} pt={3}>
                    <Box mb={3}>
                        <Typography variant="h3">Способ бронирования</Typography>
                    </Box>

                    <Grid container spacing={2}>
                        {bookingTypes.map(item => (
                            <Grid item xs={12} md={6}>
                                <AppDealBookingTypeCard
                                    data={item}
                                    active={store.values.bookingType === item.id}
                                    onSelect={handleSelect}
                                    disabled={item.id === 'paid' && !store.values.canPaidBooking}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </Paper>
        </Box>
    );
});

export default AppDealBookingType;
