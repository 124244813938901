import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const QuestionIcon = (props: SvgIconProps) => (
    <SvgIcon {...props}>
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M0.333008 6.99992C0.333008 3.31992 3.31967 0.333252 6.99967 0.333252C10.6797 0.333252 13.6663 3.31992 13.6663 6.99992C13.6663 10.6799 10.6797 13.6666 6.99967 13.6666C3.31967 13.6666 0.333008 10.6799 0.333008 6.99992ZM7.66634 9.66659V10.9999H6.33301V9.66659H7.66634ZM6.99967 12.3333C4.05967 12.3333 1.66634 9.93992 1.66634 6.99992C1.66634 4.05992 4.05967 1.66659 6.99967 1.66659C9.93967 1.66659 12.333 4.05992 12.333 6.99992C12.333 9.93992 9.93967 12.3333 6.99967 12.3333ZM4.33301 5.66658C4.33301 4.19325 5.52634 2.99992 6.99967 2.99992C8.47301 2.99992 9.66634 4.19325 9.66634 5.66658C9.66634 6.52186 9.13966 6.98213 8.62685 7.43028C8.14035 7.85543 7.66634 8.26967 7.66634 8.99992H6.33301C6.33301 7.78574 6.96109 7.30431 7.5133 6.88103C7.9465 6.54899 8.33301 6.25273 8.33301 5.66658C8.33301 4.93325 7.73301 4.33325 6.99967 4.33325C6.26634 4.33325 5.66634 4.93325 5.66634 5.66658H4.33301Z"
              fill="#AC85E0" />
    </SvgIcon>
);

export default QuestionIcon;
