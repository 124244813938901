import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Box, Typography, TextField, Button } from '@material-ui/core';
import { PhoneFormat } from '../UI/Form';
import { useStores } from '../../hooks/useStores';
import { observer } from 'mobx-react-lite';
import { cleanupMask } from '../../utils/cleanupMask';
import { config } from "../../Config";
import { inputMaskHelper } from "../../utils/inputMaskHelper";

const Step1 = observer(() => {
    const { AuthStore: store } = useStores();
    const inputPhone = useRef<any>(null);

    const [phone, setPhone] = useState('');

    const handleChangePhone = (e: any) => {
        e = inputMaskHelper(e);
        // setPhone(CheckPhone(e.target.value));
        setPhone(e.target.value);
    };

    const handlePress = (e: any) => {
        e.preventDefault();

        store.resetPassReq({ login: cleanupMask(phone) });
    };

    return (
        <>
            <Box mb={{ xxs: 3, xs: 8 }} textAlign="center">
                <Typography variant="h3" component="h1">
                    Восстановление пароля
                </Typography>
            </Box>
            <Box mb={{ xxs: 3, xs: 5 }}>
                <Typography>
                    Для восстановления пароля введите номер мобильного телефона, привязанного к личному кабинету. Если вы еще не проходили
                    процедуру привязки номера к личному кабинету <Link to="/register">зарегистрируйтесь</Link>
                </Typography>
            </Box>
            <form noValidate>
                <Box mb={{ xxs: 3, xs: 5 }}>
                    <TextField
                        variant="outlined"
                        margin="none"
                        required
                        fullWidth
                        id="phone"
                        name="phone"
                        placeholder={config.phonePlaceholderWithZero}
                        autoFocus
                        value={phone}
                        InputProps={{
                            inputComponent: PhoneFormat,
                            notched: false,
                        }}
                        inputRef={inputPhone}
                        onFocus={() => {
                            setTimeout(() => {
                                inputPhone?.current?.setSelectionRange(3, 3);
                            }, 0);
                        }}
                        onChange={handleChangePhone}
                        error={!!store.resetPassErrors?.login}
                        helperText={store.resetPassErrors?.login}
                        disabled={store.loading}
                    />
                </Box>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={handlePress}
                    disabled={store.loading}
                >
                    Далее
                </Button>
            </form>
        </>
    );
});

export default Step1;
