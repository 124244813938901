import { observable, action, computed } from 'mobx';
import moment from 'moment';
import {
    IAppDealState,
    IPaymentTypes,
    IAppDealState1,
    IAppDealFormDataPaymentDocs,
    IAppDealPaymentDocs,
    IAppDealFormData,
    IAppDealParticipantStore,
    IAppDealIndexItem,
    IAppDealInstallmentItem,
    IAppDealSimpleClientForm,
    IAppDealProperty,
} from '../types/AppDealTypes';
import API from '../api';
import { AppDealFormClientStore } from './AppDealFormClientStore';

import { cleanupMask } from '../utils/cleanupMask';
import { showNotification } from '../utils/Notification';
import { stores } from '../contexts';
import { inputMaskHelper } from '../utils/inputMaskHelper';
import { BookingType } from '../types/ApartmentTypes';

const initFormData: IAppDealState = {
    objectId: '',
    complex: '',
    objectNumber: '',
    floorNumber: '',
    houseNumber: '',
    totalArea: '',
    roomsNumber: '',
    image: '',
    developmentEnd: '',
    client: {
        firstname: '',
        surname: '',
        lastname: '',
        phone: '',
    },
    formFieldsStatus: {
        complex: false,
        client: false,
        payment: false,
    },
    files: {
        matCapitalScan: { scanType: 489571, file: '' },
        matCapitalCashScan: { scanType: 489573, file: '' },
        housingCertScan: { scanType: 489575, file: '' },
        militaryCertScan: { scanType: 489615, file: '' },
        mortgageScan: { scanType: 422391, file: '' },
        petitionScan: { scanType: 492998, file: '' },
    },
    mainPayment: '',
    secondaryPayment: 'noBenefits',
    paymentCode: null,
    bankCode: null,
    bankClerkEmail: '',
    comment: '',
    installmentPeriodCode: null,
    installmentLength: null,
    installmentFirstPayment: '',
    installmentMaxLength: 0,
    personalDataAgreement: false,
    maxFloor: '',
    priceFormat: '',
    bookingType: 'free',
    canPaidBooking: true,
};

export class AppDealFormStore {
    @observable loading = false;
    @observable fieldLoading = false;
    @observable sendLoading = false;
    @observable fileUploading = false;

    // Errors state
    @observable formErrors: any = {};

    // Form state
    @observable appDeal: IAppDealState1 | null = null;
    @observable paymentTypes: IPaymentTypes[] = [];
    @observable values: IAppDealState = initFormData;
    @observable uploadedFilesUuid: IAppDealFormDataPaymentDocs = {
        matCapitalScanUuid: '',
        matCapitalCashScanUuid: '',
        housingCertScanUuid: '',
        militaryCertScanUuid: '',
        mortgageScanUuid: '',
        petitionScanUuid: '',
    };
    @observable activePropertyTab: IAppDealProperty = 'personal';
    @observable maxNumberOfParticipants: number = 1;
    @observable minNumberOfParticipants: number = 2;
    @observable participantIndex: number = 2;
    @observable participants: IAppDealParticipantStore[] = [
        {
            index: 1,
            store: new AppDealFormClientStore(),
        },
    ];
    @observable maxShare: number = 0;
    @observable modalMessage: any = '';
    @observable monthlyPayment: string = '';
    @observable installmentEndDate: string = '';
    @observable banks: IAppDealIndexItem[] = [];
    @observable installmentItems: IAppDealInstallmentItem[] = [];
    @observable propertyTypes: IAppDealIndexItem[] = [];
    @observable showPaymentChangeModal: boolean = false;
    @observable proceedToNewTab: any = null;
    @observable isDepositorSet: boolean = false;

    // Modals state
    @observable objectIsBookedModalOpen: boolean = false;
    // Payment link modal
    @observable paymentLinkModalOpen: boolean = false;
    @observable paymentLink: string | null = null;

    @action
    setIsDepositorSet = (value: boolean) => (this.isDepositorSet = value);

    @action
    hidePaymentChangeModal = (): boolean => (this.showPaymentChangeModal = false);

    @action
    setActivePropertyTab = (name: IAppDealProperty) => {
        this.modalMessage = this.setModalMessage(name);
        if (this.modalMessage) return;
        if (this.activePropertyTab === 'part') {
            this.maxShare = 0;
            this.participants.forEach(({ store }) => store.resetPropertyPercent());
        }
        this.activePropertyTab = name;
        this.maxNumberOfParticipants = this.setMaxNumberOfParticipants;
        if (name === 'together') {
            this.participants.forEach(({ store }) => store.setClientToOver18Married());
        }
        this.participants.forEach(({ store }) => {
            store.resetFormErrors();
            store.checkIfFilled();
        });
        this.checkIfClientFilled();
    };

    @action
    setMainPayment = (id: string) => {
        const isAnyMortgageValueFilled = !!this.values.bankCode || !!this.values.bankClerkEmail || !!this.values.files.mortgageScan.file;
        if (this.values.mainPayment === 'Ипотека' && isAnyMortgageValueFilled) {
            this.showPaymentChangeModal = true;
            this.proceedToNewTab = this.proceedToChangeMainPayment.bind(null, id);
        } else {
            this.setValues(`mainPayment`, id);
            this.formErrors = {};
        }

        if (id === 'Беспроцентная рассрочка' || id === 'Trade-In') {
            this.setSecondaryPayment('noBenefits');
            this.clearOptionsFileErrors();
        }

        this.setPaymentCode();
        this.checkDealPaymentStatus();
        this.getRidOfPaymentError();
    };

    @action
    setSecondaryPayment = (id: string) => {
        const ifAnyMatCapitalValueFilled = (): boolean => {
            return (
                !!this.values.files.matCapitalCashScan.file ||
                !!this.values.files.matCapitalScan.file ||
                !!this.values.files.housingCertScan.file ||
                !!this.values.files.militaryCertScan.file
            );
        };

        if (this.values.secondaryPayment !== 'noBenefits' && ifAnyMatCapitalValueFilled()) {
            this.showPaymentChangeModal = true;
            this.proceedToNewTab = this.proceedToChangeSecondaryPayment.bind(null, id);
        } else {
            this.setValues('secondaryPayment', id);
            this.formErrors = {};
        }

        this.setPaymentCode();
        this.checkDealPaymentStatus();
        this.clearOptionsFileErrors();
    };

    proceedToChangeMainPayment = (id: string) => {
        this.setValues(`mainPayment`, id);
        this.showPaymentChangeModal = false;
        this.formErrors = {};
        this.resetMortgageValues();
    };

    proceedToChangeSecondaryPayment = (id: string) => {
        this.setValues('secondaryPayment', id);
        this.showPaymentChangeModal = false;
        this.formErrors = {};
        this.resetOptionsFiles();
    };

    resetMortgageValues = (): void => {
        this.values.bankCode = null;
        this.values.bankClerkEmail = '';
        this.values.files.mortgageScan = initFormData.files.mortgageScan;
    };

    resetOptionsFiles = (): void => {
        this.values.files.matCapitalScan = initFormData.files.matCapitalScan;
        this.values.files.matCapitalCashScan = initFormData.files.matCapitalCashScan;
        this.values.files.housingCertScan = initFormData.files.housingCertScan;
        this.values.files.militaryCertScan = initFormData.files.militaryCertScan;
    };

    clearOptionsFileErrors = (): void => {
        this.formErrors.matCapitalScan = false;
        this.formErrors.matCapitalCashScan = false;
        this.formErrors.housingCertScan = false;
        this.formErrors.militaryCertScan = false;
    };

    setPaymentCode = () => (this.values.paymentCode = this.setTotalPaymentCode);

    @computed
    get setTotalPaymentCode(): number {
        if (this.values.secondaryPayment === 'noBenefits') {
            return this.paymentTypes.filter(paymentType => paymentType.name === this.values.mainPayment)[0].code;
        }
        return this.paymentTypes.filter(
            paymentType => paymentType.name.includes(this.values.mainPayment) && paymentType.name.includes(this.values.secondaryPayment),
        )[0]?.code;
    }

    @action
    setModalMessage(name: string) {
        switch (name) {
            case 'personal': {
                if (this.participants.length > 1) {
                    return 'Чтобы сменить на тип собственности "Индивидуальная собственность", вам нужно оставить одного участника';
                }
                break;
            }
            case 'together': {
                if (this.participants.length > 2) {
                    return 'Чтобы сменить на тип собственности "Для супругов", вам нужно оставить двух участников';
                }
                if (this.checkAge()) {
                    return 'Пожалуйста, убедитесь, что участнику сделки больше 18 лет';
                }
                break;
            }

            default:
                return '';
        }
    }

    @action
    clearMessage = () => (this.modalMessage = '');

    @action
    checkAge = () => {
        return this.participants.filter(({ store }) => store.activeClientTab === 'under14' || store.activeClientTab === 'over14').length;
    };

    @action
    addParticipant = () => {
        if (this.maxNumberOfParticipants <= this.participants.length) return;
        this.participants.push({
            index: this.participantIndex++,
            store: new AppDealFormClientStore(),
        });
        this.checkIfClientFilled();
    };

    @action
    removeParticipant = (id: number) => {
        this.participants = this.participants.filter(({ index, store }) => {
            if (index === id) {
                this.maxShare -= +store.values.propertyPercent || 0;
            }
            return index !== id;
        });
        this.participantIndex -= 1;
        this.checkIfClientFilled();
    };

    @action
    setInstallmentMaxLength = () => {
        return moment(this.appDeal?.object?.development_end_date).diff(moment(), 'months');
    };

    @action
    addToMaxShare = (value: number) => (this.maxShare += +value);

    @computed
    get setMaxNumberOfParticipants(): number {
        if (this.activePropertyTab === 'together') return 2;
        if (this.activePropertyTab === 'part') return 20;
        return 1;
    }

    @action
    checkBookClientStatus = () => {
        this.values.formFieldsStatus.client =
            !!this.values.client.firstname && !!this.values.client.surname && !!this.values.client.lastname && !!this.values.client.phone;
    };

    checkDealComplexStatus = () => {
        this.values.formFieldsStatus.complex = !!this.appDeal?.object;
    };

    checkDealPaymentStatus = () => {
        if (this.appDeal?.stage) {
            this.values.formFieldsStatus.payment = this.checkPaymentFields;
        } else {
            this.values.formFieldsStatus.payment = !!this.values.paymentCode;
        }
    };

    @computed
    get checkPaymentFields(): boolean {
        const maternalCapitalFiles = (): boolean => !!this.values.files.matCapitalScan.file && !!this.values.files.matCapitalCashScan.file;
        const housingCertificateFiles = (): boolean => !!this.values.files.housingCertScan.file;
        const militaryCertificateFiles = (): boolean => !!this.values.files.militaryCertScan.file;
        const isMortgageFilled = (): boolean =>
            !!this.values.bankCode && !!this.values.bankClerkEmail && !!this.values.files.mortgageScan.file;
        const isInstallmentFilled = (): boolean =>
            !!this.values.installmentPeriodCode && !!this.values.installmentFirstPayment && this.values.personalDataAgreement;

        switch (this.values.paymentCode) {
            case 836019:
            case 881373:
                return true;

            case 836021:
                return maternalCapitalFiles();

            case 939715:
                return housingCertificateFiles();

            case 939717:
                return militaryCertificateFiles();

            case 836023:
                return isMortgageFilled();

            case 939719:
                return isMortgageFilled() && maternalCapitalFiles();

            case 939721:
                return isMortgageFilled() && housingCertificateFiles();

            case 939723:
                return isMortgageFilled() && militaryCertificateFiles();

            case 836025:
                return isInstallmentFilled();

            default:
                return false;
        }
    }

    @computed
    get setPropertyTypeCode(): string {
        if (this.activePropertyTab === 'personal' && this.participants.some(({ store }) => store.activeClientTab === 'over18Married')) {
            return 'personal_married';
        }

        if (
            this.activePropertyTab === 'personal' &&
            this.participants.some(({ store }) => store.activeClientTab === 'under14' || store.activeClientTab === 'over14')
        ) {
            return 'children';
        }

        return this.activePropertyTab;
    }

    @action
    checkIfClientFilled = () => {
        this.values.formFieldsStatus.client = this.participants.every(participant => participant.store.isFilled);
    };

    @action
    checkIfFilled = () => this.checkDealPaymentStatus();

    @computed
    get setClientsData(): any {
        return [
            ...this.participants.map(({ store }) => {
                const { contactId, propertyPercent, inn, snils, depositor, birthCertNumber, birthCertSerial } = store.values;
                const { birthDate, birthPlace, dateOfIssue, issuedBy, number, placeOfResidence, series, unitCode } = store.values.passport;
                const { email, firstname, lastname, surname, phone } = store.values.mainInfo;

                const {
                    passportScanOtherUuid,
                    innScanUuid,
                    snilsScanUuid,
                    birthCertScanUuid,
                    agreeParentScanUuid,
                    notarialScanUuid,
                    marriageScanUuid,
                } = store.uploadedFilesUuid;

                return {
                    contactId: contactId || null,
                    name: firstname,
                    surname,
                    lastname,
                    phone: phone.replace(/\D/g, ''),
                    propertyPercent: propertyPercent || null,
                    passport: `${series || ''}${number || ''}`,
                    passportDate: dateOfIssue ? dateOfIssue.split('.').reverse().join('-') : '',
                    inn,
                    snils,
                    passportIssued: issuedBy,
                    passportRegPlace: placeOfResidence,
                    passportScanMainUuid: store.uploadedFilesUuid.passportScanMainUuid,
                    passportScanOtherUuid,
                    innScanUuid,
                    snilsScanUuid,
                    agreeParentScanUuid,
                    birthCertScanUuid,
                    marriageScanUuid,
                    notarialScanUuid,
                    ageCategory: 'какая-то строка',
                    depositor: depositor ? 1 : 0,
                    birthCertNumber,
                    birthCertSerial,
                    passportDepartmentCode: unitCode,
                    birthday: birthDate ? birthDate.split('.').reverse().join('-') : '',
                    birthPlace,
                    email,
                };
            }),
        ];
    }

    @computed
    get formData(): IAppDealFormData {
        return {
            statusId: 20071693,
            paymentCode: this.values.paymentCode,
            propertyTypeCode: this.setPropertyTypeCode,
            bankCode: this.values.bankCode,
            bankEmployeeEmail: this.values.bankClerkEmail,
            installmentPeriodCode: this.values.installmentPeriodCode,
            firstPayment: +this.values.installmentFirstPayment,
            matCapitalScanUuid: this.uploadedFilesUuid.matCapitalScanUuid,
            matCapitalCashScanUuid: this.uploadedFilesUuid.matCapitalCashScanUuid,
            housingCertScanUuid: this.uploadedFilesUuid.housingCertScanUuid,
            militaryCertScanUuid: this.uploadedFilesUuid.militaryCertScanUuid,
            mortgageScanUuid: this.uploadedFilesUuid.mortgageScanUuid,
            comment: this.values.comment,
            contactData: this.setClientsData,
        };
    }

    // Get
    @action
    getBanks = () => {
        API.appDeals.getBanks().then(response => {
            if (response?.data?.success) {
                this.banks = response.data.data;
            }
        });
    };

    @action
    getAppDeal = (id: number) => {
        this.loading = true;
        //26026935
        API.appDeals
            .getAppDeal(id)
            .then(response => {
                if (response?.data?.success) {
                    this.appDeal = response.data.data;
                    response.data.data.client && this.participants[0].store.setInitialClientData(response.data.data.client);
                    this.setValues('installmentMaxLength', this.setInstallmentMaxLength());
                    this.checkDealComplexStatus();
                    this.checkDealPaymentStatus();
                }
            })
            .finally(() => {
                this.loading = false;
            });
    };
    @action
    getPaymentTypes = () => {
        this.fieldLoading = true;

        API.appDeals
            .getPaymentTypes()
            .then(response => {
                if (response?.data?.success) {
                    this.paymentTypes = response.data.data;
                }
            })
            .finally(() => {
                this.fieldLoading = false;
            });
    };

    @action
    getPropertyTypes = () => {
        API.appDeals.getPropertyTypes().then(response => {
            if (response?.data?.success) {
                this.propertyTypes = response.data.data;
            }
        });
    };

    @action
    getInstallmentLength = () => {
        API.appDeals.getInstallmentLength().then(response => {
            if (response?.data?.success) {
                this.installmentItems = response.data.data;
            }
        });
    };

    // Set form data
    @action
    setValues = (name: string, value: any) => {
        if (name === 'complex' && value) {
            this.values.formFieldsStatus.complex = true;
        }
        (this.values as any)[name] = value;
    };

    setBank = (value: number) => {
        this.values.bankCode = value;
        this.formErrors.bankCode = false;
        this.checkDealPaymentStatus();
    };

    @action
    setInstallmentCode = (value: any) => {
        this.formErrors.installmentLength = false;
        this.values.installmentPeriodCode = value?.code;
        this.values.installmentLength = value?.month;
        this.formErrors.installmentLength = false;
        this.installmentEndDate = this.setInstallmentEndDate;
        this.monthlyPayment = this.setMonthlyPayment;
        if (value.month > this.values.installmentMaxLength) {
            this.formErrors.installmentLength = 'Превышает срок сдачи';
        }
    };

    @action
    handleInstallmentDataChange = (event: any) => {
        this.formErrors.installmentFirstPayment = false;
        if (event.target.value < 0) return;

        if (this.appDeal?.object.price) {
            if (event.target.value < 0.5 * this.appDeal?.object.price)
                this.formErrors.installmentFirstPayment = 'Введите минимум 50% от стоимости квартиры';
            if (event.target.value > this.appDeal?.object.price) {
                this.formErrors.installmentFirstPayment = 'Превышает стоимость квартиры';
                return;
            }
        }
        (this.values as any)[event.target.name] = event.target.value;

        this.monthlyPayment = this.setMonthlyPayment;
        this.checkDealPaymentStatus();
    };

    @computed
    get setInstallmentEndDate(): any {
        const data = moment().add(this.values.installmentLength, 'M').format('MMMM YYYY');
        return data[0].toUpperCase() + data.slice(1);
    }
    @computed
    get setMonthlyPayment(): string {
        return `${
            // @ts-ignore
            ((this.appDeal?.object.price - this.values.installmentFirstPayment) / (this.values.installmentLength || 1)).toFixed(0)
        } ₽`;
    }

    // Change form
    @action
    handleChangeForm = (event: any) => {
        (this.values as any)[event.target.name] = event.target.value;
        this.formErrors[event.target.name] = false;
        this.checkDealPaymentStatus();
    };

    handleClientChange = (event: any) => {
        event = inputMaskHelper(event);
        this.values.client[event.target.name as keyof IAppDealSimpleClientForm] = event.target.value;
        this.formErrors[event.target.name as keyof IAppDealSimpleClientForm] = false;
        this.checkBookClientStatus();
    };

    @action
    toggleAgreementCheckbox = () => {
        this.values.personalDataAgreement = !this.values.personalDataAgreement;
        this.checkDealPaymentStatus();
    };

    @action
    setFile = (key: string, file: any) => {
        this.values.files[key as keyof IAppDealPaymentDocs].file = file;
        this.formErrors[key as keyof IAppDealPaymentDocs] = false;
    };

    @action
    setUuid = (key: string, uuid: string) => {
        const uuidKey = `${key}Uuid` as keyof IAppDealFormDataPaymentDocs;
        this.uploadedFilesUuid[uuidKey] = uuid;
    };

    @action
    resetData = () => {
        this.appDeal = null;
        this.formErrors = {};
        this.values = initFormData;
        this.participants.forEach(({ store }) => store.resetData());
    };

    @action
    handleSendToBook = (): void => {
        if (this.sendLoading) {
            showNotification('info', 'Идет загрузка данных. Пожалуйста, подождите...');
            return;
        }

        if (this.validateBookForm()) {
            return;
        }

        this.createAppDeal();
    };

    validateBookForm = (): boolean => {
        this.validateBookInputs();
        this.validateObject();
        this.validatePayment();
        // this.validatePassportScans();
        this.validatePetition();
        const passErrors = this.validateParticipantPassportScans();
        let errors = this.formErrors;

        if (passErrors?.length) {
            Object.assign(errors, passErrors[0]);
        }

        return Object.values(errors).some(item => item);
    };

    validateBookInputs = (): void => {
        Object.entries(this.values.client).forEach(([key, value]) => {
            if (!value) {
                this.formErrors[key] = true;
            }
        });
    };

    validateObject = (): void => this.validateValue('objectId');

    validatePayment = (): void => this.validateValue('paymentCode');

    // validatePassportScans = (): void => {
    //     this.validateFile('passportScanMain');
    //     this.validateFile('passportScanOther');
    // }

    validatePetition = (): void => this.validateFile('petitionScan');

    validateValue = (value: string): void => {
        if (!this.values[value as keyof IAppDealState]) {
            this.formErrors[value] = true;
        }
    };

    validateFile = (name: string): void => {
        if (!this.values.files[name as keyof IAppDealPaymentDocs].file) {
            this.formErrors[name] = true;
        }
    };

    @action
    handleSendToUpdate = (appDealId: number): void => {
        if (this.sendLoading) {
            showNotification('info', 'Идет загрузка данных. Пожалуйста, подождите...');
            return;
        }
        if (this.fileUploading) {
            showNotification('info', 'Идет загрузка файлов. Пожалуйста, подождите...');
            return;
        }

        let isValid = 0;

        if (this.validateApplicationParticipants()) {
            isValid++;
        }

        if (this.validateApplicationPayment()) {
            isValid++;
        }

        isValid === 2 && this.updateAppDeal(appDealId);
    };

    validateApplicationParticipants = (): boolean => {
        return this.participants.map(({ store }) => store.validateParticipantForm()).some(item => !item);
    };

    validateParticipantPassportScans = () => {
        return this.participants.map(({ store }) => store.validateParticipantPassportScans());
    };

    validateApplicationPayment = (): boolean => {
        this.validatePayment();

        switch (this.values.paymentCode) {
            case 836021: {
                this.validateMatCapitalFiles();
                break;
            }
            case 939715: {
                this.validateHousingCert();
                break;
            }
            case 939717: {
                this.validateMilitaryCert();
                break;
            }
            case 836025: {
                this.validateInstallment();
                break;
            }
            case 836023: {
                this.validateMortgage();
                break;
            }
            case 939719: {
                this.validateMortgage();
                this.validateMatCapitalFiles();
                break;
            }
            case 939721: {
                this.validateMortgage();
                this.validateHousingCert();
                break;
            }
            case 939723: {
                this.validateMortgage();
                this.validateMilitaryCert();
                break;
            }
        }
        return Object.values(this.formErrors).some(item => !item);
    };

    validateMatCapitalFiles = (): void => {
        this.validateFile('matCapitalScan');
        this.validateFile('matCapitalCashScan');
    };

    validateHousingCert = (): void => {
        this.validateFile('housingCertScan');
    };

    validateMilitaryCert = (): void => {
        this.validateFile('militaryCertScan');
    };

    validateMortgage = (): void => {
        this.validateValue('bankCode');
        this.validateValue('bankClerkEmail');
        this.validateFile('mortgageScan');
    };

    validateInstallment = (): void => {
        if (!this.values['installmentLength']) {
            this.formErrors['installmentLength'] = 'Выберите срок';
        }
        if (!this.values['installmentFirstPayment']) {
            this.formErrors['installmentFirstPayment'] = 'Выберите первоначальный взнос';
        }
    };

    @action
    getRidOfObjectError = () => (this.formErrors.objectId = false);

    getRidOfPaymentError = () => (this.formErrors.paymentCode = false);

    // Submits
    createAppDeal = async () => {
        try {
            this.sendLoading = true;

            await Promise.all([this.uploadFiles(), this.participants[0].store.uploadFiles()]).catch(() => {
                this.sendLoading = false;
            });

            const response = await API.appDeals.createAppDeal(this.values.objectId, {
                clientName: `${this.values.client.surname} ${this.values.client.firstname} ${this.values.client.lastname}`,
                clientPhone: cleanupMask(this.values.client.phone),
                paymentCode: this.values.paymentCode,
                comment: this.values.comment,
                petitionScanUuid: this.uploadedFilesUuid.petitionScanUuid,
                passportScanMainUuid: this.participants[0].store.uploadedFilesUuid.passportScanMainUuid,
                passportScanOtherUuid: this.participants[0].store.uploadedFilesUuid.passportScanOtherUuid,
                bookingType: this.values.bookingType,
            });

            if (response?.data?.success) {
                // Примечание!
                // После создания ссылка приходить в поле - payment_link,
                // а после, в других запросах, как - booking_payment_link
                const paymentLink = response.data.data?.payment_link;

                showNotification('success', response.data.message);
                stores.HistoryStore.history.push('/applications-and-deals');

                if (paymentLink?.length) {
                    this.setPaymentLink(paymentLink);
                    this.setPaymentLinkModalOpen(true);
                } else {
                    if (this.values.bookingType !== 'free') {
                        showNotification('danger', 'Ошибка при получении ссылки на оплату');
                    }
                }
            }
        } catch (error) {
            showNotification('danger', error?.message);

            if (error?.response?.status === 423) {
                this.setObjectIsBookedModalOpen(true);
            }
        }
        this.sendLoading = false;
    };

    @action uploadFiles = async () => {
        const promises = Object.keys(this.values.files).map((key: string) => {
            if (Object.prototype.hasOwnProperty.call(this.values.files, key)) {
                const fileObjKey = key as keyof IAppDealPaymentDocs;
                const file = this.values.files[fileObjKey].file;

                const uuidKey = `${fileObjKey}Uuid` as keyof IAppDealFormDataPaymentDocs;

                if (!this.uploadedFilesUuid[uuidKey]?.length && file) {
                    const fileData = new FormData();

                    fileData.append('scanType', this.values.files[fileObjKey].scanType.toString());
                    fileData.append('file', file);

                    this.fileUploading = true;

                    return API.appDeals
                        .uploadFile(fileData)
                        .then(response => {
                            if (response?.data?.success) {
                                this.uploadedFilesUuid[uuidKey] = response.data.data?.uuid || '';
                            }
                        })
                        .catch(error => {
                            showNotification('danger', error?.message);
                        })
                        .finally(() => {
                            this.fileUploading = false;
                        });
                }
            }

            return null;
        });

        return await Promise.all(promises || []);
    };

    @action
    updateAppDeal = (appDealId: number) => {
        Promise.all([...this.participants.map(({ store }) => store.uploadFiles()), this.uploadFiles()]).then(() => {
            this.sendLoading = true;
            //27008256
            API.appDeals
                .updateAppDeal(appDealId, this.formData)
                .then(response => {
                    if (response?.data?.success) {
                        showNotification('success', response.data.message);
                        stores.HistoryStore.history.push('/applications-and-deals');
                    }
                })
                .catch(error => {
                    showNotification('danger', error?.message);
                })
                .finally(() => {
                    this.sendLoading = false;
                });
        });
    };

    @action
    setBookingType = (type: BookingType) => {
        this.values.bookingType = type;
    };

    @action
    setPaymentLinkModalOpen = (value: boolean) => {
        this.paymentLinkModalOpen = value;
    };

    @action
    setPaymentLink = (link: string | null) => {
        this.paymentLink = link;
    };

    @action
    setObjectIsBookedModalOpen = (value: boolean) => {
        this.objectIsBookedModalOpen = value;
    };
}
