import axios from 'axios';
import { IAppDealCreateData, IAppDealFormData } from '../types/AppDealTypes';

const makeStringParams = (filters: { [index: string]: string }) => {
    let params: string = '';

    for (let key in filters) {
        if (filters[key as string]) {
            params += `&${key}=${filters[key]}`;
        }
    }

    return params;
};

class AppDealsAPI {
    call: any;

    getAppsDeals = (offset: number, limit: number, filters: any) => {
        if (this.call) {
            this.call.cancel();
        }
        this.call = axios.CancelToken.source();

        const params = makeStringParams(filters);

        return axios.get(`/lead/index?offset=${offset}&limit=${limit}${params}`, { cancelToken: this.call.token });
    };

    getHistory = (offset: number, limit: number, filters: any) => {
        if (this.call) {
            this.call.cancel();
        }
        this.call = axios.CancelToken.source();

        const params = makeStringParams(filters);

        return axios.get(`/lead/history?offset=${offset}&limit=${limit}${params}`, { cancelToken: this.call.token });
    };

    getMessages = (leadId: number) => {
        return axios.get(`/chat/lead/${leadId}`);
    };

    markAsRead = (leadId: number, message_ids: number[]) => {
        return axios.post(`/chat/lead/read/${leadId}`, message_ids);
    };

    getAppDeal = (id: number) => {
        return axios.get(`/lead/${id}`);
    };

    createAppDeal = (objectId: string, data: IAppDealCreateData) => {
        return axios.post(`/lead/create/${objectId}`, data);
    };

    getStatuses = () => {
        return axios.get(`/lead/statuses`);
    };

    getPaymentTypes = () => {
        return axios.get(`/payment/types`);
    };

    getBanks = () => {
        return axios.get('/bank/index');
    };

    getInstallmentLength = () => {
        return axios.get('/installment/index');
    };

    getPropertyTypes = () => {
        return axios.get('/property/index');
    };

    updateAppDeal = (appDealId: number, data: IAppDealFormData) => {
        return axios.post(`/lead/update/v2/${appDealId}`, data);
    };

    uploadFile = (data: any) => {
        return axios.post(`/file/upload-ftp`, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    };

    getAddress = (query: string) => {
        if (this.call) {
            this.call.cancel();
        }

        this.call = axios.CancelToken.source();

        return axios.get(`/address/search?query=${query}`, { cancelToken: this.call.token });
    };
}

const appDealsAPI = new AppDealsAPI();
export default appDealsAPI;
