import React from 'react';
import { Box, Collapse, IconButton, makeStyles, Theme, Typography } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

interface IGroupRowProps {
    title: string;
    defaultExpanded?: boolean;
}

const ConsultantsGroup: React.FC<IGroupRowProps> = ({ title, defaultExpanded, children }) => {
    const [open, setOpen] = React.useState(defaultExpanded);
    const classes = useStyles();

    const handleToggleCollapse = () => {
        setOpen(!open);
    };

    return (
        <>
            <Box className={classes.group}>
                <Typography variant="subtitle1">{title}</Typography>
                <Box ml={2}>
                    <IconButton size="small" onClick={handleToggleCollapse}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </Box>
            </Box>
            <Collapse in={open} timeout="auto" unmountOnExit>
                {children}
            </Collapse>
        </>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    group: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: theme.spacing(1.5, 2),
        marginBottom: theme.spacing(2),
        borderRadius: 8,
        // @ts-ignore
        backgroundColor: theme.palette.primary.A50,
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(1, 2),
            marginBottom: theme.spacing(1),
        },
    },
}));

export default ConsultantsGroup;
