import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import { useHistory } from 'react-router-dom';

import { TextField, InputAdornment, Paper, makeStyles } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

const SearchInput = ({ onChangeCb }: { onChangeCb?: any }) => {
    const history = useHistory();
    const parsed = queryString.parse(history.location.search);
    const [value, setValue] = useState(parsed.search || '');
    const classes = useStyles();

    useEffect(() => {
        return () => {
            setValue('');
        };
    }, [history.location.hash]);

    const handleChange = (e: any) => {
        setValue(e.target.value);
        parsed.search = e.target.value;
        delete parsed.page;

        if (e.target.value.length === 0) {
            delete parsed.search;
        }

        onChangeCb();
        history.push(`${history.location.pathname}?${queryString.stringify(parsed)}${history.location.hash}`);
    };

    return (
        <Paper elevation={0} className={classes.paper}>
            <TextField
                placeholder="ФИО, номер дома, квартиры"
                fullWidth
                className={classes.textField}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="end">
                            <SearchIcon className={classes.icon} />
                        </InputAdornment>
                    ),
                    className: classes.input,
                }}
                onChange={handleChange}
                value={value}
            />
        </Paper>
    );
};

const useStyles = makeStyles(theme => ({
    paper: {
        borderRadius: 8,
    },
    textField: {
        justifyContent: 'center',
        height: 48,
    },
    input: {
        '&:before, &:after': {
            content: 'none',
        },
    },
    icon: {
        marginRight: theme.spacing(1),
    },
}));

export default SearchInput;
