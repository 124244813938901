import { observable, action } from 'mobx';
import {
    ISignUp,
    ISignIn,
    IResetPassReq,
    IResetPassCheck,
    IResetPassConfirm,
    IResetPassData,
    IAutoLoginData,
    IAgency,
} from '../types/AuthTypes';
import API from '../api';
import { stores } from '../contexts';
import { cleanupMask } from '../utils/cleanupMask';

export const _tokenStorageKey = 'accessToken';

export class AuthStore {
    @observable loading = false;

    @observable signInErrors: any = {};

    @observable signUpStep = 1;
    @observable signUpErrors: any = {};

    @observable resetPassStep = 1;
    @observable resetPassErrors: any = {};
    @observable resetPassData: IResetPassData = {};

    @observable token = null;
    @observable isAuth = false;
    @observable logoutLoading = false;

    @observable completeCheckAuth = false;

    @observable registerData: ISignUp = {
        name: '',
        surname: '',
        lastname: '',
        phone: '',
        email: '',
        agency: {
            companyName: '',
            companyInn: '',
            isSelfEmployed: false
        },
        password: '',
        confirmPassword: '',
        deviceType: 'web',
        checkAgreements: false,
    };

    @action
    login = (data: ISignIn) => {
        this.loading = true;

        API.auth
            .login(data)
            .then(response => {
                API.setToken(response.data.data.accessToken);
                localStorage.setItem(_tokenStorageKey, response.data.data.accessToken);
                this.isAuth = true;
                // stores.ProfileStore.getProfileInfo();
            })
            .catch(error => {
                this.signInErrors = error.response?.data.errors;
            })
            .finally(() => {
                this.loading = false;
            });
    };

    @action
    autoLogin = (data: IAutoLoginData, callback?: () => void) => {
        API.auth
            .autoLogin(data)
            .then(response => {
                API.setToken(response.data.data.accessToken);
                localStorage.setItem(_tokenStorageKey, response.data.data.accessToken);
                callback?.();
                this.isAuth = true;
            })
            .catch(error => {})
            .finally(() => {});
    };

    @action
    logout = () => {
        this.logoutLoading = true;
        API.auth
            .logout()
            .then(() => {
                API.clearToken();
                localStorage.removeItem(_tokenStorageKey);
                this.isAuth = false;
                stores.HistoryStore.history.push('/');
            })
            .finally(() => {
                this.logoutLoading = false;
            });
    };

    @action
    register = () => {
        this.loading = true;

        API.auth
            .register(this.registerData)
            .then(response => {
                this.signUpStep = 2;
                this.token = response.data.data.accessToken;
            })
            .catch(error => {
                this.signUpErrors = error.response?.data.errors;
            })
            .finally(() => {
                this.loading = false;
            });
    };

    @action
    resetPassReq = (data: IResetPassReq) => {
        this.loading = true;

        API.auth
            .resetPasswordRequest(data)
            .then(response => {
                this.resetPassStep = 2;
                this.resetPassData.token = response.data.data.token;
                this.resetPassData.phone = data.login;
                this.resetPassErrors = {};
            })
            .catch(error => {
                this.resetPassErrors = error.response?.data.errors;
            })
            .finally(() => {
                this.loading = false;
            });
    };

    @action
    resetPassCheck = (data: IResetPassCheck) => {
        this.loading = true;

        API.auth
            .resetPasswordCheck(data)
            .then(() => {
                this.resetPassStep = 3;
                // this.resetPassData.token = response.data.data.token;
                this.resetPassErrors = {};
            })
            .catch(error => {
                if (error.response?.data.message) {
                    this.resetPassErrors = {
                        code: error.response.data.message,
                    };
                }
            })
            .finally(() => {
                this.loading = false;
            });
    };

    @action
    resetPassConfirm = (data: IResetPassConfirm) => {
        this.loading = true;

        API.auth
            .resetPasswordConfirm(data)
            .then(response => {
                // this.resetPassStep = 3;
                this.resetPassData = {};
                this.resetPassErrors = {};
                this.setToken(response.data.data.accessToken);
            })
            .catch(error => {
                this.resetPassErrors = error.response?.data.errors;
            })
            .finally(() => {
                this.loading = false;
            });
    };

    @action
    checkAuth = () => {
        const token = localStorage.getItem(_tokenStorageKey);

        if (token) {
            API.setToken(token);
            this.completeCheckAuth = true;
            this.isAuth = true;

            return true;
        }

        this.isAuth = false;
        this.completeCheckAuth = true;

        return false;
    };

    @action
    setValue = (name: keyof ISignUp, value: any) => {
        if (name === 'agency') {
            return;
        }

        if (name === 'phone') {
            this.registerData[name] = cleanupMask(value);
        }

        // @ts-ignore
        this.registerData[name] = value;
    };

    @action
    setSelfEmployedValue = (name: keyof IAgency, value: any) => {
        if (name === 'isSelfEmployed') {
            return;
        }

        this.registerData.agency[name] = value;
    };

    @action
    setIsSelfEmployed = (value: boolean) => {
        this.registerData.agency.isSelfEmployed = value;
    };

    @action
    setCheckAgreement = (value: boolean) => {
        this.registerData.checkAgreements = value;
    };

    @action
    setAgency = (value: string, inn: string) => {
        this.registerData.agency = {
            companyName: value,
            companyInn: inn,
            isSelfEmployed: false
        };
    };

    @action
    setToken = (token: string) => {
        localStorage.setItem(_tokenStorageKey, token);
        this.isAuth = true;
    };

    @action
    resetStore = () => {
        this.loading = false;

        this.signInErrors = {};

        this.signUpStep = 1;
        this.signUpErrors = {};

        this.resetPassStep = 1;
        this.resetPassErrors = {};
        this.resetPassData = {};

        this.logoutLoading = false;
        this.token = null;
    };
}
