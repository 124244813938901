// @ts-nocheck
import React, { useEffect, useRef } from 'react';
import { AutoSizer, MultiGrid } from 'react-virtualized';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import { Box, useMediaQuery, useTheme } from '@material-ui/core';

import { useStyles } from '../style';
import { getShortPropType } from '../../../utils/getShortPropType';
import { useStores } from '../../../hooks/useStores';

import LockIcon from '@material-ui/icons/Lock';
import { ApartmentsTileItemProps } from '../../../types/ApartmentTypes';

import ApartmentsTileItem from './ApartmentsTileItem';
import Loader from '../../Loader/Loader';
import SwitchTiles from '../../UI/SwitchTiles';
import { toJS } from 'mobx';
import CommissionIcon from '../CommissionIcon';

//Здесь используем обычный html, а не Material-UI компоненты, потому что react-virtualized плохо работает с компонентами Material

const ApartmentsTilesGrid = observer(({ forceUpdate }: any) => {
    const { ApartmentStore: store } = useStores();

    const theme = useTheme();
    const isSM = useMediaQuery(theme.breakpoints.down('sm'));
    const data = toJS(store.normalizedFloors);
    const classes = useStyles();
    let gridRef: any = useRef();

    useEffect(() => {
        if (store.filters.complex?.[0] && store.filters.houseNumber) {
            store.getApartmentsTiles(store.filters.complex?.[0].id, store.filters.houseNumber);
        }
    }, [store]);

    if (store.loading) return <Loader />;

    const Cell = ({ columnIndex, rowIndex, style, key }: ApartmentsTileItemProps) => {
        const tile: any = data?.[rowIndex]?.[columnIndex];

        const selectTile = (e) => {
            if (['svg', 'path'].includes(e.target.tagName)) {
                return;
            }

            store.setSelectedApartment(rowIndex, columnIndex);
            gridRef?.forceUpdateGrids();
        };

        const changeGridView = () => {
            store.toggleGridState();
            gridRef?.forceUpdateGrids();
            gridRef?.recomputeGridSize();
            forceUpdate();
        };

        if (rowIndex === 0 && columnIndex === 0) {
            return (
                <div onClick={changeGridView} key={key} className={classes.toggleGridButton}>
                    {store.gridState ? <SwitchTiles showLess /> : <SwitchTiles />}
                </div>
            );
        }

        if (rowIndex === 0) {
            return (
                <ApartmentsTileItem columnIndex={columnIndex} rowIndex={rowIndex} style={style} key={key}>
                    <div
                        className={clsx(classes.tableHeader, {
                            [classes.leftBorders]: tile === 1 || tile?.double || tile?.single,
                            [classes.rightBorders]: tile === 0 || tile?.single,
                            [classes.smallRightBorders]: !store.gridState && (tile === 0 || tile?.single),
                        })}
                    >
                        <div
                            className={clsx(
                                classes.tableHeaderText,
                                !store.gridState && classes.tableHeaderSmallText,
                                store.gridState && tile?.even && classes.offsetTableHeaderText,
                                !store.gridState && tile?.even && classes.offsetTableHeaderSmallText,
                                !store.gridState && tile?.single && classes.smallHeader,
                            )}
                        >
                            {typeof tile === 'object' && tile.name}
                        </div>
                    </div>
                </ApartmentsTileItem>
            );
        }

        if (!tile) {
            return (
                <ApartmentsTileItem columnIndex={columnIndex} rowIndex={rowIndex} style={style} key={key}>
                    <div className={store.gridState ? classes.emptyTile : classes.smallEmptyTile} />
                </ApartmentsTileItem>
            );
        }

        if (columnIndex === 0) {
            return (
                <ApartmentsTileItem columnIndex={columnIndex} rowIndex={rowIndex} style={style} key={key}>
                    <div className={store.gridState ? classes.fixedColumnCell : classes.smallFixedColumnCell}>
                        <div className={classes.floorNumber}>{tile}</div>
                        <div className={store.gridState ? classes.tilesSeparator : classes.smallTilesSeparator} />
                    </div>
                </ApartmentsTileItem>
            );
        }

        if (tile) {
            const bookedText = [tile.is_booked && classes.bookedText];
            const activeTile = [tile.active && classes.activeTile];
            const activeTileText = [tile.active && classes.activeTileText];
            const filteredText = [tile.isFiltered && classes.filteredText];
            const textStyles = [bookedText, filteredText, activeTileText];

            if (store.gridState) {
                return (
                    <ApartmentsTileItem
                        columnIndex={columnIndex}
                        rowIndex={rowIndex}
                        style={style}
                        key={key}
                        onClick={((e) => selectTile(e))}
                    >
                        <div
                            className={clsx(
                                classes.tile,
                                activeTile,
                                tile.is_booked && classes.bookedTile,
                                tile.isFiltered && classes.filteredTile,
                            )}
                        >
                            <div className={clsx(classes.tileData, filteredText)}>
                                <div className={clsx(classes.tileNumber, textStyles)}>
                                    {tile.object_number}
                                    {tile.is_booked && <LockIcon color="disabled" fontSize="small" className={clsx(filteredText)} />}
                                </div>
                                {tile.type_id === '2' ? (
                                    <span className={clsx(classes.tileRooms, textStyles)}>{`${tile.rooms_number}К `}</span>
                                ) : (
                                    <span className={clsx(classes.tileRooms, textStyles)}>{getShortPropType(+tile.type_id) + ' '}</span>
                                )}
                                <span className={clsx(classes.tileSize, textStyles)}>{`${Math.ceil(tile.total_area)}м²`}</span>
                                <div className={classes.priceWrapper}>
                                    <div className={clsx(classes.price, textStyles)}>
                                        {tile.priceFormat}
                                    </div>
                                    {tile.commission ? (
                                        <CommissionIcon
                                            className={classes.commissionIcon}
                                            commission={tile.commission}
                                            width="16px" height="16px"
                                        />
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </ApartmentsTileItem>
                );
            }

            return (
                <ApartmentsTileItem columnIndex={columnIndex} rowIndex={rowIndex} style={style} key={key} onClick={selectTile}>
                    <div className={clsx(classes.smallTile, activeTile)}>
                        <div className={clsx(classes.tileRooms, textStyles)}>
                            {tile.type_id === '2' ? (
                                <span className={clsx(classes.tileRooms, textStyles)}>
                                    {tile.rooms_number === 0 ? 'С' : `${tile.rooms_number}К `}
                                </span>
                            ) : (
                                <span className={clsx(classes.tileRooms, textStyles)}>{getShortPropType(+tile.type_id)}</span>
                            )}
                        </div>
                        {tile.is_booked && (
                            <div className={classes.smallLockIcon}>
                                <LockIcon color="disabled" style={{ fontSize: 15 }} className={clsx(filteredText)} />
                            </div>
                        )}
                    </div>
                </ApartmentsTileItem>
            );
        }
    };

    return (
        <Box
            flex={1}
            maxWidth={
                store.gridState
                    ? data?.[0]?.length < 12
                        ? data?.[0]?.length * 110
                        : 'auto'
                    : data?.[0]?.length < 27
                    ? data?.[0]?.length * 60 + 12
                    : 'auto'
            }
            height={isSM ? 355 : 'auto'}
        >
            <AutoSizer>
                {({ height, width }) => (
                    <MultiGrid
                        // @ts-ignore
                        cellRenderer={Cell}
                        columnCount={data?.[0]?.length || 8}
                        columnWidth={({ index }) => {
                            if (store.gridState) {
                                return index === 0 ? 54 : 122;
                            }
                            return index === 0 ? 54 : 56;
                        }}
                        rowHeight={({ index }) => {
                            if (store.gridState) {
                                return index === 0 ? 40 : 102;
                            }
                            return index === 0 ? 40 : 56;
                        }}
                        height={height}
                        rowCount={data.length}
                        width={width}
                        // overscanColumnCount={8}
                        // overscanRowCount={8}
                        fixedRowCount={1}
                        fixedColumnCount={1}
                        ref={ref => (gridRef = ref)}
                        scrollToColumn={store.activeTile[1]}
                        scrollToRow={store.activeTile[0]}
                    />
                )}
            </AutoSizer>
        </Box>
    );
});

export default ApartmentsTilesGrid;
