import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Box, Grid, useTheme, useMediaQuery, Drawer, Divider, IconButton, makeStyles, Modal, Paper, Typography } from '@material-ui/core';
import TuneIcon from '@material-ui/icons/Tune';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';

import { IApartmentItem } from '../../../types/ApartmentTypes';
import { useStores } from '../../../hooks/useStores';

import ModalApartmentFilter from './ModalApartmentFilter';
import TextButton from '../../UI/TextButton';
import ApartmentItem from '../../Apartments/ApartmentsList/ApartmentItem';
import Loader from '../../Loader/Loader';
import ApartmentSort from '../../Apartments/ApartmentsList/ApartmentSort';

const ModalApartmentSelect = observer(({ open, onClose }: any) => {
    const { ApartmentStore: store } = useStores();
    const [mobileFilter, setMobileFilter] = useState(false);

    const classes = useStyles();
    const theme = useTheme();
    const isMD = useMediaQuery(theme.breakpoints.down('md'));
    const isXS = useMediaQuery(theme.breakpoints.down('xs'));

    useEffect(() => {
        if (open) {
            store.getFilters();
            store.getApartmentsForModal(store.paginationPage, 20, store.paginationPage === 0);
        }
    }, [store.paginationPage, open]);

    const fetchMoreApartments = () => {
        store.setPaginationPage(store.paginationPage + 1);
    };

    const handleModalClose = () => {
        store.resetApartments();
        store.setPaginationPage(0);
        onClose();
    };

    return (
        <Modal disableBackdropClick disableEscapeKeyDown open={open} onClose={onClose}>
            <Paper elevation={24} className={classes.modal}>
                <Box display="flex" height="100%">
                    <Grid container spacing={4}>
                        {!isMD ? (
                            <Grid item xs={12} lg={4} className={classes.col}>
                                <ModalApartmentFilter onCloseFilter={() => setMobileFilter(false)} />
                            </Grid>
                        ) : null}

                        {isMD ? (
                            <Drawer anchor="right" open={mobileFilter} onClose={() => setMobileFilter(false)}>
                                <ModalApartmentFilter onCloseFilter={() => setMobileFilter(false)} />
                            </Drawer>
                        ) : null}

                        <Grid item xs={12} lg={8} className={classes.col}>
                            <Box display="flex" flexDirection="column" height="100%">
                                {isMD && (
                                    <Box py={2} px={{ xxs: 2, sm: 3 }} display="flex" alignContent="center" justifyContent="space-between">
                                        <Typography variant="h6">Каталог квартир</Typography>

                                        <IconButton size="small" onClick={handleModalClose}>
                                            <CloseIcon />
                                        </IconButton>
                                    </Box>
                                )}
                                <Box
                                    py={2}
                                    pr={{ xxs: 2, sm: 3 }}
                                    pl={{ xxs: 2, sm: 3, lg: 0 }}
                                    display="flex"
                                    alignContent="center"
                                    justifyContent="space-between"
                                    // minHeight="100px"
                                >
                                    <ApartmentSort store={store} isModalSort />

                                    {!isMD && (
                                        <IconButton size="small" onClick={handleModalClose}>
                                            <CloseIcon />
                                        </IconButton>
                                    )}

                                    {isMD ? (
                                        <TextButton color="primary" endIcon={<TuneIcon />} onClick={() => setMobileFilter(true)}>
                                            Фильтр
                                        </TextButton>
                                    ) : null}
                                </Box>
                                <Divider />

                                <Box className={classes.infiniteScrollWrap}>
                                    {!store.apartments.length && (
                                        <Box
                                            position="relative"
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                            flexDirection="column"
                                            textAlign="center"
                                            width="100%"
                                            minHeight="31vh"
                                        >
                                            {store.loading ? (
                                                <Loader isAbsolute />
                                            ) : (
                                                <Typography variant={isXS ? 'body2' : 'body1'}>
                                                    По Вашему запросу не найдено квартир
                                                </Typography>
                                            )}
                                        </Box>
                                    )}
                                    <InfiniteScroll
                                        dataLength={store.apartments.length}
                                        next={fetchMoreApartments}
                                        hasMore={
                                            store.apartments.length > 0 &&
                                            store.apartmentsPagination?.totalCount !== store.apartmentsPagination?.currentOffset
                                        }
                                        height="100%"
                                        loader={
                                            <Box
                                                position="relative"
                                                display="flex"
                                                alignItems="center"
                                                justifyContent="center"
                                                flexDirection="column"
                                                width="100%"
                                                minHeight="120px"
                                            >
                                                <Loader isAbsolute />
                                            </Box>
                                        }
                                        className={clsx(classes.infiniteScroll, 'infinite-scroll--x-hidden')}
                                    >
                                        {store.apartments.map((apartment: IApartmentItem) => (
                                            <ApartmentItem
                                                key={apartment.object_id}
                                                data={apartment}
                                                isModalItem
                                                onClose={handleModalClose}
                                            />
                                        ))}
                                    </InfiniteScroll>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Paper>
        </Modal>
    );
});

const useStyles = makeStyles(theme => ({
    modal: {
        position: 'absolute',
        background: '#fff',
        borderRadius: 16,
        [theme.breakpoints.up('lg')]: {
            minWidth: 320,
            margin: '24px 0',
            top: 'calc(50% - 24px)',
            left: '50%',
            width: 1056,
            maxHeight: 776,
            height: 'calc(100% - 48px)',
            transform: 'translate(-50%, -50%)',
        },
        [theme.breakpoints.down(1199)]: {
            width: 960,
        },
        [theme.breakpoints.down('md')]: {
            width: '100%',
            height: '100%',
            borderRadius: 0,
        },
    },
    col: {
        height: '100%',
    },
    infiniteScrollWrap: {
        height: 'calc(100% - 128px)',
        '& .infinite-scroll-component__outerdiv': {
            height: '100%',
        },
    },
    infiniteScroll: {
        height: '100%',
        paddingTop: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            paddingRight: theme.spacing(3),
            paddingleft: theme.spacing(3),
        },
        [theme.breakpoints.down('sm')]: {
            paddingRight: theme.spacing(2),
            paddingleft: theme.spacing(2),
        },
    },
}));

export default ModalApartmentSelect;
