import React from 'react';
import { observer } from 'mobx-react-lite';
import { Grid, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';

import { IFavoriteInline } from '../../types/FavoriteTypes';

import ApartmentCard from '../UI/ApartmentCard';

const FavoriteApartments = observer(({ favorites }: { favorites: IFavoriteInline[] | undefined }) => {
    const theme = useTheme();
    const isSM = useMediaQuery(theme.breakpoints.down('sm'));
    const classes = useStyles();

    return (
        <Grid container spacing={4} className={classes.container} justify={isSM ? 'center' : undefined}>
            {favorites?.map((favorite: IFavoriteInline) => (
                <Grid item key={favorite.id} className={classes.item}>
                    <ApartmentCard apartment={favorite.objectParams} inFavorite />
                </Grid>
            ))}
        </Grid>
    );
});

const useStyles = makeStyles(theme => ({
    container: {
        paddingBottom: theme.spacing(2.5),
    },
    item: {
        flex: '1 1 320px',
        maxWidth: 352,
    },
}));

export default FavoriteApartments;
