import React from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import { MenuItem, Select, makeStyles, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { ISortItem } from '../../../types/ApartmentTypes';

const ApartmentSort = observer(({ store, isModalSort = false }: { store: any; isModalSort?: boolean }) => {
    const classes = useStyles();
    const history = useHistory();

    const handleSort = (e: any) => {
        store.setSort(e.target.value);

        store.handleFilter(history);

        if (isModalSort) {
            store.getApartmentsForModal(store.paginationPage, 20);
        }
    };

    return (
        <Select value={store.filters.sort} onChange={handleSort} IconComponent={ExpandMoreIcon} disableUnderline className={classes.root}>
            {store.sorts.map((item: ISortItem) => (
                <MenuItem key={item.key} value={item.key}>
                    <Typography variant="subtitle2"> {item.title}</Typography>
                </MenuItem>
            ))}
        </Select>
    );
});

const useStyles = makeStyles(theme => ({
    root: {
        minWidth: 'auto',
        fontSize: 16,
        fontWeight: 500,
        color: theme.palette.primary.main,
        background: '#EBE0F7',
        cursor: 'pointer',
        borderRadius: '10px',
        transition: 'all ease 0.3s',
        '&:hover': {
            color: theme.palette.primary.light,
            // backgroundColor: 'transparent',
        },
        '&:active': {
            color: theme.palette.primary.dark,
        },
        '& .MuiSelect-select': {
            paddingLeft: 14,
            paddingRight: theme.spacing(6),
            '&:focus': {
                backgroundColor: 'transparent',
            },
        },
        '& .MuiSvgIcon-root': {
            color: theme.palette.primary.main,
            marginRight: 14,
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: 14,
            maxWidth: 'calc(100% - 100px)',
        },
    },
}));

export default ApartmentSort;
