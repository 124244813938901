import React from 'react';
import { Box, makeStyles, Paper, Theme, Typography } from '@material-ui/core';

import { BookingType, IBookingTypeObj } from '../../types/ApartmentTypes';

interface IBookingTypeCardProps {
    data: IBookingTypeObj;
    onSelect: (type: BookingType) => void;
}

const BookingTypeCard: React.FC<IBookingTypeCardProps> = ({ data, onSelect }) => {
    const classes = useStyles();

    return (
        <Paper className={classes.card} elevation={0} onClick={() => onSelect(data.id)}>
            <Box mb={2}>
                <Typography variant="h4" color="primary">
                    {data.title}
                </Typography>
            </Box>

            <ul className={classes.ul}>
                {data.description.map((li, index) => (
                    <li className={classes.li} key={index}>
                        <Typography>{li}</Typography>
                    </li>
                ))}
            </ul>
        </Paper>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    card: {
        height: '100%',
        textAlign: 'left',
        padding: 20,
        borderRadius: 16,
        border: '1px solid #EBE0F7',
        cursor: 'pointer',
        boxShadow: 'none',
        '&:hover': {
            boxShadow: '0px 27px 34px rgba(94, 1, 198, 0.07), 0px 14px 9px rgba(94, 1, 198, 0.0402488), 0px 8px 4px rgba(0, 0, 0, 0.03);',
        },
    },
    ul: {
        listStyle: 'none',
        padding: 0,
        margin: 0,
    },
    li: {
        position: 'relative',
        paddingLeft: theme.spacing(2.5),
        color: theme.palette.material.blackMedium,
        '&:not(:last-child)': {
            marginBottom: theme.spacing(2),
        },
        '&:before': {
            content: `''`,
            position: 'absolute',
            top: 8,
            left: 0,
            width: 7,
            height: 7,
            borderRadius: '50%',
            backgroundColor: theme.palette.primary.main,
        },
        '&.dense': {},
    },
}));

export default BookingTypeCard;
