import React, { useEffect } from 'react';
import { Box, Paper, makeStyles } from '@material-ui/core';
import Slider from 'react-slick';

import { IComplexFeature } from '../../types/ComplexTypes';

import CarouselArrow from '../CarouselArrow/CarouselArrow';

const ComplexFeatures = ({ data }: { data: IComplexFeature[] | null }) => {
    const classes = useStyles();
    let sliderRef: any = null;
    let sliderPosResetTimer: any = null;

    const sliderSettings = {
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        prevArrow: <CarouselArrow type="prev" />,
        nextArrow: <CarouselArrow type="next" />,
        autoplay: true,
        afterChange: (index: number) => {
            clearTimeout(sliderPosResetTimer);
            if (data?.length && index * 4 >= data?.length) {
                if (sliderRef) {
                    sliderPosResetTimer = setTimeout(() => {
                        sliderRef.slickGoTo(0);
                    }, 3000);
                }
            }
        },
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 374,
                settings: {
                    slidesToShow: 2,
                },
            },
        ],
    };

    useEffect(() => {
        return () => {
            clearTimeout(sliderPosResetTimer);
        };
    }, [sliderPosResetTimer]);

    return data?.length ? (
        <Box mx={{ xxs: -1, md: -2 }} mt={{ xxs: 7, sm: 9, md: 12 }} mb={{ xxs: 6, sm: 8, md: 14 }}>
            <Slider {...sliderSettings} className="slick-slider__visible" ref={item => (sliderRef = item)}>
                {data.map(item => (
                    <Box key={item.key} className={classes.root}>
                        <Paper elevation={0} className={classes.card}>
                            <img src={item.image} alt="" className={classes.img} />
                        </Paper>
                    </Box>
                ))}
            </Slider>
        </Box>
    ) : null;
};

const useStyles = makeStyles(theme => ({
    root: {
        width: 'auto !important',
        padding: '0 16px',
        [theme.breakpoints.down('lg')]: {
            padding: '0 1.53vw',
        },
    },
    card: {
        height: 270,
        [theme.breakpoints.down('lg')]: {
            height: '30.3vw',
        },
        [theme.breakpoints.down('sm')]: {
            height: '40.4vw',
        },
        [theme.breakpoints.down('xs')]: {
            height: '41.3vw',
        },
        '@media (max-width: 374px)': {
            height: '62.7vw',
        },
    },
    img: {
        maxWidth: 'none',
        height: '100%',
    },
}));

export default ComplexFeatures;
