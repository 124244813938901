export const getYoutubeVideoID = (url: string) => {
    if (url && typeof url === 'string') {
        const splitedUrl = url.replace(/(>|<)/gi, '').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);

        if (splitedUrl[2] !== undefined) {
            return splitedUrl[2].split(/[^0-9a-z_-]/i)[0];
        }

        return url;
    }

    return url;
};
