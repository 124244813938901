import React from 'react';
import { Box, Button, IconButton, makeStyles, Modal, Paper, Typography } from '@material-ui/core';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';

import TextButton from '../UI/TextButton';

const FavoriteRemoveModal = ({
    isOpen,
    closeModal,
    onSubmit,
    removeAll,
}: {
    isOpen: boolean;
    closeModal: any;
    onSubmit: any;
    removeAll?: boolean;
}) => {
    const classes = useStyles();

    return (
        <Modal open={isOpen} onClose={closeModal} className={classes.container}>
            <Paper elevation={3} className={classes.modal}>
                <Box p={2}>
                    <Box mb={3} display="flex" justifyContent="space-between">
                        <Typography variant="subtitle1">{removeAll ? 'Удалить все объекты из избранного?' : 'Удалить папку?'}</Typography>

                        <IconButton size="small" onClick={closeModal}>
                            <CloseOutlinedIcon />
                        </IconButton>
                    </Box>

                    <Box display="flex" justifyContent="space-between" pl={3} mt={2}>
                        <TextButton onClick={closeModal}>Отмена</TextButton>
                        <Button
                            color="primary"
                            variant="contained"
                            className={classes.button}
                            onClick={() => {
                                onSubmit();
                                closeModal();
                            }}
                        >
                            Удалить
                        </Button>
                    </Box>
                </Box>
            </Paper>
        </Modal>
    );
};

const useStyles = makeStyles(theme => ({
    modal: {
        borderRadius: 8,
        flex: '0 1 348px',
    },
    button: {
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5),
    },
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));

export default FavoriteRemoveModal;
