import React from 'react';
import { Box, makeStyles, Paper, Theme, Typography } from '@material-ui/core';
import clsx from 'clsx';

import { BookingType, IBookingTypeObj } from '../../../types/ApartmentTypes';

interface IAppDealBookingTypeCardProps {
    data: IBookingTypeObj;
    active: boolean;
    disabled?: boolean;
    onSelect: (type: BookingType) => void;
}

const AppDealBookingTypeCard: React.FC<IAppDealBookingTypeCardProps> = ({ data, active, disabled, onSelect }) => {
    const classes = useStyles();

    const handleSelect = (id: BookingType) => {
        if (!disabled) {
            onSelect(id);
        }
    };

    return (
        <Paper className={clsx(classes.card, { active, disabled })} elevation={0} onClick={() => handleSelect(data.id)}>
            <Box mb={1}>
                <Typography variant="subtitle1" className={clsx(classes.title, { invert: active, disabled })}>
                    {data.title}
                </Typography>
            </Box>

            <ul className={classes.ul}>
                {data.description.map((li, index) => (
                    <li className={classes.li} key={index}>
                        <Typography variant="caption" color="inherit">
                            {li}
                        </Typography>
                    </li>
                ))}
            </ul>
        </Paper>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    card: {
        height: '100%',
        textAlign: 'left',
        color: theme.palette.material.blackDisabled,
        padding: theme.spacing(2),
        borderRadius: 8,
        border: '1px solid #EBE0F7',
        cursor: 'pointer',
        boxShadow: 'none',
        transition: theme.transitions.create(['background-color', 'box-shadow']),
        '&:hover:not(.disabled)': {
            boxShadow: '0px 43px 58px rgba(94, 1, 198, 0.04), 0px 15px 19px rgba(0, 0, 0, 0.03), 0px 7.36424px 9px rgba(0, 0, 0, 0.01)',
        },
        '&.active': {
            color: theme.palette.material.whiteMedium,
            backgroundColor: theme.palette.primary.main,
        },
        '&.disabled': {
            cursor: 'default',
            color: theme.palette.material.whiteMedium,
            backgroundColor: theme.palette.material.overlay,
        },
    },
    title: {
        color: theme.palette.primary.main,
        transition: theme.transitions.create('color'),
        '&.invert, &.disabled': {
            color: theme.palette.primary.contrastText,
        },
    },
    ul: {
        listStyle: 'none',
        padding: 0,
        margin: 0,
    },
    li: {
        position: 'relative',
        paddingLeft: theme.spacing(3),
        '&:not(:last-child)': {
            marginBottom: 4,
        },
        '&:before': {
            content: `''`,
            position: 'absolute',
            top: 8,
            left: 8,
            width: 4,
            height: 4,
            borderRadius: '50%',
            backgroundColor: 'currentColor',
        },
    },
}));

export default AppDealBookingTypeCard;
