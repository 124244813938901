import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Button, IconButton, makeStyles, Modal, Paper, Typography } from '@material-ui/core';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import TextButton from '../UI/TextButton';
import { IAppDealTabChangeModal } from '../../types/AppDealTypes';

const AppDealTabChangeModal = observer((props: IAppDealTabChangeModal) => {
    const classes = useStyles();
    const { showModal, hideModal, setNewTab } = props;

    return (
        <Modal open={showModal} className={classes.container}>
            <Paper elevation={3} className={classes.modal}>
                <Box p={2}>
                    <Box mb={3} display="flex" justifyContent="space-between">
                        <Typography variant="subtitle1">Внимание</Typography>

                        <IconButton size="small" onClick={hideModal}>
                            <CloseOutlinedIcon />
                        </IconButton>
                    </Box>

                    <Box mb={2}>
                        <Typography variant="body1">Введенные данные не сохранятся</Typography>
                    </Box>
                    <Box display="flex" justifyContent="space-between" pl={3}>
                        <TextButton onClick={hideModal}>Отмена</TextButton>
                        <Button color="primary" variant="contained" className={classes.button} onClick={setNewTab}>
                            Продолжить
                        </Button>
                    </Box>
                </Box>
            </Paper>
        </Modal>
    );
});

export default AppDealTabChangeModal;

const useStyles = makeStyles(theme => ({
    modal: {
        borderRadius: 8,
        flex: '0 1 348px',
    },
    button: {
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5),
    },
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));
