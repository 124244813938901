import React, { useEffect } from 'react';
import { Box, Container, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';

import { useStores } from '../../hooks/useStores';

import Loader from '../../components/Loader/Loader';
import RequestError from '../../components/RequestError/RequestError';
import ComplexesItem from '../../components/Complexes/ComplexesItem';

const Complexes = observer(() => {
    const { ComplexStore: store } = useStores();

    useEffect(() => {
        store.getComplexes();
    }, [store]);

    const renderContent = () => {
        if (!store.complexes?.success) {
            return <RequestError />;
        }
        if (store.complexes?.success) {
            return (
                <>
                    <Typography variant="h2">Жилые комплексы</Typography>
                    <Box mx={{ xxs: -2, lg: -1 }}>
                        {store.complexes.data.map(complex => (
                            <ComplexesItem complex={complex} key={complex.name} />
                        ))}
                    </Box>
                </>
            );
        }
        return <div />;
    };

    if (store.loading) {
        return <Loader isAbsolute />;
    }

    return (
        <Container maxWidth="xl">
            <Box py={5}>{renderContent()}</Box>
        </Container>
    );
});

export default Complexes;
