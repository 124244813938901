import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Box, Paper } from '@material-ui/core';
import Loader from '../Loader/Loader';
import { useStores } from '../../hooks/useStores';
import noClients from '../../img/no-clients.svg';
import { observer } from 'mobx-react-lite';
import MyClientsItem from './MyClientsItem';

const MyClientsListItems = observer(() => {
    const { MyClientsStore: store } = useStores();

    const fetchMoreApartments = () => {
        store.setPaginationPage(store.paginationPage + 1);
    };

    const renderLoader = () => {
        return (
            <Paper>
                <Box display="flex" alignItems="center" justifyContent="center" width="100%" minHeight="86px">
                    <Loader />
                </Box>
            </Paper>
        );
    };

    return (
        <>
            {!store.clients.length && (
                <Box display="flex" alignItems="center" justifyContent="center" width="100%" height="100%">
                    {store.loading ? <Loader /> : <img src={noClients} alt="Клиенты не найдены" width={343} height={258} />}
                </Box>
            )}
            <InfiniteScroll
                dataLength={store.clients.length}
                next={fetchMoreApartments}
                hasMore={store.clients.length > 0 && store.clientsPagination?.totalCount !== store.clientsPagination?.currentOffset}
                loader={renderLoader()}
            >
                {store.clients.map(client => {
                    return <MyClientsItem key={client.phone} name={client.name} phone={client.phone} status={client.status} />;
                })}
            </InfiniteScroll>
        </>
    );
});
export default MyClientsListItems;
