import React from 'react';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { Box, IconButton, makeStyles, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Link } from 'react-router-dom';

import { getShortPropType } from '../../utils/getShortPropType';
import { IApartmentItem } from '../../types/ApartmentTypes';
import { useStores } from '../../hooks/useStores';

import EmptyImage from './EmptyImage';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import RadioIcon from './RadioIcon';
import { useModal } from '../../hooks/useModal';
import DownloadIcon from './DownloadIcon';

const ApartmentCard = observer(({ apartment, inFavorite }: { apartment: IApartmentItem; inFavorite?: boolean }) => {
    const { ApartmentStore, FavoriteStore: store } = useStores();
    const { isOpen: isHovered, openModal: showHover, closeModal: hideHover } = useModal();
    const classes = useStyles();

    const theme = useTheme();
    const isSM = useMediaQuery(theme.breakpoints.down('sm'));

    const isObjectSelected = store.selectedObjectsIds.includes(apartment.object_id);

    const touch = matchMedia('(hover: none)').matches;

    return (
        <Box
            display="flex !important"
            justifyContent="center"
            position="relative"
            className={clsx(classes.container, isObjectSelected && classes.selected)}
            onMouseEnter={showHover}
            onMouseLeave={hideHover}
        >
            <Box position="absolute" top={6} left={6}>
                {inFavorite && (touch || !!store.selectedObjectsIds.length || isHovered) && (
                    <IconButton
                        className={clsx(classes.radioButton, isObjectSelected && classes.radioButtonChecked)}
                        onClick={() => store.toggleObjectSelect(apartment.object_id)}
                    >
                        {isObjectSelected ? <CheckCircleIcon /> : <RadioIcon />}
                    </IconButton>
                )}
            </Box>

            <Box position="absolute" top={6} right={6}>
                <IconButton onClick={() => ApartmentStore.downloadObject([apartment.object_id])} className={classes.iconButton}>
                    <DownloadIcon />
                </IconButton>
                {inFavorite ? (
                    apartment?.isFavorite ? (
                        <IconButton
                            className={classes.iconButton}
                            onClick={() =>
                                store
                                    .removeFromFavorite(apartment.object_id)
                                    .then(() => store.getFavoriteFolder(store.activeFolderId as number))
                            }
                        >
                            <FavoriteIcon />
                        </IconButton>
                    ) : (
                        <IconButton color="primary" onClick={() => store.addToFavorite(apartment.object_id)}>
                            <FavoriteBorderIcon />
                        </IconButton>
                    )
                ) : null}
            </Box>
            <Link to={`/apartments/${apartment.object_id}`} className={classes.wrap}>
                <Box className={classes.box}>
                    <Box px={{ xxs: 2, md: 4 }} py={{ xxs: 3, md: 5 }} mb={1} className={classes.imgWrap}>
                        {apartment.images.length ? (
                            <img src={apartment.images[0]} alt="" className={classes.img} />
                        ) : (
                            <EmptyImage variant="apartment" />
                        )}
                    </Box>

                    <Box>
                        <Typography variant="body2" color="textSecondary" className={classes.withMargin}>
                            {apartment.complex}
                        </Typography>
                        <Box display="flex" alignItems="center" justifyContent="space-between">
                            <Typography variant="h3" className={classes.withMargin}>
                                {apartment.priceFormat}
                            </Typography>
                            {apartment.is_booked && (
                                <IconButton disabled size="small" className={classes.withMargin}>
                                    <LockIcon fontSize="small" />
                                </IconButton>
                            )}
                        </Box>
                        <Box className={clsx(classes.address, classes.withMargin)}>
                            <LocationOnOutlinedIcon
                                className={clsx(classes.pin, classes.mediumBlackColor)}
                                fontSize={isSM ? 'small' : 'default'}
                            />
                            <Typography variant="body2" color="textSecondary">
                                {apartment.address}
                            </Typography>
                        </Box>

                        <Typography variant={isSM ? 'body2' : 'body1'} className={classes.withMargin}>
                            <Typography variant="subtitle1" component="span">
                                {apartment.type_id === '2'
                                    ? `${apartment?.rooms_number} комн.`
                                    : getShortPropType(+apartment.type_id) + ' '}
                            </Typography>
                            • {apartment.total_area} м² • Этаж {apartment.floor_number}/{apartment.max_floor}
                        </Typography>
                    </Box>
                </Box>
            </Link>
        </Box>
    );
});

export default ApartmentCard;

const useStyles = makeStyles(theme => ({
    container: {
        border: '1px solid rgba(0, 0, 0, 0.12)',
        borderRadius: 16,
        //@ts-ignore
        borderColor: theme.palette.primary.A50,
        '&:hover': {
            boxShadow: '0px 27px 34px rgba(94, 1, 198, 0.07), 0px 14px 9px rgba(94, 1, 198, 0.0402488), 0px 8px 4px rgba(0, 0, 0, 0.03)',
        },
        background: '#fff',
        transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    },
    selected: {
        borderColor: theme.palette.primary.main,
        borderWidth: 2,
    },
    wrap: {
        width: '100%',
        [theme.breakpoints.down('xs')]: {
            maxWidth: 315,
        },
        color: 'rgba(0, 0, 0, 0.87)',
    },
    box: {
        padding: 16,
        [theme.breakpoints.down('md')]: {
            padding: 24,
        },
        [theme.breakpoints.down('sm')]: {
            padding: 16,
        },
    },
    imgWrap: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 285,
        [theme.breakpoints.down('sm')]: {
            height: '37.5vw',
            maxHeight: 253,
        },
        [theme.breakpoints.down('xs')]: {
            height: 253,
        },
    },
    img: {
        maxWidth: 224,
        maxHeight: '100%',
        transition: 'transform ease 0.3s',
    },
    address: {
        display: 'flex',
        alignItems: 'center',
    },
    pin: {
        marginRight: 5,
    },
    withMargin: {
        marginBottom: 6,
    },
    mediumBlackColor: {
        color: 'rgba(0, 0, 0, 0.6)',
    },
    radioButton: {
        //@ts-ignore
        color: theme.palette.primary.A200,
    },
    radioButtonChecked: {
        color: theme.palette.primary.main,
    },
    iconButton: {
        //@ts-ignore
        color: theme.palette.primary.A200,
        padding: 10,
    },
}));
