import React, { useEffect } from 'react';
import { Paper, Box, Grid } from '@material-ui/core';
import Step1 from './Step1';
import Step2 from './Step2';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../hooks/useStores';

const SignUp = observer(() => {
    const { AuthStore: store } = useStores();

    // Effects
    useEffect(() => {
        store.resetStore();

        return () => {
            if (store.token) {
                store.setToken(store.token || '');
            }
            store.resetStore();
        };
    }, [store]);

    // Renders
    const renderContent = () => {
        switch (store.signUpStep) {
            case 1:
                return <Step1 store={store} />;
            case 2:
                return <Step2 />;
            default:
                return <Step1 store={store} />;
        }
    };

    return (
        <div className="auth-page__content">
            <Box p={{ xxs: 2, sm: 4 }} width="100%">
                <Grid container justify="center">
                    <Paper elevation={12} className="auth-block">
                        <Box p={{ xxs: 2, sm: 4 }} textAlign="center">
                            {renderContent()}
                        </Box>
                    </Paper>
                </Grid>
            </Box>
        </div>
    );
});

export default SignUp;
