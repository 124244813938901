import React, { useEffect } from 'react';
import { Box, Divider, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { makeStyles } from '@material-ui/core/styles';

import { useStores } from '../../../hooks/useStores';
import { declOfNum } from '../../../utils/declOfNum';

import ApartmentsFilter from './ApartmentsFilter';
import ApartmentSort from './ApartmentSort';
import ApartmentListItems from './ApartmentListItems';
import ApartmentsMap from './ApartmentsMap';

const ApartmentsList = observer(() => {
    const { ApartmentStore: store } = useStores();
    const classes = useStyles();

    useEffect(() => {
        store.getCoords();

        return () => {
            store.resetApartments();
            store.resetOptionalFilters();
        };
    }, [store]);

    return (
        <>
            <ApartmentsFilter />

            <Box mr={-5}>
                <Divider className={classes.divider} />

                <Box display="flex" maxWidth="100%" flex="1 1" height="100%" flexDirection={{ xxs: 'column', sm: 'row' }}>
                    <Box flex={{ xxs: '1 1 250px', md: '1 1 520px' }} mr={{ xxs: 3, md: 1 }}>
                        <Box mt={3} mb={2} display="flex" alignItems="center" justifyContent="space-between">
                            {store.apartmentsCount ? (
                                <Typography variant="h4">
                                    Найдено {store.apartmentsCount} {declOfNum(store.apartmentsCount, ['квартира', 'квартиры', 'квартир'])}
                                </Typography>
                            ) : null}
                            <ApartmentSort store={store} />
                        </Box>
                        <ApartmentListItems />
                    </Box>

                    <ApartmentsMap coords={store.coords} />
                </Box>
            </Box>
        </>
    );
});

const useStyles = makeStyles(theme => ({
    divider: {
        //@ts-ignore
        background: theme.palette.primary.A100,
    },
    content: {
        width: '250px',
        height: '20px',
        background: '#fff',
    },
}));

export default ApartmentsList;
