import React from 'react';
import { IComplexData } from '../../types/ComplexTypes';
import { Box, Paper, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const ComplexProperties = ({ data }: { data: IComplexData }) => {
    const classes = useStyles();
    return (
        <Box maxWidth={718} mx="auto" my={{ xxs: 4, md: 6 }} className={classes.container}>
            <Paper elevation={0}>
                <Box bgcolor="#fff" px={3} py={2}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} md={3}>
                            <Box mb={0.5}>
                                <Typography variant="subtitle1" noWrap>
                                    {data.priceFormat}&nbsp;
                                </Typography>
                            </Box>
                            <Box>
                                <Typography variant="body2" color="textSecondary">
                                    Стоимость
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <Box mb={0.5}>
                                <Typography variant="subtitle1" noWrap>
                                    {data.completionDate}&nbsp;
                                </Typography>
                            </Box>
                            <Box>
                                <Typography variant="body2" color="textSecondary">
                                    Срок сдачи
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <Box mb={0.5}>
                                {data.minArea && data.maxArea ? (
                                    <Typography variant="subtitle1" noWrap>
                                        {data.minArea} - {data.maxArea} м²
                                    </Typography>
                                ) : (
                                    <Typography variant="subtitle1" noWrap>
                                        &nbsp;
                                    </Typography>
                                )}
                            </Box>
                            <Box>
                                <Typography variant="body2" color="textSecondary">
                                    Площадь
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <Box mb={0.5}>
                                {data.minFloor && data.maxFloor ? (
                                    <Typography variant="subtitle1" noWrap>
                                        {data.minFloor} / {data.maxFloor}
                                    </Typography>
                                ) : (
                                    <Typography variant="subtitle1" noWrap>
                                        &nbsp;
                                    </Typography>
                                )}
                            </Box>
                            <Box>
                                <Typography variant="body2" color="textSecondary">
                                    Этажность
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Paper>
        </Box>
    );
};

const useStyles = makeStyles(theme => ({
    container: {
        [theme.breakpoints.up('sm')]: {
            position: 'absolute',
            width: 718,
            bottom: -84,
            left: 'calc(50% - 359px)',
        },
        [theme.breakpoints.down('sm')]: {
            position: 'relative',
            top: 30,
            left: 0,
            width: 'auto',
        },
    },
}));

export default ComplexProperties;
