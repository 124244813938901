import React, { useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { observer } from 'mobx-react-lite';
import { useMediaQuery, useTheme } from '@material-ui/core';

import { useStores } from '../../../hooks/useStores';
import RequestError from '../../RequestError/RequestError';
import Loader from '../../Loader/Loader';

import { ComplexInfo } from './ComplexInfo';

export const AccreditationComplex = observer(() => {
    const { ComplexStore: store } = useStores();
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {
        store.getComplexes();
    }, [store]);

    const renderContent = () => {
        if (!store.complexes?.success) return <RequestError />;

        if (store.complexes?.success)
            return store.complexes.data
                .filter(({ banks }) => banks.length)
                .map(complex => (
                    <Grid item key={complex.id} xs={12}>
                        <ComplexInfo complex={complex} />
                    </Grid>
                ));
        return <div />;
    };

    if (store.loading) return <Loader />;

    return (
        <>
            <Box mb={6}>
                <Typography variant="h2">Аккредитация жилых комплексов</Typography>
            </Box>

            <Box mb={2}>
                <Grid container spacing={isMd ? 3 : 9}>
                    {renderContent()}
                </Grid>
            </Box>
        </>
    );
});
