import { observable, action } from 'mobx';
import API from '../api';
import { IStatisticState } from '../types/StatisticTypes';

export class StatisticStore {
    @observable loading = false;
    @observable stats: IStatisticState | null = null;

    @action
    getStats = (filters: any) => {
        this.loading = true;

        API.statistic
            .getStats(filters)
            .then(response => {
                if (response?.data?.success) {
                    this.stats = response.data.data;
                }
            })

            .finally(() => {
                this.loading = false;
            });
    };
}
