import React from 'react';
import { makeStyles, Button, useMediaQuery, useTheme, ButtonProps } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        color: theme.palette.primary.main,
        padding: 0,
        minWidth: 'auto',
        transition: 'all ease 0.3s',
        '&:hover': {
            color: theme.palette.primary.light,
            backgroundColor: 'transparent',
        },
        '&:active': {
            color: theme.palette.primary.dark,
        },
    },
    startIcon: {
        // position: 'absolute',
        // left: -22,
    },
}));

const TextButton = (props: ButtonProps) => {
    const classes = useStyles();
    const theme = useTheme();
    const isXS = useMediaQuery(theme.breakpoints.down('xs'));

    return (
        <Button
            variant="text"
            size={isXS ? 'medium' : 'large'}
            disableRipple
            classes={{ root: classes.root, startIcon: classes.startIcon }}
            {...props}
        >
            {props.children}
        </Button>
    );
};

export default TextButton;
