import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box } from '@material-ui/core';

import { IComplexField } from '../../../types/CommonTypes';
import { useStores } from '../../../hooks/useStores';
import { useSetFiltersFromQueryString } from '../../../hooks/useSetFiltersFromQueryString';

import ApartmentsFilterMenu from '../ApartmentsFilterMenu';
import ApartmentsFilterComplex from '../ApartmentsFilter/ApartmentsFilterComplex';
import ApartmentsFilterHouseNumber from '../ApartmentsFilter/ApartmentsFilterHouseNumber';
import ApartmentsFilterRoomsNumber from '../ApartmentsFilter/ApartmentsFilterRoomsNumber';
import ApartmentsFilterObjectType from '../ApartmentsFilter/ApartmentsFilterObjectType';

const ApartmentsTilesFilter = observer(({ refreshGrid }: any) => {
    const { ApartmentStore: store } = useStores();

    useSetFiltersFromQueryString();

    const handleTileFilterChange = (e: any) => {
        store.handleTileFilterChange(e);
        refreshGrid();
    };

    const handleCheckBoxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        store.handleChangeCheckboxesTiles(e);
        refreshGrid();
    };

    const resetOptionalFilters = () => {
        store.resetOptionalFilters();
        refreshGrid();
    };

    const setObjectTypeTiles = (e: any, value: any) => {
        store.toggleObjectTypeTiles(value?.type_id);
        refreshGrid();
    };

    return (
        <Box mb={1} display="flex" flexWrap="wrap">
            <ApartmentsFilterObjectType
                onChange={setObjectTypeTiles}
            />

            <ApartmentsFilterComplex
                value={store.filters.complex?.[0] || ''}
                onChange={(e: any, value: any) => {
                    store.handleFilterComplex(e, value);
                    store.handleFilter();
                }}
                options={store.complexes}
                loading={store.filterLoading}
                getOptionLabel={(option: IComplexField) => (option ? option.name : '')}
            />

            <ApartmentsFilterHouseNumber
                multiple={false}
                value={store.filters.houseNumbers?.length ? store.filters.houseNumbers?.[0] : ''}
                onChange={(e: any, value: any) => {
                    store.handleFilterHouseNumber(e, value);
                    store.handleFilter();
                }}
            />

            <ApartmentsFilterRoomsNumber
                onChange={(e: any, value: any) => {
                    store.handleAutocompleteChangeTiles('roomsNumber', value);
                    refreshGrid();
                }}
            />

            <ApartmentsFilterMenu
                numberOfActiveFilters={store.numberOfActiveFilters}
                handleChange={handleTileFilterChange}
                handleChangeCheckboxes={handleCheckBoxChange}
                resetOptionalFilters={resetOptionalFilters}
            />
        </Box>
    );
});

export default ApartmentsTilesFilter;
