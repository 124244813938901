import axios from 'axios';

class RatingsAPI {
    getRatings = () => {
        return axios.get(`/lead/rating`);
    };
}

const ratingsAPI = new RatingsAPI();
export default ratingsAPI;
