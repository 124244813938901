import React from 'react';
import { Box, Typography, Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

const HelloScreen = () => {
    const history = useHistory();

    return (
        <div className="auth-page__content auth-page__content_bg2">
            <Box px={4} py={14} maxWidth={384} width="100%" margin="auto" textAlign="center">
                <Box mb={5}>
                    <Typography variant="h3">Добро пожаловать!</Typography>
                </Box>
                <Box mb={5}>
                    <Typography variant="body1">
                        Войдите или зарегистрируйтесь, чтобы пользоваться функциями личного кабинета риелтора.
                    </Typography>
                </Box>
                <Button type="submit" fullWidth variant="contained" color="primary" size="large" onClick={() => history.push('/login')}>
                    Войти или зарегистрироваться
                </Button>
            </Box>
        </div>
    );
};

export default HelloScreen;
