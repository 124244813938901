import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box } from '@material-ui/core';

import { IAppDealParticipantStore } from '../../../types/AppDealTypes';
import AppDealParticipantForm from './AppDealParticipantForm';

const AppDealParticipantsForm = observer((props: any) => (
    <>
        {props.participants.map((participant: IAppDealParticipantStore, index: number) => (
            <Box mb={3} key={participant.index}>
                <AppDealParticipantForm participant={participant} index={index} />
            </Box>
        ))}
    </>
));

export default AppDealParticipantsForm;
