import axios from 'axios';

class NewsAPI {
    getNewsList = (offset: number, limit: number) => {
        return axios.get(`/news/index?offset=${offset}&limit=${limit}`);
    };

    getNews = (id: number) => {
        return axios.get(`/news/${id}`);
    };

    getComplexNews = (complexId: number, offset: number, limit: number) => {
        return axios.get(`/news/zhk/${complexId}?limit=${limit}&offset=${offset}`);
    };
}

const newsAPI = new NewsAPI();
export default newsAPI;
