import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Checkbox, TextField, useMediaQuery, useTheme } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import { useStores } from '../../../hooks/useStores';
import { IFilterRoomsNumber } from '../../../types/CommonTypes';
import { useStyles } from '../style';

const ApartmentsFilterRoomsNumber = observer(({ onChange }: { onChange: any }) => {
    const { ApartmentStore: store } = useStores();
    const theme = useTheme();
    const isXS = useMediaQuery(theme.breakpoints.down('xs'));
    const classes = useStyles();

    return (
        <Box width={{ xxs: 256, xs: 145, sm: 256 }} mr={{ xxs: 1, sm: 2 }} mb={{ xxs: 1, sm: 2 }}>
            <Autocomplete
                classes={{ popper: classes.autoWidthPopper }}
                multiple
                limitTags={1}
                id="roomsNumber"
                popupIcon={<ExpandMoreIcon />}
                value={(store.filters.roomsNumber?.length && store.filters.roomsNumber) || []}
                onChange={onChange}
                options={store.rooms}
                getOptionLabel={(option: IFilterRoomsNumber) => option.title}
                disableCloseOnSelect
                renderOption={(option, { selected }) => (
                    <>
                        <Checkbox
                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                            style={{ marginRight: 8 }}
                            checked={selected}
                        />
                        {option.title}
                    </>
                )}
                renderInput={params => (
                    <TextField {...params} name="roomsNumber" variant="outlined" placeholder={isXS ? 'Комнат' : 'Количество комнат'} />
                )}
                noOptionsText="Нет подходящих вариантов"
                loading={store.filterLoading}
                loadingText="Загрузка"
            />
        </Box>
    );
});

export default ApartmentsFilterRoomsNumber;
