import { observable, action } from 'mobx';
import API from '../api';
import { ISystemInfo } from '../types/SystemTypes';

export class SystemStore {
    @observable data: ISystemInfo | null = null;
    @observable links: any = null;

    @action
    getCompanyInfo = () => {
        API.system.getCompanyInfo().then(response => {
            if (response?.data?.success) {
                this.data = response.data.data;
            }
        });
    };

    @action
    getDocsLinks = () => {
        API.system.getDocsLinks().then(response => {
            if (response?.data?.success) {
                this.links = response.data.data;
            }
        });
    };
}
