import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Grid, Typography } from '@material-ui/core';

import { useStores } from '../../../hooks/useStores';
import { useStyles } from '../style';

import ButtonTab from '../../UI/ButtonTab';
import AppDealPaymentDocs from './AppDealPaymentDocs';

const AppDealPaymentOptions = observer(() => {
    const { AppDealFormStore: store } = useStores();
    const classes = useStyles();

    return (
        <>
            <Box mb={3}>
                <Box mb={2}>
                    <Typography variant="subtitle1">Выберите льготы</Typography>
                </Box>

                <Grid container spacing={1}>
                    <Grid item xs={12} sm={4} lg>
                        <ButtonTab
                            activeTab={store.values.secondaryPayment}
                            setActiveTab={store.setSecondaryPayment}
                            name="noBenefits"
                            tabStyle={classes.tabStyle}
                            activeTabStyle={classes.activeTabStyle}
                            justifyContent="center"
                        >
                            <Typography variant="subtitle2">Без льгот</Typography>
                        </ButtonTab>
                    </Grid>
                    <Grid item xs={12} sm={4} lg>
                        <ButtonTab
                            activeTab={store.values.secondaryPayment}
                            setActiveTab={store.setSecondaryPayment}
                            name="Материнский капитал"
                            tabStyle={classes.tabStyle}
                            activeTabStyle={classes.activeTabStyle}
                            justifyContent="center"
                        >
                            <Typography variant="subtitle2">Материнский капитал</Typography>
                        </ButtonTab>
                    </Grid>
                    <Grid item xs={12} sm={4} lg>
                        <ButtonTab
                            activeTab={store.values.secondaryPayment}
                            setActiveTab={store.setSecondaryPayment}
                            name="Жилищный сертификат"
                            tabStyle={classes.tabStyle}
                            activeTabStyle={classes.activeTabStyle}
                        >
                            <Typography variant="subtitle2">Жилищный сертификат</Typography>
                        </ButtonTab>
                    </Grid>
                    <Grid item xs={12} sm={4} lg>
                        <ButtonTab
                            activeTab={store.values.secondaryPayment}
                            setActiveTab={store.setSecondaryPayment}
                            name="Военный сертификат"
                            tabStyle={classes.tabStyle}
                            activeTabStyle={classes.activeTabStyle}
                            justifyContent="center"
                        >
                            <Typography variant="subtitle2">Военный сертификат</Typography>
                        </ButtonTab>
                    </Grid>
                </Grid>
            </Box>
            {store.values.secondaryPayment !== 'noBenefits' && <AppDealPaymentDocs />}
        </>
    );
});

export default AppDealPaymentOptions;
