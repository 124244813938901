import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import Slider from 'react-slick';

import SliderArrow from './SliderArrow';
import { BankItem } from '../../UI/BankItem';

const BankSlider = ({ data }: { data: any }) => {
    const classes = useStyles();
    const sliderSettings = {
        infinite: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 2,
        rows: data?.length > 2 ? 2 : 1,
        prevArrow: <SliderArrow type="prev" />,
        nextArrow: <SliderArrow type="next" />,
    };

    const renderItems = data?.map((bank: any, i: any) => (
        <Box key={i} px={2} py={2}>
            <BankItem key={i} {...bank} />
        </Box>
    ));

    return (
        <>
            <Box mx={-1} className={classes.sliderWrapper}>
                <Slider className={classes.customSlider} {...sliderSettings}>
                    {renderItems}
                </Slider>
            </Box>
        </>
    );
};

const useStyles = makeStyles(theme => ({
    customSlider: {
        marginBottom: 0,
        '& .slick-list': {
            padding: '0 40px',
        },
    },
    sliderWrapper: {
        width: '500px',
    },
}));

export default BankSlider;
