import React from 'react';
import { observer } from 'mobx-react-lite';
import { Grid, makeStyles } from '@material-ui/core';

import { Field } from './Feild';
import { Banner } from './Banner';
import { useURLParams } from '../../../hooks/useURLParams';
import { useStores } from '../../../hooks/useStores';
import Loader from '../../Loader/Loader';

export const CalculatorBlock = observer(() => {
    const { CalculatorStore: store } = useStores();
    const classes = useStyle();
    useURLParams(store);

    const handleDownload = () => {
        store.downloadLink();
    };
    const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        if (name === 'cost') store.changeCost(value);
        if (name === 'contribution') store.changeContribution(value);
        if (name === 'contributionPercent') store.changeContributionPercent(value);
        if (name === 'timing') store.changeTiming(value);
        if (name === 'rate') store.changeRate(value);
    };

    return (
        <Grid container spacing={4} className={classes.root}>
            <Grid item xs={12} md={6}>
                <Field
                    inputs={[
                        {
                            id: 'cost',
                            label: 'Стоимость квартиры, ₽',
                            value: store.cost.toLocaleString(),
                            onChangeCapture: handleFieldChange,
                        },
                    ]}
                    slider={{
                        min: 0,
                        max: 100_000_000,
                        value: store.cost,
                        step: store.getCostStep,
                    }}
                    changeSlider={(e, value) => store.changeCost(value as number)}
                />
                <Field
                    inputs={[
                        {
                            id: 'contribution',
                            label: 'Первоначальный взнос, ₽',
                            value: store.contribution.toLocaleString(),
                            onChangeCapture: handleFieldChange,
                        },
                        {
                            id: 'contributionPercent',
                            label: 'В процентах, %',
                            value: store.getContributionPercent.toFixed(0),
                            onChangeCapture: handleFieldChange,
                        },
                    ]}
                    slider={{
                        min: 0,
                        max: store.cost,
                        value: store.contribution,
                        step: store.getContributinStep,
                    }}
                    changeSlider={(e, value) => store.changeContribution(value as number)}
                />
                <Field
                    inputs={[
                        {
                            id: 'timing',
                            label: 'Сроки ипотеки, лет',
                            value: store.timing,
                            onChange: handleFieldChange,
                        },
                    ]}
                    slider={{
                        min: 1,
                        max: 30,
                        step: null,
                        marks: [1, 1.5, 2, 3, 4, 5, 6, 7, 10, 15, 20, 25, 30].map(value => ({ value })),
                        value: store.timing,
                    }}
                    changeSlider={(e, value) => store.changeTiming(value as number)}
                />
                <Field
                    inputs={[
                        {
                            id: 'percent',
                            label: 'Процент ставки, %',
                            value: store.rate,
                            onChange: handleFieldChange,
                        },
                    ]}
                    slider={{
                        min: 0,
                        max: 99.9,
                        step: 0.1,
                        value: store.rate,
                    }}
                    changeSlider={(e, value) => store.changeRate(value as number)}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                {store.loading && <Loader isAbsolute />}
                <Banner firstPayment={store.firstPayment} closingDate={store.closingDate} onCopy={handleDownload} />
            </Grid>
        </Grid>
    );
});

const useStyle = makeStyles(theme => ({
    root: {
        marginBottom: theme.spacing(8),
    },
}));
