import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Grid, Typography } from '@material-ui/core';

import { IAppDealDocsView } from '../../../types/AppDealTypes';

import DocumentItemView from './DocumentItemView';

export const AppDealDocsView = observer((props: IAppDealDocsView) => {
    const { file_inn, inn, snils_number, file_snils, file_marriage_scan, file_notarial_scan, file_agree_parent_scan } = props.data;

    return (
        <>
            {!!inn && (
                <>
                    <Box mt={3} mb={2}>
                        <Typography variant="body2">ИНН</Typography>
                    </Box>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} lg={3}>
                            <Box mb={2}>
                                <Typography color="textSecondary" variant="caption">
                                    Номер ИНН
                                </Typography>
                                <Typography variant="body1">{inn || '-'}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={5}>
                            <DocumentItemView fileUrl={file_inn}>Скан ИНН</DocumentItemView>
                        </Grid>
                    </Grid>
                </>
            )}

            {!!snils_number && (
                <>
                    <Box mt={3} mb={2}>
                        <Typography variant="body2">СНИЛС</Typography>
                    </Box>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} lg={3}>
                            <Box mb={2}>
                                <Typography color="textSecondary" variant="caption">
                                    Номер СНИЛС
                                </Typography>
                                <Typography variant="body1">{snils_number || '-'}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={5}>
                            <DocumentItemView fileUrl={file_snils}>Скан СНИЛС</DocumentItemView>
                        </Grid>
                    </Grid>
                </>
            )}

            {!!file_marriage_scan && (
                <>
                    <Box mt={3} mb={2}>
                        <Typography variant="body2">Брачный договор</Typography>
                    </Box>
                    <Grid container>
                        <Grid item xs={12} sm={5}>
                            <DocumentItemView fileUrl={file_marriage_scan}>Скан брачного договора</DocumentItemView>
                        </Grid>
                    </Grid>
                </>
            )}
            {!!file_notarial_scan && (
                <>
                    <Box mt={3} mb={2}>
                        <Typography variant="body2">Нотариальное согласие другого супруга</Typography>
                    </Box>
                    <Grid container>
                        <Grid item xs={12} sm={5}>
                            <DocumentItemView fileUrl={file_notarial_scan}>Скан согласия</DocumentItemView>
                        </Grid>
                    </Grid>
                </>
            )}

            {!!file_agree_parent_scan && (
                <Grid item xs={12} sm={6}>
                    <DocumentItemView fileUrl={file_agree_parent_scan}>
                        Согласие законного представителя / одного из родителей
                    </DocumentItemView>
                </Grid>
            )}
        </>
    );
});

export default AppDealDocsView;
