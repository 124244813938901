import React, { useEffect, useMemo } from 'react';
import { observer, useLocalStore } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { Divider, Typography, Box, makeStyles, useMediaQuery, useTheme, Paper, IconButton } from '@material-ui/core';

import { IAppDealState1, IAppDealType } from '../../../types/AppDealTypes';
import callConsultant from '../../../img/call_consultant.svg';
import { useMenu } from '../../../hooks/useMenu';
import { checkForUnread } from '../../../utils/checkForUnread';
import { AppDealChatStore } from '../../../stores/AppDealChatStore';
import AppDealManagerPopup from '../AppDealManager/AppDealManagerPopup';
import AppDealObjectInfo from '../AppDealObjectInfo';
import { useStores } from '../../../hooks/useStores';

const AppDealItem = observer(
    ({ data, cardSizeIsDense = false, tabId }: { data: IAppDealState1; cardSizeIsDense: boolean; tabId: IAppDealType }) => {
        const { AppDealFormStore } = useStores();
        const chatStore = useLocalStore(() => new AppDealChatStore());

        const { anchorEl, handleOpen, handleClose } = useMenu();

        const { lead_id, subagent_date, subagent_number, stage_title, booking_status, booking_payment_link, client, object, manager } =
            data;
        const classes = useStyles();
        const history = useHistory();
        const theme = useTheme();
        const isMD = useMediaQuery(theme.breakpoints.down('md'));
        const isXS = useMediaQuery(theme.breakpoints.down('xs'));
        const isXXS = !useMediaQuery(theme.breakpoints.up('xs'));

        const dealTitle = useMemo(() => {
            const initial = stage_title || 'Заявка';
            if (tabId !== 'applications') {
                return initial;
            }

            switch (booking_status) {
                case 'paid_wait':
                    return 'Ожидает оплаты брони';
                case 'paid':
                    return 'Платная бронь';
                default:
                    return initial;
            }
        }, [stage_title, tabId, booking_status]);

        // Effects
        useEffect(() => {
            manager && chatStore.getMessages(lead_id);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        // Handlers
        const modalOpen = (e: any) => {
            e.stopPropagation();
            handleOpen(e);
        };

        const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
            history.push(`/applications-and-deals/${lead_id}`);
        };

        const markAsRead = () => chatStore.markAsRead(lead_id);

        const handleOpenPayLinkModal = (e: React.MouseEvent<HTMLAnchorElement>) => {
            // Подхватываем клик на карточку заявки
            e.preventDefault();
            e.stopPropagation();

            AppDealFormStore.setPaymentLink(booking_payment_link!);
            AppDealFormStore.setPaymentLinkModalOpen(true);
        };

        // Renders
        const renderPayLink = () => {
            if (booking_status === 'paid_wait' && booking_payment_link?.length) {
                return (
                    <a href="/" onClick={handleOpenPayLinkModal}>
                        • Ссылка на оплату
                    </a>
                );
            }
        };

        const renderContent = () => {
            return (
                <Box onClick={handleClick}>
                    <AppDealManagerPopup
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        data={manager}
                        messages={chatStore.messages}
                        markAsRead={markAsRead}
                    />

                    <Paper variant="outlined" className={classes.itemWrapper}>
                        <Box p={2}>
                            <Box mb={2} display="flex" justifyContent="space-between">
                                <Typography variant="body2" color="primary">
                                    {dealTitle} {renderPayLink()}
                                </Typography>

                                {manager && (
                                    <IconButton onClick={modalOpen}>
                                        <Box position="relative">
                                            {checkForUnread(chatStore.messages) && <Box className={classes.messagesIndicator} />}
                                            <img src={callConsultant} alt="контакты_консультанта" />
                                        </Box>
                                    </IconButton>
                                )}
                            </Box>
                            <Box display={{ xxs: 'block', sm: 'flex' }} justifyContent="space-between" mx={-1} my={0.5}>
                                <Box display={{ xxs: 'block', sm: 'inline-flex' }} px={1} my={{ xxs: 0.5, sm: 0 }}>
                                    {/*<Typography variant="body1">Тип собственности</Typography>*/}
                                </Box>
                                {!isXS && (
                                    <Box display={{ xxs: 'block', sm: 'inline-flex' }} px={1} my={{ xxs: 0.5, sm: 0 }}>
                                        <Typography>№ {subagent_number}</Typography>
                                    </Box>
                                )}
                            </Box>
                            <Box display={{ xxs: 'block', sm: 'flex' }} justifyContent="space-between" mx={-1} my={0.5}>
                                <Box display={{ xxs: 'block', sm: 'inline-flex' }} px={1} my={{ xxs: 0.5, sm: 0 }}>
                                    {client?.name ? <Typography variant="h4">{client.name}</Typography> : '-'}
                                    {/*{client?.phone ? (*/}
                                    {/*    <NumberFormat value={client?.phone} format="+# ### ###-##-##" mask="_" displayType="text" />*/}
                                    {/*) : (*/}
                                    {/*    '-'*/}
                                    {/*)}*/}
                                    {/*</Typography>*/}
                                </Box>
                                {!isXS && (
                                    <Box display={{ xxs: 'block', sm: 'inline-flex' }} px={1} my={{ xxs: 0.5, sm: 0 }}>
                                        <Typography variant="body2" color="textSecondary">
                                            {subagent_date && subagent_date !== '0000-00-00' && moment(subagent_date).format('DD.MM.YYYY')}
                                        </Typography>
                                    </Box>
                                )}
                            </Box>

                            {!cardSizeIsDense && isMD && (
                                <Box my={{ xxs: 2, sm: 3 }}>
                                    <Divider />
                                </Box>
                            )}

                            {!cardSizeIsDense && <AppDealObjectInfo object={object} />}

                            {isXS && (
                                <>
                                    <Box my={2}>
                                        <Divider />
                                    </Box>
                                    <Box display="flex" justifyContent="space-between">
                                        <Typography variant={isXXS ? 'body2' : 'body1'}>№ {subagent_number}</Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            {subagent_date && subagent_date !== '0000-00-00' && moment(subagent_date).format('DD.MM.YYYY')}
                                        </Typography>
                                    </Box>
                                </>
                            )}
                        </Box>
                    </Paper>
                </Box>
            );
        };

        if (isMD) {
            return (
                <Box mb={3}>
                    <Paper elevation={0}>
                        <Box p={{ xxs: 2, sm: 3 }}>{renderContent()}</Box>
                    </Paper>
                </Box>
            );
        }

        return <Box mb={3}>{renderContent()} </Box>;
    },
);

const useStyles = makeStyles(theme => ({
    itemWrapper: {
        //@ts-ignore
        border: `1px solid ${theme.palette.primary.A50}`,
        borderRadius: 8,
        cursor: 'pointer',
        '&:hover': {
            //@ts-ignore
            borderColor: theme.palette.primary.A100,
            boxShadow: '0px 43px 58px rgba(94, 1, 198, 0.04), 0px 15px 19px rgba(0, 0, 0, 0.03), 0px 7.36424px 9px rgba(0, 0, 0, 0.01)',
        },
    },
    messagesIndicator: {
        width: 13,
        height: 13,
        background: '#FCD657',
        borderRadius: '50%',
        position: 'absolute',
        right: -5,
        top: -5,
    },
    contactIcon: {
        cursor: 'pointer',
        // @ts-ignore
        color: theme.palette.primary.A200,
    },
}));

export default AppDealItem;
