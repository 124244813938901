import React from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { conformToMask } from 'react-text-mask';
import moment from 'moment';
import { Box, Container, Divider, Avatar, Grid, Typography, Button, useMediaQuery, useTheme } from '@material-ui/core';

import avatar from '../../img/default-profile.png';
import { useStores } from '../../hooks/useStores';
import { useStyles } from './style';

import Loader from '../../components/Loader/Loader';
import RequestError from '../../components/RequestError/RequestError';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import NotificationsSettings from '../../components/NotificationsSettings';

const Profile = observer(() => {
    const { AuthStore, ProfileStore } = useStores();
    const classes = useStyles();
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('md'));

    if (ProfileStore.loading) {
        return <Loader />;
    }

    const renderProfileInfo = (key: string, value: string | null) => {
        if (value) {
            return (
                <Box mt={2} mb={2}>
                    <Grid container spacing={isSmall ? 0 : 4}>
                        <Grid item xs={12} md={3}>
                            <Typography color="textSecondary" component="span">
                                {key}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={9}>
                            <Typography component="span">{value}</Typography>
                        </Grid>
                    </Grid>
                </Box>
            );
        }
    };

    const renderContent = () => {
        if (ProfileStore.profileInfo?.success) {
            const { data } = ProfileStore.profileInfo;

            return (
                <>
                    <Box bgcolor="#F8F8FA" pt={3} pb={3}>
                        <Container maxWidth="xl">
                            <Breadcrumbs data={['Главная', 'Профиль']} />

                            <Box mt={3} mb={2}>
                                <Avatar src={data.photo?.fullUrl || avatar} className={classes.avatar} />
                            </Box>

                            <Grid container justify="space-between">
                                <Grid item xs={12} md={6}>
                                    <Box textAlign={{ xxs: 'center', md: 'left' }} mb={{ xxs: 3, md: 0 }}>
                                        <Typography variant="h3" component="span">
                                            {data.name}
                                        </Typography>
                                    </Box>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <Grid container spacing={3} wrap={'nowrap'} className={classes.mainButtonsWrap}>
                                        <Grid item>
                                            <Link to="/profile/edit">
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    disableElevation
                                                    className="MuiButton-smallText text-nowrap"
                                                >
                                                    Настроить профиль
                                                </Button>
                                            </Link>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                onClick={AuthStore.logout}
                                                disabled={AuthStore.logoutLoading}
                                            >
                                                Выход
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Container>
                    </Box>

                    <Divider />

                    <Container maxWidth="xl">
                        <Box mt={6} mb={4}>
                            <Box mb={2}>
                                <Typography variant="h4" component="p">
                                    Общая информация
                                </Typography>
                            </Box>
                            <Divider />
                            {renderProfileInfo('Электронная почта', data.email)}
                            {renderProfileInfo(
                                'Телефон',
                                conformToMask(
                                    data.phone,
                                    ['+', /[0-9]/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/],
                                    {
                                        guide: false,
                                    },
                                ).conformedValue,
                            )}
                            {renderProfileInfo('Агентство', data.agency?.companyName)}
                            {renderProfileInfo(
                                'Дата рождения',
                                data.date_birth ? moment(data.date_birth, 'YYYY-MM-DD').format('DD.MM.YYYY') : null,
                            )}
                        </Box>

                        <Link to="/profile/change-password">
                            <Typography color="primary" component="span">
                                Сменить пароль
                            </Typography>
                        </Link>

                        <NotificationsSettings />
                    </Container>
                </>
            );
        } else if (!ProfileStore.profileInfo?.success) {
            return <RequestError />;
        }

        return null;
    };

    return (
        <Box pb={4} width={'100%'}>
            {renderContent()}
        </Box>
    );
});

export default Profile;
