// @ts-nocheck
import React, { useState, useEffect } from 'react';
import { Box, Typography, Avatar, Grid, TextField, Button, InputAdornment, useTheme, useMediaQuery, IconButton } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import PhotoCameraOutlinedIcon from '@material-ui/icons/PhotoCameraOutlined';
import EventOutlinedIcon from '@material-ui/icons/EventOutlined';
import ClearIcon from '@material-ui/icons/Clear';

import { useHistory } from 'react-router-dom';
import { useStyles } from './styles';
import { EmailFormat, PhoneFormat } from '../UI/Form';
import { conformToMask } from 'react-text-mask';
import { IProfileEditState } from '../../types/ProfileTypes';
import { useStores } from '../../hooks/useStores';
import { observer } from 'mobx-react-lite';
import avatar from '../../img/default-profile.png';

import { MobileDatePicker, LocalizationProvider } from '@material-ui/pickers';
import MomentAdapter from '@material-ui/pickers/adapter/moment';
import moment from 'moment';
import { cleanupMask } from '../../utils/cleanupMask';
import { config } from '../../Config';
moment.locale('ru');

const ProfileEditForm = observer(() => {
    const { ProfileStore: store, AgencyStore } = useStores();
    // const store = useLocalStore(() => new ProfileStore());
    const classes = useStyles();
    const history = useHistory();
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('md'));

    const [values, setValues] = useState<IProfileEditState>({
        name: '',
        email: '',
        agency: {
            companyName: '',
            companyInn: '',
        },
        phone: '',
        date_birth: null,
        photo: null,
    });
    const [agency, setAgency] = useState<any>(null);

    const handleChange = (event: any) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value,
        });
    };

    const handleChangeDate = (date: any) => {
        setValues({
            ...values,
            date_birth: date,
        });
    };

    const clearBirthday = () => {
        setValues({
            ...values,
            date_birth: null,
        });
    };

    const handleChangeAvatar = (event: any) => {
        event.preventDefault();

        setValues({
            ...values,
            photo: event.target.files[0],
        });
    };

    const handleClick = (e: any) => {
        e.preventDefault();

        const sendData: any = Object.assign(
            {},
            {
                ...values,
                phone: cleanupMask(values.phone),
            },
        );

        if (sendData.date_birth) {
            sendData.date_birth = moment(sendData.date_birth).format('YYYY-MM-DD');
        } else {
            sendData.date_birth = '';
        }

        sendData.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        // console.log('handleClick -> sendData', sendData);

        store.updateProfileInfo(sendData, history);
    };

    const handleCancel = (e: any) => {
        e.preventDefault();

        history.push('/profile');
    };

    const getPhoto = () => {
        if (values.photo) {
            if (values.photo.fullUrl) {
                return values.photo.fullUrl;
            }

            return URL.createObjectURL(values.photo);
        }

        return avatar;
    };

    const getOptions = (currValue: any, options: any) => {
        // store.targets ? [store.formData.tripObject, ...store.targets.data
        if (currValue?.value) {
            if (options) {
                if (!options.find((item: any) => item.value === currValue.value)) {
                    return [...options, currValue];
                }
                // const newOptions = options.filter((item: any) => item.value !== currValue.value);
                // newOptions.push(currValue);

                // return newOptions;
            }

            return [currValue];
        }

        if (options) {
            return [...options];
        }

        return [];
    };

    const handleChangeAgency = (data: any) => {
        setValues({
            ...values,
            agency: {
                companyName: data.value,
                companyInn: data.data.inn,
            },
        });

        setAgency(data);
    };

    const handleRemoveAvatar = () => {
        setValues({
            ...values,
            photo: null,
        });
    };

    useEffect(() => {
        const data = store.profileInfo?.data;

        if (data) {
            setValues({
                name: data.name,
                email: data.email,
                agency: {
                    companyName: data.agency?.companyName,
                    companyInn: data.agency?.companyInn,
                },
                phone: conformToMask(
                    data.phone,
                    ['+', /[0-9]/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/],
                    {
                        guide: false,
                    },
                ).conformedValue,
                date_birth: data.date_birth ? moment(data.date_birth, 'YYYY-MM-DD') : null,
                photo: data.photo,
            });

            if (data.agency) {
                setAgency({
                    value: data.agency?.companyName,
                    data: {
                        inn: data.agency?.companyInn,
                    },
                });
            }
            // let newAgency: string[] = data.agency.split(' (ИНН ');

            // if (newAgency.length === 2) {
            // }
        }
    }, [store.profileInfo]);

    useEffect(() => {
        store.getProfileInfo();
        store.resetErrors();
    }, [store]);

    return (
        <form noValidate>
            <Box display="inline-block">
                <Grid container direction="column" alignItems="center">
                    <Grid item>
                        <label className={classes.fileAvatarWrap}>
                            <input type="file" accept="image/*" name="photo" onChange={handleChangeAvatar} hidden />
                            <Avatar src={getPhoto()} style={{ width: '72px', height: '72px' }} />
                            <div className={classes.fileAvatarIcon}>
                                <PhotoCameraOutlinedIcon color="inherit" fontSize="large" />
                            </div>
                        </label>
                    </Grid>
                    <Grid item>
                        <Button color="primary" onClick={handleRemoveAvatar}>
                            Удалить
                        </Button>
                    </Grid>
                </Grid>
            </Box>

            <Box mt={2} mb={2}>
                <Grid container alignItems="center" spacing={isSmall ? 0 : 4}>
                    <Grid item xs={12} md={3}>
                        <Typography color="textSecondary" component="span">
                            ФИО:
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <TextField
                            variant="outlined"
                            margin="none"
                            required
                            fullWidth
                            id="name"
                            name="name"
                            placeholder="ФИО"
                            onChange={handleChange}
                            value={values.name}
                            error={!!store.profileEditErrors?.name}
                            helperText={store.profileEditErrors?.name}
                            disabled={store.loading}
                            InputProps={{ notched: false }}
                        />
                    </Grid>
                </Grid>
            </Box>
            <Box mt={2} mb={2}>
                <Grid container alignItems="center" spacing={isSmall ? 0 : 4}>
                    <Grid item xs={12} md={3}>
                        <Typography color="textSecondary" component="span">
                            Электронная почта:
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <TextField
                            variant="outlined"
                            margin="none"
                            required
                            fullWidth
                            id="email"
                            name="email"
                            placeholder="example@mail.ru"
                            InputProps={{
                                inputComponent: EmailFormat,
                                notched: false,
                            }}
                            onChange={handleChange}
                            value={values.email}
                            error={!!store.profileEditErrors?.email}
                            helperText={store.profileEditErrors?.email}
                            disabled={store.loading}
                        />
                    </Grid>
                </Grid>
            </Box>
            <Box mt={2} mb={2}>
                <Grid container alignItems="center" spacing={isSmall ? 0 : 4}>
                    <Grid item xs={12} md={3}>
                        <Typography color="textSecondary" component="span">
                            Телефон:
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <TextField
                            variant="outlined"
                            margin="none"
                            required
                            fullWidth
                            id="phone"
                            name="phone"
                            placeholder={config.phonePlaceholderWithZero}
                            InputProps={{
                                inputComponent: PhoneFormat,
                                notched: false,
                            }}
                            // onChange={handleChange}
                            value={values.phone}
                            // error={!!store.profileEditErrors?.phone}
                            // helperText={store.profileEditErrors?.phone}
                            disabled={true}
                        />
                    </Grid>
                </Grid>
            </Box>
            <Box mt={2} mb={2}>
                <Grid container alignItems="center" spacing={isSmall ? 0 : 4}>
                    <Grid item xs={12} md={3}>
                        <Typography color="textSecondary" component="span">
                            Агентство:
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <Autocomplete
                            value={agency || null}
                            // options={AgencyStore?.agency || []}
                            options={getOptions(agency, AgencyStore?.agency)}
                            // options={store.organiztions?.data || []}
                            getOptionLabel={(option: any) => `${option.value}${option.data.inn ? ` (ИНН ${option.data.inn} )` : ''}`}
                            getOptionSelected={(option: any, value: any) => option.value === value.value}
                            onChange={(e, value) => handleChangeAgency(value)}
                            loadingText="Поиск..."
                            noOptionsText="Не найдено"
                            className="input-wrap"
                            disableClearable={true}
                            renderInput={(params: any) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    margin="none"
                                    placeholder="Агентство"
                                    name="agency"
                                    fullWidth
                                    onChange={e => AgencyStore.getAgency(e.target.value)}
                                    error={!!store.profileEditErrors?.agency}
                                    helperText={store.profileEditErrors?.agency}
                                    disabled={store.loading || store.profileInfo?.data?.agency?.isConfirmed}
                                    InputProps={{ notched: false }}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
            </Box>
            <Box mt={2} mb={2}>
                <Grid container alignItems="center" spacing={isSmall ? 0 : 4}>
                    <Grid item xs={12} md={3}>
                        <Typography color="textSecondary" component="span">
                            Дата рождения:
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <LocalizationProvider dateLibInstance={moment} dateAdapter={MomentAdapter} locale="ru">
                            <MobileDatePicker
                                // autoOk
                                okText="Ок"
                                cancelText="Отмена"
                                inputFormat="DD.MM.YYYY"
                                toolbarPlaceholder="Дата рождения"
                                toolbarTitle=""
                                maxDate={moment()}
                                value={values.date_birth}
                                onChange={handleChangeDate}
                                renderInput={props => (
                                    <TextField
                                        {...props}
                                        id="date_birth"
                                        name="date_birth"
                                        className="input-wrap"
                                        InputProps={{
                                            autoComplete: 'new-password',
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    {values.date_birth ? (
                                                        <IconButton aria-label="toggle password visibility" onClick={clearBirthday}>
                                                            <ClearIcon />
                                                        </IconButton>
                                                    ) : (
                                                        <EventOutlinedIcon />
                                                    )}
                                                </InputAdornment>
                                            ),
                                            notched: false,
                                        }}
                                        fullWidth
                                        error={!!store.profileEditErrors?.date_birth}
                                        helperText={store.profileEditErrors?.date_birth}
                                        disabled={store.loading}
                                        variant="outlined"
                                    />
                                )}
                            />
                        </LocalizationProvider>
                        {/* <DatePicker
                            id="date_birth"
                            name="date_birth"
                            format="DD.MM.YYYY"
                            inputVariant="outlined"
                            variant="dialog"
                            className="input-wrap"
                            cancelLabel="Отмена"
                            okLabel="Ок"
                            fullWidth
                            // autoOk
                            required
                            placeholder="Дата рождения"
                            disableFuture={true}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <EventOutlinedIcon />
                                    </InputAdornment>
                                ),
                            }}
                            onChange={handleChangeDate}
                            value={values.date_birth}
                            error={!!store.profileEditErrors?.date_birth}
                            helperText={store.profileEditErrors?.date_birth}
                            disabled={store.loading}
                        /> */}
                        {/* <MuiPickersUtilsProvider utils={MomentUtils}>
                        </MuiPickersUtilsProvider> */}
                    </Grid>
                </Grid>
            </Box>
            <Box maxWidth={400} width={'100%'} mt={6} mx={{ xxs: 'auto', md: 0 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Button variant="outlined" color="primary" size="large" onClick={handleCancel} disabled={store.loading} fullWidth>
                            Отменить
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            size="large"
                            fullWidth
                            onClick={handleClick}
                            disabled={store.loading}
                        >
                            Сохранить
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </form>
    );
});

export default ProfileEditForm;
