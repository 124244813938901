import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Grid, Paper, Typography, makeStyles } from '@material-ui/core';

import { useStores } from '../../../hooks/useStores';

import DocumentItemForm from '../AppDealDocs/DocumentItemForm';

const AppDealPetitionForm = observer(() => {
    const { AppDealFormStore: store } = useStores();
    const classes = useStyles();

    return (
        <Box mb={{ xxs: 2, md: 4 }}>
            <Paper className={classes.paper}>
                <Box p={2} pb={3}>
                    <Box mb={2}>
                        <Typography variant="subtitle1">Согласие на бронирование</Typography>
                    </Box>
                    <Grid item xs={12} sm={5}>
                        <DocumentItemForm
                            objKey="petitionScan"
                            defaultFilePath={store.appDeal?.client?.file_petition || ''}
                            defaultFileUuid={store.appDeal?.client?.petitionScanUuid || ''}
                            store={store}
                        >
                            Скан согласия на бронирование
                        </DocumentItemForm>
                    </Grid>
                </Box>
            </Paper>
        </Box>
    );
});

const useStyles = makeStyles(theme => ({
    paper: {
        borderRadius: 16,
        background: theme.palette.surface.main,
        boxShadow: 'none',
    },
}));

export default AppDealPetitionForm;
