import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
    fileAvatarWrap: {
        position: 'relative',
        display: 'inline-block',
        cursor: 'pointer',
    },
    fileAvatarIcon: {
        position: 'absolute',
        display: 'flex',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        justifyContent: 'center',
        alignItems: 'center',
        color: '#fff',
        background: 'rgba(0, 0, 0, 0.38)',
        borderRadius: '50%',
    },
});
