import { observable, action } from 'mobx';
import API from '../api';
import { IRatingsState } from '../types/RatingTypes';

export class RatingsStore {
    @observable loading = false;
    @observable ratings: IRatingsState | null = null;

    @action
    getRatings = () => {
        const cache = JSON.parse(localStorage.getItem('_abd_ratings') || 'null');

        if (cache) {
            this.loading = true;
            this.ratings = cache;

            setTimeout(() => {
                this.loading = false;
            }, 400);
        } else {
            this.loading = true;

            API.ratings
                .getRatings()
                .then(response => {
                    if (response?.data?.success) {
                        this.ratings = response.data.data;
                        localStorage.setItem('_abd_ratings', JSON.stringify(response.data.data));
                    }
                })
                .catch(error => {
                    if (error.response?.status) {
                        setTimeout(() => {
                            this.getRatings();
                        }, 200);
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    };
}
