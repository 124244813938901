export const getActiveTab = (hash: string) => {
    if (hash.includes('deals')) {
        return 'deals';
    }

    if (hash.includes('history')) {
        return 'history';
    }

    return 'deals';
};
