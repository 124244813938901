// @ts-nocheck
import React, { useState } from 'react';
import { TextField, Modal, makeStyles, createStyles, Button, Paper, Box, InputAdornment, Typography, IconButton } from '@material-ui/core';
import DateRangeIcon from '@material-ui/icons/DateRange';
import ClearIcon from '@material-ui/icons/Clear';

import { LocalizationProvider, StaticDateRangePicker } from '@material-ui/pickers';
import { DateRange } from '@material-ui/pickers/DateRangePicker/RangeTypes';
import MomentAdapter from '@material-ui/pickers/adapter/moment';
import moment from 'moment';
import 'moment/locale/ru';
moment.locale('ru');

interface IProps {
    date: DateRange | [null, null];
    onChange: any;
    modalTitle: string;
    disabled?: boolean;
}

const MuiModalRangePicker = (props: IProps) => {
    const { date, onChange, modalTitle, disabled } = props;
    const classes = useStyles();
    const [tempDate, setTempDate] = useState<DateRange>(date);
    // const [date, setDate] = useState<DateRange>([null, null]);
    const [open, setOpen] = React.useState<boolean>(false);

    const handleOpen = () => {
        if (!disabled) {
            setOpen(true);
        }
    };

    const handleClose = () => {
        setOpen(false);
        setTempDate(date);
    };

    const handleChange = (date: DateRange) => {
        setTempDate(date);
    };

    const handleAccept = () => {
        setOpen(false);
        onChange(tempDate);
    };

    const handleReset = (e: any) => {
        e.stopPropagation();
        e.preventDefault();
        setTempDate([null, null]);
        onChange([null, null]);
    };

    const getDate = (value: any) => {
        if (value[0] && value[1]) {
            const startDate = moment(value[0]._d).format('DD.MM.YYYY');
            const endDate = moment(value[1]._d).format('DD.MM.YYYY');

            return `${startDate} - ${endDate}`;
        }

        return '';
    };

    return (
        <div className={classes.root}>
            <Box>
                <TextField
                    placeholder="Выбрать период"
                    variant="outlined"
                    fullWidth
                    value={getDate(date)}
                    onClick={handleOpen}
                    disabled={disabled}
                    InputProps={{
                        endAdornment: !disabled && (
                            <InputAdornment position="end">
                                {date[0] && date[1] ? (
                                    <IconButton size="small" aria-label="toggle password visibility" onClick={handleReset}>
                                        <ClearIcon />
                                    </IconButton>
                                ) : (
                                    <DateRangeIcon />
                                )}
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>
            <Modal open={open} onClose={handleClose}>
                <Paper elevation={24} className={classes.paper}>
                    <Box component="span" className={classes.toolbarTitle}>
                        <Typography variant="overline">{modalTitle}</Typography>
                    </Box>
                    <LocalizationProvider dateLibInstance={moment} dateAdapter={MomentAdapter} locale="ru">
                        <StaticDateRangePicker
                            displayStaticWrapperAs="desktop"
                            startText="Дата от"
                            endText="Дата до"
                            showToolbar={true}
                            value={tempDate}
                            onChange={date => handleChange(date)}
                            renderInput={(startProps, endProps) => (
                                <>
                                    <TextField {...startProps} />
                                    <TextField {...endProps} />
                                </>
                            )}
                            disabled={disabled}
                        />
                    </LocalizationProvider>
                    <Box p={2} pt={0} display="flex" justifyContent="flex-end">
                        <Button color="primary" onClick={handleClose}>
                            Отменить
                        </Button>
                        <Button color="primary" onClick={handleAccept}>
                            Ок
                        </Button>
                    </Box>
                </Paper>
            </Modal>
        </div>
    );
};

const useStyles = makeStyles(theme =>
    createStyles({
        root: {
            position: 'relative',
        },
        paper: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            minWidth: 320,
            borderRadius: 16,
            transform: 'translate(-50%, -50%)',
            background: '#fff',
            overflow: 'hidden',
            '&:focus': {
                outline: 'none',
            },
            '&>div': {
                margin: '-1px -1px -1px -1px',
                backgroundColor: 'transparent',
            },
            '& .MuiPickersToolbar-toolbar': {
                paddingTop: 8,
                paddingBottom: 16,
            },
            '& .MuiPickersToolbar-dateTitleContainer': {
                justifyContent: 'center',
            },
            '& .MuiPickersDateRangePickerToolbarProps-penIcon, & .MuiPickersToolbar-toolbar > span': {
                display: 'none',
            },
            '& .MuiPickersDesktopDateRangeCalendar-rangeCalendarContainer:not(:last-child)': {
                position: 'relative',
                border: 'none',
                '&:before': {
                    content: `''`,
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    width: 2,
                    height: '100%',
                    background: 'linear-gradient(to bottom, rgba(237,237,237,1) 0%, rgba(246,246,246,1) 60%, rgba(255,255,255,1) 100%)',
                },
            },
        },
        toolbarTitle: {
            display: 'flex',
            justifyContent: 'center',
            color: '#fff',
            padding: '16px 24px 0',
            background: theme.palette.primary.main,
        },
    }),
);

export default MuiModalRangePicker;
