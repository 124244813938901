import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const RadioIcon = (props: SvgIconProps) => (
    <SvgIcon {...props}>
        <circle cx="12" cy="12" r="8" fill="#EBE0F7" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12ZM4 12C4 16.42 7.58 20 12 20C16.42 20 20 16.42 20 12C20 7.58 16.42 4 12 4C7.58 4 4 7.58 4 12Z"
            fill="#AC85E0"
        />
    </SvgIcon>
);

export default RadioIcon;
