import axios from 'axios';

class StockAPI {
    getStocks = (offset: number, limit: number) => {
        return axios.get(`/stock/index?offset=${offset}&limit=${limit}`);
    };

    getStock = (id: number) => {
        return axios.get(`/stock/${id}`);
    };

    getComplexStocks = (complexId: number, offset: number, limit: number) => {
        return axios.get(`/stock/zhk/${complexId}?limit=${limit}&offset=${offset}`);
    };

    getStockNotification = () => {
        return axios.get(`/stock/notification`);
    };
}

const stockAPI = new StockAPI();
export default stockAPI;
