import axios from 'axios';

class LearningsAPI {
    getLearnings = (offset: number, limit: number) => {
        return axios.get(`/education/index?limit=${limit}&offset=${offset}`);
    };
}

const learningsAPI = new LearningsAPI();
export default learningsAPI;
