import React from 'react';
import clsx from 'clsx';
import { Box, Grid, makeStyles, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import PhotoCameraOutlinedIcon from '@material-ui/icons/PhotoCameraOutlined';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';

import { IAppDealObjectInfo } from '../../types/AppDealTypes';

const AppDealObjectInfo = ({ object, type }: { object: IAppDealObjectInfo; type?: string }) => {
    const classes = useStyles();
    const theme = useTheme();
    const isXS = useMediaQuery(theme.breakpoints.down('xs'));
    const isXXS = !useMediaQuery(theme.breakpoints.up('xs'));

    return (
        <Box
            py={{ xxs: type === 'history' ? 2 : 0, lg: 2 }}
            px={{ xxs: type === 'history' ? 3 : 0, lg: 3 }}
            border={{ xxs: type === 'history' ? 1 : 0, lg: 1 }}
            borderRadius={16}
            borderColor={{ xxs: type === 'history' ? 'grey.200' : '#fff', lg: 'grey.200' }}
            mt={{ xxs: 2, lg: 4 }}
            mb={{ xxs: 2, sm: 0 }}
            className={classes.item}
        >
            <Grid container spacing={isXS && !isXXS ? 2 : 2}>
                <Grid item className={classes.imgCol}>
                    {object?.images.length ? (
                        <img src={object?.images[0] || ''} alt="" className={classes.img} />
                    ) : (
                        <Box width="100%" height="100%" display="flex" alignItems="center" justifyContent="center">
                            <PhotoCameraOutlinedIcon color="primary" className={classes.emptyImgIcon} />
                        </Box>
                    )}
                </Grid>

                <Grid item xs>
                    <Typography variant="body2" color="textSecondary" className={classes.withMargin}>
                        {object?.complex}
                    </Typography>
                    <Typography variant={isXS ? 'h4' : 'h3'} className={classes.withMargin}>
                        {object?.price_full}
                    </Typography>

                    <Typography variant={isXS ? 'body2' : 'body1'} className={classes.withMargin}>
                        {object?.rooms_number} комн. №{object?.object_number} • {object?.total_area} м²
                    </Typography>

                    {!isXXS ? (
                        <Box className={clsx(classes.address, classes.withMargin)}>
                            <LocationOnOutlinedIcon
                                className={clsx(classes.pin, classes.mediumBlackColor)}
                                fontSize={isXS ? 'small' : 'default'}
                            />
                            <Typography variant="body2" color="textSecondary">
                                {object?.address}
                            </Typography>
                        </Box>
                    ) : null}
                </Grid>
            </Grid>
        </Box>
    );
};

const useStyles = makeStyles(theme => ({
    item: {
        color: 'rgba(0, 0, 0, 0.87)',
        '&:hover img': {
            transform: 'scale(1.03)',
        },
    },

    imgCol: {
        width: 96,
        [theme.breakpoints.down('sm')]: {
            width: '27%',
        },
    },
    img: {
        transition: 'transform ease 0.3s',
    },
    emptyImgIcon: {
        fontSize: 38,
        color: '#EBE0F7',
    },
    address: {
        display: 'flex',
        alignItems: 'center',
    },
    pin: {
        marginRight: 5,
    },
    withMargin: {
        marginBottom: 6,
    },
    mediumBlackColor: {
        color: 'rgba(0, 0, 0, 0.6)',
    },
    tools: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
    },
    lockBtn: {
        borderRadius: 20,
    },
}));

export default AppDealObjectInfo;
