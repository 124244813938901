import React, { useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Box, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import queryString from 'query-string';

import { IApartmentItem } from '../../../types/ApartmentTypes';
import { useStores } from '../../../hooks/useStores';

import Loader from '../../Loader/Loader';
import ApartmentItem from './ApartmentItem';
import { useHistory } from 'react-router-dom';

const ApartmentListItems = observer(() => {
    const { ApartmentStore: store } = useStores();
    const { location } = useHistory();
    const theme = useTheme();
    const isXS = useMediaQuery(theme.breakpoints.down('xs'));

    useEffect(() => {
        const parsed = queryString.parse(location.search);
        const filters = {
            complex: parsed.complex || '',
            houseNumber: parsed.houseNumber || '',
            objectNumber: parsed.objectNumber || '',
            roomsNumber: parsed.roomsNumber || '',
            priceFrom: parsed.priceFrom || '',
            priceTo: parsed.priceTo || '',
            totalAreaFrom: parsed.totalAreaFrom || '',
            totalAreaTo: parsed.totalAreaTo || '',
            floorNumberFrom: parsed.floorNumberFrom || '',
            floorNumberTo: parsed.floorNumberTo || '',
            facing: parsed.facing || '',
            isHideBooked: parsed.isHideBooked || '',
            types: parsed.types || '',
            exceptFirstFloor: parsed.exceptFirstFloor || '',
            exceptLastFloor: parsed.exceptLastFloor || '',
            devEnd: parsed.devEnd || '',
            sort: parsed.sort || '',
        };

        store.getApartments(store.paginationPage, 20, filters);
    }, [location.search, store, store.paginationPage]);

    const fetchMoreApartments = () => {
        store.setPaginationPage(store.paginationPage + 1);
    };

    return (
        <>
            {!store.apartments.length && (
                <Box display="flex" alignItems="center" justifyContent="center" width="100%" height="100%">
                    {store.loading ? (
                        <Loader />
                    ) : (
                        <Typography variant={isXS ? 'body2' : 'body1'}>По Вашему запросу не найдено квартир</Typography>
                    )}
                </Box>
            )}

            <InfiniteScroll
                dataLength={store.apartments.length}
                next={fetchMoreApartments}
                hasMore={
                    store.apartments.length > 0 && store.apartmentsPagination?.totalCount !== store.apartmentsPagination?.currentOffset
                }
                height={isXS ? 210 : 632}
                loader={
                    <Box
                        position="relative"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        flexDirection="column"
                        width="100%"
                        minHeight="120px"
                    >
                        <Loader />
                    </Box>
                }
                style={{
                    overflowY: 'scroll',
                    overflowX: 'hidden',
                }}
            >
                {store.apartments.map((apartment: IApartmentItem) => (
                    <ApartmentItem key={apartment.object_id} data={apartment} />
                ))}
            </InfiniteScroll>
        </>
    );
});

export default ApartmentListItems;
