import API from '../api';
import { action, observable } from 'mobx';
import { IFavoriteAll, IFavoriteInline } from '../types/FavoriteTypes';
import { showNotification } from '../utils/Notification';

export class FavoriteStore {
    @observable favorites: IFavoriteAll | null = null;
    @observable folderFavorite: IFavoriteInline[] | null = null;
    @observable activeFolderId: number | null = null;
    @observable activeFolderTitle: string = '';
    @observable selectedObjectsIds: number[] = [];

    @observable loading: boolean = false;
    @observable loadingFolder: boolean = false;

    @action
    getAllFavorites = () => {
        this.loading = true;

        API.favorite
            .getAllFavorites()
            .then(response => {
                this.favorites = response.data.data;
            })
            .finally(() => (this.loading = false));
    };

    @action
    getFavoriteFolder = (folderId: number | null) => {
        this.loadingFolder = true;

        API.favorite
            .getFavorite(folderId)
            .then(response => {
                this.folderFavorite = response.data.data;
            })
            .finally(() => (this.loadingFolder = false));
    };

    @action
    addToFavorite = (objectId: number) => {
        return API.favorite
            .addToFavorite(objectId)
            .then(response => {
                this.favorites = response.data.data;
                return response.data.success;
            })
            .catch(error => {
                showNotification('danger', error.message);
                return false;
            });
    };

    @action
    removeFromFavorite = (objectId: number) => {
        return API.favorite
            .removeFromFavorite([objectId])
            .then(response => {
                this.favorites = response.data.data;
                return response.data.success;
            })
            .catch(error => {
                showNotification('danger', error.message);
                return false;
            });
    };

    @action
    removeSelectedFromFavorite = () => {
        Promise.all(this.selectedObjectsIds.map(objectId => this.removeFromFavorite(objectId))).then(() => {
            this.getFavoriteFolder(this.activeFolderId as number);
            this.clearActiveFolder();
            this.clearSelectedObjects();
        });
    };

    @action
    selectFolder = (folderId: number, folderTitle: string) => () => {
        if (this.activeFolderId !== folderId) {
            this.folderFavorite = null;
            this.activeFolderId = folderId;
            this.activeFolderTitle = folderTitle;
            this.getFavoriteFolder(this.activeFolderId as number);
        } else {
            this.clearActiveFolder();
        }
    };

    @action
    createFolder = (title: string) => {
        return API.favorite.createFolder(title).then(response => {
            if (this.favorites) {
                this.favorites.folders = response.data.data.folders;
            }
        });
    };

    @action
    downloadFolder = () => {
        API.favorite.shareFolder(this.activeFolderId as number).then(response => {
            const a = document.createElement('a');
            a.href = response.data?.data.pdf_url;
            document.body.appendChild(a);
            a.click();
            a.remove();
        });
    };

    @action
    renameFolder = (title: string) => {
        API.favorite.renameFolder(this.activeFolderId as number, title).then(response => {
            if (this.favorites) {
                this.favorites.folders = response.data.data.folders;
            }
        });
    };

    @action
    removeFolder = () => {
        API.favorite.removeFolder(this.activeFolderId as number).then(response => {
            if (this.favorites) {
                this.favorites.folders = response.data.data.folders;
                this.clearActiveFolder();
            }
        });
    };

    @action
    removeAll = () => {
        API.favorite.removeAll().then(response => {
            if (this.favorites) {
                this.favorites = response.data.data;
                this.clearActiveFolder();
            }
        });
    };

    @action
    moveToFolder = (folderId: number | null) => {
        API.favorite.moveToFolder(folderId, this.selectedObjectsIds).then(response => {
            this.favorites = response.data.data;
            this.clearSelectedObjects();
            this.getFavoriteFolder(this.activeFolderId);
        });
    };

    @action
    createAndMove = (title: string) => {
        this.createFolder(title).then(() => {
            this.moveToFolder(this.favorites?.folders[this.favorites?.folders.length - 1].id as number);
        });
    };

    @action
    toggleObjectSelect = (objectId: number) => {
        this.selectedObjectsIds.includes(objectId)
            ? this.selectedObjectsIds.splice(this.selectedObjectsIds.indexOf(objectId), 1)
            : this.selectedObjectsIds.push(objectId);
    };

    @action
    clearFavorites = () => {
        this.favorites = null;
        this.folderFavorite = null;
        this.activeFolderId = null;
        this.clearSelectedObjects();
    };

    @action
    clearSelectedObjects = () => (this.selectedObjectsIds = []);

    clearActiveFolder = () => (this.activeFolderId = null);
}
