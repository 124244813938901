import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Typography, useMediaQuery, useTheme } from '@material-ui/core';

import { useStyles } from '../style';
import { useForceUpdate } from '../../../hooks/useForceUpdate';
import { useStores } from '../../../hooks/useStores';
import emptyTile from '../../../img/emptyTile.svg';

import ApartmentsTilesGrid from './ApartmentsTilesGrid';
import ApartmentInfo from '../ApartmentInfo/ApartmentInfo';
import ApartmentTilesLegend from './ApartmentTilesLegend';
import ApartmentsTilesFilter from './ApartmentsTilesFilter';

const ApartmentsTiles = observer(() => {
    const { ApartmentStore: store } = useStores();
    const classes = useStyles();
    const theme = useTheme();
    const isSM = useMediaQuery(theme.breakpoints.down('sm'));

    const forceUpdate = useForceUpdate();

    const refreshGrid = () => {
        forceUpdate();
    };

    useEffect(() => {
        if (store.filters.complex?.length > 1) {
            store.resetMainFilters();
        }
        return () => {
            store.resetOptionalFilters();
            store.clearSelectedApartment();
            store.clearTiles();
        };
    }, [store]);

    return (
        <Box display="flex" flexDirection="column" flex="1">
            <ApartmentsTilesFilter refreshGrid={refreshGrid} />
            <ApartmentTilesLegend />

            <Box display={isSM ? 'block' : 'flex'} flex="1">
                {!store.filters.complex || !store.filters.houseNumbers ? (
                    <Box margin="auto">
                        <img src={emptyTile} alt="пустая шахматка" />
                        <Box width={312} textAlign="center" margin="32px auto">
                            <Typography variant="h3" className={classes.emptyTileText}>
                                Убедитесь, что выбран жилой комплекс и дом
                            </Typography>
                        </Box>
                    </Box>
                ) : (
                    <>
                        <ApartmentsTilesGrid forceUpdate={forceUpdate} />
                        <Box className={classes.sidebar} ml={1}>
                            <ApartmentInfo />
                        </Box>
                    </>
                )}
            </Box>
        </Box>
    );
});

export default ApartmentsTiles;
