import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import Slider from 'react-slick';
import CarouselArrow from '../CarouselArrow/CarouselArrow';
import ComplexProperties from './ComplexProperties';

const ComplexCarousel = ({ data }: { data: any }) => {
    const classes = useStyles();
    const sliderSettings = {
        infinite: false,
        dots: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: <CarouselArrow type="prev" />,
        nextArrow: <CarouselArrow type="next" />,
        dotsClass: 'slick-dots slick-dots_complex',
    };

    return data?.images?.length ? (
        <Box mx={-2} mt={4} mb={{ xxs: 10, md: 14 }} position="relative">
            <Slider {...sliderSettings} className="slick-slider__visible">
                {data?.images?.map((item: string, i: number) => (
                    <Box key={i} px={2}>
                        <Box className={classes.imageWrap}>
                            <img src={item} alt="" className={`${classes.image} img_rounded`} />
                        </Box>
                    </Box>
                ))}
            </Slider>
            <ComplexProperties data={data} />
        </Box>
    ) : null;
};

const useStyles = makeStyles(theme => ({
    imageWrap: {
        position: 'relative',
        width: '100%',
        height: 0,
        paddingTop: '46%',

        [theme.breakpoints.between('sm', 'sm')]: {
            paddingTop: '54%',
        },
        [theme.breakpoints.down('xs')]: {
            paddingTop: '63%',
        },
    },
    image: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    },
}));

export default ComplexCarousel;
