import React from 'react';
import { Typography, FormLabel, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
    formLabel: {
        marginBottom: 2,
    },
});

const FormLabelCustom = (props: any) => {
    const classes = useStyles();

    return (
        <FormLabel
            {...props}
            component={({ children }: { children: any }) => (
                <Typography variant="body2" className={classes.formLabel}>
                    {children[0]}
                </Typography>
            )}
        >
            {props.children}
        </FormLabel>
    );
};

export default FormLabelCustom;
