import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Container, Typography, useMediaQuery, useTheme, Grid } from '@material-ui/core';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined';

import { useStores } from '../../hooks/useStores';
import Loader from '../../components/Loader/Loader';
import ConsultantsGroup from '../../components/Feedback/ConsultantsGroup';
import ConsultantsTable from '../../components/Feedback/ConsultantsTable';
import ConsultantsCard from '../../components/Feedback/ConsultantsCard';
import { transformNormalContentText } from '../../utils/transformNormalContentText';

const Feedback = observer(() => {
    const { FeedbackStore, SystemStore } = useStores();

    const theme = useTheme();
    const isSM = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        FeedbackStore.getConsultants();
    }, [FeedbackStore]);

    return (
        <Container maxWidth="xl">
            <Box mt={{ xxs: 4, md: 6 }} mb={{ xxs: 7, md: 9 }}>
                <Box mb={{ xxs: 3, md: 5 }}>
                    <Typography variant="h2">Контакты</Typography>
                </Box>

                <Box mb={{ xxs: 3, md: 5 }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={5} lg={4}>
                            <Box mb={2}>
                                <Typography variant={isSM ? 'subtitle1' : 'h4'}>Часы работы</Typography>
                            </Box>
                            <Grid container spacing={1}>
                                <Grid item>
                                    <QueryBuilderIcon color="secondary" />
                                </Grid>
                                <Grid item xs>
                                    <Typography
                                        variant={isSM ? 'body2' : 'body1'}
                                        dangerouslySetInnerHTML={{ __html: transformNormalContentText(SystemStore.data?.work_time || '') }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Box mb={2}>
                                <Typography variant={isSM ? 'subtitle1' : 'h4'}>Наш адрес</Typography>
                            </Box>
                            <Grid container alignItems="center" spacing={1}>
                                <Grid item>
                                    <RoomOutlinedIcon color="secondary" />
                                </Grid>
                                <Grid item xs>
                                    <Typography
                                        variant={isSM ? 'body2' : 'body1'}
                                        dangerouslySetInnerHTML={{ __html: transformNormalContentText(SystemStore.data?.address || '') }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>

                <Box position="relative">
                    {FeedbackStore.contacts.map((group, index) => (
                        <ConsultantsGroup
                            key={group.ID}
                            title={group.NAME}
                            // defaultExpanded={index === 0}
                        >
                            {isSM ? <ConsultantsCard data={group.ITEMS} /> : <ConsultantsTable data={group.ITEMS} />}
                        </ConsultantsGroup>
                    ))}

                    {FeedbackStore.loading && <Loader isAbsolute />}
                </Box>
            </Box>
        </Container>
    );
});

export default Feedback;
