import { Box, Divider, Typography } from '@material-ui/core';
import React from 'react';

import { IConsultants } from '../../types/FeedbackTypes';
import { transformNormalContentText } from '../../utils/transformNormalContentText';
import EmailLink from './EmailLink';
import PhoneLink from './PhoneLink';

interface IConsultantsCardProps {
    data: IConsultants[];
}

const ConsultantsCard: React.FC<IConsultantsCardProps> = ({ data }) => {
    return (
        <Box width="100%" px={2}>
            {data.map((item, index) => (
                <Box key={index} my={2}>
                    {index > 0 && <Divider />}
                    <Box mt={index > 0 ? 2 : -1}>
                        <Typography>{item.NAME}</Typography>
                    </Box>
                    <Box my={2}>
                        <Typography variant="body2" color="textSecondary">
                            Должность
                        </Typography>
                        <Typography variant="body2" noWrap>
                            {item.POSITION}
                        </Typography>
                    </Box>
                    <Box my={2}>
                        <Typography variant="body2" color="textSecondary">
                            Обязанности
                        </Typography>
                        <Typography variant="body2" dangerouslySetInnerHTML={{ __html: transformNormalContentText(item.DESCRIPTION) }} />
                    </Box>
                    <Box>
                        <Typography variant="body2" color="textSecondary">
                            Контакты
                        </Typography>
                        <Typography variant="body2" noWrap>
                            <EmailLink email={item.EMAIL} />
                            <PhoneLink phone={item.PHONE} />
                        </Typography>
                    </Box>
                </Box>
            ))}
        </Box>
    );
};

export default ConsultantsCard;
