import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Dialog, IconButton, makeStyles, Popover, TextField, Typography } from '@material-ui/core';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import { useHistory, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../hooks/useStores';

interface IAppDealPayLinkModalProps {}

const AppDealPayLinkModal: React.FC<IAppDealPayLinkModalProps> = observer(() => {
    const { AppDealFormStore } = useStores();

    const history = useHistory();
    const location = useLocation();

    const inputRef = useRef<HTMLInputElement>(null);
    const copyBtnRef = useRef<HTMLButtonElement>(null);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const classes = useStyles();

    // Effects
    useEffect(() => {
        // Auto close popover
        if (anchorEl) {
            setTimeout(() => {
                setAnchorEl(null);
            }, 500);
        }
    }, [anchorEl]);

    // Handlers
    const handleShowPopover = (target: HTMLButtonElement) => {
        setAnchorEl(target);
    };

    const handleFocus = () => {
        inputRef.current?.select();
    };

    const handleCopy = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (!!AppDealFormStore.paymentLink && copyBtnRef.current) {
            if (window.navigator.clipboard) {
                window.navigator.clipboard
                    .writeText(AppDealFormStore.paymentLink)
                    .then(() => handleShowPopover(copyBtnRef.current!))
                    .catch(err => console.log(err));

                return;
            }

            // Для старых браузеров, в основном на мобильных браузерах
            // нет поддержки window.navigator.clipboard
            inputRef.current?.select();
            document.execCommand('copy');
            inputRef.current?.blur();

            setTimeout(() => {
                handleShowPopover(copyBtnRef.current!);
            }, 400);
        }
    };

    const handleClose = () => {
        AppDealFormStore.setPaymentLink(null);
        AppDealFormStore.setPaymentLinkModalOpen(false);
    };

    const handleBackToList = () => {
        handleClose();

        if (location.pathname !== '/applications-and-deals') {
            history.push('/applications-and-deals');
        }
    };

    // Renders
    return (
        <Dialog
            scroll="body"
            PaperProps={{ className: classes.modal, elevation: 24 }}
            open={AppDealFormStore.paymentLinkModalOpen}
            onClose={handleClose}
        >
            <Box p={{ xxs: 3, md: 4 }} display="flex" alignItems="center" textAlign="left" flexDirection="column">
                <Box mb={{ xxs: 3, md: 4 }} width="100%" display="flex" justifyContent="space-between">
                    <Typography variant="h3" align="left">
                        Квартира доступна для бронирования
                    </Typography>
                    <Box mt="-5px" mr="-5px">
                        <IconButton size="small" onClick={handleClose}>
                            <CloseOutlinedIcon />
                        </IconButton>
                    </Box>
                </Box>

                <Box mb={{ xxs: 3, md: 4 }}>
                    <Typography>Для закрепления квартиры за клиентом – направьте ему ссылку на оплату.</Typography>
                </Box>

                <Box width="100%" mb={{ xxs: 3, md: 4 }} className={classes.inputWrap}>
                    <TextField
                        inputRef={inputRef}
                        fullWidth
                        value={AppDealFormStore.paymentLink}
                        variant="outlined"
                        color="primary"
                        InputProps={{
                            endAdornment: (
                                <>
                                    <IconButton ref={copyBtnRef} size="small" onClick={handleCopy}>
                                        <FileCopyOutlinedIcon />
                                    </IconButton>
                                    <Popover
                                        open={Boolean(anchorEl)}
                                        anchorEl={anchorEl}
                                        onClose={handleClose}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'center',
                                        }}
                                        transformOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'center',
                                        }}
                                        disablePortal
                                    >
                                        Скопировано!
                                    </Popover>
                                </>
                            ),
                        }}
                        onFocus={handleFocus}
                    />
                </Box>

                <Box width="100%" maxWidth={304}>
                    <Button variant="contained" color="primary" size="large" fullWidth onClick={handleBackToList}>
                        Вернуться к списку заявок
                    </Button>
                </Box>
            </Box>
        </Dialog>
    );
});

const useStyles = makeStyles({
    modal: {
        borderRadius: 16,
        maxWidth: 536,
    },
    inputWrap: {
        '& .MuiPopover-root': {
            pointerEvents: 'none',
        },
    },
});

export default AppDealPayLinkModal;
