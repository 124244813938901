import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router';
import { CssBaseline, ThemeProvider } from '@material-ui/core';

import App from './App';
import './index.scss';
import { stores } from './contexts';
import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/browser';
import { theme } from './styles/muiTheme';

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'production') {
    Sentry.init({ dsn: 'https://e9b91f3a9c9449ccac92dd256cff6d66@sentry.netimob.com/19' });
}

ReactDOM.render(
    <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router history={stores.HistoryStore.history}>
            <App />
        </Router>
    </ThemeProvider>,
    document.getElementById('root'),
);

// Помогает при верстке, после можно отключить, так как при интеграции с апи может помешать
if (module.hot) {
    module.hot.accept();
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
