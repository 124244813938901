import React, { useState } from 'react';
import { Box, Typography, TextField, Button, InputAdornment, IconButton } from '@material-ui/core';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../hooks/useStores';

type IPassword = {
    [key: string]: boolean;
};

const Step3 = observer(() => {
    const { AuthStore: store } = useStores();
    const [showPassword, setShowPassword] = useState<IPassword>({ newPassword: false, confirmPassword: false });
    const [values, setValues] = useState<{ newPassword: string; confirmPassword: string }>({
        newPassword: '',
        confirmPassword: '',
    });

    const handleChange = (event: any) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value,
        });
    };

    const togglePassword = (key: string) => {
        setShowPassword({ ...showPassword, [key]: !showPassword[key] });
    };

    const handlePress = (e: any) => {
        e.preventDefault();

        store.resetPassConfirm({
            token: store.resetPassData.token || '',
            deviceType: 'web',
            newPassword: values.newPassword,
            confirmPassword: values.confirmPassword,
        });
    };

    return (
        <>
            <Box mb={{ xxs: 3, xs: 8 }} textAlign="center">
                <Typography variant="h3" component="h1">
                    Восстановление пароля
                </Typography>
            </Box>
            <form noValidate>
                <Box mt={4} mb={4}>
                    <TextField
                        variant="outlined"
                        margin="none"
                        required
                        fullWidth
                        id="newPassword"
                        name="newPassword"
                        type={showPassword['newPassword'] ? 'text' : 'password'}
                        placeholder="Пароль"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton aria-label="toggle password visibility" onClick={() => togglePassword('newPassword')}>
                                        {showPassword['newPassword'] ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                            notched: false,
                        }}
                        autoFocus
                        onChange={handleChange}
                        value={values.newPassword}
                        error={!!store.resetPassErrors?.newPassword}
                        helperText={store.resetPassErrors?.newPassword}
                        disabled={store.loading}
                    />
                </Box>
                <Box mt={4} mb={4}>
                    <TextField
                        variant="outlined"
                        margin="none"
                        required
                        fullWidth
                        id="confirmPassword"
                        name="confirmPassword"
                        type={showPassword['confirmPassword'] ? 'text' : 'password'}
                        placeholder="Подтвердите пароль"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton aria-label="toggle password visibility" onClick={() => togglePassword('confirmPassword')}>
                                        {showPassword['confirmPassword'] ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                            notched: false,
                        }}
                        onChange={handleChange}
                        value={values.confirmPassword}
                        error={!!store.resetPassErrors?.confirmPassword}
                        helperText={store.resetPassErrors?.confirmPassword}
                        disabled={store.loading}
                    />
                </Box>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={handlePress}
                    disabled={store.loading}
                >
                    Подтвердить
                </Button>
            </form>
        </>
    );
});

export default Step3;
