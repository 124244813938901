import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Button, ButtonGroup, ClickAwayListener, Modal, Paper, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Document, Page, pdfjs } from 'react-pdf';

import { useStyles } from '../style';
import { IDocumentPreviewModalProps } from '../../../types/AppDealTypes';

import Loader from '../../Loader/Loader';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const DocumentPreviewModal = observer(({ imgPreview, pdfPreview, showPreview, closePreview }: IDocumentPreviewModalProps) => {
    const [numberOfPages, setNumberOfPages] = useState<number | null>(null);
    const [pageNumber, setPageNumber] = useState(1);
    const classes = useStyles();

    const goToPrevPage = () => setPageNumber(prevState => (prevState === 1 ? prevState : prevState - 1));
    const goToNextPage = () => setPageNumber(prevState => (prevState === numberOfPages ? prevState : prevState + 1));

    return (
        <Modal open={showPreview} className={classes.container}>
            <>
                <ClickAwayListener onClickAway={closePreview}>
                    <Box display="flex" flexDirection="column">
                        <Box onClick={closePreview}>
                            <Paper elevation={2} className={classes.closePreviewIcon}>
                                <CloseIcon className={classes.whiteIcon} />
                            </Paper>
                        </Box>
                        <Box maxWidth="80vw" maxHeight="80vh" overflow="auto">
                            {!!pdfPreview && (
                                <Document
                                    file={pdfPreview}
                                    loading={<Loader size={20} isAbsolute />}
                                    onLoadSuccess={({ numPages }) => setNumberOfPages(numPages)}
                                    error="Не удалось открыть PDF-файл"
                                >
                                    <Page pageNumber={pageNumber} renderAnnotationLayer={false} />
                                </Document>
                            )}
                            {!!imgPreview && <img src={imgPreview} alt="" />}
                        </Box>
                        {!!pdfPreview && (
                            <Box alignSelf="center">
                                <Box textAlign="center" color="#fff">
                                    <Box my={2}>
                                        <Typography>
                                            Страница {pageNumber} из {numberOfPages}
                                        </Typography>
                                    </Box>
                                </Box>
                                <ButtonGroup disableElevation variant="contained" color="primary">
                                    <Button onClick={goToPrevPage}>Назад</Button>
                                    <Button onClick={goToNextPage}>Вперед</Button>
                                </ButtonGroup>
                            </Box>
                        )}
                    </Box>
                </ClickAwayListener>
            </>
        </Modal>
    );
});

export default DocumentPreviewModal;
