import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Tab } from '@material-ui/core';

const StyledTab = withStyles(theme => ({
    root: {
        textTransform: 'unset',
        minWidth: '105px !important',
        minHeight: 40,
        fontSize: 16,
        background: '#fff',
        '@media (max-width: 767px)': {
            minWidth: '95px !important',
            fontSize: 14,
        },
        '@media (max-width: 374px)': {
            minWidth: '85px !important',
            minHeight: 32,
            fontSize: 14,
        },
    },
    selected: {
        borderRadius: 8,
        background: theme.palette.primary.main,
        color: '#fff',
        transition: 'all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0.3ms',
    },
    textColorInherit: {
        opacity: 1,
    },
}))((props: any) => <Tab {...props} />);

export default StyledTab;
