import { observable, action } from 'mobx';
import API from '../api';
import { IConsultantsGroup } from '../types/FeedbackTypes';

export class FeedbackStore {
    @observable loading = false;
    @observable contacts: IConsultantsGroup[] = [];

    @action
    getConsultants = () => {
        this.loading = true;

        API.feedback
            .getConsultants()
            .then(response => {
                if (response?.data?.success) {
                    this.contacts = response.data.data;
                }
            })
            .finally(() => {
                this.loading = false;
            });
    };
}
