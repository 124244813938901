import React, { useState } from 'react';
import {
    Typography,
    makeStyles,
    Box,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Grid,
    useMediaQuery,
    useTheme,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import { IComplexBanks } from '../../types/CommonTypes';
import TextButton from '../UI/TextButton';
import { BankItem } from './BankItem';
import { Link } from 'react-router-dom';

interface IBankAccordionProps {
    banks: IComplexBanks[];
    itemsToShow?: number;
    fullWidth?: boolean;
    withIcon?: boolean;
}

export const BankAccordion: React.FC<IBankAccordionProps> = ({ banks, itemsToShow, fullWidth, withIcon }) => {
    const classes = useStyle();
    const [expanded, setExpanded] = useState<string | false>();
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.down('md'));

    const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : false);
    };

    const firstBanks = banks?.slice(0, itemsToShow ? itemsToShow : 2);
    const otherBanks = banks?.slice(itemsToShow ? itemsToShow : 2);

    //Renders
    const renderFirstBanks = () => {
        return (
            <Grid container spacing={fullWidth || isMd ? 3 : 6}>
                {fullWidth ? (
                    <>
                        {firstBanks?.map((bank: any, i: any) => (
                            <Grid item xs={12}>
                                <BankItem key={i} {...bank} />
                            </Grid>
                        ))}
                    </>
                ) : (
                    <>
                        {firstBanks?.map((bank: any, i: any) => (
                            <Grid item xs={12} sm={6} md={3}>
                                <Link to={bank.LINK} target="_blank" className="router-link">
                                    <BankItem key={i} {...bank} />
                                </Link>
                            </Grid>
                        ))}
                    </>
                )}
            </Grid>
        );
    };

    const renderOthersBanks = () => {
        return (
            <Grid container spacing={fullWidth || isMd ? 3 : 6}>
                {fullWidth ? (
                    <>
                        {otherBanks?.map((bank: any, i: any) => (
                            <Grid item xs={12}>
                                <BankItem key={i} {...bank} />
                            </Grid>
                        ))}
                    </>
                ) : (
                    <>
                        {otherBanks?.map((bank: any, i: any) => (
                            <Grid item xs={12} sm={6} md={3}>
                                <Link to={bank.LINK} target="_blank" className="router-link">
                                    <BankItem key={i} {...bank} />
                                </Link>
                            </Grid>
                        ))}
                    </>
                )}
            </Grid>
        );
    };

    const CustomIcon = () => {
        if (expanded) {
            return <ExpandLessIcon />;
        }
        return <ExpandMoreIcon />;
    };

    return (
        <>
            {!!banks.length && (
                <>
                    {renderFirstBanks()}

                    {!!otherBanks.length && (
                        <Box width="100%" mt={isMd ? 3 : 5}>
                            <Accordion
                                expanded={expanded === 'panel1'}
                                onChange={handleChange('panel1')}
                                classes={{ root: classes.accordionRoot }}
                            >
                                <AccordionSummary>
                                    <TextButton endIcon={withIcon ? <CustomIcon /> : ''}>
                                        {expanded ? (
                                            <Typography>Скрыть</Typography>
                                        ) : (
                                            <TextButton> Еще {otherBanks.length} банков</TextButton>
                                        )}
                                    </TextButton>
                                </AccordionSummary>

                                <AccordionDetails classes={{ root: classes.accordionDetailsRoot }}>
                                    <>{renderOthersBanks()}</>
                                </AccordionDetails>
                            </Accordion>
                        </Box>
                    )}
                </>
            )}
        </>
    );
};

const useStyle = makeStyles(theme => ({
    accordionRoot: {
        display: 'flex',
        flexDirection: 'column-reverse',
        width: '100%',
        boxShadow: 'none',
        background: 'transparent',
        padding: `0 ${theme.spacing(3)}`,
        '&.Mui-expanded': {
            marginTop: theme.spacing(3),
        },
        '& .MuiAccordionSummary-root': {
            width: 'fit-content',
            padding: 0,
        },
    },
    accordionDetailsRoot: {
        padding: 0,
    },
}));
