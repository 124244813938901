import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Grid, Typography } from '@material-ui/core';

import { IComplexBanks } from '../../types/ComplexTypes';
import { BankAccordion } from '../UI/BankAccordion';

const ComplexBanks = observer(({ data }: { data: IComplexBanks[] }) => {
    return (
        <>
            {!!data.length && (
                <Box>
                    <Box mb={5}>
                        <Typography variant="h2">Аккредитовано банками:</Typography>
                    </Box>

                    <Grid container>
                        <BankAccordion withIcon banks={data} itemsToShow={4} />
                    </Grid>
                </Box>
            )}
        </>
    );
});

export default ComplexBanks;
