import React from 'react';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { Link, useHistory } from 'react-router-dom';
import { Divider, Grid, Typography, Box, Button, makeStyles, useMediaQuery, IconButton, useTheme } from '@material-ui/core';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import LockIcon from '@material-ui/icons/Lock';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import DownloadIcon from '../../UI/DownloadIcon';

import { IApartmentItemProps } from '../../../types/ApartmentTypes';
import { showNotification } from '../../../utils/Notification';
import moneyFormat from '../../../utils/moneyFormat';
import { getShortPropType } from '../../../utils/getShortPropType';
import { formatDevEnd } from '../../../utils/formatDevEnd';
import { useStores } from '../../../hooks/useStores';

import EmptyImage from '../../UI/EmptyImage';
import CommissionIcon from '../CommissionIcon';

const ApartmentItem = observer((props: IApartmentItemProps) => {
    const { ApartmentStore: store } = useStores();
    const classes = useStyles();
    const history = useHistory();
    const theme = useTheme();
    const isSM = useMediaQuery(theme.breakpoints.down('sm'));
    const isXS = useMediaQuery(theme.breakpoints.down('xs'));
    const isXXS = !useMediaQuery(theme.breakpoints.up('xs'));
    const {
        object_id,
        images,
        complex,
        address,
        rooms_number,
        total_area,
        floor_number,
        max_floor,
        development_end,
        is_booked,
        object_number,
        house_number,
        type_id,
        price,
        isFavorite,
        can_paid_booking,
        commission
    } = props.data;

    //// Handlers
    const handleSelect = () => {
        if (is_booked) {
            showNotification('danger', 'Объект уже забронирован', 'center', 700);
        } else {
            history.push({
                state: {
                    object_id,
                    complex,
                    object_number,
                    floor_number,
                    house_number,
                    total_area,
                    rooms_number,
                    development_end,
                    max_floor,
                    price,
                    priceFormat: `${moneyFormat(price)} ₽`,
                    image: images[0],
                    can_paid_booking,
                    commission,
                },
            });

            props.onClose();
        }
    };

    const renderContent = () => {
        return (
            <Grid container className={classes.container}>
                <Link to={`/apartments/${object_id}`} className={clsx(classes.link, props.isModalItem && classes.pointerNone)}>
                    <Grid item className={classes.imgCol}>
                        {images?.length ? <img src={images[0]} alt="" className={classes.img} /> : <EmptyImage variant="apartment" />}
                    </Grid>
                </Link>

                <Grid item xs>
                    <Box display="flex" justifyContent="space-between" position="relative">
                        <Typography variant="subtitle2" color="textSecondary" className={classes.withMargin}>
                            {complex}
                        </Typography>

                        <Box position="absolute" display="flex" right={0}>
                            {isFavorite ? (
                                <IconButton //@ts-ignore
                                    style={{ color: theme.palette.primary.A200 }}
                                    onClick={() => store.removeApartmentFromListFromFavorite(object_id)}
                                >
                                    <FavoriteIcon />
                                </IconButton>
                            ) : (
                                <IconButton //@ts-ignore
                                    style={{ color: theme.palette.primary.A200 }}
                                    onClick={() => store.addApartmentFromListToFavorite(object_id)}
                                >
                                    <FavoriteBorderIcon />
                                </IconButton>
                            )}
                            <Box
                                onClick={() => store.downloadObject([object_id])}
                                className={classes.pointer}
                                py={1.5}
                                //@ts-ignore
                                style={{ color: theme.palette.primary.A200 }}
                            >
                                <DownloadIcon />
                            </Box>
                        </Box>
                    </Box>
                    <div className={clsx(classes.link, props.isModalItem && classes.pointerNone)}>
                        <div className={classes.price}>
                            <Typography variant={isXS ? 'h4' : 'subtitle1'} className={clsx(classes.withMargin, classes.positionRelative)}>
                                {moneyFormat(price)} ₽
                            </Typography>
                            {commission ? (
                                <CommissionIcon
                                    className={classes.commissionIcon}
                                    commission={commission}
                                    width="16px" height="16px"
                                />
                            ) : null}
                        </div>
                        {!isXXS ? (
                            <Box className={clsx(classes.address, classes.withMargin)}>
                                <LocationOnOutlinedIcon
                                    className={clsx(classes.pin, classes.mediumBlackColor)}
                                    fontSize={isXS ? 'small' : 'default'}
                                />
                                <Typography variant="caption" color="textSecondary">
                                    {address}
                                </Typography>
                            </Box>
                        ) : null}
                        <Typography variant={isXS ? 'body2' : 'body1'} className={classes.withMargin}>
                            <Typography variant="subtitle1" component="span">
                                {type_id === '2' ? (rooms_number === 0 ? 'С' : `${rooms_number}К `) : getShortPropType(+type_id)}
                            </Typography>
                            • {total_area} м² • Этаж {floor_number}/{max_floor || ''}
                        </Typography>

                        <Grid container alignItems="center" justify="space-between" spacing={1}>
                            <Grid item>
                                <Typography variant="caption" color="textSecondary">
                                    Сдача в {formatDevEnd(development_end)}
                                </Typography>
                            </Grid>
                            <Grid item>
                                {is_booked && (
                                    <Box display="flex" justifyContent="flex-end" width={144}>
                                        {isSM ? (
                                            <IconButton disabled size="small">
                                                <LockIcon fontSize="small" />
                                            </IconButton>
                                        ) : (
                                            <Button
                                                variant="contained"
                                                startIcon={<LockIcon />}
                                                disabled
                                                classes={{ root: classes.lockBtn }}
                                            >
                                                <Typography variant="caption" color="textSecondary">
                                                    Забронирована
                                                </Typography>
                                            </Button>
                                        )}
                                    </Box>
                                )}
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </Grid>
        );
    };

    return (
        <>
            {props.isModalItem ? (
                <Box className={classes.pointer} onClick={handleSelect}>
                    {renderContent()}
                    <Divider className={classes.divider} />
                </Box>
            ) : (
                <>
                    {renderContent()}
                    <Divider className={classes.divider} />
                </>
            )}
        </>
    );
});

const useStyles = makeStyles(theme => ({
    container: {
        maxWidth: 520,
        paddingRight: 10,
    },
    link: {
        color: 'rgba(0, 0, 0, 0.87)',
    },
    pointerNone: {
        pointerEvents: 'none',
    },
    imgCol: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 88,
        height: 88,
        marginRight: 8,
        [theme.breakpoints.down('xs')]: {
            width: 60,
            height: '100%',
        },
    },
    img: {
        maxWidth: '100%',
        maxHeight: '100%',
        transition: 'transform ease 0.3s',
    },
    address: {
        display: 'flex',
        alignItems: 'center',
    },
    pin: {
        marginRight: 5,
    },
    withMargin: {
        marginBottom: 4,
    },
    mediumBlackColor: {
        color: 'rgba(0, 0, 0, 0.6)',
    },
    tools: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
    },
    lockBtn: {
        borderRadius: 20,
        background: 'rgba(0, 0, 0, 0.08) !important',
        padding: '4px 16px',
    },
    pointer: {
        cursor: 'pointer',
    },
    divider: {
        marginTop: 12,
        marginBottom: 24,
        marginRight: 10,
        //@ts-ignore
        background: theme.palette.primary.A50,
    },
    positionRelative: {
        position: 'relative',
    },
    price: {
        display: 'inline-block',
        position: 'relative'
    },
    commissionIcon: {
        position: 'absolute',
        top: '2px',
        right: '-20px',
    }
}));

export default ApartmentItem;
