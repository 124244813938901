import axios from 'axios';
import { IProfileChangePass, IProfileNotifications } from '../types/ProfileTypes';

class ProfileAPI {
    callNotification: any = null;

    getProfileInfo = () => {
        return axios.get('/user/info');
    };

    changePassword = (data: IProfileChangePass) => {
        return axios.post('/user/change-password', data);
    };

    updateProfileInfo = (data: FormData) => {
        return axios.post('/user/update', data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    };

    changeNotifications = (data: IProfileNotifications) => {
        if (this.callNotification) {
            this.callNotification.cancel();
        }
        this.callNotification = axios.CancelToken.source();

        return axios.post('/user/notification-settings', data, { cancelToken: this.callNotification.token });
    };
}

const profileAPI = new ProfileAPI();
export default profileAPI;
