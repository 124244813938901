import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Checkbox, FormControlLabel, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { useStyles } from '../style';

import { useStores } from '../../../hooks/useStores';

const ApartmentsFilterFloorExcept = observer(({ onChange }: { onChange: any }) => {
    const { ApartmentStore: store } = useStores();

    const classes = useStyles();

    return (
        <Box mb={{ xxs: 2, sm: 3, md: 4 }}>
            <FormControlLabel
                className={clsx(classes.object, store.filters.exceptFirstFloor ? classes.activeButton : classes.plainBackground)}
                classes={{ root: classes.margin }}
                control={
                    <Checkbox
                        name="exceptFirstFloor"
                        checked={!!store.filters.exceptFirstFloor}
                        onChange={onChange}
                        classes={{ root: classes.checkboxRoot }}
                    />
                }
                label={
                    <Typography
                        variant="subtitle2"
                        className={clsx(classes.buttonText, store.filters.exceptFirstFloor && classes.activeButtonText)}
                    >
                        Кроме первого
                    </Typography>
                }
            />

            <FormControlLabel
                className={clsx(classes.object, store.filters.exceptLastFloor ? classes.activeButton : classes.plainBackground)}
                classes={{ root: classes.margin }}
                control={
                    <Checkbox
                        name="exceptLastFloor"
                        checked={!!store.filters.exceptLastFloor}
                        onChange={onChange}
                        classes={{ root: classes.checkboxRoot }}
                    />
                }
                label={
                    <Typography
                        variant="subtitle2"
                        className={clsx(classes.buttonText, store.filters.exceptLastFloor && classes.activeButtonText)}
                    >
                        Кроме последнего
                    </Typography>
                }
            />
        </Box>
    );
});

export default ApartmentsFilterFloorExcept;
