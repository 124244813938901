import React from 'react';
import MaskedInput from 'react-text-mask';
import emailMask from 'text-mask-addons/dist/emailMask';
import { InputBaseComponentProps } from '@material-ui/core';
import NumberFormat from 'react-number-format';

export const EmailFormat: React.ElementType<InputBaseComponentProps> = ({ inputRef, ...other }) => {
    return (
        <MaskedInput
            {...other}
            ref={ref => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={emailMask}
            // placeholderChar={'\u2000'}
            // showMask
        />
    );
};

export const PhoneFormatLogin: React.ElementType<InputBaseComponentProps> = ({ inputRef, ...other }) => {
    return (
        <MaskedInput
            {...other}
            ref={ref => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/[0-9]/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/]}
            placeholderChar={'_'}
            // showMask
        />
    );
};

export const PhoneFormat: React.ElementType<InputBaseComponentProps> = ({ inputRef, ...other }) => {
    return (
        <MaskedInput
            {...other}
            ref={ref => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={['+', '7', ' ', /[9]/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/]}
            placeholderChar={'_'}
            // showMask
        />
    );
};

export const RFPassportNumberFormat = (props: any) => {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            format="######"
            mask="_"
        />
    );
};

export const RFPassportSeriesFormat = (props: any) => {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            format="####"
            mask="_"
        />
    );
};

export const RFBirthCertificateSeriesFormat: React.ElementType<InputBaseComponentProps> = ({ inputRef, ...other }) => {
    return (
        <MaskedInput
            {...other}
            ref={ref => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/./, /./, /./, /./, /./, /./, /./, /./, /./, /./]}
            placeholderChar={'_'}
        />
    );
};

export const RFBirthCertificateFormat = (props: any) => {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            format="##########"
            mask="_"
        />
    );
};

export const RFPassportUnitCodeFormat = (props: any) => {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            format="###-###"
            mask="_"
        />
    );
};

export const RFPassportDateFormat = (props: any) => {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.formattedValue,
                    },
                });
            }}
            format="##.##.####"
            mask="_"
        />
    );
};

export const SnilsMaskFormat = (props: any) => {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            format="###-###-### ##"
            mask="_"
        />
    );
};

export const InnMaskFormat = (props: any) => {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            format="############"
            mask="_"
        />
    );
};

export const NumberInput = (props: any) => {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
        />
    );
};

export const ShareInputFormat = (props: any) => {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
        />
    );
};

export const MoneyInput = (props: any) => {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator={true}
        />
    );
};
