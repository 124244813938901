import React from 'react';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import { Grid, Typography } from '@material-ui/core';

import { useStyles } from '../style';

import ButtonTab from '../../UI/ButtonTab';
import { useStores } from '../../../hooks/useStores';

const AppDealPropertySelect = observer(() => {
    const { AppDealFormStore: store } = useStores();
    const { activePropertyTab, setActivePropertyTab } = store;
    const classes = useStyles();

    return (
        <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={4} lg={4}>
                <ButtonTab
                    activeTabStyle={classes.activeTabStyle}
                    tabStyle={classes.tabStyle}
                    name="personal"
                    setActiveTab={setActivePropertyTab}
                    activeTab={activePropertyTab}
                >
                    <Typography variant="subtitle1">Индивидуальная собственность</Typography>
                    <Typography
                        variant="caption"
                        color="textSecondary"
                        className={clsx({ [classes.activeTextColor]: activePropertyTab === 'personal' })}
                    >
                        На одного человека
                    </Typography>
                </ButtonTab>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
                <ButtonTab
                    activeTabStyle={classes.activeTabStyle}
                    tabStyle={classes.tabStyle}
                    name="together"
                    setActiveTab={setActivePropertyTab}
                    activeTab={activePropertyTab}
                >
                    <Typography variant="subtitle1">Для супругов</Typography>
                    <Typography
                        variant="caption"
                        color="textSecondary"
                        className={clsx({ [classes.activeTextColor]: activePropertyTab === 'together' })}
                    >
                        С разделением на обоих супругов
                    </Typography>
                </ButtonTab>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
                <ButtonTab
                    activeTabStyle={classes.activeTabStyle}
                    tabStyle={classes.tabStyle}
                    name="part"
                    setActiveTab={setActivePropertyTab}
                    activeTab={activePropertyTab}
                >
                    <Typography variant="subtitle1">Долевая собственность</Typography>
                    <Typography
                        variant="caption"
                        color="textSecondary"
                        className={clsx({ [classes.activeTextColor]: activePropertyTab === 'part' })}
                    >
                        В сделке участвуют несколько человек
                    </Typography>
                </ButtonTab>
            </Grid>
        </Grid>
    );
});

export default AppDealPropertySelect;
