import { Box, Button, IconButton, makeStyles, Modal, Paper, Tooltip, Typography, useMediaQuery } from '@material-ui/core';
import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { HelpOutline } from '@material-ui/icons';
import { theme } from '../../styles/muiTheme';

interface IMyClientsForm {
    open: boolean;
    onClose: () => void;
    onButtonClick: () => void;
    message: string;
    isError: boolean;
}

const MyClientsForm = ({ open, onClose, onButtonClick, message, isError }: IMyClientsForm) => {
    const isMD = useMediaQuery(theme.breakpoints.down('md'));

    const classes = useStyles();

    //Renders
    const renderButton = () => {
        return (
            <>
                {isError ? (
                    <Button fullWidth variant="contained" color="primary" size="large" onClick={onClose}>
                        Понятно
                    </Button>
                ) : (
                    <Button fullWidth variant="contained" color="primary" size="large" onClick={onButtonClick}>
                        На главную
                    </Button>
                )}
            </>
        );
    };

    return (
        <Modal open={open} onClose={onClose}>
            <Paper elevation={24} className={classes.modal}>
                <Box mb={4}>
                    <Box mb={1} display={'flex'} justifyContent={'flex-end'}>
                        <IconButton onClick={onClose}>
                            <CloseIcon width={23} height={23} />
                        </IconButton>
                    </Box>
                    <Typography className={classes.messageText}>
                        {!isError ? message : 'Упс! Данный клиент уже обратился в отдел продаж Ак Барс Дом'}
                        {isError && (
                            <Tooltip
                                classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
                                arrow={true}
                                placement={isMD ? 'bottom' : 'bottom-end'}
                                className={classes.inline}
                                title={<Typography variant="body2">*в течение 30 суток его будут сопровождать наши сотрудники.</Typography>}
                            >
                                <HelpOutline className={classes.helpIcon} />
                            </Tooltip>
                        )}
                    </Typography>
                </Box>
                {renderButton()}
            </Paper>
        </Modal>
    );
};

const useStyles = makeStyles(theme => ({
    modal: {
        padding: '26px',
        position: 'absolute',
        background: '#fff',
        borderRadius: 16,
        top: '50%',
        transform: 'translate(-50%, -50%)',
        [theme.breakpoints.up('lg')]: {
            left: '64%',
            width: 360,
        },
        [theme.breakpoints.up('xl')]: {
            left: '59%',
            width: 360,
        },
        [theme.breakpoints.down('md')]: {
            left: '50%',
            width: 300,
        },
    },
    messageText: {
        fontSize: '22px',
        fontWeight: 400,
        lineHeight: '28px',
        textAlign: 'center',
        position: 'relative',
        [theme.breakpoints.down('md')]: {
            paddingBottom: 23,
        },
    },
    inline: {
        display: 'inline',
    },
    tooltip: {
        boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
        border: `1px solid ${theme.palette.material.overlay}`,
        width: 244,
        color: theme.palette.secondary.main,
        opacity: 0,
        backgroundColor: theme.palette.background.default,
    },
    arrow: {
        '&:before': {
            border: `1px solid ${theme.palette.material.overlay}`,
        },
        color: theme.palette.background.default,
    },
    helpIcon: {
        position: 'absolute',
        bottom: 3,
        right: 40,
        borderRadius: 1,
        opacity: 0.6,
        width: 20,
        color: theme.palette.secondary.main,
        [theme.breakpoints.down('md')]: {
            right: 'calc(50% - 10px)',
            bottom: 0,
        },
    },
}));

export default MyClientsForm;
