import React from 'react';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import { Box, makeStyles, Paper, Typography } from '@material-ui/core';

import { IComplexesObjectType } from '../../types/ComplexTypes';

import ComplexObjectIcons from './ComplexObjectIcons';

const ComplexItemObjects = ({ item, complexId }: { item: IComplexesObjectType; complexId: number }) => {
    const classes = useStyles();

    return (
        <Box mt={1} mr={1} width={200}>
            <Link to={`/apartments?&complex=${complexId}&types=${item.type_id}`}>
                <Paper variant="outlined" classes={{ outlined: classes.paper }}>
                    <Box className={classes.object}>
                        <ComplexObjectIcons typeId={item.type_id} className={classes.objectIcon} />

                        <Box>
                            <Typography variant="body2" noWrap>
                                {item.title}
                            </Typography>
                            <Typography variant="subtitle2" color="primary">
                                {item.amount} шт.
                            </Typography>
                        </Box>
                    </Box>
                </Paper>
            </Link>
        </Box>
    );
};

const useStyles = makeStyles(theme => ({
    objectIcon: {
        marginRight: 10,
        //@ts-ignore
        color: theme.palette.primary.A100,
    },
    paper: {
        //@ts-ignore
        ...theme.overrides.MuiPaper.elevation4,
        //@ts-ignore
        borderColor: theme.palette.primary.A50,
        borderRadius: 8,
        cursor: 'pointer',
        '&:hover': {
            //@ts-ignore
            ...theme.overrides.MuiPaper.elevation3,
            borderColor: theme.palette.primary.main,
        },
    },
    object: {
        display: 'flex',
        alignItems: 'center',
        padding: '6px 12px',
    },
}));

export default observer(ComplexItemObjects);
