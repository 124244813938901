import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
    avatar: {
        width: '72px',
        height: '72px',
        background: 'rgba(0, 0, 0, 0.08)',

        [theme.breakpoints.down('sm')]: {
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    mainButtonsWrap: {
        justifyContent: 'center',

        [theme.breakpoints.up('md')]: {
            justifyContent: 'flex-end',
        },
    },
}));
