import React from 'react';
import { Box, CircularProgress, Grid, makeStyles } from '@material-ui/core';

const Loader = ({ size = 40, isAbsolute = false }) => {
    const classes = useStyles();

    if (isAbsolute) {
        return (
            <Box className={classes.isAbsolute}>
                <div className={classes.overline} />
                <Grid container justifyContent="center" alignItems="center">
                    <CircularProgress size={size} color="primary" />
                </Grid>
            </Box>
        );
    }

    return (
        <Box className={classes.root}>
            <Grid container justifyContent="center" alignItems="center">
                <CircularProgress size={size} color="primary" />
            </Grid>
        </Box>
    );
};

const useStyles = makeStyles({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
        flexBasis: '100%',
    },
    isAbsolute: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1,
    },
    overline: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(255,255,255, .7)',
        zIndex: -1,
    },
});

export default Loader;
