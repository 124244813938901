import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Container, makeStyles, Typography } from '@material-ui/core';

import LearningProgram from './components/LearningProgram';
import { useStores } from '../../hooks/useStores';

const LearningScreen = observer(() => {
    const { LearningStore: store } = useStores();

    const classes = useStyles();

    // Effects
    useEffect(() => {
        store.getLearnings(0, 20);
        return () => {};
    }, []);

    // Renders
    return (
        <Container maxWidth="xl">
            <Box mt={{ xs: 1.5, sm: 3 }}>
                <Typography variant="h2">Обучающие материалы</Typography>
            </Box>
            <Box mt={{ xs: 1.5, sm: 3 }} className={classes.educationList}>
                {store.learningPrograms.map(program => (
                    <LearningProgram key={program.id} program={program} />
                ))}
            </Box>
        </Container>
    );
});

const useStyles = makeStyles(theme => ({
    educationList: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: theme.spacing(2),
    },
}));

export default LearningScreen;
