import { observable, action } from 'mobx';
import API from '../api';
import { IComplexes, IComplex } from '../types/ComplexTypes';

export const _tokenStorageKey = 'accessToken';

export class ComplexStore {
    @observable loading = false;

    @observable complexes: IComplexes | null = null;

    @observable complex: IComplex | null = null;

    @action
    getComplexes = () => {
        this.loading = true;

        API.complex
            .getComplexes()
            .then(response => {
                if (response?.data?.success) {
                    this.complexes = response.data;
                }
            })
            .finally(() => {
                this.loading = false;
            });
    };

    @action
    getComplex = (id: number) => {
        this.loading = true;

        API.complex
            .getComplex(id)
            .then(response => {
                if (response?.data?.success) {
                    this.complex = response.data;
                }
            })
            .finally(() => {
                this.loading = false;
            });
    };
}
