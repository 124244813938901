import axios from 'axios';

class StatisticAPI {
    getStats = (filters: any) => {
        return axios.get(`/lead/stats?${filters}`);
    };
}

const statisticAPI = new StatisticAPI();
export default statisticAPI;
