import { IBookingTypeObj } from '../types/ApartmentTypes';

export const bookingTypes: IBookingTypeObj[] = [
    {
        id: 'free',
        title: 'Бесплатное бронирование',
        description: [
            'Бронирование на 3 рабочих дня до 14.00 по МСК',
            'Необходимо подтверждение консультантом',
            'Для перехода на этап оформления сделки нужен готовый пакет документов',
            'Возможность повторного бронирования одной и той же квартиры исключена',
        ],
    },
    {
        id: 'paid',
        title: 'Платное бронирование',
        description: [
            'Срок бронирования до 10 календарных дней. Стоимость услуги: 12 000 руб.  (в т.ч. НДС 20% - 2000 руб.)',
            'Цена на квартиру не изменится на время бронирования',
            'Есть время на подготовку документов к сделке',
            'Автоматическое бронирование без подтверждения консультантом',
        ],
    },
];
