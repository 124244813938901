import axios from 'axios';

class FiltersAPI {
    // get filters data
    getComplexes = () => {
        return axios.get(`/house/complexes`);
    };

    getHouses = (complexId: number) => {
        return axios.get(`/house/complex-houses/${complexId}`);
    };

    getFilters = () => {
        return axios.get(`/house/filters-info`);
    };
}

const filtersAPI = new FiltersAPI();
export default filtersAPI;
