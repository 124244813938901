import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react-lite';

import { ApartmentsTileItemProps } from '../../../types/ApartmentTypes';

const ApartmentsTileItem: FunctionComponent<ApartmentsTileItemProps> = observer(({ columnIndex, rowIndex, style, onClick, children }) => {
    return (
        <div
            className={
                columnIndex % 2 ? (rowIndex % 2 === 0 ? 'GridItemOdd' : 'GridItemEven') : rowIndex % 2 ? 'GridItemOdd' : 'GridItemEven'
            }
            style={style}
            onClick={onClick}
        >
            {children}
        </div>
    );
});

export default ApartmentsTileItem;
