import React from 'react';
import {
    AccordionDetails,
    AccordionSummary,
    Box,
    Grid,
    TextField,
    Typography,
    useMediaQuery,
    useTheme,
    Accordion,
    FormControlLabel,
    Checkbox,
} from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CancelIcon from '@material-ui/icons/Cancel';

import { useStores } from '../../../hooks/useStores';
import { PhoneFormat } from '../../UI/Form';
import { useStyles } from '../style';

import AppDealClientSelect from '../AppDealClient/AppDealClientSelect';
import AppDealBirthCertificateForm from '../AppDealBirthCertificate/AppDealBirthCertificateForm';
import AppDealDocsForm from '../AppDealDocs/AppDealDocsForm';
import AppDealClientChangeModal from '../AppDealTabChangeModal';
import AppDealModal from '../AppDealModal';
import { config } from '../../../Config';

const AppDealParticipantForm = observer((props: any) => {
    const { AppDealFormStore: storeGlobal } = useStores();
    const { store, index: id } = props.participant || {};
    const classes = useStyles();
    const theme = useTheme();
    const isSM = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <>
            <AppDealModal modalMessage={store.modalMessage} closeModal={store.clearMessage} />

            <Accordion classes={{ rounded: classes.rounded }}>
                <AccordionSummary
                    classes={{
                        root: classes.accordionRoot,
                        expandIcon: classes.expandIcon,
                    }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography variant="h4">
                        {storeGlobal.activePropertyTab === 'together' ? 'Супруг(а)' : `Участник ${props.index + 1}`}
                    </Typography>
                    {id > 1 && <CancelIcon className={classes.deleteIcon} onClick={() => storeGlobal.removeParticipant(id)} />}
                </AccordionSummary>

                <AccordionDetails style={{ flexDirection: 'column' }}>
                    <Box>
                        <Box mb={3}>
                            <Typography variant="subtitle1">Заполните первичные данные</Typography>
                        </Box>

                        <Grid container spacing={isSM ? 1 : 2}>
                            <Grid item xs={12} sm={6} md={4} lg={3} className={classes.paddingBottom}>
                                <TextField
                                    fullWidth
                                    required
                                    variant="outlined"
                                    name="surname"
                                    label="Фамилия"
                                    value={store.values.mainInfo.surname}
                                    onChange={store.handleChangeMainInfo}
                                    InputProps={{ notched: false }}
                                    disabled={store.loading || props.index === 0}
                                    error={store.formErrors?.surname}
                                    helperText={store.formErrors?.surname && 'Введите фамилию'}
                                    FormHelperTextProps={{ classes: { root: classes.errorFormTextInput } }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} className={classes.paddingBottom}>
                                <TextField
                                    fullWidth
                                    required
                                    variant="outlined"
                                    name="firstname"
                                    label="Имя"
                                    value={store.values.mainInfo.firstname}
                                    onChange={store.handleChangeMainInfo}
                                    InputProps={{ notched: false }}
                                    disabled={store.loading || props.index === 0}
                                    error={store.formErrors?.firstname}
                                    helperText={store.formErrors?.firstname && 'Введите имя'}
                                    FormHelperTextProps={{ classes: { root: classes.errorFormTextInput } }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} className={classes.paddingBottom}>
                                <TextField
                                    fullWidth
                                    required
                                    variant="outlined"
                                    name="lastname"
                                    label="Отчество"
                                    value={store.values.mainInfo.lastname}
                                    onChange={store.handleChangeMainInfo}
                                    InputProps={{ notched: false }}
                                    disabled={store.loading || props.index === 0}
                                    error={store.formErrors?.lastname}
                                    helperText={store.formErrors?.lastname && 'Введите отчество'}
                                    FormHelperTextProps={{ classes: { root: classes.errorFormTextInput } }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} className={classes.paddingBottom}>
                                <TextField
                                    fullWidth
                                    required
                                    variant="outlined"
                                    name="phone"
                                    label="Телефон"
                                    placeholder={config.phonePlaceholder}
                                    value={store.values.mainInfo.phone}
                                    onChange={store.handleChangeMainInfo}
                                    InputProps={{ inputComponent: PhoneFormat, notched: false }}
                                    error={store.formErrors?.phone}
                                    helperText={store.formErrors?.phone && 'Введите телефон'}
                                    FormHelperTextProps={{ classes: { root: classes.errorFormTextInput } }}
                                    disabled={store.loading || props.index === 0}
                                />
                            </Grid>
                            <Grid item xs={12} sm={8} md={6} lg={5} className={classes.paddingBottom}>
                                <TextField
                                    fullWidth
                                    required
                                    variant="outlined"
                                    name="email"
                                    label="Email"
                                    value={store.values.mainInfo.email}
                                    onChange={store.handleChangeMainInfo}
                                    disabled={store.loading}
                                    InputProps={{ notched: false }}
                                    error={store.formErrors?.email}
                                    helperText={store.formErrors?.email && 'Введите e-mail'}
                                    FormHelperTextProps={{ classes: { root: classes.errorFormTextInput } }}
                                />
                            </Grid>

                            {storeGlobal.activePropertyTab === 'part' && (
                                <>
                                    <Grid item xs={6} sm={4} md={3} lg={3} className={classes.paddingBottom}>
                                        <TextField
                                            fullWidth
                                            required
                                            variant="outlined"
                                            name="propertyPercent"
                                            label="Доля в сделке"
                                            value={store.values.propertyPercent || ''}
                                            onChange={store.handleChangePropertyPercent}
                                            InputProps={{ notched: false }}
                                            disabled={store.loading}
                                            type="number"
                                            error={store.formErrors?.propertyPercent}
                                            helperText={store.formErrors?.propertyPercent && 'Введите долю'}
                                            FormHelperTextProps={{ classes: { root: classes.errorFormTextInput } }}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={store.values.depositor}
                                                    onChange={store.toggleDepositorCheckbox}
                                                    name="depositor"
                                                />
                                            }
                                            label={<Typography variant="body2">Депонент</Typography>}
                                        />
                                    </Grid>
                                </>
                            )}

                            {storeGlobal.activePropertyTab === 'together' && (
                                <Grid item>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={store.values.depositor}
                                                onChange={store.toggleDepositorCheckbox}
                                                name="depositor"
                                            />
                                        }
                                        label={<Typography variant="body2">Депонент</Typography>}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </Box>

                    {storeGlobal.activePropertyTab !== 'together' && <AppDealClientSelect store={store} />}

                    <AppDealClientChangeModal
                        showModal={store.showChangeClientModal}
                        setNewTab={store.proceedToNewClient}
                        hideModal={store.hideChangeClientModal}
                    />

                    <Box mt={5}>
                        <Box mb={3}>
                            <Typography variant="subtitle1">Заполните документы</Typography>
                        </Box>

                        <>
                            {store.activeClientTab === 'under14' ? (
                                <AppDealBirthCertificateForm store={store} />
                            ) : (
                                <>
                                    {/* скрыта в рамках задачи ABD-720
                                        <AppDealPassportForm store={store} /> 
                                    */}
                                    <AppDealDocsForm store={store} />
                                </>
                            )}
                        </>
                    </Box>
                </AccordionDetails>
            </Accordion>
        </>
    );
});

export default AppDealParticipantForm;
