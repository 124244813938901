import React from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { observer } from 'mobx-react-lite';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Grid,
    makeStyles,
    Typography,
    useMediaQuery,
    useTheme,
} from '@material-ui/core';
import NumberFormat from 'react-number-format';

import { IAppDealParticipantView } from '../../../types/AppDealTypes';

import AppDealPassportView from '../AppDealPassport/AppDealPassportView';
import AppDealDocsView from '../AppDealDocs/AppDealDocsView';
import AppDealBirthCertificateView from '../AppDealBirthCertificate/AppDealBirthCertificateView';

export const AppDealParticipantView = observer((props: IAppDealParticipantView) => {
    const classes = useStyles();
    const {
        lastname,
        firstname,
        surname,
        phone,
        email,
        depositor,
        passport_number,
        passport_issued,
        passport_date_of_issue,
        passport_department_code,
        birthday,
        birthPlace,
        address,
        file_passport_main,
        file_passport_registration,
        file_inn,
        inn,
        snils_number,
        file_snils,
        file_marriage_scan,
        file_notarial_scan,
        birthCertNumber,
        birthCertSerial,
        file_birth_cert_scan,
        file_agree_parent_scan,
    } = props.data;
    const theme = useTheme();
    const isSM = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Accordion classes={{ rounded: classes.rounded }}>
            <AccordionSummary
                classes={{
                    root: classes.root,
                    expandIcon: classes.expandIcon,
                }}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography variant="h4">{props.status === 15923527 ? 'Участник' : `Участник ${props.id + 1}`}</Typography>
            </AccordionSummary>

            <AccordionDetails style={{ flexDirection: 'column' }}>
                <Box mb={3}>
                    <Typography variant="subtitle1">Первичные данные</Typography>
                </Box>
                <Grid container spacing={isSM ? 1 : 2}>
                    <Grid item xs={12} sm={6} lg={3}>
                        <Box mb={2}>
                            <Typography color="textSecondary" variant="caption">
                                Фамилия
                            </Typography>
                            <Typography variant="body1">{surname || '-'}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3}>
                        <Box mb={2}>
                            <Typography color="textSecondary" variant="caption">
                                Имя
                            </Typography>
                            <Typography variant="body1">{firstname || '-'}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3}>
                        <Box mb={2}>
                            <Typography color="textSecondary" variant="caption">
                                Отчество
                            </Typography>
                            <Typography variant="body1">{lastname || '-'}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={3}>
                        <Box mb={2}>
                            <Typography color="textSecondary" variant="caption">
                                Телефон
                            </Typography>
                            <Typography variant="body1">
                                {phone ? <NumberFormat value={phone} format="+# ### ###-##-##" mask="_" displayType="text" /> : '-'}
                            </Typography>
                        </Box>
                    </Grid>
                    {props.status !== 15923527 && (
                        <Grid item xs={12} sm={6} lg={4}>
                            <Box mb={2}>
                                <Typography color="textSecondary" variant="caption">
                                    Email
                                </Typography>
                                <Typography variant="body1">{email || '-'}</Typography>
                            </Box>
                        </Grid>
                    )}
                    {/*<Grid item xs={12} sm={6} lg={3}>*/}
                    {/*    <Box mb={2}>*/}
                    {/*        <Typography color="textSecondary" variant="caption">*/}
                    {/*            Доля в сделка, %*/}
                    {/*        </Typography>*/}
                    {/*        <Typography variant="body1">{'-'}</Typography>*/}
                    {/*    </Box>*/}
                    {/*</Grid>*/}
                    {props.status !== 15923527 && (
                        <Grid item xs={12} sm={6} lg={4}>
                            <Box mb={2}>
                                <Typography color="textSecondary" variant="caption">
                                    Депонент
                                </Typography>
                                <Typography variant="body1">{depositor === 1 ? 'Да' : 'Нет' || '-'}</Typography>
                            </Box>
                        </Grid>
                    )}
                </Grid>
                {props.status !== 15923527 && (
                    <>
                        <Box mt={2} mb={3}>
                            <Typography variant="subtitle1">Документы</Typography>
                        </Box>

                        {birthPlace && (
                            <AppDealPassportView
                                data={{
                                    passport_number,
                                    passport_issued,
                                    passport_date_of_issue,
                                    passport_department_code,
                                    birthday,
                                    birthPlace,
                                    address,
                                    file_passport_main,
                                    file_passport_registration,
                                }}
                            />
                        )}
                        {!!birthCertNumber && (
                            <AppDealBirthCertificateView data={{ birthCertNumber, birthCertSerial, file_birth_cert_scan }} />
                        )}
                        <AppDealDocsView
                            data={{
                                file_inn,
                                inn,
                                snils_number,
                                file_snils,
                                file_marriage_scan,
                                file_notarial_scan,
                                file_agree_parent_scan,
                            }}
                        />
                    </>
                )}
            </AccordionDetails>
        </Accordion>
    );
});

const useStyles = makeStyles(theme => ({
    root: {
        marginLeft: theme.spacing(4),
    },
    expandIcon: {
        position: 'absolute',
        left: -28,
    },
    rounded: {
        borderRadius: 8,
        //@ts-ignore
        border: `1px solid ${theme.palette.primary.A100}`,
        boxShadow: 'none',
    },
    depositor: {
        alignSelf: 'center',
    },
}));

export default AppDealParticipantView;
