import React from 'react';
import { Typography, Button, SvgIcon, SvgIconProps, Paper, Box, makeStyles } from '@material-ui/core';

import vector1 from '../../../img/vector_1.svg';
import vector2 from '../../../img/vector_2.svg';

export const Banner = ({ firstPayment = '', closingDate = '', onCopy = () => {} }) => {
    const classes = useStyle();
    return (
        <Paper className={classes.root}>
            <Box>
                <Typography variant="h3" component="p">
                    Ежемесячный платёж
                </Typography>
                <Typography variant="h1" component="p" className={classes.payment}>
                    {firstPayment}
                </Typography>
            </Box>
            <Box mt={1} mb={3}>
                <Typography variant="h4" component="p">
                    Дата закрытия ипотеки
                </Typography>{' '}
                <Typography variant="h2" component="p">
                    {closingDate}
                </Typography>
            </Box>
            <Button fullWidth variant="contained" className={classes.btn} startIcon={<Icon />} onClick={onCopy}>
                Поделиться расчетом
            </Button>
        </Paper>
    );
};

const Icon = (props: SvgIconProps) => (
    <SvgIcon {...props}>
        <path d="M12.5 1L8.5 5H11.5V14H13.5V5H16.5L12.5 1ZM18.5 23H6.5C5.96957 23 5.46086 22.7893 5.08579 22.4142C4.71071 22.0391 4.5 21.5304 4.5 21V9C4.5 8.46957 4.71071 7.96086 5.08579 7.58579C5.46086 7.21071 5.96957 7 6.5 7H9.5V9H6.5V21H18.5V9H15.5V7H18.5C19.0304 7 19.5391 7.21071 19.9142 7.58579C20.2893 7.96086 20.5 8.46957 20.5 9V21C20.5 21.5304 20.2893 22.0391 19.9142 22.4142C19.5391 22.7893 19.0304 23 18.5 23Z" />
    </SvgIcon>
);

const useStyle = makeStyles(theme => ({
    root: {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
        backgroundImage: `url(${vector2}), url(${vector1})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'bottom 30px right, bottom right',
        borderRadius: theme.spacing(2),
        padding: theme.spacing(5),
    },
    btn: {
        color: theme.palette.primary.main,
    },
    payment: {
        fontWeight: 700,
    },
}));
