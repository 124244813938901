import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const SwitchTiles = ({ showLess }: any) => {
    const classes = useStyles();

    return (
        <Box width={24} display="flex" flexWrap="wrap">
            {showLess ? (
                <>
                    <Box className={classes.square} />
                    <Box className={classes.square} />
                    <Box className={classes.square} />
                    <Box className={classes.square} />
                    <Box className={classes.square} />
                    <Box className={classes.square} />
                    <Box className={classes.square} />
                    <Box className={classes.square} />
                    <Box className={classes.square} />
                </>
            ) : (
                <>
                    <Box className={classes.bigSquare} />
                    <Box className={classes.bigSquare} />
                    <Box className={classes.bigSquare} />
                    <Box className={classes.bigSquare} />
                </>
            )}
        </Box>
    );
};

const useStyles = makeStyles(theme => ({
    square: {
        width: 6,
        height: 6,
        background: theme.palette.primary.main,
        borderRadius: 1,
        margin: '0.5px',
        cursor: 'pointer',
    },
    bigSquare: {
        width: 9,
        height: 9,
        background: theme.palette.primary.main,
        borderRadius: 1,
        margin: '1px',
        cursor: 'pointer',
    },
}));

export default SwitchTiles;
