import { Typography } from '@material-ui/core';
import React from 'react';
import NumberFormat from 'react-number-format';
import { cleanupMask } from '../../utils/cleanupMask';

interface IPhoneLinkProps {
    phone: string;
}

const PhoneLink: React.FC<IPhoneLinkProps> = ({ phone }) => {
    const rawPhone = cleanupMask(phone);

    return (
        <a href={`tel:+${rawPhone}`}>
            <Typography variant="body2" noWrap>
                <NumberFormat value={rawPhone} format="+# ### ###-##-##" mask="_" displayType="text" />
            </Typography>
        </a>
    );
};

export default PhoneLink;
