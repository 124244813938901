import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Checkbox, TextField, Typography } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import { IFilterDevEnd } from '../../../types/ApartmentTypes';
import { useStores } from '../../../hooks/useStores';
import { useStyles } from '../style';

const ApartmentsFilterDevEnd = observer(() => {
    const { ApartmentStore: store } = useStores();
    const classes = useStyles();

    return (
        <Box mb={{ xxs: 2, sm: 3, md: 4 }}>
            <Typography variant="subtitle2">Срок сдачи</Typography>
            <Autocomplete
                multiple
                limitTags={1}
                id="devEnd"
                popupIcon={<ExpandMoreIcon />}
                value={(store.filters.devEnd?.length && store.filters.devEnd) || []}
                onChange={(e, value: any) => {
                    store.handleAutocompleteChange('devEnd', value);
                }}
                renderOption={(option, { selected }) => (
                    <>
                        <Checkbox
                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                            style={{ marginRight: 8 }}
                            checked={selected}
                        />
                        {option.title}
                    </>
                )}
                disableCloseOnSelect
                options={store.devEnd}
                getOptionLabel={(option: IFilterDevEnd) => option.title}
                renderInput={params => <TextField {...params} name="devEnd" variant="outlined" placeholder="Выбрать" />}
                noOptionsText="Нет подходящих вариантов"
                className={classes.devEndSelect}
                loading={store.filterLoading}
                loadingText="Загрузка"
            />
        </Box>
    );
});

export default ApartmentsFilterDevEnd;
