import { observable, action } from 'mobx';
// import API from '../api';

export const _tokenStorageKey = 'accessToken';

export class DocumentsStore {
    @observable loading = false;

    @observable documentsData: any = null;

    @action
    getDocument = () => {
        this.loading = true;

        //   API request
    };
    
    @action
    downloadDocument = () => {
        this.loading = true;

        //   API request
    };
}
