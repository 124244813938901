import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Paper, Typography, Grid, makeStyles } from '@material-ui/core';

import { useStores } from '../../../hooks/useStores';

import DocumentItemView from '../AppDealDocs/DocumentItemView';

const AppDealPetitionView = observer(() => {
    const { AppDealFormStore: store } = useStores();
    const classes = useStyles();

    if (!store.appDeal?.file_petition) return null;

    return (
        <Box mb={{ xxs: 2, md: 4 }}>
            <Paper className={classes.paper}>
                <Box p={2}>
                    <Box mb={2}>
                        <Typography variant="subtitle1">Согласие на бронирование</Typography>
                    </Box>

                    <Grid item xs={12} sm={6}>
                        <DocumentItemView fileUrl={store.appDeal?.file_petition}>Скан согласия на бронирование</DocumentItemView>
                    </Grid>
                </Box>
            </Paper>
        </Box>
    );
});

const useStyles = makeStyles(theme => ({
    paper: {
        borderRadius: 16,
        background: theme.palette.surface.main,
        boxShadow: 'none',
    },
}));

export default AppDealPetitionView;
