import React from 'react';
import { Fab, makeStyles, useTheme, useMediaQuery } from '@material-ui/core';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import clsx from 'clsx';

const CarouselArrow = (props: any) => {
    const { className, addClassName, style, onClick, type } = props;
    const classes = useStyles();
    const theme = useTheme();
    const isXS = useMediaQuery(theme.breakpoints.down('lg'));

    return (
        <div
            className={clsx(className, addClassName, classes.slickArrow, type === 'next' ? classes.slickArrowNext : classes.slickArrowPrev)}
            style={{ ...style }}
        >
            <Fab size={isXS ? 'small' : 'medium'} onClick={onClick} className={classes.slickArrowFab}>
                {type === 'next' ? <NavigateNextIcon /> : <NavigateBeforeIcon />}
            </Fab>
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    slickArrow: {
        width: '48px',
        height: '48px',
        zIndex: 1,
        [theme.breakpoints.down('lg')]: {
            width: '40px',
            height: '40px',
        },
        '&::before': {
            content: 'none',
        },
    },
    slickArrowFab: {
        background: '#fff',
        transition: 'background 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',

        '&:hover': {
            background: theme.palette.secondary.main,
            color: '#fff',
        },
    },
    slickArrowPrev: {
        '@media (max-width: 1200px)': {
            left: -8,
        },
        [theme.breakpoints.down('md')]: {
            left: 0,
        },
        [theme.breakpoints.down('xs')]: {
            left: 8,
        },
    },
    slickArrowNext: {
        '@media (max-width: 1200px)': {
            right: -8,
        },
        [theme.breakpoints.down('md')]: {
            right: 0,
        },
        [theme.breakpoints.down('xs')]: {
            right: 8,
        },
    },
}));

export default CarouselArrow;
