import React from 'react';
import { Box, Typography, Table, TableBody, TableRow, TableCell } from '@material-ui/core';
import { IStatisticViewProps, IStatisticItem } from '../../types/StatisticTypes';
import Loader from '../Loader/Loader';

const StatisticView = ({ data = [], loading }: IStatisticViewProps) => {
    const renderList = () => {
        return (
            <>
                <Box mt={1} mb={2} display="flex" justifyContent="space-between">
                    <Typography variant="h3">{data[0].title}</Typography>
                    <Typography variant="h3" color="secondary">
                        {data[0].value}
                    </Typography>
                </Box>

                <Table>
                    <TableBody>
                        {data.slice(1).map((item: IStatisticItem, index: number) => (
                            <TableRow key={index}>
                                <TableCell>
                                    <Typography color="textSecondary">{item.title}</Typography>
                                </TableCell>
                                <TableCell align="right">
                                    <Typography>{item.value}</Typography>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </>
        );
    };

    return (
        <Box
            p={{ xxs: 0, sm: 3 }}
            pb={{ xxs: 0, sm: 1 }}
            border={{ xxs: 0, sm: 1 }}
            borderRadius={16}
            borderColor={{ xxs: '#fff', sm: 'grey.200' }}
        >
            {loading ? (
                <Box width="100%" height="300px" display="flex" position="relative">
                    <Loader isAbsolute />
                </Box>
            ) : data.length ? (
                renderList()
            ) : null}
        </Box>
    );
};

export default StatisticView;
