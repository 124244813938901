import React from 'react';
import { Box, Grid, Typography, Switch, Divider } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../hooks/useStores';

const NotificationsSettings = observer(() => {
    const { ProfileStore: store } = useStores();

    const handleChange = (e: any) => {
        store.handleChangeNotifcation(e);
    };

    if (!store.profileNotifications) {
        return null;
    }

    return (
        <Box mt={8}>
            <Box mb={2}>
                <Typography variant="h4" component="p">
                    Уведомления
                </Typography>
            </Box>
            <Divider />
            <Box mt={4} mb={2} maxWidth={400}>
                <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={9}>
                        <Typography color="textSecondary" component="span">
                            Разрешить все уведомления
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Switch
                            color="primary"
                            checked={store.profileNotifications.all}
                            onChange={handleChange}
                            name="all"
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                            // disabled={store.profileNotificationsLoading}
                        />
                    </Grid>
                </Grid>
            </Box>
            <Box mt={2} mb={2} maxWidth={400}>
                <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={9}>
                        <Typography color="textSecondary" component="span">
                            Изменение статуса заявки
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Switch
                            color="primary"
                            checked={store.profileNotifications.changeStatusRequest}
                            onChange={handleChange}
                            name="changeStatusRequest"
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                            // disabled={store.profileNotificationsLoading}
                        />
                    </Grid>
                </Grid>
            </Box>
            <Box mt={2} mb={2} maxWidth={400}>
                <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={9}>
                        <Typography color="textSecondary" component="span">
                            Новости и обучение
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Switch
                            color="primary"
                            checked={store.profileNotifications.newsStock}
                            onChange={handleChange}
                            name="newsStock"
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                            // disabled={store.profileNotificationsLoading}
                        />
                    </Grid>
                </Grid>
            </Box>
            <Box mt={2} mb={2} maxWidth={400}>
                <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={9}>
                        <Typography color="textSecondary" component="span">
                            Срок истечения брони
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Switch
                            color="primary"
                            checked={store.profileNotifications.expirationDate}
                            onChange={handleChange}
                            name="expirationDate"
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                            // disabled={store.profileNotificationsLoading}
                        />
                    </Grid>
                </Grid>
            </Box>
            <Box mt={2} mb={2} maxWidth={400}>
                <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={9}>
                        <Typography color="textSecondary" component="span">
                            Перевод в статус «сделка»
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Switch
                            color="primary"
                            checked={store.profileNotifications.changeStatusToDeals}
                            onChange={handleChange}
                            name="changeStatusToDeals"
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                            // disabled={store.profileNotificationsLoading}
                        />
                    </Grid>
                </Grid>
            </Box>
            <Box mt={2} mb={2} maxWidth={400}>
                <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={9}>
                        <Typography color="textSecondary" component="span">
                            Смена статуса сделки
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Switch
                            color="primary"
                            checked={store.profileNotifications.changeStatusDeals}
                            onChange={handleChange}
                            name="changeStatusDeals"
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                            // disabled={store.profileNotificationsLoading}
                        />
                    </Grid>
                </Grid>
            </Box>
            <Box mt={2} mb={2} maxWidth={400}>
                <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={9}>
                        <Typography color="textSecondary" component="span">
                            Статус отчетов (документов)
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Switch
                            color="primary"
                            checked={store.profileNotifications.reportStatus}
                            onChange={handleChange}
                            name="reportStatus"
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                            // disabled={store.profileNotificationsLoading}
                        />
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
});

export default NotificationsSettings;
