import React from 'react';
import {
    AuthStore,
    ProfileStore,
    AgencyStore,
    ComplexStore,
    AppsDealsStore,
    EmployeesStore,
    NewsStore,
    StockStore,
    FeedbackStore,
    ApartmentStore,
    AppDealFormStore,
    SystemStore,
    RatingsStore,
    StatisticStore,
    FavoriteStore,
    HistoryStore,
    CalculatorStore,
    DocumentsStore,
} from '../stores';
import { LearningStore } from '../stores/LearningStore';
import { MyClientsStore } from '../stores/MyClientsStore';

export const stores = {
    AuthStore: new AuthStore(),
    ProfileStore: new ProfileStore(),
    AgencyStore: new AgencyStore(),
    ComplexStore: new ComplexStore(),
    AppsDealsStore: new AppsDealsStore(),
    EmployeesStore: new EmployeesStore(),
    NewsStore: new NewsStore(),
    StockStore: new StockStore(),
    FeedbackStore: new FeedbackStore(),
    ApartmentStore: new ApartmentStore(),
    AppDealFormStore: new AppDealFormStore(),
    SystemStore: new SystemStore(),
    RatingsStore: new RatingsStore(),
    StatisticStore: new StatisticStore(),
    FavoriteStore: new FavoriteStore(),
    HistoryStore: new HistoryStore(),
    CalculatorStore: new CalculatorStore(),
    DocumentsStore: new DocumentsStore(),
    MyClientsStore: new MyClientsStore(),
    LearningStore: new LearningStore(),
};

export const storesContext = React.createContext(stores);
