import React from 'react';
import { Typography, makeStyles, Box } from '@material-ui/core';
import { RoomOutlined } from '@material-ui/icons';

import { IAccreditationBankImage } from '../../../types/CalculatorTypes';

export const Image: React.FC<IAccreditationBankImage> = ({ image, address, name }) => {
    const classes = useStyle(image);
    return (
        <div className={classes.root}>
            <Box>
                <Typography variant="h3" component="p">
                    {name}
                </Typography>
            </Box>
            <Box>
                <Typography variant="body2" component="p" className={classes.street}>
                    <RoomOutlined fontSize="small" />
                    {address}
                </Typography>
            </Box>
        </div>
    );
};

const useStyle = (src: string) =>
    makeStyles(theme => ({
        root: {
            minWidth: 360,
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'flex-start',
            flexDirection: 'column',
            position: 'relative',
            borderRadius: theme.spacing(2),
            padding: theme.spacing(2),
            background: `linear-gradient(180deg, transparent 23.44%, rgba(0, 0, 0, 0.96) 100%), url(${src}) `,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            color: theme.palette.primary.contrastText,
            [theme.breakpoints.down('md')]: {
                marginBottom: 12,
                minHeight: 192,
                minWidth: 'auto',
            },
        },
        street: {
            display: 'inline-flex',
            alignItems: 'center',
            '& > :first-child': {
                marginRight: theme.spacing(1) / 2,
            },
        },
    }))();
