import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';

const SliderLink = (props: any) => {
    const history = useHistory();
    const classes = useStyles();
    let pageX: any,
        pageY: any = null;

    return (
        <div
            onClick={e => e.preventDefault()}
            onMouseDown={(e: any) => {
                pageX = e.pageX;
                pageY = e.pageY;
            }}
            onMouseUp={(e: any) => {
                if (e.pageX === pageX && e.pageY === pageY) {
                    history.push(props.to);
                }
            }}
            {...props}
            className={`${props.className} ${classes.root}`}
        >
            {props.children}
        </div>
    );
};

const useStyles = makeStyles({
    root: {
        cursor: 'pointer',
    },
});

export default SliderLink;
