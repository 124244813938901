import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Link, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { Container, Box, Grid, Button, useTheme, useMediaQuery, Drawer, Fab, makeStyles } from '@material-ui/core';
import TuneIcon from '@material-ui/icons/Tune';
import ShortTextIcon from '@material-ui/icons/ShortText';
import NotesIcon from '@material-ui/icons/Notes';
import AddIcon from '@material-ui/icons/Add';

import { IAppDealType } from '../../types/AppDealTypes';
import { useStores } from '../../hooks/useStores';
import { getActiveTab } from '../../utils/getActiveTab';

import AppDealFilter from '../../components/AppsDeals/AppDealFilter/AppDealFilter';
import SearchInput from '../../components/UI/SearchInput';
import TextButton from '../../components/UI/TextButton';
import AppDealHistoryFilter from '../../components/AppsDeals/AppDealFilter/AppDealHistoryFilter';
import AppsDealsTabsContent from '../../components/AppsDeals/AppsDealTabs/AppsDealsTabsContent';
import AppsDealsTabs from '../../components/AppsDeals/AppsDealTabs/AppsDealsTabs';
import AppDealPayLinkModal from '../../components/AppsDeals/AppDealPayLinkModal';

const AppsDeals = observer(() => {
    const { AppsDealsStore: store } = useStores();
    const [activeTab, setActiveTab] = useState<IAppDealType>('deals');
    const [mobileFilter, setMobileFilter] = useState(false);
    const [cardSizeIsDense, setCardSizeIsDense] = useState(false);

    const classes = useStyles();
    const history = useHistory();
    const hash = history.location.hash;

    const theme = useTheme();
    const isMD = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {
        setTimeout(() => {
            store.resetAppsDeals();
            setActiveTab(getActiveTab(hash));
        }, 1);
    }, [hash, store]);

    useEffect(() => {
        const parsed = queryString.parse(history.location.search);

        const filters = {
            type: 'deal_request',
            search: parsed.search || '',
            realtors: parsed.realtors || '',
            dateFrom: parsed.dateFrom || '',
            dateTo: parsed.dateTo || '',
            stage: parsed.stage || '',
            complex: parsed.complex || '',
            houseNumbers: parsed.houseNumbers || '',
            objectNumber: parsed.objectNumber || '',
            priceFrom: parsed.priceFrom || '',
            priceTo: parsed.priceTo || '',
        };

        !hash.includes('history') && store.getAppsDeals(store.paginationPage, 20, filters);
    }, [store, hash, history.location.search, store.paginationPage, store.filtersIsReset]);

    useEffect(() => {
        const parsed = queryString.parse(history.location.search);

        const filters = {
            search: parsed.search || '',
            dateFrom: parsed.dateFrom || '',
            dateTo: parsed.dateTo || '',
            status: parsed.status || '',
        };

        hash.includes('history') && store.getHistory(store.paginationPage, 20, filters);
    }, [store.paginationPage, hash, store, history.location.search]);

    useEffect(() => {
        return () => {
            store.resetAppsDeals();
            store.resetFilters();
        };
    }, [store]);

    const renderFilter = () => (
        <>
            {isMD ? (
                <Drawer anchor="right" open={mobileFilter} onClose={() => setMobileFilter(false)}>
                    {activeTab === 'history' ? (
                        <AppDealHistoryFilter onCloseFilter={() => setMobileFilter(false)} />
                    ) : (
                        <AppDealFilter onCloseFilter={() => setMobileFilter(false)} />
                    )}
                </Drawer>
            ) : (
                <>{activeTab === 'history' ? <AppDealHistoryFilter /> : <AppDealFilter />}</>
            )}
        </>
    );

    return (
        <Container maxWidth="xl">
            <Box mt={{ xxs: 4, md: 6 }} mb={{ xxs: 7, md: 9 }}>
                <Grid container spacing={4}>
                    <Grid item xs={12} lg={4}>
                        <Box mb={{ xxs: 0, lg: 5 }} display={isMD ? 'block' : 'flex'}>
                            <AppsDealsTabs activeTab={activeTab} />
                        </Box>

                        {!isMD && activeTab === 'deals' && (
                            <Box my={5}>
                                <Link to="/applications-and-deals/create">
                                    <Button color="primary" variant="contained" size="large" fullWidth>
                                        Добавить сделку
                                    </Button>
                                </Link>
                            </Box>
                        )}
                        {renderFilter()}
                    </Grid>

                    <Grid item xs={12} lg={8}>
                        <Box mb={{ xxs: 3, lg: 4 }}>
                            <SearchInput onChangeCb={store.resetAppsDeals} />
                        </Box>

                        {isMD && (
                            <Box display="flex" justifyContent="space-between" mb={3}>
                                <TextButton color="primary" startIcon={<TuneIcon />} onClick={() => setMobileFilter(true)}>
                                    Фильтр
                                </TextButton>
                                {activeTab !== 'history' && (
                                    <TextButton
                                        color="primary"
                                        startIcon={cardSizeIsDense ? <NotesIcon /> : <ShortTextIcon />}
                                        onClick={() => setCardSizeIsDense(!cardSizeIsDense)}
                                    >
                                        Показать {cardSizeIsDense ? 'больше' : 'меньше'}
                                    </TextButton>
                                )}
                            </Box>
                        )}

                        <AppsDealsTabsContent tabPanelId={activeTab} cardSizeIsDense={cardSizeIsDense} />
                    </Grid>
                </Grid>
            </Box>

            {isMD && activeTab === 'deals' && (
                <Box className={classes.addBtn}>
                    <Link to="/applications-and-deals/create">
                        <Fab color="primary" aria-label="add">
                            <AddIcon />
                        </Fab>
                    </Link>
                </Box>
            )}

            <AppDealPayLinkModal />
        </Container>
    );
});

const useStyles = makeStyles(theme => ({
    addBtn: {
        position: 'fixed',
        bottom: 30,
        right: 30,
        zIndex: 20,
        [theme.breakpoints.down('xs')]: {
            bottom: 20,
            right: 20,
        },
    },
}));

export default AppsDeals;
