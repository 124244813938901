import React from 'react';
import { Box, Typography } from '@material-ui/core';

const MainNotAccepted = () => {
    return (
        <div className="auth-page__content auth-page__content_bg2">
            <Box p={{ xxs: 2, md: 4 }} maxWidth={448} width="100%" margin="auto" textAlign="center">
                <Box mb={5}>
                    <Typography variant="h3">Благодарим вас за регистрацию!</Typography>
                </Box>
                <Box>
                    <Typography variant="body1">Ваш запрос на регистрацию находится на согласовании у руководителя агентства.</Typography>
                </Box>
            </Box>
        </div>
    );
};

export default MainNotAccepted;
