import React from 'react';
import Slider from 'react-slick';
import { Box, Typography } from '@material-ui/core';

import { IApartmentItem } from '../../types/ApartmentTypes';

import CarouselArrow from '../CarouselArrow/CarouselArrow';
import ApartmentCard from '../UI/ApartmentCard';

const RelatedObjectsCarousel = ({ data }: { data: any }) => {
    const sliderSettings = {
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        prevArrow: <CarouselArrow type="prev" />,
        nextArrow: <CarouselArrow type="next" />,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    if (!data.length) {
        return null;
    }

    return (
        <Box>
            <Box mb={3}>
                <Typography variant="h4">Похожие объекты</Typography>
            </Box>

            <Box mx={-2}>
                <Slider {...sliderSettings} className="slick-slider__visible">
                    {data &&
                        data.map((apartment: IApartmentItem) => (
                            <Box p={2} key={apartment.object_id}>
                                <ApartmentCard apartment={apartment} />
                            </Box>
                        ))}
                </Slider>
            </Box>
        </Box>
    );
};

export default RelatedObjectsCarousel;
