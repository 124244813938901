import React from 'react';
import { Container, Typography, makeStyles } from '@material-ui/core';

import { AccreditationComplex } from '../../components/Calculator/AccreditationComplex';
import { CalculatorBlock } from '../../components/Calculator/CalculatorBlock/CalculatorBlock';

const Calculator = () => {
    const classes = useStyle();
    return (
        <div className={classes.root}>
            <Container maxWidth="xl" className={classes.container}>
                <Typography variant="h2" component="h1">
                    Ипотечный калькулятор
                </Typography>
                <Typography variant="h3" component="p" color="primary" className={classes.subtitle}>
                    Рассчитать условия ипотеки
                </Typography>
                
                <CalculatorBlock />

                <AccreditationComplex />
            </Container>
        </div>
    );
};

export default Calculator;

const useStyle = makeStyles(theme => ({
    root: {
        width: '100%',
        background: theme.palette.surface.main,
    },
    container: {
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5),
    },
    subtitle: {
        marginTop: theme.spacing(3) / 2,
        marginBottom: theme.spacing(6) + 4,
    },
}));
