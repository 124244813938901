import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import {
    Box,
    Typography,
    Grid,
    Select,
    MenuItem,
    Button,
    TextField,
    FormControlLabel,
    Checkbox,
    useMediaQuery,
    IconButton,
    useTheme,
    Divider,
    CircularProgress,
} from '@material-ui/core';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { IFilterDevEnd } from '../../../types/ApartmentTypes';
import { IComplexField } from '../../../types/CommonTypes';
import { useStores } from '../../../hooks/useStores';
import { smoothScrollToTop } from '../../../utils/smoothScrollToTop';
import { declOfNum } from '../../../utils/declOfNum';
import { useStyles } from '../../Apartments/style';

import Loader from '../../Loader/Loader';
import { MoneyInput } from '../../UI/Form';
import FormLabelCustom from '../../UI/FormLabelCustom';
import TextButton from '../../UI/TextButton';

const ModalApartmentFilter = observer(({ onCloseFilter }: { onCloseFilter: any }) => {
    const { ApartmentStore: store } = useStores();

    const classes = useStyles();
    const theme = useTheme();
    const isMD = useMediaQuery(theme.breakpoints.down('md'));

    const handleResetFilters = () => {
        store.resetFilters();
    };

    const handleFilter = (e: React.FormEvent) => {
        e.preventDefault();

        store.setPaginationPage(0);
        smoothScrollToTop();

        store.getApartmentsForModal(store.paginationPage, 20, true);

        if (isMD) {
            onCloseFilter();
        }
    };

    useEffect(() => {
        store.getFilters();
        store.getComplexes();
        store.resetFilters();

        return () => {
            store.resetFilters();
            store.afterFiltersReset();
        };
    }, []);

    return (
        <Box className={classes.modalFiltersWrap} component="form" onSubmit={handleFilter}>
            <Box px={{ xxs: 2, sm: 3 }}>
                <Box py={2}>
                    <Grid container justify="space-between" alignItems="center">
                        <Typography variant="h4">Фильтр</Typography>
                        {isMD ? (
                            <IconButton size="small" onClick={onCloseFilter}>
                                <CloseOutlinedIcon />
                            </IconButton>
                        ) : (
                            <TextButton onClick={handleResetFilters} disabled={store.filtersIsReset}>
                                Сбросить
                            </TextButton>
                        )}
                    </Grid>
                </Box>
                <Divider />
            </Box>

            <Box px={{ xxs: 2, sm: 3 }} pt={2} pb={8} className={classes.scrollWrap}>
                {store.filterLoading && <Loader isAbsolute />}

                <Box mb={{ xxs: 2, sm: 3, md: 4 }}>
                    <FormLabelCustom id="complex">Название ЖК</FormLabelCustom>
                    <Autocomplete
                        multiple
                        limitTags={1}
                        popupIcon={<ExpandMoreIcon />}
                        value={store.filters.complex || []}
                        onChange={(e, value: any) => {
                            store.handleChangeComplex(e, value);
                            store.getHousesInModal(value);
                        }}
                        options={store.complexes}
                        getOptionLabel={(option: IComplexField) => option.name}
                        renderInput={params => <TextField {...params} name="complex" variant="outlined" placeholder="Выбрать" />}
                        className={classes.complexSelect}
                        noOptionsText="Нет подходящих вариантов"
                    />
                </Box>

                <Box mb={{ xxs: 2, sm: 3, md: 4 }}>
                    <FormLabelCustom id="houseNumber">Номер дома</FormLabelCustom>
                    <Autocomplete
                        freeSolo
                        clearOnBlur
                        clearOnEscape
                        popupIcon={<ExpandMoreIcon />}
                        value={store.filters.houseNumbers}
                        onChange={(e, value: any) => store.handleChangeHouseNumber(e, value)}
                        options={store.freeSoloHouseNumber.length ? [store.freeSoloHouseNumber, ...store.houseNumbers] : store.houseNumbers}
                        getOptionLabel={(option: string) => option}
                        renderInput={params => (
                            <TextField
                                {...params}
                                name="complex"
                                variant="outlined"
                                placeholder="Введите номер"
                                onChange={store.handleHouseNumberFreeSoloInput}
                            />
                        )}
                        className={classes.complexSelect}
                        noOptionsText="Нет подходящих вариантов"
                    />
                </Box>

                <Box mb={{ xxs: 2, sm: 3, md: 4 }}>
                    <FormLabelCustom id="objectNumber">Номер помещения</FormLabelCustom>
                    <TextField
                        fullWidth
                        name="objectNumber"
                        variant="outlined"
                        placeholder="Введите номер"
                        value={store.filters.objectNumber}
                        onChange={store.handleChange}
                    />
                </Box>

                <Box mb={{ xxs: 2, sm: 3, md: 4 }}>
                    <FormLabelCustom id="roomsNumber">Количество комнат</FormLabelCustom>
                    <Autocomplete
                        multiple
                        limitTags={1}
                        id="roomsNumber"
                        popupIcon={<ExpandMoreIcon />}
                        value={(store.filters.roomsNumber?.length && store.filters.roomsNumber) || []}
                        onChange={(e, value: any) => store.handleAutocompleteChange('roomsNumber', value)}
                        options={store.rooms}
                        getOptionLabel={(option: IFilterDevEnd) => option.title}
                        renderInput={params => <TextField {...params} name="roomsNumber" variant="outlined" placeholder="Выбрать" />}
                        noOptionsText="Нет подходящих вариантов"
                        className={classes.roomsNumberSelect}
                    />
                </Box>

                <Box mb={{ xxs: 2, sm: 3, md: 4 }}>
                    <FormLabelCustom id="price">Стоимость, млн ₽</FormLabelCustom>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <TextField
                                name="priceFrom"
                                variant="outlined"
                                placeholder="От"
                                value={store.filters.priceFrom}
                                onChange={store.handleChange}
                                InputProps={{
                                    inputComponent: MoneyInput,
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                name="priceTo"
                                variant="outlined"
                                placeholder="До"
                                value={store.filters.priceTo}
                                onChange={store.handleChange}
                                InputProps={{
                                    inputComponent: MoneyInput,
                                }}
                            />
                        </Grid>
                    </Grid>
                </Box>

                <Box mb={{ xxs: 2, sm: 3, md: 4 }}>
                    <FormLabelCustom id="totalAreaFrom">Площадь, м²</FormLabelCustom>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <TextField
                                name="totalAreaFrom"
                                variant="outlined"
                                placeholder="От"
                                value={store.filters.totalAreaFrom}
                                onChange={store.handleChange}
                                InputProps={{
                                    inputComponent: MoneyInput,
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                name="totalAreaTo"
                                variant="outlined"
                                placeholder="До"
                                value={store.filters.totalAreaTo}
                                onChange={store.handleChange}
                                InputProps={{
                                    inputComponent: MoneyInput,
                                }}
                            />
                        </Grid>
                    </Grid>
                </Box>

                <Box mb={{ xxs: 2, sm: 3, md: 4 }}>
                    <FormLabelCustom id="devEnd">Срок сдачи</FormLabelCustom>
                    <Autocomplete
                        multiple
                        limitTags={1}
                        id="devEnd"
                        popupIcon={<ExpandMoreIcon />}
                        value={(store.filters.devEnd?.length && store.filters.devEnd) || []}
                        onChange={(e, value: any) => store.handleAutocompleteChange('devEnd', value)}
                        options={store.devEnd}
                        getOptionLabel={(option: IFilterDevEnd) => option.title}
                        renderInput={params => <TextField {...params} name="devEnd" variant="outlined" placeholder="Выбрать" />}
                        noOptionsText="Нет подходящих вариантов"
                        className={classes.devEndSelect}
                    />
                </Box>

                <Box mb={{ xxs: 2, sm: 3, md: 4 }}>
                    <FormLabelCustom id="floor">Этаж</FormLabelCustom>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <TextField
                                name="floorNumberFrom"
                                variant="outlined"
                                placeholder="От"
                                value={store.filters.floorNumberFrom}
                                onChange={store.handleChange}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                name="floorNumberTo"
                                variant="outlined"
                                placeholder="До"
                                value={store.filters.floorNumberTo}
                                onChange={store.handleChange}
                            />
                        </Grid>
                    </Grid>
                </Box>

                <Box mb={{ xxs: 2, sm: 3, md: 4 }}>
                    <Box>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="exceptFirstFloor"
                                    checked={store.filters.exceptFirstFloor}
                                    onChange={store.handleChangeCheckboxes}
                                />
                            }
                            label="Кроме первого"
                        />
                    </Box>
                    <Box>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="exceptLastFloor"
                                    checked={store.filters.exceptLastFloor}
                                    onChange={store.handleChangeCheckboxes}
                                />
                            }
                            label="Кроме последнего"
                        />
                    </Box>
                </Box>

                <Box mb={{ xxs: 2, sm: 3, md: 4 }}>
                    <FormLabelCustom id="facing">Тип отделки</FormLabelCustom>
                    <Select
                        name="facing"
                        labelId="facing"
                        placeholder="Выбрать"
                        variant="outlined"
                        fullWidth
                        IconComponent={ExpandMoreIcon}
                        value={store.filters.facing}
                        onChange={store.handleChange}
                    >
                        <MenuItem value=" " disabled>
                            Выбрать
                        </MenuItem>
                        {store.facing.map((item: string) => (
                            <MenuItem key={item} value={item}>
                                {item}
                            </MenuItem>
                        ))}
                    </Select>
                </Box>

                <Box mb={{ xxs: 2, sm: 3, md: 4 }}>
                    <FormControlLabel
                        control={
                            <Checkbox name="isHideBooked" checked={store.filters.isHideBooked} onChange={store.handleChangeCheckboxes} />
                        }
                        label="Скрыть забронированные"
                    />
                </Box>

                <Box mt={2} mb={2} textAlign="center">
                    {isMD ? <TextButton onClick={handleResetFilters}>Сбросить</TextButton> : null}
                </Box>
            </Box>

            <Box className={classes.fixedBtn}>
                {store.apartmentsCount !== null ? (
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        size="large"
                        fullWidth
                        className={store.apartmentsCount === 0 ? classes.filterBtnDisabled : ''}
                    >
                        {store.apartmentsCountLoading ? (
                            <CircularProgress size={28} className={classes.filterBtnLoader} />
                        ) : store.apartmentsCount === 0 ? (
                            'Не найдено'
                        ) : (
                            `Показать ${store.apartmentsCount} ${declOfNum(store.apartmentsCount, ['квартиру', 'квартиры', 'квартир'])}`
                        )}
                    </Button>
                ) : null}
            </Box>
        </Box>
    );
});

export default ModalApartmentFilter;
