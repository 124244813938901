export const getShortPropType = (typeId: number) => {
    switch (typeId) {
        case 1: {
            return 'ГП';
        }
        case 2: {
            return 'К';
        }
        case 5: {
            return 'Кл';
        }
        case 6: {
            return 'Оф';
        }
        case 7: {
            return 'П';
        }
        case 8: {
            return 'Ск';
        }
        case 9: {
            return 'ТП';
        }
        case 10: {
            return 'Св';
        }
    }
};
