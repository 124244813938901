export const inputMaskHelper = (e: any) => {
  let text = e.target.value;

  if (text && text.length) {
    if (text.lastIndexOf('+7 8', 0) === 0) {
      e.target.value = text.replace('+7 8', '+7 ');
    }
  }

  return e;
}
