import React from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import { Button, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

import { BookingType, IApartmentBookProps } from '../../types/ApartmentTypes';
import { useModal } from '../../hooks/useModal';
import BookingTypeModal from './BookingTypeModal';

const BookApartment = observer(({ data, className, ...props }: IApartmentBookProps) => {
    const classes = useStyles();
    const history = useHistory();
    const { isOpen, openModal, closeModal } = useModal();

    // Hanlders
    const handleNavigateToForm = (type: BookingType) => {
        if (data) {
            const {
                object_id,
                complex,
                rooms_number,
                object_number,
                total_area,
                floor_number,
                house_number,
                images,
                max_floor,
                development_end,
                priceFormat,
                can_paid_booking,
            } = data;

            closeModal();
            history.push({
                pathname: '/applications-and-deals/create',
                state: {
                    object_id,
                    complex,
                    object_number,
                    floor_number,
                    house_number,
                    total_area,
                    rooms_number,
                    image: images[0],
                    development_end,
                    max_floor,
                    priceFormat,
                    bookingType: type,
                    can_paid_booking,
                },
            });
        }
    };

    return (
        <>
            <Button
                color="primary"
                variant="contained"
                size="large"
                className={clsx(classes.bookBtn, className)}
                onClick={openModal}
                {...props}
            >
                Забронировать
            </Button>

            <BookingTypeModal open={isOpen} onClose={closeModal} onSelect={handleNavigateToForm} />
        </>
    );
});

const useStyles = makeStyles(theme => ({
    bookBtn: {
        minWidth: 288,
        [theme.breakpoints.down('sm')]: {
            minWidth: 220,
        },
    },
}));

export default BookApartment;
