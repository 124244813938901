import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Checkbox, TextField, useMediaQuery, useTheme } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import { IApartmentFilterComplex } from '../../../types/ApartmentTypes';
import { useStyles } from '../style';

const ApartmentsFilterComplex = observer(
    ({ value, onChange, options, getOptionLabel, loading, multiple = false }: IApartmentFilterComplex) => {
        const theme = useTheme();
        const isXS = useMediaQuery(theme.breakpoints.down('xs'));
        const classes = useStyles();

        return (
            <Box width={{ xxs: 256, xs: 145, sm: 256 }} mr={{ xxs: 1, sm: 2 }} mb={{ xxs: 1, sm: 2 }}>
                <Autocomplete
                    classes={{ popper: classes.autoWidthPopper }}
                    multiple={multiple}
                    limitTags={1}
                    popupIcon={<ExpandMoreIcon />}
                    value={value}
                    onChange={onChange}
                    renderOption={(option, { selected }) => {
                        if (multiple) {
                            return (
                                <>
                                    <Checkbox
                                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {option.name}
                                </>
                            );
                        }
                        return getOptionLabel(option);
                    }}
                    disableCloseOnSelect={multiple}
                    options={options}
                    getOptionLabel={getOptionLabel}
                    renderInput={params => (
                        <TextField {...params} name="complex" variant="outlined" placeholder={isXS ? 'ЖК' : 'Название ЖК'} />
                    )}
                    noOptionsText="Нет подходящих вариантов"
                    loading={loading}
                    loadingText="Загрузка"
                />
            </Box>
        );
    },
);

export default ApartmentsFilterComplex;
