import axios from 'axios';

class FeedbackAPI {
    getConsultants = () => {
        return axios.get(`/feedback/consultants`);
    };
}

const feedbackAPI = new FeedbackAPI();
export default feedbackAPI;
