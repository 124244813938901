import React from 'react';
import { Box, TextField, useMediaQuery, useTheme } from '@material-ui/core';
import { observer } from 'mobx-react-lite';

import { useStores } from '../../../hooks/useStores';
import { useStyles } from '../style';
import { IFilterTypes } from '../../../types/CommonTypes';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const ApartmentsFilterObjectType = observer(({ onChange }: { onChange: any }) => {
    const { ApartmentStore: store } = useStores();
    const theme = useTheme();
    const isXS = useMediaQuery(theme.breakpoints.down('xs'));
    const classes = useStyles();

    const getValue = (): IFilterTypes => {
        const selectedValue = store.filters.types[0] || 0;
        const filterValue = store.types.find((item: IFilterTypes) => {
            return item.type_id === Number(selectedValue);
        });

        return filterValue ? filterValue : { type_id: 0, type_name: '' };
    }

    return (
        <Box width={{ xxs: 256, xs: 145, sm: 256 }} mr={{ xxs: 1, sm: 2 }} mb={{ xxs: 1, sm: 2 }}>
            <Autocomplete
                disablePortal
                classes={{ popper: classes.autoWidthPoper }}
                popupIcon={<ExpandMoreIcon />}
                value={getValue()}
                onChange={onChange}
                options={store.types}
                getOptionLabel={(option: IFilterTypes) => option.type_name}
                renderOption={(option, { selected }) => option.type_name}
                renderInput={params => (
                    <TextField {...params} name="objectType" variant="outlined" placeholder={isXS ? 'Объект' : 'Тип объекта'} />
                )}
                noOptionsText="Нет подходящих вариантов"
                loading={store.filterLoading}
                loadingText="Загрузка"
            />
        </Box>
    );
});

export default ApartmentsFilterObjectType;
