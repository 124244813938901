import React from 'react';
import { Box, MenuItem, Select, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { useStores } from '../../../hooks/useStores';

const ApartmentsFilterFacing = observer(({ onChange }: { onChange: any }) => {
    const { ApartmentStore: store } = useStores();

    return (
        <Box mb={{ xxs: 2, sm: 3, md: 4 }}>
            <Typography variant="subtitle2">Тип отделки</Typography>
            <Select
                name="facing"
                labelId="facing"
                placeholder="Выбрать"
                variant="outlined"
                fullWidth
                IconComponent={ExpandMoreIcon}
                value={store.filters.facing}
                onChange={onChange}
                disabled={store.filterLoading}
            >
                <MenuItem value=" " disabled>
                    Выбрать
                </MenuItem>
                {store.facing.map((item: string) => (
                    <MenuItem key={item} value={item}>
                        {item}
                    </MenuItem>
                ))}
            </Select>
        </Box>
    );
});

export default ApartmentsFilterFacing;
