import React, { useState } from 'react';
import { Box, Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';

import { useStores } from '../../hooks/useStores';
import MuiModalRangePicker from '../UI/MuiModalRangePicker';

const SelectDocumentPeriod = observer(() => {
    const { DocumentsStore: store } = useStores();
    const classes = useStyle();
    const [datePeriod, setDatePeriod] = useState();

    //Handlers
    const handleDownloadDocument = () => {
        if (datePeriod) {
            store.downloadDocument();
        }
    };

    const handleChangeDatePeriod = (period: any) => {
        setDatePeriod(period);
    };

    return (
        <Paper className={classes.customPaper}>
            <Grid container>
                <Grid item xs={12} md={6}>
                    <Box>
                        <Box mb={2}>
                            <Typography variant="h4">Сформировать отчёт</Typography>
                        </Box>

                        <Box width={256}>
                            <Typography variant="subtitle2">Период</Typography>
                            <MuiModalRangePicker
                                date={datePeriod || [null, null]}
                                onChange={handleChangeDatePeriod}
                                modalTitle="Выбрать период"
                            />
                        </Box>
                    </Box>
                </Grid>

                <Grid item xs={12} md={6} className={classes.customGrid}>
                    <Box className={classes.btnWrapper}>
                        <Box className={classes.downloadBtn} onClick={handleDownloadDocument}>
                            <Typography variant="subtitle1">Сформировать отчёт</Typography>

                            <Typography variant="caption" color="textSecondary">
                                Файл в формате Excel
                            </Typography>

                            <Typography className={classes.overflowText} variant="caption" color="textSecondary">
                                Акт-отчёт, акт выполненных работ, счёт фактура
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Paper>
    );
});

export default SelectDocumentPeriod;

const useStyle = makeStyles(theme => ({
    customPaper: {
        boxShadow: 'none',
        borderRadius: 8,
        padding: `${theme.spacing(2)}px `,
    },
    downloadBtn: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        padding: `${theme.spacing(2)}px `,
        textAlign: 'left',
        // @ts-ignore
        border: `1px solid ${theme.palette.primary.A50}`,
        background: '#fff',
        borderRadius: 8,
        boxShadow: '0px 43px 58px rgba(94, 1, 198, 0.04), 0px 15px 19px rgba(0, 0, 0, 0.03), 0px 7.36424px 9px rgba(0, 0, 0, 0.01)',
        color: theme.palette.primary.main,
        cursor: 'pointer',
        '&:hover': {
            border: `1px solid ${theme.palette.primary.main}`,
            background: '#fff',
        },
    },
    customGrid: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('md')]: {
            justifyContent: 'flex-start',
        },
    },
    btnWrapper: {
        width: '100%',
        maxWidth: 414,
        height: 96,
        margin: `${theme.spacing(1.75)}px ${theme.spacing(1.75)}px ${theme.spacing(1.75)}px ${theme.spacing(1.75)}px`,
        [theme.breakpoints.down('md')]: {
            marginLeft: 0,
        },
    },
    overflowText: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
}));
