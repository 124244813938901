import React from 'react';
import { Box, Grid, makeStyles, Paper, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { observer } from 'mobx-react-lite';

import DocumentItemForm from '../AppDealDocs/DocumentItemForm';
import { useStores } from '../../../hooks/useStores';

const AppDealPassportScanForm = observer(() => {
    const { AppDealFormStore } = useStores();
    const theme = useTheme();
    const classes = useStyles();
    const isXS = useMediaQuery(theme.breakpoints.down('xs'));
    const store = AppDealFormStore.participants[0].store;

    return (
        <Box mb={{ xxs: 2, md: 4 }}>
            <Paper className={classes.paper}>
                <Box p={2} pb={3}>
                    <Box mb={2}>
                        <Typography variant="subtitle1">Фото паспорта</Typography>
                    </Box>

                    <Grid container spacing={isXS ? 1 : 2}>
                        <Grid item xs={12} sm={5}>
                            <DocumentItemForm
                                objKey="passportScanMain"
                                defaultFilePath={store.appDeal?.client?.file_passport_main || ''}
                                defaultFileUuid={store.appDeal?.client?.passportScanMainUuid || ''}
                                store={store}
                            >
                                Основной разворот паспорта
                            </DocumentItemForm>
                        </Grid>
                        <Grid item xs={12} sm={5}>
                            <DocumentItemForm
                                objKey="passportScanOther"
                                defaultFilePath={store.appDeal?.client?.file_passport_registration || ''}
                                defaultFileUuid={store.appDeal?.client?.passportScanOtherUuid || ''}
                                store={store}
                            >
                                Страница с пропиской
                            </DocumentItemForm>
                        </Grid>
                    </Grid>
                </Box>
            </Paper>
        </Box>
    );
});

const useStyles = makeStyles(theme => ({
    paper: {
        borderRadius: 16,
        background: theme.palette.surface.main,
        boxShadow: 'none',
    },
}));

export default AppDealPassportScanForm;
