import { action, observable } from 'mobx';

import API from '../api';
import { IAppDealChatMessage } from '../types/AppDealTypes';

export class AppDealChatStore {
    @observable messages: IAppDealChatMessage[] = [];

    @action
    getMessages = (leadId: number) =>
        API.appDeals.getMessages(leadId).then(response => {
            this.messages = response?.data.data.messages;
        });

    @action
    markAsRead = (leadId: number) => {
        API.appDeals
            .markAsRead(
                leadId,
                this.messages.map(({ id }) => id),
            )
            .then(response => {
                this.messages = response?.data.data.messages;
            });
    };
}
