import React from 'react';
import { Box, Checkbox, FormControlLabel } from '@material-ui/core';
import { observer } from 'mobx-react-lite';

import { useStores } from '../../../hooks/useStores';

const ApartmentsFilterHideBooked = observer(({ onChange }: { onChange: any }) => {
    const { ApartmentStore: store } = useStores();

    return (
        <Box mb={{ xxs: 2, sm: 3, md: 4 }}>
            <FormControlLabel
                control={<Checkbox name="isHideBooked" checked={!!store.filters.isHideBooked} onChange={onChange} />}
                label="Скрыть забронированные"
            />
        </Box>
    );
});

export default ApartmentsFilterHideBooked;
