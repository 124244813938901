import axios from 'axios';

class ComplexAPI {
    callNotification: any = null;

    getComplexes = () => {
        return axios.get('/house/complexes');
    };

    getComplex = (id: number) => {
        return axios.get(`/house/complex/${id}`);
    };
}

const complexAPI = new ComplexAPI();
export default complexAPI;
