import React from 'react';
import { Typography, Breadcrumbs as BreadcrumbsUI } from '@material-ui/core';
import { Link } from 'react-router-dom';

const Breadcrumbs = ({ data }: { data: any }) => {
    return (
        <BreadcrumbsUI aria-label="breadcrumb">
            {data.map((item: any, i: number) =>
                i < data.length - 1 ? (
                    <Link key={item} to="/">
                        <Typography color="textSecondary">{item}</Typography>
                    </Link>
                ) : (
                    <Typography key={item} color="textSecondary">
                        {item}
                    </Typography>
                ),
            )}
        </BreadcrumbsUI>
    );
};

export default Breadcrumbs;
