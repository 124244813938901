import React, { useState } from 'react';
import { Box, IconButton, makeStyles, Paper } from '@material-ui/core';
import { observer } from 'mobx-react-lite';

import { IAppDealChatMessage, IAppDealManager } from '../../../types/AppDealTypes';
import { checkForUnread } from '../../../utils/checkForUnread';

import AppDealManagerContacts from './AppDealManagerContacts';
import AppDealManagerMessages from './AppDealManagerMessages';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import clsx from 'clsx';

const AppDealManager = observer(
    ({
        data,
        onClose,
        messages,
        markAsRead,
    }: {
        onClose?: any;
        data: IAppDealManager;
        messages: IAppDealChatMessage[];
        markAsRead: any;
    }) => {
        const [messagesShown, setMessagesShown] = useState(false);
        const classes = useStyles();

        const isUnread = checkForUnread(messages);

        const showMessages = () => {
            setMessagesShown(true);
            isUnread && markAsRead();
        };
        const hideMessages = () => setMessagesShown(false);

        return (
            <Paper elevation={3} variant={onClose ? 'elevation' : 'outlined'} className={clsx(!onClose && classes.paper)}>
                <Box p={2.5} position="relative">
                    {messagesShown ? (
                        <AppDealManagerMessages hideMessages={hideMessages} messages={messages} />
                    ) : (
                        <AppDealManagerContacts data={data} isUnread={isUnread} showMessages={showMessages} />
                    )}

                    {onClose && (
                        <Box position="absolute" right={12} top={12}>
                            <IconButton size="small" onClick={onClose}>
                                <CloseOutlinedIcon />
                            </IconButton>
                        </Box>
                    )}
                </Box>
            </Paper>
        );
    },
);

const useStyles = makeStyles(theme => ({
    paper: {
        //@ts-ignore
        borderColor: theme.palette.primary.A50,
    },
}));

export default AppDealManager;
