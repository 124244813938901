import React from 'react';
import { IComplexAdvantage } from '../../types/ComplexTypes';
import { Box, Grid, Typography } from '@material-ui/core';
import { transformNormalContentText } from '../../utils/transformNormalContentText';

const ComplexAdvantages = ({ data }: { data: IComplexAdvantage[] | null | undefined }): any => {
    const getDirection = (index: number): 'row' | 'row-reverse' => {
        if (index % 2 === 0) {
            return 'row-reverse';
        }

        return 'row';
    };
    if (!data) return null;

    return data?.map((item, i) => (
        <Box key={item.key} mt={{ xxs: 4, xs: 7, md: 12 }}>
            <Grid container spacing={4} direction={getDirection(i)}>
                <Grid item xs={12} md={6}>
                    {item.image && <img src={item.image} alt="" className="img_rounded" />}
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box mb={3}>
                        <Typography
                            variant="h3"
                            dangerouslySetInnerHTML={{
                                __html: transformNormalContentText(item.name),
                            }}
                        />
                    </Box>
                    <Box>
                        <Typography color="textSecondary">
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: transformNormalContentText(item.description),
                                }}
                            />
                        </Typography>

                        {/* <Typography color="textSecondary">{item.description}</Typography> */}
                    </Box>
                </Grid>
            </Grid>
        </Box>
    ));
};

export default ComplexAdvantages;
