import React from 'react';
import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Box, Typography, useMediaQuery, useTheme, makeStyles } from '@material-ui/core';

import { IAppDealType, IAppDealState1 } from '../../../types/AppDealTypes';
import { useStores } from '../../../hooks/useStores';
import historyNotFound from '../../../img/history_not_found.svg';
import historyEmpty from '../../../img/history_empty.svg';

import { TabPanel } from '../../UI/TabPanel';
import AppDealItem from '../AppDealItem/AppDealItem';
import AppDealHistoryItem from '../AppDealHistory/AppDealHistoryItem';
import Loader from '../../Loader/Loader';

const AppsDealsTabsContent = observer(({ tabPanelId, cardSizeIsDense }: { tabPanelId: IAppDealType; cardSizeIsDense: boolean }) => {
    const { AppsDealsStore: store } = useStores();

    const location = useLocation();
    const classes = useStyles();
    const theme = useTheme();
    const isMD = useMediaQuery(theme.breakpoints.down('md'));
    const isXS = useMediaQuery(theme.breakpoints.down('xs'));
    const isHistoryTabActive = tabPanelId === 'history';

    const fetchMoreAppsDeals = () => {
        store.setPaginationPage(store.paginationPage + 1);
    };

    const hasMore = () => {
        const dealsLength = isHistoryTabActive ? store.appsDealsHistory.length : store.appsDeals.length;
        return dealsLength > 0 && store.appsDealsPagination?.totalCount !== store.appsDealsPagination?.currentOffset;
    };

    const EmptyText = ({ children }: { children: React.ReactElement }) => {
        return (
            <Box
                position="relative"
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
                textAlign="center"
                width="100%"
                minHeight="31vh"
            >
                <Box mt={4}>{children}</Box>
            </Box>
        );
    };

    const renderEmptyHistoryText = () => {
        return (
            <EmptyText>
                {location.search ? (
                    <>
                        <img src={historyNotFound} alt="заявки_не_найдены" />
                        <Box mt={3}>
                            <Typography variant="subtitle1" className={classes.notFoundColor}>
                                Заявки не найдены
                            </Typography>
                        </Box>
                    </>
                ) : (
                    <>
                        <Box display="flex" justifyContent="center">
                            <img src={historyEmpty} alt="заявок_нет" />
                        </Box>
                        <Box mt={3}>
                            <Typography variant="subtitle1" className={classes.notFoundColor}>
                                История заявок и сделок пуста
                            </Typography>
                        </Box>
                    </>
                )}
            </EmptyText>
        );
    };

    const renderEmptyAppsDealsText = () => {
        return (
            <EmptyText>
                <Typography variant={isXS ? 'body2' : 'body1'}>Объекты не найдены</Typography>
            </EmptyText>
        );
    };

    return (
        <TabPanel value={tabPanelId} id={tabPanelId} key={tabPanelId}>
            {store.loading && renderLoader()}
            {!store.loading && isHistoryTabActive && store.emptyAppsDealsHistory && renderEmptyHistoryText()}
            {!store.loading && !isHistoryTabActive && store.emptyAppsDeals && renderEmptyAppsDealsText()}
            <InfiniteScroll dataLength={store.appsDeals.length} next={fetchMoreAppsDeals} hasMore={hasMore()} loader={renderLoader()}>
                {tabPanelId !== 'history'
                    ? store.appsDeals.map((item: IAppDealState1) => (
                          <AppDealItem key={item.lead_id} data={item} cardSizeIsDense={isMD && cardSizeIsDense} tabId={tabPanelId} />
                      ))
                    : store.appsDealsHistory.map(historyItem => <AppDealHistoryItem historyItem={historyItem} key={historyItem.lead_id} />)}
            </InfiniteScroll>
        </TabPanel>
    );
});

const useStyles = makeStyles(theme => ({
    notFoundColor: {
        //@ts-ignore
        color: theme.palette.primary.A100,
    },
}));

const renderLoader = () => {
    return (
        <Box
            position="relative"
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            textAlign="center"
            width="100%"
            minHeight="31vh"
        >
            <Loader isAbsolute />
        </Box>
    );
};

export default AppsDealsTabsContent;
