import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Tabs } from '@material-ui/core';

const StyledTabs = withStyles({
    root: {
        position: 'relative',
        zIndex: 1,
        minHeight: 40,
        boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.12)',
        borderRadius: '8px',
        '@media (max-width: 991px)': {
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.12)',
        },
        '@media (max-width: 374px)': {
            minHeight: 32,
        },
    },
    indicator: {
        height: '100%',
        zIndex: -1,
        borderRadius: '10px',
    },
})((props: any) => <Tabs {...props} />);

export default StyledTabs;
