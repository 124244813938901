import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, makeStyles, Paper, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import Slider from 'react-slick';
import { scroller, Element } from 'react-scroll';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import { useStores } from '../../../hooks/useStores';

import EmptyImage from '../../UI/EmptyImage';
import BookApartment from '../../Apartment/BookApartment';

const ApartmentInfoMain = observer(({ showCardDetails }: any) => {
    const { ApartmentStore: { selectedApartment: item } = {} } = useStores();

    const theme = useTheme();
    const isSM = useMediaQuery(theme.breakpoints.down('sm'));
    const classes = useStyles();

    const scrollDown = () => {
        showCardDetails();
        scroller.scrollTo('cardDown', {
            duration: 800,
            smooth: 'easeOutQuad',
            containerId: 'details',
        });
    };

    if (!item) {
        return (
            <Box p={2} display="flex" flexDirection="column">
                <Box px={{ xxs: 2, md: 4 }} py={{ xxs: 3, md: 5 }} mb={1}>
                    <EmptyImage variant="apartment" tile />
                    <Box mb={13} mt={8}>
                        <Typography variant="h4" align="center" className={classes.emptyText}>
                            Выберите квартиру из шахматки для просмотра
                        </Typography>
                    </Box>
                </Box>
            </Box>
        );
    }

    return (
        <Box p={2} display="flex" flexDirection="column" minHeight={550}>
            <Element id="cardTop" name="cardTop" />
            <Box px={{ xxs: 2, md: 4 }} py={{ xxs: 3, md: 5 }} mb={1} className="slick-card" height={248}>
                {item?.images.length ? (
                    <Slider {...sliderSettings} className={classes.slider}>
                        {item.images.map((imgPath: string, index: number) => (
                            <Box key={index} className={classes.sliderItem}>
                                <img src={imgPath} alt="" className={classes.sliderImg} />
                            </Box>
                        ))}
                    </Slider>
                ) : (
                    <EmptyImage variant="apartment" tile />
                )}
            </Box>

            <Box mb={7}>
                <Typography variant="body2" color="textSecondary" className={classes.withMargin}>
                    {item?.complex}
                </Typography>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Typography variant="h3" className={classes.withMargin}>
                        {item?.priceFormat}
                    </Typography>
                </Box>
                <Box className={clsx(classes.address, classes.withMargin)}>
                    <LocationOnOutlinedIcon className={clsx(classes.pin, classes.mediumBlackColor)} fontSize="small" />
                    <Typography variant="body2" color="textSecondary">
                        {item?.address}
                    </Typography>
                </Box>

                <Typography variant={isSM ? 'body2' : 'body1'} className={classes.withMargin}>
                    <Typography variant="subtitle1" component="span">
                        {item.type_id === '2' ? `${item?.rooms_number} комн.` : item.type_name + ' '}
                    </Typography>
                    • {item?.total_area} м² • Этаж {item?.floor_number}/{item?.max_floor}
                </Typography>
            </Box>
            <Paper elevation={item.is_booked ? 24 : 3}>
                <BookApartment data={item} className={classes.bookBtn} disabled={item.is_booked} />
            </Paper>
            <Box mt={2.5} alignSelf="center" display="flex" className={classes.pointer} onClick={scrollDown}>
                <Typography variant="button" color="primary">
                    Подробнее
                </Typography>
                <Box ml={1}>
                    <ExpandMoreIcon color="primary" />
                </Box>
            </Box>
        </Box>
    );
});

export default ApartmentInfoMain;

const ArrowButton = (props: any) => {
    const { className, type, style, onClick } = props;
    const classes = useStyles();

    return (
        <div
            className={clsx(className, classes.slickArrow, type === 'next' ? classes.slickArrowNext : classes.slickArrowPrev)}
            style={{ ...style }}
            onClick={onClick}
        >
            {type === 'next' ? <ChevronRightIcon fontSize="large" /> : <ChevronLeftIcon fontSize="large" />}
        </div>
    );
};

const sliderSettings = {
    infinite: true,
    speed: 500,
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <ArrowButton />,
    nextArrow: <ArrowButton type="next" />,
};

const useStyles = makeStyles(theme => ({
    img: {
        transition: 'transform ease 0.3s',
    },
    address: {
        display: 'flex',
        alignItems: 'center',
    },
    pin: {
        marginRight: 5,
    },
    withMargin: {
        marginBottom: 6,
    },
    mediumBlackColor: {
        color: 'rgba(0, 0, 0, 0.6)',
    },
    emptyText: {
        //@ts-ignore
        color: theme.palette.primary.A100,
    },
    slider: {
        marginBottom: 0,
    },
    sliderItem: {
        display: 'flex !important',
        alignItems: 'center',
        justifyContent: 'center',
    },
    sliderImg: {
        maxHeight: 168,
    },
    slickArrow: {
        width: '48px',
        height: '48px',
        zIndex: 1,
        '&::before': {
            content: 'none',
        },
        //@ts-ignore
        color: theme.palette.material.blackMedium,
        '&:hover': {
            color: theme.palette.primary.main,
        },
    },
    slickArrowPrev: {},
    slickArrowNext: { right: -37 },
    bookBtn: {
        width: '100%',
        alignSelf: 'center',
    },
    pointer: {
        cursor: 'pointer',
    },
}));
