import { action, observable } from 'mobx';
import { IMyClientsCard, IMyClientsForm } from '../types/MyClientsTypes';
import { inputMaskHelper } from '../utils/inputMaskHelper';
import MyClientsAPI from '../api/myClientsAPI';
import { IPagination } from '../types/CommonTypes';
import { cleanupMask } from '../utils/cleanupMask';

const initialClientsStoreState: IMyClientsForm = {
    surname: '',
    name: '',
    patronymic: '',
    phone: '',
};

export class MyClientsStore {
    @observable clientForm: IMyClientsForm = initialClientsStoreState;
    @observable clientFormErrors: any = {};

    @observable messageModal: string = '';
    @observable isOpenMessageModal: boolean = false;
    @observable isErrorMessageModal: boolean = false;
    @observable loading: boolean = false;

    @observable clients: IMyClientsCard[] = [];
    @observable clientsPagination: IPagination | null = null;
    @observable paginationPage = 0;

    @action
    getClients = (offset: number, limit: number) => {
        this.loading = true;

        MyClientsAPI.getClients(offset * limit, limit)
            .then(response => {
                if (offset === 0) {
                    this.clients = [...response?.data?.data?.items];
                } else {
                    this.clients = [...this.clients, ...response?.data?.data?.items];
                }

                this.clientsPagination = response?.data?.data?.pagination;
            })
            .finally(() => (this.loading = false));
    };

    @action
    handleChangeForm = (event: any) => {
        event = inputMaskHelper(event);
        this.clientForm[event.target.name as keyof IMyClientsForm] = event.target.value;
        this.clientFormErrors[event.target.name as keyof IMyClientsForm] = false;
    };

    @action
    addClient = () => {
        this.loading = true;

        this.clientForm.phone = cleanupMask(this.clientForm.phone);

        MyClientsAPI.addClient(this.clientForm)
            .then(response => {
                this.messageModal = response.data.message;
                this.isOpenMessageModal = true;
                this.isErrorMessageModal = false;
                this.clientForm = initialClientsStoreState;
                this.clientFormErrors = {};
                this.clients = [];

                //заново загружаем клиентов
                this.paginationPage = 0;
                this.getClients(this.paginationPage, this.clientsPagination?.limit || 10);
            })
            .catch(error => {
                if ((error.response?.data?.message as string)?.slice(0, 27) === 'Данный клиент уже обратился') {
                    this.isErrorMessageModal = true;
                    this.isOpenMessageModal = true;
                } else {
                    this.clientFormErrors = error.response?.data.errors;
                }
            })
            .finally(() => (this.loading = false));
    };

    @action
    setIsOpenMessageModal = (value: boolean) => {
        this.isOpenMessageModal = value;
    };

    @action
    setPaginationPage = (page: number) => (this.paginationPage = page);
}
