import { TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const TextFieldWithoutHelper = withStyles({
    root: {
        '& input': {
            padding: '14.5px 10px',
        },
    },
})(TextField);

export default TextFieldWithoutHelper;
