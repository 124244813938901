import { Typography } from '@material-ui/core';
import React from 'react';

interface IEmailLinkProps {
    email: string;
}

const EmailLink: React.FC<IEmailLinkProps> = ({ email }) => {
    return (
        <a href={`mailto:${email}`}>
            <Typography variant="body2" noWrap>
                {email}
            </Typography>
        </a>
    );
};

export default EmailLink;
