import React, { useEffect } from 'react';
import { Paper, Box, Grid } from '@material-ui/core';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../hooks/useStores';

const ResetPassword = observer(() => {
    const { AuthStore: store } = useStores();

    useEffect(() => {
        store.resetStore();

        return () => {
            store.resetStore();
        };
    }, [store]);

    const renderContent = () => {
        switch (store.resetPassStep) {
            case 1:
                return <Step1 />;
            case 2:
                return <Step2 />;
            case 3:
                return <Step3 />;
            default:
                return <Step1 />;
        }
    };

    return (
        <div className="auth-page__content">
            <Box p={{ xxs: 2, sm: 4 }} width="100%">
                <Grid container justify="center">
                    <Paper elevation={12} className="auth-block">
                        <Box p={{ xxs: 2, sm: 4 }}>{renderContent()}</Box>
                    </Paper>
                </Grid>
            </Box>
        </div>
    );
});

export default ResetPassword;
