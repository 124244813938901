import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import { Box, Paper, Typography, IconButton, Grid, makeStyles } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import AttachmentIcon from '@material-ui/icons/Attachment';
import ClearIcon from '@material-ui/icons/Clear';

import { IDocumentItem, IAppDealDocs, IAppDealPaymentDocs } from '../../../types/AppDealTypes';
import { useStyles } from '../style';
import { usePreview } from '../../../hooks/usePreview';

import DocumentPreviewModal from './DocumentPreviewModal';

const DocumentItemForm = observer(({ children, objKey, defaultFilePath, defaultFileUuid, store }: IDocumentItem) => {
    const classes = useStyles();
    const iconButtonClasses = iconButtonStyles();

    const {
        sizeError,
        setSizeError,
        pdfPreview,
        setPdfPreview,
        imgPreview,
        setImgPreview,
        showPreview,
        openPreview,
        closePreview,
    } = usePreview();

    const file = store.values?.files[(objKey as keyof IAppDealDocs) || (objKey as keyof IAppDealPaymentDocs)]?.file || '';

    const handleChange = (e: any) => {
        setSizeError(false);
        const file = e.target.files;

        if (!isSizeValid(file[0])) return;

        store.setFile(objKey, file[0]);

        store.checkIfFilled();

        handlePdfPreview(file[0]);
        handleImagePreview(file[0], e);
    };

    const isSizeValid = (file: File): boolean => {
        const fileSizeLimit = objKey === 'marriageScan' || objKey === 'notarialScan' ? 200 * 1024 * 1024 : 10 * 1024 * 1024;
        if (file.size > fileSizeLimit) {
            setSizeError(true);
            return false;
        }
        return true;
    };

    const handlePdfPreview = (file: File) => {
        const isPdf = file?.type.includes('pdf');
        return isPdf && setPdfPreview(file);
    };

    const handleImagePreview = (file: File, e: any) => {
        const isImage = file?.type.includes('image');

        if (isImage) {
            const reader = new FileReader();

            reader.onload = (e: any) => {
                setImgPreview(e.target?.result);
            };

            reader.readAsDataURL(e.target.files[0]);
        }
    };

    const handleClear = () => {
        setPdfPreview(null);
        setImgPreview('');
        store.setFile(objKey, null);
        store.setUuid(objKey, '');
        store.checkIfFilled();
    };

    useEffect(() => {
        if (defaultFilePath && defaultFileUuid) {
            store.setFile(objKey, defaultFilePath);
            store.setUuid(objKey, defaultFileUuid);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.appDeal]);

    return (
        <Box position="relative">
            <DocumentPreviewModal imgPreview={imgPreview} pdfPreview={pdfPreview} showPreview={showPreview} closePreview={closePreview} />

            <Paper className={clsx(classes.docItem, { [classes.errorBorderStyle]: store.formErrors?.[objKey] })} elevation={1}>
                <Box p={1}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs>
                            <Box display="flex" justifyContent="space-between" alignItems="center" position="relative">
                                <Typography
                                    variant="body2"
                                    color={store.formErrors?.[`${objKey}Uuid`] ? 'error' : 'initial'}
                                    className={clsx({ [classes.textUnderline]: file })}
                                >
                                    <Box component="span" onClick={openPreview}>
                                        {children}
                                    </Box>
                                </Typography>
                                {file && <CheckCircleIcon color="primary" className={classes.circleIcon} />}
                                {file ? (
                                    <IconButton
                                        classes={{ root: iconButtonClasses.root }}
                                        onClick={handleClear}
                                        title="Удалить файл"
                                        disabled={store.fileUploading}
                                        color="primary"
                                    >
                                        <ClearIcon />
                                    </IconButton>
                                ) : (
                                    <IconButton
                                        classes={{ root: iconButtonClasses.root }}
                                        component="label"
                                        title="Прикрепить файл"
                                        disabled={store.fileUploading}
                                        color="primary"
                                    >
                                        <input
                                            type="file"
                                            accept="image/x-png, image/jpeg, application/pdf"
                                            onChange={handleChange}
                                            hidden
                                        />
                                        <AttachmentIcon />
                                    </IconButton>
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Paper>

            {sizeError && (
                <Box position="absolute" bottom={-24}>
                    <Typography color="error" variant="caption">
                        Размер файла не должен превышать 10МБ
                    </Typography>
                </Box>
            )}
            {store.formErrors?.[objKey] && (
                <Box position="absolute" bottom={-24}>
                    <Typography color="error" variant="caption">
                        Документ не загружен
                    </Typography>
                </Box>
            )}
        </Box>
    );
});

export default DocumentItemForm;

const iconButtonStyles = makeStyles({
    root: {
        padding: 4,
    },
});
