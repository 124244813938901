import React, { useState } from 'react';
import { Box, Typography, TextField, Button } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../hooks/useStores';

const Step2 = observer(() => {
    const { AuthStore: store } = useStores();

    const [code, setCode] = useState('');

    const handlePress = (e: any) => {
        e.preventDefault();

        store.resetPassCheck({ token: store.resetPassData.token || '', code: code });
    };

    return (
        <>
            <Box mb={{ xxs: 3, xs: 8 }} textAlign="center">
                <Typography variant="h3" component="h1">
                    Восстановление пароля
                </Typography>
            </Box>
            <Box mb={{ xxs: 3, xs: 5 }}>
                <Typography>Вам отправлен код подтверждения на номер</Typography>
                <Typography>{store.resetPassData?.phone}</Typography>
            </Box>
            <form noValidate>
                <Box mb={{ xxs: 3, xs: 5 }}>
                    <TextField
                        variant="outlined"
                        margin="none"
                        required
                        fullWidth
                        id="code"
                        name="code"
                        placeholder="Код из смс"
                        autoFocus
                        onChange={e => setCode(e.target.value)}
                        value={code}
                        error={!!store.resetPassErrors?.code}
                        helperText={store.resetPassErrors?.code}
                        disabled={store.loading}
                        InputProps={{ notched: false }}
                    />
                </Box>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={handlePress}
                    disabled={store.loading}
                >
                    Далее
                </Button>
            </form>
        </>
    );
});

export default Step2;
