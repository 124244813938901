import React, { MouseEvent, useState, useMemo } from 'react';
import { alpha, Box, Dialog, IconButton, makeStyles, Typography } from '@material-ui/core';
import Slider from 'react-slick';

import { FilledDocumentsIcon, PlayIcon } from 'components/UI/Icons/SvgIcons';
import { ILearningProgram } from 'types/LearningTypes';
import { getYoutubeVideoID } from 'utils/youtube';
import YouTube from 'react-youtube';
import { useAllMQ } from 'hooks/useAllMQ';

interface ILearningProgramProps {
    program: ILearningProgram;
}

const sliderSettings = {
    infinite: false,
    draggable: false,
    speed: 500,
    dots: true,
};

const LearningProgram = (props: ILearningProgramProps) => {
    const {
        program: { name, description, files, links },
    } = props;
    const [currentVideo, setCurrentVideo] = useState(0);
    const [open, setOpen] = useState(false);
    const { isSM, isXS } = useAllMQ();

    const videoIDs = useMemo(() => links.map(link => getYoutubeVideoID(link)), [links]);
    const currentVideoId = videoIDs[currentVideo];

    const videoWidth = useMemo(() => {
        if (isXS) {
            return 320;
        }
        if (isSM) {
            return 480;
        }
        return 640;
    }, [isSM, isXS]);

    const videoHeight = useMemo(() => {
        if (isXS) {
            return 240;
        }
        if (isSM) {
            return 320;
        }
        return 480;
    }, [isSM, isXS]);

    const classes = useStyles();

    // Handlers
    const handlePlayClick = () => {
        handleToggleModal();
    };

    const handleSlideClick = (i: number) => (e: MouseEvent) => {
        e.stopPropagation();
        setCurrentVideo(i);
    };

    const handleToggleModal = () => {
        setOpen(o => !o);
    };

    // Renders
    return (
        <Box className={classes.program}>
            <Box className={classes.programVideo}>
                <img src={`https://img.youtube.com/vi/${currentVideoId}/maxresdefault.jpg`} alt="" />
                <IconButton className={classes.playButton} onClick={handlePlayClick}>
                    <PlayIcon />
                </IconButton>
            </Box>
            <Box className={classes.programInfo}>
                <Box>
                    <Slider {...{ ...sliderSettings, slidesToShow: 3, slidesToScroll: 3 }} className={classes.slider}>
                        {videoIDs.map((id, i) => (
                            <Box key={id} className={classes.slide} onClickCapture={handleSlideClick(i)}>
                                <img src={`https://img.youtube.com/vi/${id}/maxresdefault.jpg`} alt={id} />
                            </Box>
                        ))}
                    </Slider>
                </Box>
                <Box mt={4} className={classes.programText}>
                    <Typography variant="subtitle1" className={classes.programTitle}>
                        {name}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" className={classes.programDescription} title={description}>
                        {description}
                    </Typography>
                </Box>
                <Box mt={2}>
                    <Slider {...{ ...sliderSettings, slidesToShow: 2, slidesToScroll: 2 }} className={classes.slider}>
                        {files.map(file => (
                            <a
                                key={file.uuid}
                                className={classes.programFile}
                                href={file.fullUrl}
                                title={file.fileName}
                                download
                                target={file.fullUrl.includes('.pdf') ? '_blank' : undefined}
                                rel="noopener noreferrer"
                            >
                                <FilledDocumentsIcon />
                                <Typography variant="caption" component="p">
                                    {file.fileName}
                                </Typography>
                            </a>
                        ))}
                    </Slider>
                </Box>
            </Box>
            <Dialog fullScreen open={open} onClose={handleToggleModal} PaperProps={{ className: classes.modal, elevation: 24 }}>
                <YouTube videoId={currentVideoId} opts={{ width: videoWidth, height: videoHeight }} />
            </Dialog>
        </Box>
    );
};

const useStyles = makeStyles(theme => ({
    modal: {
        maxWidth: theme.spacing(80),
        maxHeight: theme.spacing(60),
        overflow: 'hidden',
        [theme.breakpoints.down('sm')]: {
            maxWidth: theme.spacing(60),
            maxHeight: theme.spacing(40),
        },
        [theme.breakpoints.down('xs')]: {
            maxWidth: theme.spacing(40),
            maxHeight: theme.spacing(30),
        },
    },
    program: {
        flexBasis: '100%',
        [theme.breakpoints.up('sm')]: {
            flexBasis: `calc(50% - ${theme.spacing(2)}px)`,
        },
        [theme.breakpoints.up('xl')]: {
            flexBasis: `calc(33% - ${theme.spacing(2)}px)`,
        },
        backgroundColor: theme.palette.background.paper,
        borderRadius: theme.shape.borderRadius * 2,
        overflow: 'hidden',
    },
    programVideo: {
        position: 'relative',
    },
    playButton: {
        width: 40,
        height: 40,
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 1,
        '&:hover': {
            backgroundColor: theme.palette.primary.dark,
        },
    },
    programInfo: {
        padding: theme.spacing(2, 2, 4.5, 2),
    },
    programText: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1),
        '& > p': {
            display: 'box',
            boxOrient: 'vertical',
            whiteSpace: 'pre-wrap',
            overflow: 'hidden',
            lineClamp: 3,
        },
    },
    programTitle: {},
    programDescription: {},
    programFile: {
        userSelect: 'none',
        cursor: 'pointer',
        gap: theme.spacing(1),
        flexDirection: 'column',
        padding: theme.spacing(0.5, 0.75),
        borderRadius: theme.shape.borderRadius * 0.75,
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.primary.main,
        transition: theme.transitions.create(['background-color'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        '& > p': {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        '&:hover': {
            backgroundColor: alpha(theme.palette.primary.dark, 0.1),
        },
    },

    slider: {
        marginBottom: 0,
        '& .slick-slide > div': {
            marginRight: theme.spacing(1),
        },
        '& .slick-dots': {
            bottom: theme.spacing(-3),
        },
    },
    slide: {
        userSelect: 'none',
        webkitUserDrag: 'none',
        borderRadius: theme.shape.borderRadius * 0.5,
        overflow: 'hidden',
        cursor: 'pointer',
    },
}));

export default LearningProgram;
