import React from 'react';
import { Box, Button, Dialog, DialogProps, IconButton, makeStyles, Typography } from '@material-ui/core';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import { Link } from 'react-router-dom';

interface IAppDealObjectIsBookedProps extends Omit<DialogProps, 'onClose' | 'children'> {
    onClose: () => void;
}

const AppDealObjectIsBooked: React.FC<IAppDealObjectIsBookedProps> = ({ onClose, ...props }) => {
    const classes = useStyles();

    return (
        <Dialog onClose={onClose} scroll="body" PaperProps={{ className: classes.modal, elevation: 24 }} {...props}>
            <Box p={{ xxs: 3, md: 4 }} display="flex" alignItems="center" textAlign="left" flexDirection="column">
                <Box mb={{ xxs: 3, md: 4 }} width="100%" display="flex" justifyContent="space-between">
                    <Typography variant="h3" align="left">
                        К сожалению, вы опоздали
                    </Typography>

                    <Box mt="-5px" mr="-5px">
                        <IconButton size="small" onClick={onClose}>
                            <CloseOutlinedIcon />
                        </IconButton>
                    </Box>
                </Box>

                <Box mb={{ xxs: 3, md: 4 }}>
                    <Typography>Данная квартира уже забронирована. </Typography>
                    <Typography>Переходите в каталог, чтобы подобрать другие варианты.</Typography>
                </Box>

                <Box width="100%" maxWidth={304}>
                    <Link to="/apartments">
                        <Button variant="contained" color="primary" size="large" fullWidth>
                            Подобрать квартиру
                        </Button>
                    </Link>
                </Box>
            </Box>
        </Dialog>
    );
};

const useStyles = makeStyles({
    modal: {
        borderRadius: 16,
        maxWidth: 536,
    },
});

export default AppDealObjectIsBooked;
