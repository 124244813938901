import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Paper, Grid, Typography, TextField, useTheme, useMediaQuery } from '@material-ui/core';

import { useStores } from '../../../hooks/useStores';
import { useStyles } from '../style';

import { PhoneFormat } from '../../UI/Form';
import { config } from "../../../Config";

const AppDealSimpleClientForm = observer(() => {
    const { AppDealFormStore: store } = useStores();
    const classes = useStyles();
    const theme = useTheme();
    const isSM = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Box mb={{ xxs: 2, md: 4 }}>
            <Paper className={classes.paper}>
                <Box p={2} pt={3}>
                    <Box mb={3}>
                        <Typography variant="h3">Участники</Typography>
                    </Box>
                    <Box className={classes.wrapper} flexDirection="column">
                        <Box mb={2} alignSelf="flex-start">
                            <Typography variant="h4">Участник</Typography>
                        </Box>
                        <form>
                            <Grid container spacing={isSM ? 1 : 2}>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <TextField
                                        fullWidth
                                        required
                                        variant="outlined"
                                        name="surname"
                                        label="Фамилия"
                                        value={store.values.client.surname}
                                        onChange={store.handleClientChange}
                                        disabled={store.loading}
                                        InputProps={{ notched: false }}
                                        error={!!store.formErrors?.surname}
                                        helperText={!!store.formErrors?.surname && 'Введите фамилию'}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <TextField
                                        fullWidth
                                        required
                                        variant="outlined"
                                        name="firstname"
                                        label="Имя"
                                        value={store.values.client.firstname}
                                        onChange={store.handleClientChange}
                                        disabled={store.loading}
                                        InputProps={{ notched: false }}
                                        error={!!store.formErrors?.firstname}
                                        helperText={!!store.formErrors?.firstname && 'Введите имя'}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <TextField
                                        fullWidth
                                        required
                                        variant="outlined"
                                        name="lastname"
                                        label="Отчество"
                                        value={store.values.client.lastname}
                                        onChange={store.handleClientChange}
                                        disabled={store.loading}
                                        InputProps={{ notched: false }}
                                        error={!!store.formErrors?.lastname}
                                        helperText={!!store.formErrors?.lastname && 'Введите отчество'}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <TextField
                                        fullWidth
                                        required
                                        variant="outlined"
                                        name="phone"
                                        label="Телефон"
                                        placeholder={config.phonePlaceholder}
                                        value={store.values.client.phone}
                                        onChange={store.handleClientChange}
                                        InputProps={{ inputComponent: PhoneFormat, notched: false }}
                                        disabled={store.loading}
                                        error={!!store.formErrors?.phone}
                                        helperText={!!store.formErrors?.phone && 'Введите телефон'}
                                    />
                                </Grid>
                            </Grid>
                        </form>
                    </Box>
                </Box>
            </Paper>
        </Box>
    );
});

export default AppDealSimpleClientForm;
