import React from 'react';

import { Typography, TextField, TextFieldProps, Slider, SliderProps, makeStyles } from '@material-ui/core';

type FieldType = {
    inputs: TextFieldProps[];
    slider: SliderProps;
    changeSlider: (e: any, value: number | number[]) => void;
};

export const Field = ({ inputs, slider, changeSlider }: FieldType) => {
    const classes = useStyle(inputs.length);
    return (
        <div className={classes.root}>
            {inputs.map(({ label, id, ...field }) => (
                <div key={id}>
                    <Typography variant="subtitle2" component="label" htmlFor={id}>
                        {label}
                    </Typography>
                    <TextField variant="outlined" name={id} id={id} {...field} />
                </div>
            ))}
            <Slider className={classes.slider} onChange={changeSlider} {...slider} />
        </div>
    );
};

const useStyle = (inputsLen: number) =>
    makeStyles(theme => ({
        root: {
            display: 'grid',
            gridTemplateColumns: inputsLen === 2 ? '8fr 4fr' : '1fr',
            gridGap: theme.spacing(1),
            marginBottom: theme.spacing(3),
            '& > div': {
                display: 'inherit',
                gridGap: 'inherit',
            },
        },
        slider: {
            gridColumn: '1 / -1',
        },
    }))();
