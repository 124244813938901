import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Container, Typography } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { useStores } from '../../hooks/useStores';
import Loader from '../../components/Loader/Loader';
import RequestError from '../../components/RequestError/RequestError';
import { transformNormalContentText } from '../../utils/transformNormalContentText';

const Stock = observer(() => {
    const { stockId } = useParams();
    const { StockStore: store } = useStores();

    useEffect(() => {
        store.getStock(stockId);
    }, [stockId, store]);

    const renderContent = () => {
        if (store.stock?.success) {
            const { title, expiredAt, photo, text } = store.stock.data;

            return (
                <>
                    <Box mb={{ xxs: 1, sm: 2 }}>
                        <Typography variant="h3">{title}</Typography>
                    </Box>
                    <Typography variant="body2" color="textSecondary">
                        Действует до {expiredAt}
                    </Typography>

                    <Box my={{ xxs: 3, md: 4 }}>
                        <img src={photo?.fullUrl} alt="" className="img img_rounded" />
                    </Box>

                    <Box my={3}>
                        <Typography component="div" dangerouslySetInnerHTML={{ __html: transformNormalContentText(text) }} />
                    </Box>
                </>
            );
        } else if (!store.stock?.success) {
            return <RequestError />;
        }

        return <div></div>;
    };

    if (store.loading) {
        return <Loader />;
    }

    return (
        <Box mt={{ xxs: 4, md: 6 }} mb={{ xxs: 7, md: 9 }} maxWidth={720} mx="auto">
            <Container maxWidth="xl">{renderContent()}</Container>
        </Box>
    );
});

export default Stock;
