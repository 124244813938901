import axios from 'axios';
import { IMyClientsForm } from '../types/MyClientsTypes';

class MyClientsAPI {
    addClient = (data: IMyClientsForm) => {
        return axios.post('/partner/create-client', data);
    };

    getClients = (offset: number, limit: number) => {
        return axios.get(`/partner/clients-fixation?limit=${limit}&offset=${offset}`);
    };
}

const myClientsAPI = new MyClientsAPI();
export default myClientsAPI;
