import React, { useEffect } from 'react';
import { Box, Container, makeStyles, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';

import { useStores } from '../../hooks/useStores';
import DocumentsForm from '../../components/Documents/DocumentsForm';
import SelectDocumentPeriod from '../../components/Documents/SelectDocumentPeriod';

const DocumentsScreen = observer(() => {
    const { DocumentsStore: store } = useStores();
    const classes = useStyle();

    // Effects
    useEffect(() => {
        // API request
        store.getDocument();
    }, [store]);

    return (
        <Box py={5} className={classes.root}>
            <Container maxWidth="xl">
                <Box>
                    <Box mb={8}>
                        <Typography variant="h2">Отчёты и документы</Typography>
                    </Box>

                    <Box mb={5}>
                        <SelectDocumentPeriod />
                    </Box>

                    <Box mb={5}>
                        <DocumentsForm />
                    </Box>
                </Box>
            </Container>
        </Box>
    );
});

export default DocumentsScreen;

const useStyle = makeStyles(theme => ({
    root: {
        width: '100%',
        background: theme.palette.surface.main,
    },
}));
