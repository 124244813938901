import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const ShareButtonIcon = (props: SvgIconProps) => (
    <SvgIcon {...props}>
        <path d="M8 0L4 4H7V13H9V4H12L8 0ZM14 22H2C1.46957 22 0.960859 21.7893 0.585786 21.4142C0.210714 21.0391 0 20.5304 0 20V8C0 7.46957 0.210714 6.96086 0.585786 6.58579C0.960859 6.21071 1.46957 6 2 6H5V8H2V20H14V8H11V6H14C14.5304 6 15.0391 6.21071 15.4142 6.58579C15.7893 6.96086 16 7.46957 16 8V20C16 20.5304 15.7893 21.0391 15.4142 21.4142C15.0391 21.7893 14.5304 22 14 22Z" />
    </SvgIcon>
);

export default ShareButtonIcon;
