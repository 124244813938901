import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Popover, makeStyles } from '@material-ui/core';

import { IAppDealChatMessage, IAppDealManager } from '../../../types/AppDealTypes';

import AppDealManager from './AppDealManager';

const AppDealManagerPopup = observer(
    ({
        anchorEl,
        onClose,
        data,
        messages,
        markAsRead,
    }: {
        anchorEl: any;
        onClose: any;
        data: IAppDealManager;
        messages: IAppDealChatMessage[];
        markAsRead: any;
    }) => {
        const classes = useStyles();
        return (
            <Popover
                id="manager-contact"
                anchorEl={anchorEl}
                keepMounted
                open={!!anchorEl}
                onClose={(e: any) => {
                    e.stopPropagation();
                    onClose();
                }}
                onClick={e => e.stopPropagation()}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                disableRestoreFocus
                classes={{ paper: classes.paper }}
            >
                <Box width={326}>
                    <AppDealManager data={data} onClose={onClose} messages={messages} markAsRead={markAsRead} />
                </Box>
            </Popover>
        );
    },
);

const useStyles = makeStyles(theme => ({
    //@ts-ignore
    paper: { ...theme.overrides.MuiPaper.elevation3 },
}));

export default AppDealManagerPopup;
