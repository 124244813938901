import { observer } from 'mobx-react-lite';
import React, { useRef } from 'react';
import { Box, Button, Grid, IconButton, makeStyles, Paper, TextField, Typography, useMediaQuery } from '@material-ui/core';
import FormLabelCustom from '../UI/FormLabelCustom';
import { useStores } from '../../hooks/useStores';
import { PhoneFormat } from '../UI/Form';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import { theme } from '../../styles/muiTheme';

const MyClientsForm = observer(({ onMobileClose }: { onMobileClose?: () => void }) => {
    const { MyClientsStore: store } = useStores();
    const isMD = useMediaQuery(theme.breakpoints.down('md'));
    const inputLogin = useRef<any>(null);

    const classes = useStyles();

    //Handlers
    const handleAdd = (e: any) => {
        e.preventDefault();
        store.addClient();
    };

    //Renders
    return (
        <Paper elevation={0} className={classes.formContainer}>
            <Box p={2}>
                <Box mb={4}>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Typography variant="h4">Добавление клиента</Typography>
                        {isMD && (
                            <IconButton size="small" onClick={onMobileClose}>
                                <CloseOutlinedIcon />
                            </IconButton>
                        )}
                    </Grid>
                </Box>
                <form noValidate>
                    <Box mb={4}>
                        <FormLabelCustom id="surname">Фамилия</FormLabelCustom>
                        <TextField
                            variant="outlined"
                            margin="none"
                            required
                            fullWidth
                            id="surname"
                            name="surname"
                            placeholder="Фамилия"
                            onChange={store.handleChangeForm}
                            value={store.clientForm.surname}
                            error={!!store.clientFormErrors?.surname}
                            helperText={store.clientFormErrors?.surname}
                            disabled={store.loading}
                            InputProps={{ notched: false }}
                        />
                    </Box>
                    <Box mb={4}>
                        <FormLabelCustom id="name">Имя</FormLabelCustom>
                        <TextField
                            variant="outlined"
                            margin="none"
                            required
                            fullWidth
                            id="name"
                            name="name"
                            placeholder="Имя"
                            onChange={store.handleChangeForm}
                            value={store.clientForm.name}
                            error={!!store.clientFormErrors?.name}
                            helperText={store.clientFormErrors?.name}
                            disabled={store.loading}
                            InputProps={{ notched: false }}
                        />
                    </Box>
                    <Box mb={4}>
                        <FormLabelCustom id="patronymic">Отчество</FormLabelCustom>
                        <TextField
                            variant="outlined"
                            margin="none"
                            required
                            fullWidth
                            id="patronymic"
                            name="patronymic"
                            placeholder="Отчество"
                            onChange={store.handleChangeForm}
                            value={store.clientForm.patronymic}
                            error={!!store.clientFormErrors?.patronymic}
                            helperText={store.clientFormErrors?.patronymic}
                            disabled={store.loading}
                            InputProps={{ notched: false }}
                        />
                    </Box>
                    <Box mb={4}>
                        <FormLabelCustom id="phone">Номер телефона</FormLabelCustom>
                        <TextField
                            variant="outlined"
                            margin="none"
                            required
                            fullWidth
                            name="phone"
                            placeholder={'Ввести'}
                            autoFocus
                            value={store.clientForm.phone}
                            InputProps={{
                                inputComponent: PhoneFormat,
                                notched: false,
                            }}
                            onChange={store.handleChangeForm}
                            inputRef={inputLogin}
                            onFocus={() => {
                                setTimeout(() => {
                                    inputLogin?.current?.setSelectionRange(3, 3);
                                }, 0);
                            }}
                            error={!!store.clientFormErrors?.phone}
                            helperText={store.clientFormErrors?.phone}
                            disabled={store.loading}
                        />
                    </Box>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        size="large"
                        onClick={handleAdd}
                        disabled={store.loading}
                    >
                        Зафиксировать
                    </Button>
                </form>
            </Box>
        </Paper>
    );
});

const useStyles = makeStyles(theme => ({
    formContainer: {
        [theme.breakpoints.down('md')]: {
            width: 462,
        },
        [theme.breakpoints.down('xs')]: {
            width: 320,
        },
    },
}));

export default MyClientsForm;
