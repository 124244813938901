import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';

import { useStores } from '../../../hooks/useStores';

import { MoneyInput } from '../../UI/Form';

import TextFieldWithoutHelper from '../../UI/TextFieldWithoutHelper';

const ApartmentsFilterPriceFromTo = observer(({ onChange }: { onChange: any }) => {
    const { ApartmentStore: store } = useStores();

    return (
        <Box mb={{ xxs: 2, sm: 3, md: 4 }}>
            <Typography variant="subtitle2">Стоимость, млн ₽</Typography>
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <TextFieldWithoutHelper
                        name="priceFrom"
                        variant="outlined"
                        placeholder="От"
                        value={store.filters.priceFrom}
                        onChange={onChange}
                        InputProps={{
                            inputComponent: MoneyInput,
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextFieldWithoutHelper
                        name="priceTo"
                        variant="outlined"
                        placeholder="До"
                        value={store.filters.priceTo}
                        onChange={onChange}
                        InputProps={{
                            inputComponent: MoneyInput,
                        }}
                    />
                </Grid>
            </Grid>
        </Box>
    );
});

export default ApartmentsFilterPriceFromTo;
