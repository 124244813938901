import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Button, makeStyles, Modal, Paper, Typography } from '@material-ui/core';

const AppDealModal = observer(({ modalMessage, closeModal }: { modalMessage: string; closeModal: any }) => {
    const classes = useStyles();

    return (
        <Modal open={!!modalMessage} className={classes.container}>
            <Paper elevation={3} className={classes.modal}>
                <Box p={2} display="flex" alignItems="center" textAlign="center" flexDirection="column">
                    <Typography variant="subtitle1">{modalMessage}</Typography>
                    <Box mt={2} onClick={closeModal}>
                        <Button color="primary" variant="contained">
                            Ок
                        </Button>
                    </Box>
                </Box>
            </Paper>
        </Modal>
    );
});

export default AppDealModal;

const useStyles = makeStyles({
    modal: {
        borderRadius: 8,
        flex: '0 1 320px',
    },
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
});
