import React from 'react';
import { makeStyles, Grid } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    leftContent: {
        flex: 0,
        marginRight: 5,
        whiteSpace: 'nowrap',
        color: theme.palette.text.secondary,
    },
    dottedLine: {
        flex: 1,
        transform: 'translateY(-5px)',
        borderBottom: `1px dashed ${theme.palette.text.secondary}`,
    },
    rightContent: {
        flex: 0,
        marginLeft: 5,
        whiteSpace: 'nowrap',
    },
}));

interface IProps {
    leftContent: string | number;
    rightContent: string | number | undefined | React.ReactElement;
    dottedLine?: boolean;
}

const DottedListItem = ({ leftContent, rightContent, dottedLine }: IProps) => {
    const classes = useStyles();

    return (
        <Grid container justify="space-between" alignItems="center">
            <Grid item xs className={classes.leftContent}>
                {leftContent}
            </Grid>
            {dottedLine && <Grid item xs className={classes.dottedLine} />}
            <Grid item xs className={classes.rightContent}>
                {rightContent}
            </Grid>
        </Grid>
    );
};

export default DottedListItem;
