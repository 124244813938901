import React from 'react';
import { saveAs } from 'file-saver';
import { observable, action, computed } from 'mobx';

import API from '../api';
import { stores } from '../contexts';
import {
    IApartmentItem,
    IApartmentData,
    IApartmentFilters,
    IApartmentTiles,
    IFilterDevEnd,
    IApartmentFloor,
    IApartmentTileItem,
    IApartmentComplexCoords,
} from '../types/ApartmentTypes';
import { IComplexField, IFilterRoomsNumber } from '../types/CommonTypes';
import { IPagination } from '../types/CommonTypes';

export class ApartmentStore {
    @observable loading = false;
    @observable filterLoading = false;
    @observable apartmentsCountLoading = false;

    // Catalog
    @observable apartments: IApartmentItem[] = [];
    @observable apartmentsTiles: IApartmentTiles[] = [];
    @observable normalizedFloors: IApartmentTileItem[][] = [];
    @observable apartmentsPagination: IPagination | null = null;
    @observable paginationPage = 0;
    @observable apartmentsCount: number | null = null;
    @observable coords: IApartmentComplexCoords[] = [];

    // Apartment single
    @observable apartment: IApartmentData | null = null;
    @observable selectedApartment: IApartmentTileItem | null = null;
    @observable activeTile: number[] | undefined[] = [undefined, undefined];

    // Filters
    @observable filtersIsReset = false;
    @observable complexes = [];
    @observable houseNumbers: string[] = [];
    @observable freeSoloHouseNumber: string = '';
    @observable rooms = [];
    @observable devEnd = [];
    @observable facing = [];
    @observable sorts = [];
    @observable types = [];
    @observable filters: IApartmentFilters = {
        complex: null,
        complexTiles: '',
        houseNumbers: null,
        houseNumberTiles: '',
        objectNumber: '',
        roomsNumber: null,
        priceFrom: '',
        priceTo: '',
        totalAreaFrom: '',
        totalAreaTo: '',
        devEnd: null,
        floorNumberFrom: '',
        floorNumberTo: '',
        exceptFirstFloor: false,
        exceptLastFloor: false,
        facing: ' ',
        isHideBooked: false,
        sort: 1,
        types: [],
    };
    @observable numberOfActiveFilters: null | number = null;

    //Tiles
    @observable gridState: boolean = true;
    @observable isOpenFilters: boolean = false;

    @action
    getAppliedFilters = () => {
        const complexes = this.filters.complex?.map((complex: IComplexField) => complex?.id).join(',') || this.filters.complex;
        const roomsNumber = this.filters.roomsNumber?.map((room: IFilterRoomsNumber) => room.rooms_number);
        const devEndDates = this.filters.devEnd?.map((devEnd: IFilterDevEnd) => `${devEnd.quarter}/${devEnd.year}`);

        const filters = {
            complex: this.filters.complex ? complexes : '',
            houseNumber: this.filters.houseNumbers || '',
            objectNumber: this.filters.objectNumber || '',
            roomsNumber: this.filters.roomsNumber ? roomsNumber.join(',') : '',
            types: this.filters.types ? this.filters.types.join(',') : '',
            priceFrom: parseFloat(this.filters.priceFrom as string) * 1000000 || '',
            priceTo: parseFloat(this.filters.priceTo as string) * 1000000 || '',
            totalAreaFrom: this.filters.totalAreaFrom || '',
            totalAreaTo: this.filters.totalAreaTo || '',
            floorNumberFrom: this.filters.floorNumberFrom || '',
            floorNumberTo: this.filters.floorNumberTo || '',
            facing: this.filters.facing !== ' ' ? this.filters.facing : '',
            isHideBooked: this.filters.isHideBooked || '',
            exceptFirstFloor: this.filters.exceptFirstFloor || '',
            exceptLastFloor: this.filters.exceptLastFloor || '',
            devEnd: this.filters.devEnd ? devEndDates.join(',') : '',
            sort: this.filters.sort || '',
        };

        return filters;
    };

    @action
    getApartments = (offset: number, limit: number, filters: any) => {
        this.loading = true;

        API.apartment
            .getApartments(offset * limit, limit, filters)
            .then(response => {
                if (response?.data.success) {
                    this.apartments = [...this.apartments, ...response.data.data?.items];
                    this.apartmentsPagination = response.data.data?.pagination;
                }
            })
            .finally(() => {
                this.loading = false;
            });
    };

    @action
    getCoords = () => {
        API.apartment.getCoords().then(response => {
            this.coords = response.data?.data;
        });
    };

    fillEmptyApartments = () => {
        this.apartmentsTiles?.forEach((entrance: { floors: IApartmentFloor[] }) => {
            let maxApartmentsLength = 0;
            entrance.floors.forEach(floor => {
                maxApartmentsLength = maxApartmentsLength > floor.apartments.length ? maxApartmentsLength : floor.apartments.length;
            });

            entrance.floors.forEach(({ apartments }) => {
                apartments.length = maxApartmentsLength;

                apartments.forEach(apartment => {
                    if (apartment) {
                        apartment.active = false;
                        apartment.isFiltered = false;
                    }
                });
            });
        });
    };

    loopThroughAllApartments = (sortingCallbacks: Function[]) => {
        this.normalizedFloors.forEach(floor => {
            floor.forEach(apartment => {
                if (apartment && typeof apartment === 'object') {
                    return (apartment.isFiltered = sortingCallbacks.some(callback => callback(apartment)));
                }
            });
        });
    };

    constructArrayCallbacks = () => {
        const callbacks = [];
        this.filters.roomsNumber?.length && callbacks.push(this.filterByRooms);
        this.filters.objectNumber && callbacks.push(this.filterByObjectNumber);
        this.filters.priceFrom && callbacks.push(this.filterByPriceFrom);
        this.filters.priceTo && callbacks.push(this.filterByPriceTo);
        this.filters.totalAreaFrom && callbacks.push(this.filterByTotalAreaFrom);
        this.filters.totalAreaTo && callbacks.push(this.filterByTotalAreaTo);
        this.filters.facing.length > 1 && callbacks.push(this.filterByFacing);
        this.filters.isHideBooked && callbacks.push(this.filterByBooked);
        this.filters.floorNumberFrom && callbacks.push(this.filterByFloorNumberFrom);
        this.filters.floorNumberTo && callbacks.push(this.filterByFloorNumberTo);
        this.filters.types.length && callbacks.push(this.filterByObjectType);
        this.filters.exceptFirstFloor && callbacks.push(this.filterOutFirstFloor);
        this.filters.exceptLastFloor && callbacks.push(this.filterOutLastFloor);

        return callbacks;
    };

    filterByRooms = (apartment: IApartmentTileItem) =>
        this.filters.roomsNumber?.every(({ rooms_number }: any) => +rooms_number !== apartment.rooms_number);

    filterByObjectNumber = (apartment: IApartmentTileItem): boolean => apartment.object_number !== this.filters.objectNumber;

    filterByPriceFrom = (apartment: IApartmentTileItem) => apartment.price < +this.filters.priceFrom * 1000000;

    filterByPriceTo = (apartment: IApartmentTileItem) => apartment.price > +this.filters.priceTo * 1000000;

    filterByTotalAreaFrom = (apartment: IApartmentTileItem) => Math.ceil(apartment.total_area) < +this.filters.totalAreaFrom;

    filterByTotalAreaTo = (apartment: IApartmentTileItem) => Math.ceil(apartment.total_area) > +this.filters.totalAreaTo;

    filterByFacing = (apartment: IApartmentTileItem) => apartment.facing !== this.filters.facing;

    filterByBooked = (apartment: IApartmentTileItem) => apartment.is_booked;

    filterByFloorNumberFrom = (apartment: IApartmentTileItem) => apartment.floor_number < +this.filters.floorNumberFrom;

    filterByFloorNumberTo = (apartment: IApartmentTileItem) => apartment.floor_number > +this.filters.floorNumberTo;

    filterByObjectType = (apartment: IApartmentTileItem) => !this.filters.types.includes(+apartment.type_id);

    filterOutFirstFloor = (apartment: IApartmentTileItem) =>
        apartment.floor_number === +this.normalizedFloors[this.normalizedFloors.length - 1][0];

    filterOutLastFloor = (apartment: IApartmentTileItem) => apartment.floor_number === +this.normalizedFloors[1][0];

    checkPrice = (): boolean => !!this.filters.priceFrom || !!this.filters.priceTo;

    checkTotalArea = (): boolean => !!this.filters.totalAreaFrom || !!this.filters.totalAreaTo;

    checkFacing = (): boolean => this.filters.facing.length > 1;

    checkBooked = (): boolean => this.filters.isHideBooked;

    checkFloors = (): boolean =>
        !!this.filters.floorNumberFrom || !!this.filters.floorNumberTo || this.filters.exceptFirstFloor || this.filters.exceptLastFloor;

    checkObjectType = (): boolean => !!this.filters.types.length;

    checkDevEnd = (): boolean => !!this.filters.devEnd?.length;

    checkObjectNumber = (): boolean => !!this.filters.objectNumber;

    countActiveFilters = () => {
        const filters = [
            this.checkPrice(),
            this.checkTotalArea(),
            this.checkFacing(),
            this.checkBooked(),
            this.checkFloors(),
            this.checkDevEnd(),
            this.checkObjectNumber()
        ];
        return filters.filter(callback => callback).length;
    };

    setNumberFilters = () => (this.numberOfActiveFilters = this.countActiveFilters());

    @computed
    get normalizeFloors() {
        const floorsRows: any = [];
        const maxFloorIndex = this.apartmentsTiles?.[0].floors.length;

        this.apartmentsTiles?.forEach(({ floors, entrance_number }) => {
            const entranceArray: any[] = new Array(floors[0].apartments.length);
            entranceArray[0] = 1;
            entranceArray[entranceArray.length - 1] = 0;

            const middleTile = Math.floor((entranceArray.length - 1) / 2);

            entranceArray[middleTile] = {};

            if (entranceArray.length === 2) {
                entranceArray[middleTile].double = true;
            }
            if (entranceArray.length === 1) {
                entranceArray[middleTile].single = true;
            }
            if (entranceArray.length % 2 === 0) {
                entranceArray[middleTile].even = true;
            }
            entranceArray[middleTile].name = `Подъезд ${entrance_number}`;

            floorsRows[maxFloorIndex]
                ? floorsRows[maxFloorIndex].push(...entranceArray)
                : (floorsRows[maxFloorIndex] = [undefined, ...entranceArray]);

            floors.forEach(({ apartments, floor_number }, floor_id) => {
                floorsRows[floor_id] ? floorsRows[floor_id].push(...apartments) : (floorsRows[floor_id] = [floor_number, ...apartments]);
            });
        });

        return floorsRows.reverse();
    }

    @action
    getApartmentsTiles = (complexId: number, houseId: number) => {
        this.loading = true;

        API.apartment
            .getApartmentsTiles(complexId, houseId)
            .then(response => {
                this.apartmentsTiles = response.data?.data?.entrances;
                this.fillEmptyApartments();
                this.normalizedFloors = this.normalizeFloors;
            })
            .finally(() => {
                this.loading = false;
            });
    };

    @action
    setSelectedApartment = (rowNumber: number, columnNumber: number) => {
        if (this.activeTile[0]) {
            this.normalizedFloors[this.activeTile[0] as any][this.activeTile[1] as any].active = false;
        }

        this.selectedApartment = this.normalizedFloors[rowNumber][columnNumber];
        this.activeTile = [rowNumber, columnNumber];
        this.normalizedFloors[rowNumber][columnNumber].active = true;
    };

    @action
    clearSelectedApartment = () => {
        this.selectedApartment = null;
    };

    @action
    clearTiles = () => {
        this.normalizedFloors = [];
        this.activeTile = [undefined, undefined];
        this.gridState = true;
    };

    @action
    getApartmentCount = () => {
        this.apartmentsCountLoading = true;

        API.apartment
            .getApartmentsCount(this.getAppliedFilters())
            .then(response => {
                this.apartmentsCount = response.data?.data?.cnt;
            })
            .finally(() => {
                this.apartmentsCountLoading = false;
            });
    };

    @action
    setApartmentsCount = (count: number) => (this.apartmentsCount = count);

    @action
    getApartmentsForModal = (offset: number, limit: number, reload?: boolean) => {
        this.loading = true;

        API.apartment
            .getApartments(offset * limit, limit, this.getAppliedFilters())
            .then(response => {
                if (response?.data.success) {
                    this.apartments = reload ? response.data.data?.items : [...this.apartments, ...response.data.data?.items];
                    this.apartmentsPagination = response.data?.data?.pagination;
                }
            })
            .finally(() => {
                this.loading = false;
            });
    };

    @action
    setPaginationPage = (page: number) => (this.paginationPage = page);

    @action
    resetApartments = () => (this.apartments = []);

    @action
    getApartment = (id: number) => {
        this.loading = true;

        API.apartment
            .getApartment(id)
            .then(response => {
                this.apartment = response?.data;
            })
            .finally(() => {
                this.loading = false;
            });
    };

    // get filters data
    @action
    getComplexes = () => {
        this.filterLoading = true;

        API.filters
            .getComplexes()
            .then(response => {
                this.complexes = response.data?.data;
            })
            .finally(() => {
                this.filterLoading = false;
            });
    };

    @action
    getHouses = (ids: string) => {
        const complexIds = ids.split(',');
        this.filterLoading = true;

        if (complexIds.length) {
            const promises = complexIds.map((complexId: string) => API.filters.getHouses(parseInt(complexId)));

            Promise.all(promises)
                .then(responses => {
                    this.houseNumbers = responses.flatMap(response => response.data?.data);
                })
                .finally(() => {
                    this.filterLoading = false;
                });
        }
    };

    @action
    getHousesTiles = (id: number) => {
        this.filterLoading = true;

        API.filters
            .getHouses(id)
            .then(response => {
                this.houseNumbers = response.data?.data;
            })
            .finally(() => {
                this.filterLoading = false;
            });
    };

    @action
    getFilters = () => {
        this.filterLoading = true;

        API.filters
            .getFilters()
            .then(response => {
                if (response.data?.data) {
                    this.rooms = response.data.data.rooms;
                    this.devEnd = response.data.data.devEnd;
                    this.facing = response.data.data.facing;
                    this.sorts = response.data.data.sorts;
                    this.types = response.data.data.types;
                }
            })
            .finally(() => {
                this.filterLoading = false;
            });
    };

    @action
    resetFilters = () => {
        this.filtersIsReset = true;
        this.apartmentsCount = null;
        this.houseNumbers = [];
        this.freeSoloHouseNumber = '';
        this.filters = {
            complex: null,
            complexTiles: '',
            houseNumberTiles: '',
            houseNumbers: null,
            objectNumber: '',
            roomsNumber: null,
            priceFrom: '',
            priceTo: '',
            totalAreaFrom: '',
            totalAreaTo: '',
            devEnd: null,
            floorNumberFrom: '',
            floorNumberTo: '',
            exceptFirstFloor: false,
            exceptLastFloor: false,
            facing: ' ',
            isHideBooked: false,
            sort: 1,
            types: [],
        };
    };

    @action
    afterFiltersReset = () => {
        this.resetApartments();
    };

    @action
    resetTileFilters = () => {
        this.resetOptionalFilters();
        this.resetMainFilters();
    };

    @action
    resetMainFilters = () => {
        this.filters.complex = null;
        this.filters.houseNumbers = null;
    };

    @action
    resetOptionalFilters = () => {
        this.filters = {
            complex: this.filters.complex,
            houseNumbers: this.filters.houseNumbers,
            complexTiles: '',
            houseNumberTiles: '',
            objectNumber: '',
            roomsNumber: null,
            priceFrom: '',
            priceTo: '',
            totalAreaFrom: '',
            totalAreaTo: '',
            devEnd: null,
            floorNumberFrom: '',
            floorNumberTo: '',
            exceptFirstFloor: false,
            exceptLastFloor: false,
            facing: ' ',
            isHideBooked: false,
            sort: 1,
            types: [],
        };
        this.loopThroughAllApartments(this.constructArrayCallbacks());
        this.setNumberFilters();

        this.getApartmentCount();
    };

    @action
    resetPopupFilters = () => {
        const { history } = stores.HistoryStore;

        this.filters = {
            complex: this.filters.complex,
            houseNumbers: this.filters.houseNumbers,
            houseNumberTiles: '',
            complexTiles: '',
            objectNumber: '',
            roomsNumber: this.filters.roomsNumber,
            priceFrom: '',
            priceTo: '',
            totalAreaFrom: '',
            totalAreaTo: '',
            devEnd: null,
            floorNumberFrom: '',
            floorNumberTo: '',
            exceptFirstFloor: false,
            exceptLastFloor: false,
            facing: ' ',
            isHideBooked: false,
            sort: 1,
            types: this.filters.types,
        };

        this.loopThroughAllApartments(this.constructArrayCallbacks());
        this.setNumberFilters();

        this.getApartmentCount();

        history.push(`${history.location.pathname}`);
        this.handleFilter();
        this.getApartments(this.paginationPage, 20, this.getAppliedFilters());
    };

    // set filters

    @action
    handleFilter = () => {
        const {
            location: { search, pathname, hash },
        } = stores.HistoryStore.history;

        let filterParams = '';

        Object.entries(this.getAppliedFilters()).forEach(([key, value]) => {
            if (value) {
                filterParams += `&${key}=${value}`;
            }
        });

        // reset and set if has changes
        if (search.replace('?', '') !== `${filterParams}`) {
            this.resetApartments();
            this.setPaginationPage(0);
            // set url

            stores.HistoryStore.history.push(`${pathname}?${hash.includes('tiles') ? '#tiles' : ''}${filterParams}`);
        }
        this.setNumberFilters();
    };

    @action
    handleFiltersObjectTabChange = () => {
        const {
            location: { pathname, hash },
        } = stores.HistoryStore.history;

        let filterParams = '';

        const filters = {
            complex: this.filters.complex?.length === 1 ? this.filters.complex?.[0].id : '',
            houseNumbers: this.filters.houseNumbers?.length === 1 ? this.filters.houseNumbers?.[0] : '',
        };

        Object.entries(filters).forEach(([key, value]) => {
            if (value) {
                filterParams += `&${key}=${value}`;
            }

            stores.HistoryStore.history.push(`${pathname}?${hash.includes('tiles') ? '#tiles' : ''}${filterParams}`);
        });
    };

    @action
    setFilter = (name: string, value: any): any => {
        (this.filters[name as keyof IApartmentFilters] as IApartmentFilters) = value;
    };

    @action
    setSort = (value: number) => {
        this.resetApartments();
        this.paginationPage = 0;
        this.filters.sort = value;
    };

    @action
    setFilterComplex = (ids: string) => {
        const complexes: IComplexField[] = [];
        const complexIds = ids.split(',');

        complexIds.forEach((complexId: string) => {
            const index = this.complexes.findIndex((complex: IComplexField) => complex.id === parseInt(complexId));

            complexes.push(this.complexes[index]);
        });

        if (complexes.length) {
            this.filters.complex = complexes;
        }

        this.getApartmentCount();
    };

    @action
    setFilterRoomsNumber = (ids: string) => {
        const roomsIds = ids.split(',');

        this.filters.roomsNumber = this.rooms.filter(({ rooms_number }) => roomsIds.includes(rooms_number));

        this.getApartmentCount();
    };

    @action
    setFilterTypes = (ids: string) => {
        this.filters.types = ids.split(',');

        this.getApartmentCount();
    };

    @action
    setFilterdevEnd = (dates: string) => {
        const devEndDates: IFilterDevEnd[] = [];
        const roomsIds = dates.split(',');

        roomsIds.forEach((devEnd: string) => {
            const devEndDatesArray = devEnd.split('/');
            const index = this.devEnd.findIndex(
                (item: IFilterDevEnd) => item.quarter.toString() === devEndDatesArray[0] && item.year.toString() === devEndDatesArray[1],
            );

            devEndDates.push(this.devEnd[index]);
        });

        this.filters.devEnd = devEndDates;

        this.getApartmentCount();
    };

    // filter changes
    @action
    handleChange = (event: any) => {
        (this.filters[event.target.name as keyof IApartmentFilters] as IApartmentFilters) = event.target.value;

        this.filtersIsReset = false;
        this.getApartmentCount();
        this.setNumberFilters();
    };

    @action
    handleTileFilterChange = (event: any) => {
        (this.filters[event.target.name as keyof IApartmentFilters] as IApartmentFilters) = event.target.value;

        this.loopThroughAllApartments(this.constructArrayCallbacks());
        this.setNumberFilters();
    };

    findObjectColAndRow = () => {
        this.normalizedFloors.forEach((floor, rowIndex) => {
            floor.forEach((apartment, columnIndex) => {
                if (apartment && typeof apartment === 'object') {
                    const checkMatch = this.filterByObjectNumber(apartment);

                    if (!checkMatch) {
                        this.setSelectedApartment(rowIndex, columnIndex);
                    }

                    return (apartment.isFiltered = this.filters.objectNumber ? checkMatch : false);
                }
            });
        });
    };

    @action
    handleObjectNumberChange = (event: any) => {
        (this.filters[event.target.name as keyof IApartmentFilters] as IApartmentFilters) = event.target.value;

        this.findObjectColAndRow();

        this.setNumberFilters();
    };

    @action
    handleChangeComplex = (event: any, value: any) => {
        this.filters.complex = value;
        this.filters.complexTiles = this.filters.complex?.[0];
        // reset
        this.filtersIsReset = false;
        this.houseNumbers = [];
        this.freeSoloHouseNumber = '';
        this.filters.houseNumbers = null;

        this.getApartmentCount();
    };

    @action
    getHousesInModal = (value: any) => {
        if (value && value.length) {
            const complexes = this.filters.complex?.map((complex: IComplexField) => complex?.id);
            this.getHouses(complexes.join(','));
        }
    };

    @action
    handleFilterComplex = (event: any, value: any): void => {
        this.handleChangeComplex(event, value ? [value] : value);
        this.clearSelectedApartment();
        this.clearTiles();
        this.resetOptionalFilters();
        this.filters.complex?.[0].id && this.getHousesTiles(this.filters.complex[0].id);
    };

    @action
    handleFilterHouseNumber = (event: any, value: any) => {
        this.handleChangeHouseNumberTiles(value ? [value] : value);
        this.clearSelectedApartment();
        this.clearTiles();
        this.resetOptionalFilters();
        //this.filters.complex?.[0].id && this.getHousesTiles(this.filters.complex[0].id);
        this.handleFilter();
    };

    @action
    handleChangeHouseNumber = (event: any, value: any) => {
        this.filters.houseNumbers = value;
        this.filters.houseNumberTiles = !!this.filters.houseNumbers?.length ? this.filters.houseNumbers?.[0] : '';

        this.filtersIsReset = false;
        this.freeSoloHouseNumber = '';
        this.getApartmentCount();
    };

    @action
    handleNumberOfAptsWhenChangeHouses = (event: any, value: any) => {
        const numberOfApts = this.coords
            .filter(({ id }) => id === this.filters.complex[0].id)[0]
            //@ts-ignore
            .buildings.filter(({ title }) => title === value)[0]?.amount;
        this.setApartmentsCount(numberOfApts);

        this.handleChangeHouseNumber(event, value);
    };

    @action
    handleChangeHouseNumberTiles = (value: any) => {
        this.filters.houseNumbers = value;

        this.filtersIsReset = false;
        this.freeSoloHouseNumber = '';
        this.getApartmentCount();
        value && this.getApartmentsTiles(this.filters.complex?.[0].id, this.filters.houseNumbers?.[0]);
    };

    @action
    handleHouseNumberFreeSoloInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.freeSoloHouseNumber = event.target.value;
    };

    @action
    handleAutocompleteChange = (filter: string, value: any) => {
        (this.filters[filter as keyof IApartmentFilters] as IApartmentFilters) = value;

        this.filtersIsReset = false;
        this.getApartmentCount();
    };

    @action
    handleAutocompleteChangeTiles = (filter: string, value: any) => {
        (this.filters[filter as keyof IApartmentFilters] as IApartmentFilters) = value;

        this.loopThroughAllApartments(this.constructArrayCallbacks());
        this.setNumberFilters();
    };

    @action
    handleChangeCheckboxes = (event: React.ChangeEvent<HTMLInputElement>) => {
        (this.filters[event.target.name as keyof IApartmentFilters] as any) = event.target.checked;

        this.filtersIsReset = false;
        this.getApartmentCount();
        this.setNumberFilters();
    };

    @action
    handleChangeCheckboxesTiles = (event: React.ChangeEvent<HTMLInputElement>) => {
        (this.filters[event.target.name as keyof IApartmentFilters] as any) = event.target.checked;

        this.loopThroughAllApartments(this.constructArrayCallbacks());
        this.setNumberFilters();
    };

    @action
    withImmediatelyReset =
        (func: any) =>
        (...args: any) => {
            func(...args);
            this.handleFilter();
        };

    @action
    toggleGridState = () => (this.gridState = !this.gridState);

    @action
    openFilters = () => (this.isOpenFilters = true);

    @action
    closeFilters = () => (this.isOpenFilters = false);

    @action
    toggleFilters = () => (this.isOpenFilters = !this.isOpenFilters);

    @action
    toggleObjectTypeTiles = (typeId: number) => {
        this.filters.types.includes(typeId)
            ? this.filters.types.splice(this.filters.types.indexOf(typeId), 1)
            : this.filters.types.push(typeId);

        this.loopThroughAllApartments(this.constructArrayCallbacks());
        this.setNumberFilters();
    };

    @action
    toggleObjectType = (typeId: number) => {
        this.filters.types.includes(typeId)
            ? this.filters.types.splice(this.filters.types.indexOf(typeId), 1)
            : this.filters.types.push(typeId);
        this.getApartmentCount();
        this.setNumberFilters();
        this.handleFilter();
    };

    @action
    setObjectTypeFilter = (typeId: number|null) => {
        if (typeId === null) {
            this.filters.types = [];
        } else {
            this.filters.types = [typeId];
        }
    }

    @action
    setObjectType = (typeId: number|null) => {
        this.setObjectTypeFilter(typeId);
        this.getApartmentCount();
        this.setNumberFilters();
        this.handleFilter();
    };

    @action
    setObjectTypeTiles = (typeId: number|null) => {
        this.setObjectTypeFilter(typeId);
        this.loopThroughAllApartments(this.constructArrayCallbacks());
        this.setNumberFilters();
    };

    @action
    downloadObject = (complexId: number[]) => {
        API.apartment.downloadObject(complexId).then(response => {
            saveAs(response.data?.data.pdf_url, 'Индивидуальное предложение.pdf');
        });
    };

    @action
    addApartmentToFavorite = () => {
        if (this.apartment) {
            this.apartment.data.isFavorite = true;
            stores.FavoriteStore.addToFavorite(this.apartment.data.object_id as number).then((success: boolean) => {
                if (!success) {
                    //@ts-ignore
                    this.apartment.data.isFavorite = false;
                }
            });
        }
    };

    @action
    removeApartmentFromFavorite = () => {
        if (this.apartment) {
            this.apartment.data.isFavorite = false;
            stores.FavoriteStore.removeFromFavorite(this.apartment.data.object_id as number).then((success: boolean) => {
                if (!success) {
                    //@ts-ignore
                    this.apartment.data.isFavorite = true;
                }
            });
        }
    };

    @action
    addApartmentFromListToFavorite = (objectId: number) => {
        const apartment = this.apartments.filter(({ object_id }) => object_id === objectId)[0];
        apartment.isFavorite = true;
        stores.FavoriteStore.addToFavorite(objectId).then((success: boolean) => {
            if (!success) {
                apartment.isFavorite = false;
            }
        });
    };

    @action
    removeApartmentFromListFromFavorite = (objectId: number) => {
        const apartment = this.apartments.filter(({ object_id }) => object_id === objectId)[0];
        apartment.isFavorite = false;
        stores.FavoriteStore.removeFromFavorite(objectId).then((success: boolean) => {
            if (!success) {
                apartment.isFavorite = true;
            }
        });
    };
}
