import { useEffect } from 'react';
import queryString from 'query-string';
import { useHistory } from 'react-router-dom';

import { getActiveTab } from '../utils/getActiveTab';
import { useStores } from './useStores';

export const useSetAppDealFilters = () => {
    const { EmployeesStore, AppsDealsStore: store } = useStores();

    const {
        location: { search, hash },
    } = useHistory();
    const parsed = queryString.parse(search || hash);

    useEffect(() => {
        if (parsed.objectNumber) {
            store.setFilter('objectNumber', parsed.objectNumber);
        }
        if (parsed.priceFrom) {
            store.setFilter('priceFrom', parseFloat(parsed.priceFrom as string) / 1000000 || '');
        }
        if (parsed.priceTo) {
            store.setFilter('priceTo', parseFloat(parsed.priceTo as string) / 1000000 || '');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const realtorIds = parsed.realtors as string;

        if (parsed.realtors) {
            if (EmployeesStore.employees.length) {
                store.setFilterRealtor(EmployeesStore.employees, realtorIds);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [EmployeesStore.employees]);

    useEffect(() => {
        if (parsed.stage) {
            if (store.statuses) {
                store.setFilterStatus(parsed.stage.toString(), getActiveTab(hash));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.statuses]);

    useEffect(() => {
        const complexesIds = parsed.complex as string;

        if (parsed.complex) {
            if (store.complexes.length) {
                store.setFilterComplex(complexesIds);
                store.getHouses(complexesIds);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.complexes]);

    useEffect(() => {
        if (parsed.houseNumbers) {
            if (store.houseNumbers?.length) {
                store.setFilterHouseNumbers(parsed.houseNumbers as string);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.houseNumbers]);
};
