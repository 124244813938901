import { observable, action } from 'mobx';
import {} from '../types/AuthTypes';
import API from '../api';
import { IProfileInfo, IProfileUpdate, IProfileChangePass, IProfileNotifications } from '../types/ProfileTypes';
import { showNotification } from '../utils/Notification';

export const _tokenStorageKey = 'accessToken';

export class ProfileStore {
    @observable loading = false;

    @observable profileInfo: IProfileInfo | null = null;
    @observable userRole: string | null = null;

    @observable profileEditErrors: any = {};

    @observable changePassErrors: any = {};

    @observable profileNotifications: IProfileNotifications | null = null;
    @observable profileNotificationsLoading: boolean = false;

    @action
    getProfileInfo = async () => {
        this.loading = true;
        API.profile
            .getProfileInfo()
            .then(response => {
                if (response?.data?.success) {
                    this.profileInfo = response.data;
                    this.profileNotifications = response.data.data.notificationSetting;
                    this.userRole = response.data.data.agency?.role || 'unconfirmed';
                }
            })
            .finally(() => {
                this.loading = false;
            });
    };

    @action
    changePassword = (data: IProfileChangePass, history: any) => {
        this.loading = true;

        API.profile
            .changePassword(data)
            .then(response => {
                history.push('/profile');
            })
            .catch(error => {
                this.changePassErrors = error.response?.data.errors;
            })
            .finally(() => {
                this.loading = false;
            });
    };

    @action
    updateProfileInfo = (data: IProfileUpdate, history: any) => {
        // console.log('ProfileStore -> updateProfileInfo -> data', data);
        // return;
        const form = new FormData();
        form.append('name', data.name);
        form.append('email', data.email);
        if (data.agency.companyName && data.agency.companyInn) {
            form.append('agency[companyName]', data.agency.companyName);
            form.append('agency[companyInn]', data.agency.companyInn);
        }
        form.append('phone', data.phone);
        form.append('date_birth', data.date_birth);
        form.append('timezone', data.timezone);
        if (data.photo && !data.photo.fullUrl) {
            form.append('photo', data.photo, data.photo.name);
        } else if (!data.photo) {
            form.append('removePhoto', 'true');
        }

        this.loading = true;

        API.profile
            .updateProfileInfo(form)
            .then(response => {
                this.profileInfo = response.data;
                showNotification('success', response.data.message, 'center', 700);
                setTimeout(() => {
                    history.push('/profile');
                }, 700);
                // showNotification
            })
            .catch(error => {
                this.profileEditErrors = error.response?.data.errors;
            })
            .finally(() => {
                this.loading = false;
            });
    };

    @action
    handleChangeNotifcation = (e: any) => {
        if (this.profileNotifications) {
            this.profileNotificationsLoading = true;

            if (e.target.name === 'all') {
                for (const key in this.profileNotifications) {
                    this.profileNotifications[key] = e.target.checked;
                }
            } else {
                this.profileNotifications[e.target.name] = e.target.checked;
                if (e.target.checked) {
                    let allActive = true;
                    for (const key in this.profileNotifications) {
                        if (key !== 'all') {
                            if (this.profileNotifications[key] === false) {
                                allActive = false;
                                break;
                            }
                        }
                    }
                    this.profileNotifications['all'] = allActive;
                } else {
                    this.profileNotifications['all'] = false;
                }
            }

            API.profile
                .changeNotifications(this.profileNotifications)
                .then(response => {
                    this.profileInfo = response.data;
                    this.profileNotifications = response.data.data.notificationSetting;
                })
                .catch(error => {
                    this.profileNotifications = this.profileInfo?.data.notificationSetting;
                })
                .finally(() => {
                    this.profileNotificationsLoading = false;
                });
        }
    };

    @action
    resetStore = () => {
        this.loading = false;

        this.profileInfo = null;
        this.userRole = null;
    };

    @action
    resetErrors = () => {
        this.profileEditErrors = {};
        this.changePassErrors = {};
    };
}
