import React, { useEffect } from 'react';
import { Box, Container, Typography, makeStyles, Divider } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';

import { useModal } from '../../hooks/useModal';
import { useStores } from '../../hooks/useStores';
import noFavorite from '../../img/no_favorite.svg';

import Loader from '../../components/Loader/Loader';
import FavoriteFolders from '../../components/Favorite/FavoriteFolders';
import FavoriteApartments from '../../components/Favorite/FavoriteApartments';
import FavoriteActionButtons from '../../components/Favorite/FavoriteActionButtons';
import TextButton from '../../components/UI/TextButton';
import FavoriteRemoveModal from '../../components/Favorite/FavoriteRemoveModal';
import FavoriteActionButtonsMobile from '../../components/Favorite/FavoriteActionButtonsMobiles';

const Favorite = observer(() => {
    const { FavoriteStore: store } = useStores();
    const classes = useStyles();
    const { isOpen, openModal, closeModal } = useModal();

    const touch = matchMedia('(hover: none)').matches;

    useEffect(() => {
        store.getAllFavorites();
        return () => {
            store.clearFavorites();
        };
    }, [store]);

    if (store.loading) return <Loader isAbsolute />;

    if (!store.favorites?.folders.length && !store.favorites?.inline.length)
        return (
            <Box margin="auto" display="flex" flexDirection="column" py={6}>
                <img src={noFavorite} alt="" />
                <Box mt={5}>
                    <Typography variant="h3" className={classes.noObjects}>
                        Нет избранных объектов
                    </Typography>
                </Box>
            </Box>
        );

    return (
        <Box className={classes.wrapper}>
            <Container maxWidth="xl" className={classes.container}>
                <Box py={5} display="flex" flexDirection="column" height="100%">
                    <Box position="relative">
                        <Typography variant="h2">Избранное</Typography>

                        {!!store.selectedObjectsIds.length ? (
                            touch ? (
                                <FavoriteActionButtonsMobile />
                            ) : (
                                <FavoriteActionButtons />
                            )
                        ) : (
                            <Box position="absolute" right={0} top={0} onClick={openModal}>
                                <TextButton className={classes.buttonDelete} startIcon={<DeleteForeverOutlinedIcon />}>
                                    Удалить все
                                </TextButton>
                            </Box>
                        )}
                    </Box>

                    <FavoriteRemoveModal isOpen={isOpen} closeModal={closeModal} onSubmit={store.removeAll} removeAll />

                    <Box mt={8}>
                        <FavoriteFolders />

                        <Divider className={classes.divider} />

                        <FavoriteApartments favorites={store.favorites?.inline} />
                    </Box>
                </Box>
            </Container>
        </Box>
    );
});

export default Favorite;

const useStyles = makeStyles(theme => ({
    wrapper: {
        width: '100vw',
        background: theme.palette.surface.main,
    },
    noObjects: {
        //@ts-ignore
        color: theme.palette.primary.A200,
    },
    container: {
        height: '100%',
    },
    divider: {
        //@ts-ignore
        background: theme.palette.primary.A100,
        marginBottom: theme.spacing(4),
    },
    buttonDelete: {
        padding: '5px 7px 5px 14px',
        //@ts-ignore
        border: `1px solid ${theme.palette.material.secondary}`,
        filter:
            'drop-shadow(0px 43px 58px rgba(94, 1, 198, 0.04)) drop-shadow(0px 15px 19px rgba(0, 0, 0, 0.03)) drop-shadow(0px 7.36424px 9px rgba(0, 0, 0, 0.01))',
        borderRadius: 10,
        //@ts-ignore
        color: theme.palette.material.secondary,
        '&:hover': {
            //@ts-ignore
            color: theme.palette.material.secondary,
        },
    },
}));
