import React from 'react';
import { TabPanelProps } from '../../types/TabsTypes';

export const TabPanel = (props: TabPanelProps) => {
    const { children, value, id, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== id} id={`tabpanel-${id}`} aria-labelledby={`tab-${id}`} {...other}>
            {value === id ? children : null}
        </div>
    );
};

export const a11yProps = (id: string) => {
    return {
        id: `tab-${id}`,
        value: id,
        'aria-controls': `tabpanel-${id}`,
    };
};
