import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { Box, Typography, Grid, Button, TextField, useMediaQuery, IconButton, useTheme, Paper } from '@material-ui/core';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useHistory } from 'react-router-dom';

import { IComplexField } from '../../../types/CommonTypes';
import { useStores } from '../../../hooks/useStores';
import { getActiveTab } from '../../../utils/getActiveTab';
import { smoothScrollToTop } from '../../../utils/smoothScrollToTop';
import { IEmployee } from '../../../types/EmployeesTypes';
import { IAppDealStatus } from '../../../types/AppDealTypes';
import { useStyles } from '../../Apartments/style';
import { useSetAppDealFilters } from '../../../hooks/useSetAppDealFilters';

import FormLabelCustom from '../../UI/FormLabelCustom';
import TextButton from '../../UI/TextButton';
import MuiModalRangePicker from '../../UI/MuiModalRangePicker';
import { MoneyInput } from '../../UI/Form';
import TextFieldWithoutHelper from '../../UI/TextFieldWithoutHelper';

const AppDealFilter = observer(({ onCloseFilter }: { onCloseFilter?: any }) => {
    const { ProfileStore, EmployeesStore, AppsDealsStore: store } = useStores();
    const classes = useStyles();
    const history = useHistory();
    const theme = useTheme();
    const isMD = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {
        if (ProfileStore.userRole === 'director') {
            EmployeesStore.getConfirmedEmployees(0, 20);
        }

        store.getStatuses();
        store.getComplexes();
        store.getFilters();
    }, [EmployeesStore, ProfileStore.userRole, store]);

    useSetAppDealFilters();

    //// Handlers

    const handleFilter = (e: React.FormEvent) => {
        e.preventDefault();

        let filterParams = '';

        // base param
        filterParams += getActiveTab(history.location.hash) === 'applications' ? `&type=request` : `&type=deal`;

        if (store.filters.realtor && store.filters.realtor.length) {
            const realtors = store.filters.realtor.map((realtor: IEmployee) => realtor.id);

            filterParams += `&realtors=${realtors.join(',')}`;
        }
        if (store.filters.date && store.filters.date[0] && store.filters.date[1]) {
            filterParams += `&dateFrom=${moment(store.filters.date[0]).format('YYYY-MM-DD')}`;
            filterParams += `&dateTo=${moment(store.filters.date[1]).format('YYYY-MM-DD')}`;
        }
        if (store.filters.status && store.filters.status.length) {
            const stage = store.filters.status.map((status: IAppDealStatus) => status.id);

            filterParams += `&stage=${stage.join(',')}`;
        }
        if (store.filters.complex && store.filters.complex.length) {
            const complexes = store.filters.complex.map((complex: IComplexField) => complex.id);

            filterParams += `&complex=${complexes.join(',')}`;
        }
        if (store.filters.houseNumbers && store.filters.houseNumbers.length) {
            const houseNumbers = store.filters.houseNumbers.map((houseNumber: IComplexField) => houseNumber);

            filterParams += `&houseNumbers=${houseNumbers.join(',')}`;
        }
        if (store.filters.objectNumber && store.filters.objectNumber !== ' ') {
            filterParams += `&objectNumber=${store.filters.objectNumber}`;
        }
        if (store.filters.priceFrom && store.filters.priceFrom !== ' ') {
            filterParams += `&priceFrom=${parseFloat(store.filters.priceFrom as string) * 1000000}`;
        }
        if (store.filters.priceTo && store.filters.priceTo !== ' ') {
            filterParams += `&priceTo=${parseFloat(store.filters.priceTo as string) * 1000000}`;
        }

        // reset and set if has changes
        if (history.location.search.replace('?', '') !== `${filterParams}`) {
            store.resetAppsDeals();
            store.setFiltersIsReset(false);
            store.setPaginationPage(0);
            smoothScrollToTop();

            // set url
            history.push(`${history.location.pathname}?${filterParams}${history.location.hash}`);
        }

        if (isMD) {
            onCloseFilter();
        }
    };

    //// Renders
    return (
        <Paper elevation={0}>
            <Box p={2}>
                <Box mb={4}>
                    <Grid container justify="space-between" alignItems="center">
                        <Typography variant="h4">Фильтр</Typography>
                        {isMD ? (
                            <IconButton size="small" onClick={onCloseFilter}>
                                <CloseOutlinedIcon />
                            </IconButton>
                        ) : (
                            <TextButton onClick={store.handleResetFilters} disabled={store.filtersIsReset}>
                                Сбросить
                            </TextButton>
                        )}
                    </Grid>
                </Box>

                {ProfileStore.userRole === 'director' && (
                    <Box mb={4}>
                        <FormLabelCustom id="realtor">Риелтор</FormLabelCustom>
                        <Autocomplete
                            multiple
                            limitTags={1}
                            popupIcon={<ExpandMoreIcon />}
                            value={(store.filters.realtor?.length && store.filters.realtor) || []}
                            onChange={(e, value: any) => store.handleAutocompleteChange('realtor', value)}
                            options={EmployeesStore.employees}
                            getOptionLabel={(option: any) => option.name}
                            renderInput={params => <TextField {...params} name="realtor" variant="outlined" placeholder="Выбрать" />}
                            loading={store.filterLoading}
                        />
                    </Box>
                )}

                <Box mb={4}>
                    <FormLabelCustom id="period">Период</FormLabelCustom>
                    <MuiModalRangePicker
                        date={store.filters.date}
                        onChange={store.handleDateChange}
                        modalTitle="Период"
                        disabled={ProfileStore.userRole === 'realtor'}
                    />
                </Box>

                <Box mb={4}>
                    <FormLabelCustom id="status">Статус</FormLabelCustom>
                    <Autocomplete
                        multiple
                        limitTags={1}
                        popupIcon={<ExpandMoreIcon />}
                        value={(store.filters.status?.length && store.filters.status) || []}
                        onChange={(e, value: any) => store.handleAutocompleteChange('status', value)}
                        options={
                            getActiveTab(history.location.hash) === 'deals' ? store.statuses?.deal || [] : store.statuses?.request || []
                        }
                        getOptionLabel={(option: any) => option?.title}
                        renderInput={params => <TextField {...params} name="status" variant="outlined" placeholder="Выбрать" />}
                        loading={store.filterLoading}
                    />
                </Box>

                <Box mb={4}>
                    <FormLabelCustom id="complex">Жилой комплекс</FormLabelCustom>
                    <Autocomplete
                        multiple
                        limitTags={1}
                        popupIcon={<ExpandMoreIcon />}
                        value={(store.filters.complex?.length && store.filters.complex) || []}
                        onChange={(e, value: any) => store.handleChangeComplex(value)}
                        options={store.complexes}
                        getOptionLabel={(option: IComplexField) => option.name}
                        renderInput={params => <TextField {...params} name="complex" variant="outlined" placeholder="Выбрать" />}
                        className={classes.complexSelect}
                        noOptionsText="Нет подходящих вариантов"
                        loading={store.filterLoading}
                        loadingText="Загрузка"
                    />
                </Box>

                <Box mb={4}>
                    <FormLabelCustom id="houseNumber">Номер дома</FormLabelCustom>
                    <Autocomplete
                        freeSolo
                        clearOnBlur
                        clearOnEscape
                        multiple
                        limitTags={1}
                        popupIcon={<ExpandMoreIcon />}
                        value={(store.filters.houseNumbers?.length && store.filters.houseNumbers) || []}
                        onChange={(e, value: any) => store.handleChangeHouseNumber(value)}
                        options={store.freeSoloHouseNumber.length ? [store.freeSoloHouseNumber, ...store.houseNumbers] : store.houseNumbers}
                        getOptionLabel={(option: string) => option}
                        loading={store.filterLoading}
                        loadingText="Загрузка"
                        renderInput={params => (
                            <TextField
                                {...params}
                                name="complex"
                                variant="outlined"
                                placeholder="Введите номер"
                                onChange={store.handleHouseNumberFreeSoloInput}
                            />
                        )}
                        className={classes.complexSelect}
                        noOptionsText="Нет подходящих вариантов"
                    />
                </Box>

                <Box mb={4}>
                    <FormLabelCustom id="objectNumber">Номер квартиры</FormLabelCustom>
                    <TextFieldWithoutHelper
                        fullWidth
                        name="objectNumber"
                        variant="outlined"
                        placeholder="Введите номер"
                        value={store.filters.objectNumber}
                        onChange={store.handleChangeFilters}
                    />
                </Box>

                <Box mb={4}>
                    <FormLabelCustom id="price">Стоимость ₽</FormLabelCustom>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <TextFieldWithoutHelper
                                name="priceFrom"
                                variant="outlined"
                                placeholder="От"
                                value={store.filters.priceFrom}
                                onChange={store.handleChangeFilters}
                                InputProps={{
                                    inputComponent: MoneyInput,
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextFieldWithoutHelper
                                name="priceTo"
                                variant="outlined"
                                placeholder="До"
                                value={store.filters.priceTo}
                                onChange={store.handleChangeFilters}
                                InputProps={{
                                    inputComponent: MoneyInput,
                                }}
                            />
                        </Grid>
                    </Grid>
                </Box>

                <Button variant="contained" color="primary" size="large" fullWidth onClick={handleFilter}>
                    Применить
                </Button>

                <Box mt={2} textAlign="center">
                    {isMD ? <TextButton onClick={store.handleResetFilters}>Сбросить</TextButton> : null}
                </Box>
            </Box>
        </Paper>
    );
});

export default AppDealFilter;
