import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Paper, Box, Grid, Typography, TextField, Button, Divider, InputAdornment, IconButton } from '@material-ui/core';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import logo from '../../img/logo.svg';
import { PhoneFormat } from '../UI/Form';
import { ISignIn } from '../../types/AuthTypes';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../hooks/useStores';
import { cleanupMask } from '../../utils/cleanupMask';
import { config } from '../../Config';
import { inputMaskHelper } from '../../utils/inputMaskHelper';

const SignIn = observer(() => {
    const { AuthStore: store, SystemStore } = useStores();
    const inputLogin = useRef<any>(null);

    const [showPassword, setShowPassword] = useState(false);
    const [values, setValues] = useState<ISignIn>({
        login: '',
        password: '',
        deviceType: 'web',
    });

    // Effects
    useEffect(() => {
        store.resetStore();

        return () => {
            store.resetStore();
        };
    }, [store]);

    // Handlers
    const togglePassword = () => {
        setShowPassword(!showPassword);
    };

    const handleChange = (e: any) => {
        e = inputMaskHelper(e);

        setValues({
            ...values,
            [e.target.name]: e.target.value,
        });
    };

    const handleLogin = (e: any) => {
        e.preventDefault();

        store.login({
            ...values,
            login: cleanupMask(values.login),
        });
    };

    // Renders
    return (
        <div className="auth-page__content">
            <Box p={{ xxs: 2, sm: 4 }} width="100%">
                <Grid container justify="center">
                    <Paper elevation={12} className="auth-block">
                        <Box p={{ xxs: 2, xs: 4 }} textAlign="center">
                            <Grid container justify="center">
                                <Box maxWidth={88}>
                                    <img src={logo} alt="" />
                                </Box>
                            </Grid>
                            <Box mt={{ xxs: 1, xs: 4 }} mb={{ xxs: 3, xs: 8 }}>
                                <Typography variant="h3" component="h1">
                                    Вход
                                </Typography>
                            </Box>
                            <form noValidate>
                                <TextField
                                    variant="outlined"
                                    margin="none"
                                    required
                                    fullWidth
                                    name="login"
                                    placeholder={config.phonePlaceholderWithZero}
                                    autoFocus
                                    value={values.login}
                                    InputProps={{
                                        inputComponent: PhoneFormat,
                                        notched: false,
                                    }}
                                    onChange={handleChange}
                                    inputRef={inputLogin}
                                    onFocus={() => {
                                        setTimeout(() => {
                                            inputLogin?.current?.setSelectionRange(3, 3);
                                        }, 0);
                                    }}
                                    error={!!store.signInErrors?.login}
                                    helperText={store.signInErrors?.login}
                                    disabled={store.loading}
                                />

                                <Box mt={4} mb={2}>
                                    <TextField
                                        variant="outlined"
                                        margin="none"
                                        required
                                        fullWidth
                                        name="password"
                                        type={showPassword ? 'text' : 'password'}
                                        placeholder="Пароль"
                                        value={values.password}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton aria-label="toggle password visibility" onClick={togglePassword}>
                                                        {showPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                            notched: false,
                                        }}
                                        onChange={handleChange}
                                        error={!!store.signInErrors?.password}
                                        helperText={store.signInErrors?.password}
                                        disabled={store.loading}
                                    />
                                </Box>
                                <Grid container justify="flex-end">
                                    <Link to="/reset-password">
                                        <Typography>Забыли пароль?</Typography>
                                    </Link>
                                </Grid>
                                <Box mt={4} mb={2}>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        onClick={handleLogin}
                                        disabled={store.loading}
                                    >
                                        Войти
                                    </Button>
                                </Box>
                                <Box mb={2}>
                                    <Typography>
                                        Нет аккаунта? <Link to="/register">Зарегистрироваться</Link>
                                    </Typography>
                                </Box>
                                <Divider />
                                <Box mt={2}>
                                    <Typography variant="caption">
                                        Используя приложение, вы принимаете условия
                                        <br />
                                        <a href={SystemStore.links?.['offer'] || '#'} target="_blank" rel="noopener noreferrer">
                                            <Typography variant="inherit" component={'span'} color="primary">
                                                договора-оферты
                                            </Typography>{' '}
                                        </a>
                                        и{' '}
                                        <a href={SystemStore.links?.['policy'] || '#'} target="_blank" rel="noopener noreferrer">
                                            <Typography variant="inherit" component={'span'} color="primary">
                                                политики конфиденциальности
                                            </Typography>{' '}
                                        </a>
                                    </Typography>
                                </Box>
                            </form>
                        </Box>
                    </Paper>
                </Grid>
            </Box>
        </div>
    );
});

export default SignIn;
