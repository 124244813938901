import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export const useURLParams = (store: any): void => {
  const { search } = useLocation()

  useEffect(() => {
    if (search) {
      const query = new URLSearchParams(search)
      const params = [
        query.get("cost") ?? "8000000",
        query.get("contribution") ?? "2000000",
        query.get("timing") ?? "20",
        query.get("rate") ?? "10.5"
      ]
      store.setParams(params)
    }


  }, [search, store])
}