import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Grid, TextField, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { useStores } from '../../../hooks/useStores';
import { useStyles } from '../style';
import { IAppDealIndexItem } from '../../../types/AppDealTypes';

import DocumentItemForm from '../AppDealDocs/DocumentItemForm';

const AppDealPaymentBank = observer(() => {
    const { AppDealFormStore: store } = useStores();
    const classes = useStyles();

    return (
        <Box mb={3}>
            <Box mb={2}>
                <Typography variant="subtitle1">Информация о банке</Typography>
            </Box>
            <Grid container spacing={1} alignItems="stretch">
                <Grid item xs={12} sm={6} lg={4} className={classes.paddingBottom}>
                    <Autocomplete
                        limitTags={1}
                        id="bank"
                        popupIcon={<ExpandMoreIcon />}
                        onOpen={store.getBanks}
                        onChange={(e, value: any) => store.setBank(value?.code || '')}
                        options={store.banks}
                        getOptionLabel={(option: IAppDealIndexItem) => option.name}
                        renderInput={params => (
                            <TextField
                                {...params}
                                name="bankCode"
                                variant="outlined"
                                placeholder="Выбрать банк"
                                error={store.formErrors?.bankCode}
                                helperText={store.formErrors?.bankCode && 'Выберите банк'}
                                FormHelperTextProps={{ classes: { root: classes.errorFormTextInput } }}
                            />
                        )}
                        noOptionsText="Нет подходящих вариантов"
                        loadingText="Загрузка"
                    />
                </Grid>

                <Grid item xs={12} sm={6} lg={4} className={classes.paddingBottom}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        name="bankClerkEmail"
                        label="Email специалиста банка"
                        value={store.values.bankClerkEmail}
                        onChange={store.handleChangeForm}
                        InputProps={{ notched: false }}
                        error={store.formErrors?.bankClerkEmail}
                        helperText={store.formErrors?.bankClerkEmail && 'Введите e-mail'}
                        FormHelperTextProps={{ classes: { root: classes.errorFormTextInput } }}
                    />
                </Grid>

                <Grid item xs={12} sm={6} lg={4}>
                    <DocumentItemForm
                        objKey="mortgageScan"
                        defaultFilePath={store.appDeal?.file_mortgage_certificate || ''}
                        defaultFileUuid={store.appDeal?.mortgageScanUuid || ''}
                        store={store}
                    >
                        Скан сертификата об одобрении ипотеки
                    </DocumentItemForm>
                </Grid>
            </Grid>
        </Box>
    );
});
export default AppDealPaymentBank;
