import { createTheme } from '@material-ui/core';

export const theme = createTheme({
    breakpoints: {
        values: {
            xxs: 0,
            xs: 375,
            sm: 576,
            md: 768,
            lg: 992,
            xl: 1072,
        },
    },
    palette: {
        secondary: { main: 'rgba(0,0,0,0.6)' },
        primary: {
            // @ts-ignore
            A50: '#ebe0f7',
            A100: '#CEB5EB',
            A200: '#AC85E0',
            main: '#5E01C6',
            light: '#EFEFF4',
            contrastText: '#fff',
            disabled: 'rgba(0,0,0,0.38)',
        },
        error: { main: '#FF2262' },
        success: { main: '#37C49B' },
        background: {
            default: '#fff',
        },
        surface: { main: '#f2f1f8' },
        material: {
            light: '#ebe0f7',
            blackMedium: 'rgba(0,0,0,0.6)',
            blackDisabled: 'rgba(0,0,0,0.38)',
            whiteMedium: 'rgba(255, 255, 255, 0.7)',
            overlay: 'rgba(0,0,0,0.16)',
            secondary: '#F8477A',
        },
        text: {
            secondary: 'rgba(0,0,0,0.38)',
        },
    },
    typography: {
        fontFamily: ['Rubik', 'sans-serif'].join(','),
        h1: {
            fontSize: '46px',
            fontWeight: 500,
        },
        h2: {
            fontSize: '30px',
            fontWeight: 500,
        },
        h3: {
            fontSize: '22px',
            fontWeight: 500,
        },
        h4: {
            fontSize: '18px',
            fontWeight: 500,
            lineHeight: '24px',
        },
        subtitle1: {
            fontSize: '16px',
            fontWeight: 500,
            letterSpacing: '0.15px',
            lineHeight: '24px',
        },
        subtitle2: {
            fontSize: '14px',
            letterSpacing: '0.1px',
            fontWeight: 500,
        },
        body1: {
            fontSize: '16px',
        },
        body2: {
            fontSize: '14px',
        },
        caption: {
            fontSize: '12px',
            letterSpacing: '0.4px',
        },
        button: {
            fontSize: '16px',
            fontWeight: 500,
            textTransform: 'initial',
        },
        // label: {
        //     fontSize: 12,
        //     lineHeight: 16,
        //     letterSpacing: 0.4,
        // },
    },
    shape: {
        borderRadius: 8,
    },
    overrides: {
        MuiButton: {
            root: {
                textTransform: 'unset',
            },
            textSizeLarge: {
                fontSize: 16,
            },
            sizeLarge: {
                fontSize: 16,
                padding: '10px 22px 9px',
                minWidth: '144px',
            },
        },
        MuiInputAdornment: {
            root: {
                color: '#666666',
            },
        },
        MuiPaper: {
            root: {
                overflow: 'hidden',
            },
            elevation0: {
                boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.12)',
                '@media (max-width: 991px)': {
                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.12)',
                },
            },
            elevation1: {
                boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.07), 0px 2px 4px rgba(0, 0, 0, 0.06), 0px 1px 6px rgba(0, 0, 0, 0.1)',
            },
            elevation2: {
                boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.07), 0px 3px 8px rgba(0, 0, 0, 0.06), 0px 1px 10px rgba(0, 0, 0, 0.1)',
            },
            elevation3: {
                boxShadow:
                    '0px 27px 34px rgba(94, 1, 198, 0.07), 0px 14px 9px rgba(94, 1, 198, 0.0402488), 0px 8px 4px rgba(0, 0, 0, 0.03)',
            },
            elevation4: {
                boxShadow: '0px 43px 58px rgba(94, 1, 198, 0.04), 0px 15px 19px rgba(0, 0, 0, 0.03), 0px 7.36424px 9px rgba(0, 0, 0, 0.01)',
            },
            elevation24: {
                boxShadow: 'none',
            },
            rounded: {
                borderRadius: '16px',
            },
        },
        MuiTableRow: {
            root: {
                '&:last-child .MuiTableCell-body': {
                    borderBottom: 'none !important',
                },
            },
        },
        MuiTableCell: {
            root: { padding: '16px 6px' },
        },
        MuiFilledInput: {
            root: {
                borderRadius: 8,
                backgroundColor: '#fff',
                '&:hover': {
                    backgroundColor: '#fff',
                },
                '&.Mui-focused': {
                    backgroundColor: '#fff',
                },
            },
        },
        // MuiFormControl: {
        //     root: {
        //         '& .MuiOutlinedInput-root': {
        //             height: 48,
        //         },
        //     },
        // },
        MuiOutlinedInput: {
            root: {
                // maxHeight: 48,
                backgroundColor: '#fff',
                '& $notchedOutline': {
                    borderColor: '#CEB5EB',
                },
                '&:hover $notchedOutline': {
                    borderColor: '#CEB5EB',
                    // Reset on touch devices, it doesn't add specificity
                    '@media (hover: none)': {
                        borderColor: 'rgba(0, 0, 0, 0.08)',
                    },
                },
            },
            input: {
                padding: '22px 14px 10px',
            },
        },
        MuiListItemIcon: {
            root: {
                minWidth: 'auto',
                marginRight: '16px',
            },
        },
        MuiSelect: {
            filled: {
                '&:focus': {
                    borderRadius: 8,
                    backgroundColor: '#fff',
                },
            },
        },
        MuiAutocomplete: {
            inputRoot: {
                '&[class*="MuiOutlinedInput-root"]': {
                    paddingTop: 5,
                    paddingBottom: 5,
                    paddingRight: '39px !important',
                },
            },
        },
        MuiFormHelperText: {
            contained: {
                marginLeft: 0,
            },
        },
        MuiInputLabel: {
            outlined: {
                transform: 'translate(14px, 18px) scale(1)',
            },
        },
        MuiPopover: {
            paper: {
                padding: '8px 16px 6px 16px',
            },
        },
    },
});
