import React from 'react';
import { Box } from '@material-ui/core';
import { observer } from 'mobx-react-lite';

import { IComplexData, IComplexesApartment, IComplexesData, IComplexesObjectType } from '../../types/ComplexTypes';

import ComplexesItemApartments from '../Complexes/ComplexesItemApartments';
import ComplexItemObjects from '../Complexes/ComplexItemObjects';

const ComplexObjects = observer(({ complex }: { complex: IComplexesData | IComplexData }) => {
    return (
        <Box display="flex" flexWrap="wrap">
            <Box display="flex" flexWrap="wrap">
                {complex.numberOfApartments.map((item: IComplexesApartment) => (
                    <ComplexesItemApartments complexId={complex.id} item={item} key={item.rooms_number} />
                ))}
            </Box>
            <Box display="flex" flexWrap="wrap">
                {complex.objectTypesNumbers.map((item: IComplexesObjectType) => (
                    <ComplexItemObjects complexId={complex.id} item={item} key={item.type} />
                ))}
            </Box>
        </Box>
    );
});

export default ComplexObjects;
