import React, { useState } from 'react';
import { Box, Grid, Typography, TextField, InputAdornment, Button, IconButton, useTheme, useMediaQuery } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import { observer, useLocalStore } from 'mobx-react-lite';
import { IProfileChangePass } from '../../types/ProfileTypes';
import { ProfileStore } from '../../stores';

type IPassword = {
    [key: string]: boolean;
};

const ProfileChangePasswordForm = observer(() => {
    const store = useLocalStore(() => new ProfileStore());
    const history = useHistory();
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('md'));

    const [showPassword, setShowPassword] = useState<IPassword>({
        currentPassword: false,
        newPassword: false,
        confirmPassword: false,
    });

    const [values, setValues] = useState<IProfileChangePass>({
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
    });

    const togglePassword = (key: string) => {
        setShowPassword({ ...showPassword, [key]: !showPassword[key] });
    };

    const handleChange = (event: any) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value,
        });
    };

    const handleClick = (e: any) => {
        e.preventDefault();

        store.changePassword(values, history);
    };

    const handleCancel = (e: any) => {
        e.preventDefault();

        history.push('/profile');
    };

    return (
        <form noValidate>
            <Box my={2}>
                <Grid container alignItems="center" spacing={isSmall ? 1 : 4}>
                    <Grid item xs={12} md={3}>
                        <Typography color="textSecondary" component="span">
                            Текущий пароль:
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <TextField
                            variant="outlined"
                            margin="none"
                            required
                            fullWidth
                            id="currentPassword"
                            name="currentPassword"
                            type={showPassword['currentPassword'] ? 'text' : 'password'}
                            placeholder="Текущий пароль"
                            InputProps={{
                                autoComplete: 'new-password',
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => togglePassword('currentPassword')}
                                        >
                                            {showPassword['currentPassword'] ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                                notched: false,
                            }}
                            onChange={handleChange}
                            value={values.currentPassword}
                            error={!!store.changePassErrors?.currentPassword}
                            helperText={store.changePassErrors?.currentPassword}
                            disabled={store.loading}
                        />
                    </Grid>
                </Grid>
            </Box>
            <Box my={2}>
                <Grid container alignItems="center" spacing={isSmall ? 1 : 4}>
                    <Grid item xs={12} md={3}>
                        <Typography color="textSecondary" component="span">
                            Новый пароль:
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <TextField
                            variant="outlined"
                            margin="none"
                            required
                            fullWidth
                            id="newPassword"
                            name="newPassword"
                            type={showPassword['newPassword'] ? 'text' : 'password'}
                            placeholder="Новый пароль"
                            InputProps={{
                                autoComplete: 'new-password',
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton aria-label="toggle password visibility" onClick={() => togglePassword('newPassword')}>
                                            {showPassword['newPassword'] ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                                notched: false,
                            }}
                            onChange={handleChange}
                            value={values.newPassword}
                            error={!!store.changePassErrors?.newPassword}
                            helperText={store.changePassErrors?.newPassword}
                            disabled={store.loading}
                        />
                    </Grid>
                </Grid>
            </Box>
            <Box my={2}>
                <Grid container alignItems="center" spacing={isSmall ? 1 : 4}>
                    <Grid item xs={12} md={3}>
                        <Typography color="textSecondary" component="span">
                            Подтвердите пароль:
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <TextField
                            variant="outlined"
                            margin="none"
                            required
                            fullWidth
                            id="confirmPassword"
                            name="confirmPassword"
                            type={showPassword['confirmPassword'] ? 'text' : 'password'}
                            placeholder="Подтвердите пароль"
                            InputProps={{
                                autoComplete: 'new-password',
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => togglePassword('confirmPassword')}
                                        >
                                            {showPassword['confirmPassword'] ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                                notched: false,
                            }}
                            onChange={handleChange}
                            value={values.confirmPassword}
                            error={!!store.changePassErrors?.confirmPassword}
                            helperText={store.changePassErrors?.confirmPassword}
                            disabled={store.loading}
                        />
                    </Grid>
                </Grid>
            </Box>

            <Box maxWidth={400} width={'100%'} mt={6} mx={{ xxs: 'auto', md: 0 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Button variant="outlined" color="primary" size="large" onClick={handleCancel} disabled={store.loading} fullWidth>
                            Отменить
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            size="large"
                            fullWidth
                            onClick={handleClick}
                            disabled={store.loading}
                        >
                            Сохранить
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </form>
    );
});

export default ProfileChangePasswordForm;
