import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Paper, Typography } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import { useStyles } from '../style';
// import DocumentPreviewModal from './DocumentPreviewModal';
import clsx from 'clsx';

const DocumentItemView = observer(({ fileUrl, children }: { fileUrl: string; children: string }) => {
    const classes = useStyles();
    // const [showPreview, setShowPreview] = useState<boolean>(false);
    // const [pdfPreview, setPdfPreview] = useState<any>(null);
    // const [imgPreview, setImgPreview] = useState<string>('');
    //
    // const closePreview = () => setShowPreview(false);

    // const openPreview = () => fileUrl && setShowPreview(true);
    //
    // useEffect(() => {
    //     fileUrl.includes('pdf') ? setPdfPreview(fileUrl) : setImgPreview(fileUrl);
    // }, [fileUrl]);

    return (
        <Box>
            {/*<DocumentPreviewModal pdfPreview={pdfPreview} imgPreview={imgPreview} showPreview={showPreview} closePreview={closePreview} />*/}
            <Paper className={classes.docItem} elevation={1}>
                <Box p={1.5}>
                    <Box position="relative">
                        {fileUrl && <CheckCircleIcon color="primary" className={classes.circleIconView} />}
                        <Box ml={1}>
                            <Typography variant="body2" className={clsx({ [classes.textUnderline]: fileUrl })}>
                                <Box
                                    component="span"
                                    // onClick={openPreview}
                                >
                                    {children}
                                </Box>
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Paper>
        </Box>
    );
});

export default DocumentItemView;
