import { observable, action } from 'mobx';
import API from '../api';
import { IStockItem, IStock, IStockNotification } from '../types/StockTypes';

export const _ViewNotificationsStoreKey = 'view_notifications';

export class StockStore {
    @observable loading = false;
    @observable stocks: IStockItem[] = [];
    @observable stock: IStock | null = null;

    @observable isShowNotification: boolean = false;
    @observable stockNotification: IStockNotification | null = null;

    @action
    getStocks = (offset: number, limit: number) => {
        this.loading = true;

        API.stock
            .getStocks(offset, limit)
            .then(response => {
                if (response?.data?.success) {
                    this.stocks = response.data.data.items.reverse();
                }
            })
            .finally(() => {
                this.loading = false;
            });
    };

    @action
    getStock = (id: number) => {
        this.loading = true;

        API.stock
            .getStock(id)
            .then(response => {
                if (response?.data?.success) {
                    this.stock = response.data;
                }
            })
            .finally(() => {
                this.loading = false;
            });
    };

    @action
    getStockNotification = () => {
        this.loading = true;

        API.stock
            .getStockNotification()
            .then(response => {
                if (response?.data?.success && response?.data?.data?.id) {
                    this.stockNotification = response.data.data;

                    if (this.stockNotification && this.stockNotification?.showPeriod === 'once') {
                        this.checkOnceNotification();
                    } else {
                        this.setIsShowNotification(true);
                    }
                }
            })
            .finally(() => {
                this.loading = false;
            });
    };

    getComplexStocks = (id: number, offset: number, limit: number) => {
        this.loading = true;

        API.stock
            .getComplexStocks(id, offset, limit)
            .then(response => {
                if (response?.data?.success) {
                    this.stocks = response.data.data.items;
                }
            })
            .finally(() => {
                this.loading = false;
            });
    };

    @action
    checkOnceNotification = () => {
        const localStorageData = localStorage.getItem(_ViewNotificationsStoreKey);

        if (localStorageData && this.stockNotification?.id) {
            const currentId = this.stockNotification.id;

            const notificationsId = JSON.parse(localStorageData) as number[];

            if (notificationsId.some(item => item === currentId)) {
                return;
            }
        }

        this.setIsShowNotification(true);
    };

    @action
    closeNotification = () => {
        this.setIsShowNotification(false);

        if (this.stockNotification?.showPeriod === 'once') {
            this.saveOnceNotification();
        }
    };

    @action
    saveOnceNotification = () => {
        if (!this.stockNotification?.id) {
            return;
        }

        let notificationsId: number[] = [];

        const localStorageData = localStorage.getItem(_ViewNotificationsStoreKey);

        if (localStorageData) {
            notificationsId = JSON.parse(localStorageData);
        }

        notificationsId.push(this.stockNotification.id);

        localStorage.setItem(_ViewNotificationsStoreKey, JSON.stringify(notificationsId));
    };

    @action
    setIsShowNotification = (value: boolean) => {
        this.isShowNotification = value;
    };

    @action
    resetStocks = () => {
        this.stocks = [];
    };
}
