import { observable, action } from 'mobx';
import API from '../api';
import { INewsItem, INews } from '../types/NewsTypes';

export class NewsStore {
    @observable loading = false;
    @observable newsList: INewsItem[] = [];
    @observable news: INews | null = null;

    @action
    getNewsList = (offset: number, limit: number) => {
        this.loading = true;

        API.news
            .getNewsList(offset, limit)
            .then(response => {
                if (response?.data?.success) {
                    this.newsList = response.data.data.items;
                }
            })
            .finally(() => {
                this.loading = false;
            });
    };

    @action
    getNews = (id: number) => {
        this.loading = true;

        API.news
            .getNews(id)
            .then(response => {
                if (response?.data?.success) {
                    this.news = response.data;
                }
            })
            .finally(() => {
                this.loading = false;
            });
    };

    @action
    getComplexNews = (id: number, offset: number, limit: number) => {
        this.loading = true;

        API.news
            .getComplexNews(id, offset, limit)
            .then(response => {
                if (response?.data?.success) {
                    this.newsList = response.data.data.items;
                }
            })
            .finally(() => {
                this.loading = false;
            });
    };

    @action
    resetNews = () => {
        this.newsList = [];
    };
}
