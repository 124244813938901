import React from 'react';
import { observer } from 'mobx-react-lite';
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
import { Box, makeStyles, Typography } from '@material-ui/core';

import createFolder from '../../img/create_folder.svg';
import { useStores } from '../../hooks/useStores';
import { useModal } from '../../hooks/useModal';

import FavoriteFolderModal from './FavoriteFolderModal';

const FavoriteCreateFolder = observer(() => {
    const { FavoriteStore: store } = useStores();
    const { isOpen, openModal, closeModal } = useModal();
    const classes = useStyles();

    return (
        <>
            <Box className={classes.folder} onClick={openModal}>
                <Box width={118} textAlign="center" mt={2.5}>
                    <CreateNewFolderIcon color="primary" fontSize="large" />
                    <Typography variant="subtitle1" color="primary">
                        {!!store.selectedObjectsIds.length
                            ? `Переместить 
                            в новую папку`
                            : 'Создать папку'}
                    </Typography>
                </Box>
            </Box>

            {!!store.selectedObjectsIds.length ? (
                <FavoriteFolderModal isOpen={isOpen} closeModal={closeModal} onSubmit={store.createAndMove} type="move" />
            ) : (
                <FavoriteFolderModal isOpen={isOpen} closeModal={closeModal} onSubmit={store.createFolder} type="create" />
            )}
        </>
    );
});

const useStyles = makeStyles(theme => ({
    folder: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        background: `url(${createFolder}) no-repeat`,
        width: 232,
        height: 144,
        cursor: 'pointer',
        marginBottom: theme.spacing(4.5),
    },
}));

export default FavoriteCreateFolder;
