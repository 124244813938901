import React, { useEffect, useState } from 'react';
import { Container, Typography, Box, makeStyles, Grid, useMediaQuery, Drawer, Fab } from '@material-ui/core';
import MyClientsForm from '../../components/MyClients/MyClientsForm';
import MyClientsMessageModal from '../../components/MyClients/MyClientsMessageModal';
import { useStores } from '../../hooks/useStores';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import MyClientsListItems from '../../components/MyClients/MyClientsListItems';
import { theme } from '../../styles/muiTheme';
import AddIcon from '@material-ui/icons/Add';

const MyClients = observer(() => {
    const { MyClientsStore: store } = useStores();
    const history = useHistory();
    const isMD = useMediaQuery(theme.breakpoints.down('md'));
    const [mobileForm, setMobileForm] = useState(false);

    const classes = useStyle();

    //Effects
    useEffect(() => {
        store.getClients(store.paginationPage, 10);
    }, [store.paginationPage]);

    useEffect(() => {
        store.setPaginationPage(0);
        store.setIsOpenMessageModal(false);
    }, []);

    //Handlers
    const handleClose = () => {
        store.setIsOpenMessageModal(false);
    };

    const handleMainPage = () => {
        history.push('/');
    };

    //Renders
    const renderForm = () => (
        <>
            {isMD ? (
                <Drawer anchor="right" open={mobileForm} onClose={() => setMobileForm(false)}>
                    <MyClientsForm onMobileClose={() => setMobileForm(false)} />
                </Drawer>
            ) : (
                <MyClientsForm />
            )}
        </>
    );

    return (
        <div className={classes.root}>
            <Container maxWidth="xl" className={classes.container}>
                <Box mb={6}>
                    <Typography variant="h2" component="h1">
                        Мои клиенты
                    </Typography>
                </Box>
                <Grid container spacing={5}>
                    <Grid item xs={12} lg={4}>
                        {renderForm()}
                    </Grid>
                    <Grid item xs={12} lg={8}>
                        <MyClientsListItems />
                    </Grid>
                </Grid>
                {isMD && (
                    <Box className={classes.addBtn}>
                        <Fab color="primary" aria-label="add" onClick={() => setMobileForm(true)}>
                            <AddIcon />
                        </Fab>
                    </Box>
                )}
                <MyClientsMessageModal
                    open={store.isOpenMessageModal}
                    onClose={handleClose}
                    onButtonClick={handleMainPage}
                    message={store.messageModal}
                    isError={store.isErrorMessageModal}
                />
            </Container>
        </div>
    );
});

const useStyle = makeStyles(theme => ({
    root: {
        width: '100%',
        background: theme.palette.surface.main,
    },
    container: {
        paddingTop: theme.spacing(6),
        paddingBottom: theme.spacing(6),
    },
    title: {
        paddingTop: theme.spacing(6),
        paddingBottom: theme.spacing(6),
    },
    addBtn: {
        position: 'fixed',
        bottom: 30,
        right: 30,
        zIndex: 20,
        [theme.breakpoints.down('xs')]: {
            bottom: 20,
            right: 20,
        },
    },
}));

export default MyClients;
