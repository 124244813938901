import React, { useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react-lite';

import { useStores } from '../../../hooks/useStores';
import { IApartmentComplexCoords, IApartmentCoords } from '../../../types/ApartmentTypes';

type Props = {
    coords: IApartmentComplexCoords[];
};

const ApartmentsMap = observer(({ coords }: Props) => {
    const { ApartmentStore: store } = useStores();
    const classes = useStyles();
    const myMap: any = useRef(null);

    const initMap = () => {
        myMap.current = new window.ymaps.Map('YMapsID', setMapOptions(), {
            minZoom: 3,
            maxZoom: 20,
            maxAnimationZoomDifference: 50,
        });

        myMap.current.controls.add('zoomControl', {
            size: 'small',
            float: 'none',
            position: {
                top: '16px',
                right: '40px',
            },
        });
    };

    const setMapOptions = () => {
        let coords: IApartmentCoords | null = null;

        if (store.filters.complex?.length) {
            coords = store?.coords.filter(({ id }) => store.filters.complex[0].id === id)[0].coords;
        }

        return {
            center: coords ? [+coords.latitude, +coords.longitude] : [55.784, 49.13],
            zoom: store.filters.complex?.length ? 17 : 12,
            controls: [],
            behaviors: ['default', 'scrollZoom'],
        };
    };

    const redraw = () => {
        myMap.current.geoObjects.removeAll();

        setPlacemarks(coords, store.filters.complex, store.filters.houseNumbers, store.apartmentsCount).forEach((placemark: any) => {
            const filtered = placemark.filter((placemark: any) => placemark);
            if (!filtered.length) return;

            const complexId = filtered[0]?.properties._data.complexId;
            const complex = coords.filter(({ id }) => id === complexId)[0];
            const clusterer = createClusterer(complex?.name, complex?.amount, filtered.length);

            clusterer.events.add('click', function (e) {
                const target = e.get('target') as any;

                if (target._clusterListeners) {
                    myMap.current.setCenter([+complex.coords.latitude, +complex.coords.longitude]);
                    myMap.current.setZoom(17, { duration: 1000, smooth: true });
                    const complexId = target.properties._data.geoObjects[0].properties._data.complexId.toString();

                    store.setFilterComplex(complexId);
                    store.getHouses(complexId);
                    store.handleFilter();
                } else {
                    store.setApartmentsCount(target.properties._data.amount);
                    if (!store.filters.complex) {
                        store.setFilterComplex(target.properties._data.complexId.toString());
                    }
                    store.setFilter('houseNumbers', [target.properties._data.houseNumber]);
                    store.handleFilter();
                    store.getApartmentCount();
                }
            });
            // @ts-ignore
            clusterer.add(filtered);

            myMap.current.geoObjects.add(clusterer);
        });

        if (store.filters.complex?.length === 1) {
            const { id } = store.filters.complex[store.filters.complex.length - 1];
            const complex: IApartmentComplexCoords = store?.coords.filter((item: IApartmentComplexCoords) => item.id === id)[0];

            if (complex?.coords) {
                myMap.current.setCenter([+complex.coords.latitude, +complex.coords.longitude]);
                myMap.current.setZoom(17, { duration: 1000, smooth: true });
            }
        } else {
            myMap.current.setZoom(12, { duration: 1000, smooth: true });
        }
    };

    useEffect(() => {
        window.ymaps.ready(initMap);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        myMap.current && redraw();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [coords, store.filters.houseNumbers, store.filters.complex, store.apartmentsCount]);

    return (
        <div className={classes.mapContainer}>
            <div id="YMapsID" className={classes.map} />
        </div>
    );
});

export default ApartmentsMap;

const useStyles = makeStyles(theme => ({
    mapContainer: {
        flex: '1 2 1279px',
        position: 'relative',
        [theme.breakpoints.down('xs')]: {
            maxHeight: 320,
        },
    },
    placemark: {
        width: 150,
        height: 40,
        background: '#fff',
    },
    map: {
        width: '100%',
        height: '100%',
        position: 'absolute',
    },
}));

const setPlacemarks = (
    coords: IApartmentComplexCoords[],
    complexFilters: [] | null,
    houseNumberFilter: [] | null,
    apartmentsCount: number,
) => {
    //TODO переписать это нормально
    if (complexFilters?.length) {
        return coords
            .filter(({ coords }) => coords)
            .filter(({ id }: any) => complexFilters?.some((complex: any) => id === complex.id))
            .map(({ id, buildings }: any) =>
                // eslint-disable-next-line array-callback-return
                buildings.map(({ title, amount, coords }: any) => {
                    if (coords) {
                        return createPlacemark(coords, title, amount, id, houseNumberFilter, apartmentsCount);
                    }
                }),
            );
    } else {
        return coords
            .filter(({ coords }) => coords)
            .map(({ id, buildings }: any) =>
                // eslint-disable-next-line array-callback-return
                buildings.map(({ title, amount, coords }: any) => {
                    if (coords) {
                        return createPlacemark(coords, title, amount, id, houseNumberFilter, apartmentsCount);
                    }
                }),
            );
    }
};

const createPlacemark = (
    { latitude, longitude }: IApartmentCoords,
    houseNumber: string,
    amount: number,
    complexId: number,
    houseNumberFilter: [] | null,
    apartmentsCount: number,
) => {
    return new window.ymaps.Placemark(
        [+latitude, +longitude],
        { complexId, houseNumber, amount },
        {
            iconLayout:
                !!houseNumberFilter?.length && houseNumberFilter?.some(item => item === houseNumber)
                    ? selectedPlacemarkLayout(houseNumber, amount)
                    : placemarkLayout(houseNumber, amount),
            iconShape: {
                type: 'Rectangle',
                // @ts-ignore
                coordinates: [
                    [0, 0],
                    [getTextSize(houseNumber, amount) + 16, 35],
                ],
            },
        },
    );
};

const getTextSize = (text: string, number: number) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    // @ts-ignore
    ctx.font = '14px Rubik';
    // @ts-ignore
    const width = ctx.measureText(text).width + ctx.measureText(number?.toString()).width;

    canvas.remove();

    return width + 32;
};

const createClusterer = (name: string, amount: number, quantity: number) => {
    return new window.ymaps.Clusterer({
        // @ts-ignore
        clusterIconLayout: clustererLayout(name, amount),
        clusterIconShape: {
            type: 'Rectangle',
            coordinates: [
                [0, 0],
                [getTextSize(name, amount), 35],
            ],
        },
        minClusterSize: quantity,
        gridSize: 128,
        clusterDisableClickZoom: true,
        hasBalloon: false,
        hasHint: false,
    });
};

const clustererLayout = (complexName: string, amount: number) =>
    //@ts-ignore
    window.ymaps.templateLayoutFactory.createClass(
        `<div class='placemark-container'><span class='placemark-amount'>${amount}</span> • <span class='placemark-complex'>${complexName}</span></div>`,
    );

const placemarkLayout = (houseNumber: string, amount: number) =>
    //@ts-ignore
    window.ymaps.templateLayoutFactory.createClass(
        `<div class='placemark-container'><span class='placemark-amount'>${amount}</span> • <span class='placemark-complex'>№ ${houseNumber}</span></div>`,
    );

const selectedPlacemarkLayout = (houseNumber: string, amount: number) =>
    //@ts-ignore
    window.ymaps.templateLayoutFactory.createClass(
        `<div class='placemark-container placemark-container_selected'><span class='placemark-amount placemark-amount_selected'>${amount} • </span><span class='placemark-complex placemark-complex_selected'>№ ${houseNumber}</span></div>`,
    );
