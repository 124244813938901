import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';

import { useStores } from '../../../hooks/useStores';

import TextFieldWithoutHelper from '../../UI/TextFieldWithoutHelper';

const ApartmentsFilterFloorFromTo = observer(({ onChange }: { onChange: any }) => {
    const { ApartmentStore: store } = useStores();

    return (
        <Box mb={{ xxs: 2, sm: 3, md: 4 }}>
            <Typography variant="subtitle2">Этаж</Typography>
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <TextFieldWithoutHelper
                        name="floorNumberFrom"
                        variant="outlined"
                        placeholder="От"
                        value={store.filters.floorNumberFrom}
                        onChange={onChange}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextFieldWithoutHelper
                        name="floorNumberTo"
                        variant="outlined"
                        placeholder="До"
                        value={store.filters.floorNumberTo}
                        onChange={onChange}
                    />
                </Grid>
            </Grid>
        </Box>
    );
});

export default ApartmentsFilterFloorFromTo;
