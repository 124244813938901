import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import { Box, makeStyles, Typography } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Link } from 'react-scroll';

import { useStores } from '../../../hooks/useStores';
import object from '../../../img/object.svg';
import participants from '../../../img/participants.svg';
import payment from '../../../img/payment.svg';

const AppDealNav = observer(() => {
    const { AppDealFormStore: store } = useStores();
    const classes = useStyles();
    const [activeObject, setActiveObject] = useState(false);
    const [activeClient, setActiveClient] = useState(false);
    const [activePayment, setActivePayment] = useState(false);

    return (
        <Box className={classes.navigation}>
            <Link
                to="object"
                spy={true}
                smooth={true}
                className="pageNavLink"
                activeClass="nav-active"
                duration={300}
                onSetActive={() => setActiveObject(true)}
                onSetInactive={() => setActiveObject(false)}
                offset={-113}
            >
                <Box className={clsx(classes.navigationItem, { [classes.navigationItemActive]: activeObject })} mb={1}>
                    {store.values.formFieldsStatus.complex && (
                        <CheckCircleIcon className={clsx(classes.progressIcon, { [classes.progressIconActive]: activeObject })} />
                    )}
                    <Box className={clsx(classes.padding, { [classes.paddingActive]: activeObject })}>
                        <Typography className={clsx({ [classes.navigationItemActive]: activeObject })} variant="caption">
                            Объект
                        </Typography>
                    </Box>
                    <img src={object} alt="объект" />
                </Box>
            </Link>

            <Link
                to="client"
                spy={true}
                smooth={true}
                duration={300}
                className="pageNavLink"
                activeClass="nav-active"
                onSetActive={() => setActiveClient(true)}
                onSetInactive={() => setActiveClient(false)}
                offset={-113}
            >
                <Box className={clsx(classes.navigationItem, { [classes.navigationItemActive]: activeClient })} mb={1}>
                    {store.values.formFieldsStatus.client && (
                        <CheckCircleIcon className={clsx(classes.progressIcon, { [classes.progressIconActive]: activeClient })} />
                    )}
                    <Box className={clsx(classes.padding, { [classes.paddingActive]: activeClient })}>
                        <Typography className={clsx({ [classes.navigationItemActive]: activeClient })} variant="caption">
                            Участники
                        </Typography>
                    </Box>
                    <img src={participants} alt="участники" />
                </Box>
            </Link>
            <Link
                to="payment"
                spy={true}
                smooth={true}
                duration={300}
                className="pageNavLink"
                activeClass="nav-active"
                onSetActive={() => setActivePayment(true)}
                onSetInactive={() => setActivePayment(false)}
                offset={-193}
            >
                <Box className={clsx(classes.navigationItem, { [classes.navigationItemActive]: activePayment })}>
                    {store.values.formFieldsStatus.payment && (
                        <CheckCircleIcon className={clsx(classes.progressIcon, { [classes.progressIconActive]: activePayment })} />
                    )}
                    <Box className={clsx(classes.padding, { [classes.paddingActive]: activePayment })}>
                        <Typography className={clsx({ [classes.navigationItemActive]: activePayment })} variant="caption">
                            Способ оплаты
                        </Typography>
                    </Box>
                    <img src={payment} alt="payment" />
                </Box>
            </Link>
        </Box>
    );
});

export default AppDealNav;

const useStyles = makeStyles(theme => ({
    navigation: {
        padding: theme.spacing(2),
        // @ts-ignore
        border: `1px solid ${theme.palette.primary.A50}`,
        boxShadow: '0px 43px 58px rgba(94, 1, 198, 0.04), 0px 15px 19px rgba(0, 0, 0, 0.03), 0px 7.36424px 9px rgba(0, 0, 0, 0.01)',
        borderRadius: 16,
        // [theme.breakpoints.down('sm')]: {
        //     display: 'none',
        // },
    },
    navigationItem: {
        position: 'relative',
        // @ts-ignore
        border: `1px solid ${theme.palette.primary.A50}`,
        borderRadius: 6,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        // @ts-ignore
        color: theme.palette.primary.A100,
        // [theme.breakpoints.down('lg')]: {
        //     display: 'block',
        // },
    },
    navigationItemActive: {
        color: theme.palette.primary.main,
        // @ts-ignore
        fontSize: '14px',
        // @ts-ignore
        fontWeight: 500,
    },
    padding: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        transition: 'padding 0.2s ease',
    },
    paddingActive: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    progressIcon: {
        width: 18,
        height: 18,
        position: 'absolute',
        top: -9,
        left: -9,
    },
    progressIconActive: {
        width: 24,
        height: 24,
        top: -12,
        left: -12,
    },
}));
